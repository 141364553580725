import "./ShiftTimingsModalSelectable.css";
import { Table, TextInput } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
const ShiftTimingsModalSelectable = ({ close }) => {
	const shifts = useSelector((state) => state?.shift?.data);
	const [search, setSearch] = useState("");

	const rows = shifts
		?.filter(
			(d) =>
				d?.startTime?.toLowerCase().includes(search.toLowerCase()) ||
				d?.endTime?.toLowerCase().includes(search.toLowerCase())
		)
		?.sort((a, b) => a.startTime.localeCompare(b.startTime))
		?.map((shift, id) => (
			<tr key={id}>
				<td>From : {shift?.startTime}</td>
				<td>To : {shift?.endTime}</td>
				<td
					className="holiday_btns"
					style={{ paddingRight: "32px", textAlign: "end" }}
				>
					<div
						className="view_button_select"
						onClick={() => close(shift)}
					>
						<span>Select</span>
					</div>
				</td>
			</tr>
		));

	return (
		<div>
			<div
				className="present_modal_header"
				style={{ paddingBottom: "10px", paddingTop: "20px" }}
			>
				<TextInput
					w={300}
					styles={() => ({
						input: {
							padding: "10px",
							borderRadius: "10px",
							border: "1px solid #9DB2B8",
							boxShadow: "0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
						},
					})}
					placeholder="Search by shift time"
					rightSection={
						<svg
							width="22"
							height="20"
							viewBox="0 0 27 28"
							fill="none"
						>
							<ellipse
								cx="10.3418"
								cy="10.5395"
								rx="8.34184"
								ry="8.65079"
								stroke="#222222"
								strokeWidth="3"
							/>
							<path
								d="M19.75 20.2969L25.3557 26.1102"
								stroke="#222222"
								strokeWidth="3"
								strokeLinecap="round"
							/>
						</svg>
					}
					onChange={(e) => {
						if (setSearch) setSearch(e?.target?.value);
					}}
				/>
			</div>
			<div className="modal_table">
				<div className="table_content">
					<Table verticalSpacing="xs" horizontalSpacing="lg">
						<tbody>{rows}</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default ShiftTimingsModalSelectable;
