import { call, put, select, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
  createHub,
  createHubFulfilled,
  createHubRejected,
  deleteHub,
  deleteHubFulfilled,
  deleteHubRejected,
  getHubs,
  getHubsFulfilled,
  getHubsRejected,
  updateHub,
  updateHubFulfilled,
  updateHubRejected,
} from "../reducers/hubReducer";
import { createQueryString, getErrorMessage } from "../../utils/functions";

function* createHubSaga(action) {
  try {
    const hub = yield select((state) => state.hub);
    const formData = action.payload?.data || hub?.formData;
    const res = yield call(api.post, "/hub", formData);
    // console.log("createHubSaga", res?.data);
    if (createHub.prototype.onSuccess) {
      createHub.prototype.onSuccess();
    }
    yield put(createHubFulfilled(res?.data));
  } catch (err) {
    if (createHub.prototype.onReject) {
      createHub.prototype.onReject();
    }
    yield put(createHubRejected(getErrorMessage(err)));
  }
}

function* getHubsSaga() {
  try {
    const query = {
      populateDrivers: true,
    };
    const res = yield call(api.get, `/hub${createQueryString(query)}`);
    // console.log("getHubsSaga", res?.data);
    if (getHubs.prototype.onSuccess) {
      getHubs.prototype.onSuccess();
    }
    yield put(getHubsFulfilled(res?.data));
  } catch (err) {
    if (getHubs.prototype.onReject) {
      getHubs.prototype.onReject();
    }
    yield put(getHubsRejected(getErrorMessage(err)));
  }
}

function* updateHubSaga(action) {
  try {
    const hub = yield select((state) => state.hub);
    const id = action.payload?.id || hub?.id;
    const data = action.payload?.data || hub?.formData;
    const res = yield call(api.patch, `/hub/${id}`, data);
    // console.log("updateHubSaga", res?.data);
    if (updateHub.prototype.onSuccess) {
      updateHub.prototype.onSuccess();
    }
    yield put(updateHubFulfilled(res?.data));
  } catch (err) {
    if (updateHub.prototype.onReject) {
      updateHub.prototype.onReject();
    }
    yield put(updateHubRejected(getErrorMessage(err)));
  }
}

function* deleteHubSaga(action) {
  try {
    const id = action.payload?.id;
    const newId = action.payload?.newId;
    const res = yield call(api.delete, `/hub/${id}/${newId}`);
    // console.log("updateHubSaga", res?.data);
    if (deleteHub.prototype.onSuccess) {
      deleteHub.prototype.onSuccess();
    }
    yield put(deleteHubFulfilled(res?.data));
  } catch (err) {
    if (deleteHub.prototype.onReject) {
      deleteHub.prototype.onReject();
    }
    yield put(deleteHubRejected(getErrorMessage(err)));
  }
}

export function* hubSaga() {
  yield takeLatest(createHub.type, createHubSaga);
  yield takeLatest(getHubs.type, getHubsSaga);
  yield takeLatest(updateHub.type, updateHubSaga);
  yield takeLatest(deleteHub.type, deleteHubSaga);
}
