import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  loading: false,
  error: null,
  token: null,
  isAuthenticated: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state) => {
      state.loading = true;
    },
    loginFulfilled: (state, action) => {
      // console.log("loginFulfilled");
      state.loading = false;
      state.token = action.payload.token;
      if (action.payload) state.data = action.payload.user;
    },
    loginRejected: (state, action) => {
      // console.log("loginRejected", action.payload.message);
      state.loading = false;
      state.error = action.payload;
    },
    getUser: (state) => {
      state.loading = true;
      state.error = null;
    },
    getUserFulfilled: (state, action) => {
      state.loading = false;
      if (action.payload) state.data = action.payload;
    },
    getUserRejected: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    updateUser: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateUserFulfilled: (state, action) => {
      state.loading = false;
      if (action.payload) state.data = action.payload;
    },
    updateUserRejected: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    setUser: (state, action) => {
      Object.assign(state, action.payload);
    },
    logout: (state, action) => {
      console.log("logout", action?.payload);
      state = initialState;
      localStorage.clear();
      // location.reload(); // try to watch state instead of doing this
    },
    verifyToken: (state) => {
      state.loading = true;
      state.error = null;
    },
    verifyTokenFulfilled: (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload;
    },
  },
});

export const {
  login,
  loginFulfilled,
  loginRejected,
  getUser,
  getUserFulfilled,
  getUserRejected,
  updateUser,
  updateUserFulfilled,
  updateUserRejected,
  setUser,
  logout,
  verifyToken,
  verifyTokenFulfilled,
} = userSlice.actions;

export default userSlice.reducer;
