import "./DriverApproval.css";
import { Divider, Modal, Stepper } from "@mantine/core";
import { useEffect, useState } from "react";
import StyledStepper from "../../../components/StyledStepper/StyledStepper";
import UploadedDocuments from "./UploadedDocuments/UploadedDocuments";
import DriverDetailsForm from "./DriverDetailsForm/DriverDetailsForm";
import AdminDetailsForm from "./AdminDetailsForm/AdminDetailsForm";
import { useNavigate, useParams } from "react-router-dom";
import PhotoUpload from "../../../components/PhotoUpload/PhotoUpload";
import ApprovalList from "../../../components/ApprovalList/ApprovalList";
import { useDisclosure } from "@mantine/hooks";
import {
	getDriverById,
	updateDriverPicture,
} from "../../../redux/reducers/driverReducer";
import { useDispatch, useSelector } from "react-redux";
import { ApprovalStatus, FileName } from "../../../utils/constants";
import { toast } from "react-toastify";

const DriverApproval = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState(0);
	const [opened, { open, close }] = useDisclosure(false);

	const driverFormData = useSelector(
		(state) => state?.driver?.fetchedDriver?.data
	);
	useEffect(() => {
		dispatch(getDriverById({ id: id }));
	}, [id]);

	const uploadProfile = (file) => {
		if (file) {
			updateDriverPicture.prototype.onSuccess = () => {
				toast.success("Image updated successfully!", {
					autoClose: 2000,
					theme: "dark",
				});
			};
			dispatch(
				updateDriverPicture({
					id: driverFormData._id,
					file,
					query: { fileName: FileName.Picture },
				})
			);
		}
	};
	if (driverFormData) {
		if (driverFormData?.approval?.status == ApprovalStatus.Approved) {
			navigate("/dashboard/");
		}
	}
	return (
		<div className="DriverApproval_box">
			<div
				style={{ cursor: "pointer" }}
				onClick={() => navigate("/dashboard/")}
			>
				<svg width="25" viewBox="0 0 31 24" fill="none">
					<path
						d="M2.90991 10.752H28.5484C28.9338 10.752 29.3033 10.8778 29.5758 11.1017C29.8482 11.3256 30.0013 11.6293 30.0013 11.946C30.0013 12.2627 29.8482 12.5665 29.5758 12.7904C29.3033 13.0143 28.9338 13.1401 28.5484 13.1401H2.90991C2.52458 13.1401 2.15504 13.0143 1.88257 12.7904C1.6101 12.5665 1.45703 12.2627 1.45703 11.946C1.45703 11.6293 1.6101 11.3256 1.88257 11.1017C2.15504 10.8778 2.52458 10.752 2.90991 10.752Z"
						fill="black"
					/>
					<path
						d="M3.50984 11.9424L15.56 21.8438C15.8328 22.068 15.986 22.3721 15.986 22.6892C15.986 23.0063 15.8328 23.3104 15.56 23.5346C15.2872 23.7588 14.9172 23.8848 14.5313 23.8848C14.1455 23.8848 13.7755 23.7588 13.5027 23.5346L0.426836 12.7878C0.291535 12.6769 0.184189 12.5451 0.110945 12.4C0.0377017 12.255 0 12.0995 0 11.9424C0 11.7853 0.0377017 11.6298 0.110945 11.4847C0.184189 11.3397 0.291535 11.2079 0.426836 11.097L13.5027 0.350182C13.7755 0.125964 14.1455 0 14.5313 0C14.9172 0 15.2872 0.125964 15.56 0.350182C15.8328 0.5744 15.986 0.878505 15.986 1.1956C15.986 1.51269 15.8328 1.81679 15.56 2.04101L3.50984 11.9424Z"
						fill="black"
					/>
				</svg>
			</div>

			<div className="DriverApproval_box_header">
				<div
					style={{
						position: "relative",
						width: "100%",
						paddingLeft: "25px",
					}}
				>
					<h2>
						{activeTab == 0
							? "Uploaded Documents"
							: activeTab == 1
							? "Driver Details"
							: activeTab == 2
							? "Admin Details"
							: activeTab == 3
							? "Driver Approved"
							: null}
					</h2>
					{activeTab !== 3 && (
						<div className="diverprofile_image_box">
							<PhotoUpload
								onImageUpload={uploadProfile}
								picture={driverFormData?.picture}
							/>
							<p>{driverFormData?.name}</p>
						</div>
					)}
					<StyledStepper
						active={activeTab}
						onStepClick={setActiveTab}
					>
						<Stepper.Step description="Step 1" icon={" "}>
							<UploadedDocuments setActiveTab={setActiveTab} />
						</Stepper.Step>
						<Stepper.Step description="Step 2" icon={" "}>
							<DriverDetailsForm setActiveTab={setActiveTab} />
						</Stepper.Step>
						<Stepper.Step description="Step 3" icon={" "}>
							<AdminDetailsForm setActiveTab={setActiveTab} />
						</Stepper.Step>
						<Stepper.Completed>
							<div className="stepper_completed tab_contents">
								<div>
									<svg
										width="120"
										viewBox="0 0 152 152"
										fill="none"
									>
										<circle
											cx="76"
											cy="76"
											r="73"
											fill="white"
											stroke="url(#paint0_linear_393_3900)"
											strokeWidth="6"
										/>
										<path
											d="M71.4933 47.6957L76.8418 52.5094C77.2555 52.8817 77.8937 52.8446 78.2615 52.4267L91.0137 37.9355"
											stroke="url(#paint1_linear_393_3900)"
											strokeWidth="6"
											strokeLinecap="round"
										/>
										<path
											d="M92.843 50.7457C92.843 54.1871 91.765 57.542 89.7605 60.3393C87.756 63.1366 84.9256 65.2357 81.6669 66.3419C78.4081 67.4481 74.8848 67.5058 71.5916 66.5068C68.2984 65.5078 65.4008 63.5024 63.3059 60.7722C61.2109 58.042 60.0238 54.7241 59.9112 51.2846C59.7986 47.8451 60.7662 44.4567 62.6781 41.5953C64.59 38.7339 67.3502 36.5433 70.571 35.331C73.7918 34.1188 77.3114 33.9459 80.6355 34.8366"
											stroke="url(#paint2_linear_393_3900)"
											strokeWidth="4"
											strokeLinecap="round"
										/>
										<path
											d="M42.7461 105.82C42.7461 89.2518 56.1776 75.8203 72.7461 75.8203H79.9999C96.5684 75.8203 110 89.2518 110 105.82V113.996C110 115.653 108.657 116.996 107 116.996H45.7461C44.0892 116.996 42.7461 115.653 42.7461 113.996V105.82Z"
											fill="url(#paint3_linear_393_3900)"
										/>
										<defs>
											<linearGradient
												id="paint0_linear_393_3900"
												x1="70.0225"
												y1="3.8254e-08"
												x2="152.298"
												y2="73.185"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#03AB70" />
												<stop
													offset="1"
													stopColor="#029AC9"
												/>
											</linearGradient>
											<linearGradient
												id="paint1_linear_393_3900"
												x1="91.0137"
												y1="44.961"
												x2="84.0846"
												y2="54.9319"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#03AB70" />
												<stop
													offset="1"
													stopColor="#029AC9"
												/>
											</linearGradient>
											<linearGradient
												id="paint2_linear_393_3900"
												x1="75.0772"
												y1="34.2754"
												x2="92.9075"
												y2="50.1356"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#03AB70" />
												<stop
													offset="1"
													stopColor="#029AC9"
												/>
											</linearGradient>
											<linearGradient
												id="paint3_linear_393_3900"
												x1="73.7282"
												y1="75.8203"
												x2="94.6895"
												y2="106.274"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#03AB70" />
												<stop
													offset="1"
													stopColor="#029AC9"
												/>
											</linearGradient>
										</defs>
									</svg>
								</div>
								<h5>Driver Added</h5>
								<button className="black_button" onClick={open}>
									Pending List
								</button>

								<p>
									Navigate to the driver approval list by
									clicking the pending list
								</p>
								<Modal
									opened={opened}
									onClose={close}
									centered
									withCloseButton={false}
									size="xl"
									padding={0}
									radius={10}
									overlayProps={{
										blur: 5,
										color: "#fff",
										backgroundopacity: 1,
									}}
								>
									<ApprovalList close={close} />
								</Modal>
							</div>
						</Stepper.Completed>
					</StyledStepper>
				</div>
			</div>
		</div>
	);
};

export default DriverApproval;
