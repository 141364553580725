import { createSlice } from "@reduxjs/toolkit";
import { isEmpty, isObject } from "../../utils/functions";

const initialState = {
  data: [],
  loading: false,
  error: null,
  currentRecruiter: {
    data: {},
    loading: false,
    error: null,
  },
};

const recruiterReducer = createSlice({
  name: "recruiter",
  initialState,
  reducers: {
    createRecruiter: (state) => {
      state.loading = true;
      state.error = null;
    },
    createRecruiterFulfilled: (state) => {
      state.loading = false;
    },
    createRecruiterRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getRecruiters: (state) => {
      state.loading = true;
      state.error = null;
    },
    getRecruitersFulfilled: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) state.data = action.payload;
    },
    getRecruitersRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateRecruiter: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateRecruiterFulfilled: (state) => {
      state.loading = false;
    },
    updateRecruiterRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    getRecruiterById: (state) => {
      state.currentRecruiter.loading = true;
      state.currentRecruiter.error = null;
    },
    getRecruiterByIdFulfilled: (state, action) => {
      state.currentRecruiter.loading = false;
      if (isObject(action.payload) && !isEmpty(action.payload))
        state.currentRecruiter.data = action.payload;
      state.currentRecruiter.error = null;
    },
    getRecruiterByIdRejected: (state, action) => {
      state.currentRecruiter.loading = false;
      state.currentRecruiter.error = action.payload;
    },

    updateRecruiterSalary: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateRecruiterSalaryFulfilled: (state) => {
      state.loading = false;
    },
    updateRecruiterSalaryRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteRecruiter: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteRecruiterFulfilled: (state) => {
      state.loading = false;
    },
    deleteRecruiterRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createRecruiter,
  createRecruiterFulfilled,
  createRecruiterRejected,
  getRecruiters,
  getRecruitersFulfilled,
  getRecruitersRejected,
  updateRecruiter,
  updateRecruiterFulfilled,
  updateRecruiterRejected,
  updateRecruiterSalary,
  updateRecruiterSalaryFulfilled,
  updateRecruiterSalaryRejected,
  deleteRecruiter,
  deleteRecruiterFulfilled,
  deleteRecruiterRejected,
} = recruiterReducer.actions;

export default recruiterReducer.reducer;


