import { Flex, Menu } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import "./SelectWithSearch.css";
import { useSelector } from "react-redux";

const SelectWithSearch = ({
	data,
	value,
	placeholder,
	searchPlaceholder,
	searchEnterable,
	rightIcon,
	getValue,
	searchOnEnter,
	onRightIconClick,
}) => {
	const [selectedItem, setSelectedItem] = useState("");
	const [dropdownData, setDropdownData] = useState([]);
	const [searchText, setSearchText] = useState("");
	const hasRun = useRef(false);

	useEffect(() => {
		if (data?.length) {
			setDropdownData(data);
		}
	}, [data]);

	const moveToSelected = (selectedItem) => {
		setSelectedItem(selectedItem.name);
		getValue(selectedItem);
	};
	const filteredData = dropdownData.filter((item) =>
		item.name.toLowerCase().includes(searchText.toLowerCase())
	);

	useEffect(() => {
		if (!hasRun.current && value) {
			value.forEach((val) => {
				const selected = dropdownData.find(
					(item) => item.id === val._id
				);
				if (selected) {
					setSelectedItem(selected);
					hasRun.current = true;
				}
			});
		}
	}, [dropdownData, value]);

	return (
		<Flex>
			<Menu shadow="md" width={200} height={"auto"}>
				<Menu.Target>
					<div className="select_with_search_dropdown_div">
						{!selectedItem && (
							<div className="placeholder">{placeholder}</div>
						)}

						{selectedItem && (
							<div className="selected_item">{selectedItem}</div>
						)}

						<div className="icon">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="10"
								viewBox="0 0 17 10"
								fill="#658A92"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M0.346081 2.05202L7.92299 9.75653C8.24194 10.0812 8.75723 10.0812 9.07701 9.75653L16.6539 2.05202C17.1154 1.58339 17.1154 0.820934 16.6539 0.351473C16.1925 -0.117157 15.4436 -0.117157 14.9821 0.351473L8.49959 6.94225L2.0187 0.351473C1.55643 -0.117157 0.807522 -0.117157 0.346081 0.351473C-0.11536 0.820934 -0.11536 1.58339 0.346081 2.05202Z"
								/>
							</svg>
						</div>
					</div>
				</Menu.Target>
				<Menu.Dropdown
					style={{
						height: "200px",
						overflowY: "scroll",
						display: filteredData.length > 0 ? "block" : "grid",
						background: "#1A414B",
						border: "0.5px solid #9DB2B8",
						borderRadius: "5px",
						padding: "0",
						overflow: "hidden",
					}}
				>
					<Menu.Label
						pos={"static"}
						top={0}
						p={"10px"}
						m={0}
						display={"flex"}
						style={{
							borderBottom: "0.5px solid #9DB2B8",
						}}
					>
						<input
							className="select_with_search_dropdown_input"
							type="text"
							placeholder={searchPlaceholder}
							value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									searchOnEnter(e.target.value);
									setSelectedItem(e.target.value);
								}
							}}
						/>
						{searchEnterable && (
							<p className="enter_on_click_search">
								Press Enter to save
							</p>
						)}
					</Menu.Label>

					<div className="select_with_search_custom_menu_item_div">
						{filteredData.map((item, index) => (
							<Menu.Item
								className="select_with_search_custom_menu_item"
								key={index}
								p={0}
							>
								<Flex
									justify={"space-between"}
									align={"center"}
								>
									<div
										className="select_with_search_dropdown_menu"
										onClick={() => moveToSelected(item)}
									>
										<span>{item.name}</span>
										<div
											onClick={() => {
												onRightIconClick(item);
											}}
										>
											{rightIcon}
										</div>
									</div>
								</Flex>
							</Menu.Item>
						))}
					</div>
				</Menu.Dropdown>
			</Menu>
		</Flex>
	);
};

export default SelectWithSearch;
