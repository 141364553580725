import { Input, Modal, Select, TextInput, Tooltip } from "@mantine/core";
import "./AdminDetailsForm.css";
import SingleSelectDropdown from "../../../../components/SingleSelectDropdown/SingleSelectDropdown";
import MultiSelectDropdown from "../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { checkURLType, onChangeFormValue } from "../../../../utils/functions";
import {
	checkDriverStatus,
	updateDriver,
} from "../../../../redux/reducers/driverReducer";
import { useParams } from "react-router-dom";
import ViewDocumentModal from "../../../../components/ViewDocumentModal/ViewDocumentModal";

import ShiftSelect from "../../../../components/ShiftSelect/ShiftSelect";
import HubSelect from "../../../../components/HubSelect/HubSelect";
import RecruiterSelect from "../../../../components/RecruiterSelect/RecruiterSelect";
import VehicleSelect from "../../../../components/VehicleSelect/VehicleSelect";
import SupervisorSelect from "../../../../components/SupervisorSelect/SupervisorSelect";

const AdminDetailsForm = ({ setActiveTab }) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [disbale, setDisable] = useState(true);
	const [formValue, setFormValue] = useState({});
	const [supervisorId, setSupervisorId] = useState("");
	const [salary, setSalary] = useState("");
	const [number, setNumber] = useState("");
	const driverFormData = useSelector(
		(state) => state?.driver?.fetchedDriver?.data
	);

	const [opened, setOpened] = useState(false);
	const [documentImage, setDocumentImage] = useState(null);
	const [documentName, setDocumentName] = useState(null);
	const [currentVehicle, setCurrentVehicle] = useState(null);

	const supervisors = useSelector((state) => state.supervisor?.data);
	const hubs = useSelector((state) => state.hub?.data);
	const shifts = useSelector((state) => state.shift?.data);
	const vehicles = useSelector((state) => state.vehicle?.data);
	const vehicleInfo = vehicles?.find(
		(veh) => veh?._id == currentVehicle?._id
	);
	const selectHubData = [];
	hubs?.forEach((hub) =>
		selectHubData.push({ value: hub?._id, label: hub?.name })
	);

	// useEffect(() => {
	// 	console.log(formValue);
	// }, [formValue]);
	const selectShiftData = [];
	shifts?.forEach((shift) =>
		selectShiftData.push({
			value: shift?._id,
			label: `${shift.startTime} : ${shift.endTime}`,
		})
	);

	const selectVehicleData = [];
	vehicles.forEach((vehicle) =>
		selectVehicleData.push({
			value: vehicle?._id,
			label: vehicle?.vehicle_no,
		})
	);

	// useEffect(() => {
	// 	setFormValue(driverFormData);
	// }, [driverFormData]);
	useEffect(() => {
		const supervisorNumber = supervisors.find(
			(item) => item._id === supervisorId
		);
		setNumber(supervisorNumber?.phone);
	}, [supervisorId]);
	useEffect(() => {
		console.log(formValue);
		if (
			formValue?.shift?.length &&
			formValue?.hub?.length &&
			formValue?.clients?.length &&
			formValue?.supervisor?.length &&
			formValue?.recruiter?.length &&
			formValue?.vehicle?.length &&
			formValue?.salary?.length
		) {
			setDisable(false);
		} else {
			setDisable(true);
		}
	}, [formValue]);

	const OnClickNext = () => {
		updateDriver.prototype.onSuccess = () => {
			dispatch(checkDriverStatus({ id: id }));
		};
		checkDriverStatus.prototype.onSuccess = () => {
			setActiveTab(3);
		};

		if (
			formValue.shift &&
			formValue.hub &&
			formValue.salary &&
			formValue.clients &&
			formValue.supervisor &&
			formValue.recruiter &&
			formValue.vehicle
		) {
			dispatch(updateDriver({ id: id, data: formValue }));
		} else {
			alert("Please fill in all the details");
		}
	};
	const open = (image, imageName) => {
		setDocumentImage(image);
		setDocumentName(imageName);
		setOpened(true);
	};

	const close = () => {
		setOpened(false);
	};

	return (
		<div className="tab_contents">
			<div
				className="AdminDetailsForm_box"
				style={{ display: "block", width: "100%" }}
			>
				<table>
					{/* Shift dropdown */}
					<tr>
						<td>Shift</td>
						<td>
							<div>
								<ShiftSelect
									getValue={(value) => {
										onChangeFormValue(
											"shift",
											value,
											setFormValue
										);
									}}
									value={formValue?.shift}
								/>
							</div>
						</td>
					</tr>
					<tr class="spacer"></tr>
					{/* Hub dropdown */}
					<tr>
						<td>Assign Hub</td>
						<td>
							<div>
								<HubSelect
									getValue={(value) => {
										onChangeFormValue(
											"hub",
											value,
											setFormValue
										);
									}}
									value={formValue?.hub}
								/>
							</div>
						</td>
					</tr>
					<tr class="spacer"></tr>
					{/* Supervisor dropdown */}
					<tr>
						<td>Assign Supervisor</td>
						<td>
							<div className="flex">
								<SupervisorSelect
									getValue={(value) => {
										value
											? setSupervisorId(value)
											: setNumber("");
										onChangeFormValue(
											"supervisor",
											value,
											setFormValue
										);
									}}
									value={formValue?.supervisor}
									hub={formValue?.hub}
								/>
								{supervisors?.find(
									(s) => s._id === formValue?.supervisor
								) ? (
									<Input
										radius="md"
										w={220}
										size="sm"
										value={number}
										readOnly
									/>
								) : (
									""
								)}
								{/* {!formValue?.hub?.length ? (
									<p className="choose_supervisor">
										Choose hub to assign supervisor
									</p>
								) : (
									<></>
								)}
								{formValue?.hub?.length ? (
									<SingleSelectDropdown
										type={"supervisor"}
										hub={formValue.hub}
										getValue={(value) => {
											value == ""
												? setNumber("")
												: setSupervisorId(value);
											onChangeFormValue(
												"supervisor",
												value,
												setFormValue
											);
										}}
									/>
								) : (
									<></>
								)} */}
							</div>
						</td>
					</tr>
					<tr class="spacer"></tr>
					{/* Recruiter dropdown */}
					<tr>
						<td>Assign Recruiter</td>
						<td>
							<div>
								{/* <SingleSelectDropdown
									type={"recruiter"}
									W
									getValue={(value) => {
										onChangeFormValue(
											"recruiter",
											value,
											setFormValue
										);
									}}
								/> */}
								<RecruiterSelect
									getValue={(value) => {
										onChangeFormValue(
											"recruiter",
											value,
											setFormValue
										);
									}}
									value={formValue?.recruiter}
								/>
							</div>
						</td>
					</tr>
					<tr class="spacer"></tr>
					{/* Clients dropdown */}
					<tr>
						<td>Add Client(s)</td>
						<td>
							<div>
								<MultiSelectDropdown
									getValue={(value) => {
										setFormValue({
											...formValue,
											clients: value,
										});
									}}
								/>
							</div>
						</td>
					</tr>
					<tr class="spacer"></tr>
					{/* assign vehicle dropdown */}
					<tr>
						<td>Assign Vehicle</td>
						<td>
							<div className="flex">
								<VehicleSelect
									getValue={(value) => {
										setFormValue({
											...formValue,
											vehicle: value?._id,
										});
										setCurrentVehicle(value?._id);
									}}
									value={formValue?.vehicleInfo}
								/>
								{currentVehicle && (
									<div className="flex m-auto">
										<div className="flex mr-40">
											<span className="mr-8">RC</span>
											<button
												className="black_button oval_button"
												onClick={() =>
													checkURLType(
														vehicleInfo?.rc_image
													) === "image"
														? open(
																vehicleInfo?.rc_image,
																"RC"
														  )
														: window.open(
																vehicleInfo?.rc_image,
																"_blank"
														  )
												}
											>
												View
											</button>
										</div>
										<div className="flex">
											<span className="mr-8">
												Insurance
											</span>
											<button
												className="black_button oval_button"
												onClick={() =>
													checkURLType(
														vehicleInfo?.insurance_file
													) === "image"
														? open(
																vehicleInfo?.insurance_file,
																"Insurance"
														  )
														: window.open(
																vehicleInfo?.insurance_file,
																"_blank"
														  )
												}
											>
												View
											</button>
										</div>
										<Modal
											opened={opened}
											onClose={close}
											centered
											withCloseButton={false}
											size="lg"
											padding={0}
											radius={10}
											overlayProps={{
												blur: 5,
												color: "#fff",
												backgroundopacity: 1,
											}}
										>
											<ViewDocumentModal
												close={close}
												image={documentImage}
												documentName={documentName}
											/>
										</Modal>
									</div>
								)}
								{/* <SingleSelectDropdown
									type={"vehicle"}
									getValue={(value) => {
										console.log(value);
										setFormValue({
											...formValue,
											vehicle: value,
										});
										setCurrentVehicle(value);
									}}
								/>
								{currentVehicle && (
									<div className="flex m-auto">
										<div className="flex mr-40">
											<span className="mr-8">RC</span>
											<button
												className="black_button oval_button"
												onClick={() =>
													checkURLType(
														vehicleInfo?.rc_image
													) === "image"
														? open(
																vehicleInfo?.rc_image,
																"RC"
														  )
														: window.open(
																vehicleInfo?.rc_image,
																"_blank"
														  )
												}
											>
												View
											</button>
										</div>
										<div className="flex">
											<span className="mr-8">
												Insurance
											</span>
											<button
												className="black_button oval_button"
												onClick={() =>
													checkURLType(
														vehicleInfo?.insurance_file
													) === "image"
														? open(
																vehicleInfo?.insurance_file,
																"Insurance"
														  )
														: window.open(
																vehicleInfo?.insurance_file,
																"_blank"
														  )
												}
											>
												View
											</button>
										</div>
										<Modal
											opened={opened}
											onClose={close}
											centered
											withCloseButton={false}
											size="sm"
											padding={0}
											radius={10}
											overlayProps={{
												blur: 5,
												color: "#fff",
												backgroundopacity: 1,
											}}
										>
											<ViewDocumentModal
												close={close}
												image={documentImage}
												documentName={documentName}
											/>
										</Modal>
									</div>
								)} */}
							</div>
						</td>
					</tr>
					<tr class="spacer"></tr>
					{/* Salary Input */}
					<tr>
						<td>Salary Details</td>
						<td>
							<div className="flex">
								<TextInput
									onChange={(e) => {
										if (
											/^\d*\.?\d*$/.test(
												e.target.value
											) ||
											e.target.value === ""
										) {
											setSalary(e.target.value);
											onChangeFormValue(
												"salary",
												e.target.value,
												setFormValue
											);
										}
									}}
									value={salary}
									maw={200}
									placeholder="Daily Wage"
									radius="md"
									size="xs"
									maxLength={4}
								/>
								<span
									className="daily_text"
									style={{ alignSelf: "center" }}
								>
									Daily
								</span>
							</div>
						</td>
					</tr>
				</table>

				{/* DriverDetailsForm_row */}
			</div>
			<div className="stepper_btn_grp2">
				<button className="prev_btn" onClick={() => setActiveTab(1)}>
					Previous
				</button>
				<Tooltip
					withArrow
					disabled={!disbale}
					// multiline={true}
					label="Fill in all the details"
				>
					<button
						disabled={disbale}
						className="next_button"
						onClick={OnClickNext}
					>
						Next
					</button>
				</Tooltip>
			</div>
		</div>
	);
};

export default AdminDetailsForm;
