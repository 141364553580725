import { HoverCard, Modal, Select, Table, Text } from "@mantine/core";
import "./TableBox.css";
import PresentModal from "../PresentModal/PresentModal";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import StatusConfirmationModal from "../StatusConfirmationModal/StatusConfirmationModal";
import ZeroScreens from "../ZeroScreens/ZeroScreens";
import Loading from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { AttendanceStatus } from "../../utils/constants";
import {
	getFormattedDate,
	getFormattedTime,
	isToday,
} from "../../utils/functions";
import moment from "moment-timezone";
import { updateAttendanceByDriverId } from "../../redux/reducers/attendanceReducer";
import { useNavigate } from "react-router-dom";

const TableBox = ({ state, city, hubId, shiftId, searchQuery, status }) => {
	// const [lateModal, setLateModal] = useState(false);
	const [statusModals, setStatusModals] = useState({});
	const [drivers, setDrivers] = useState([]);

	// redux
	const driversSel = useSelector((state) => state.driver?.drivers?.data);
	const loading = useSelector((state) => state.driver?.drivers?.loading);

	const dispatch = useDispatch();

	// For opening modal
	const openStatusModal = (driverId) => {
		setStatusModals({ ...statusModals, [driverId]: true });
	};

	// For closing modal
	const closeStatusModal = (driverId) => {
		setStatusModals({ ...statusModals, [driverId]: false });
	};

	function markPresent(driver, setUnmark = false) {
		dispatch(
			updateAttendanceByDriverId({
				id: setUnmark ? null : driver.attendance?._id,
				data: {
					user: driver?._id,
					checkIn: getFormattedTime(),
					date: getFormattedDate(moment()),
					hub: driver?.hub?._id,
					status: "present",
					mode: "online",
					shift: driver?.shift?._id,
				},
			})
		);
		return true;
	}

	useEffect(() => {
		setDrivers(driversSel);
	}, [driversSel]);

	const navigate = useNavigate();

	const sortedDrivers = [...drivers].sort((a, b) => {
		if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return -1;
		}
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return 1;
		}
		return 0;
	});

	const rows = sortedDrivers
		?.map((driver, id) => {
			if (state && state != "all" && driver?.hub?.state !== state) {
				return null;
			}
			if (
				state &&
				driver?.hub?.state === state &&
				city &&
				city != "all" &&
				driver?.hub?.city !== city
			) {
				return null;
			}
			if (hubId && hubId != "all" && driver?.hub?._id !== hubId) {
				return null;
			}
			if (shiftId && shiftId != "all" && driver?.shift?._id !== shiftId) {
				return null;
			}
			if (searchQuery?.length) {
				if (
					!driver.name
						?.toString()
						.toLowerCase()
						.includes(searchQuery) &&
					!driver.username
						?.toString()
						.toLowerCase()
						.includes(searchQuery)
				) {
					return null;
				}
			}
			if (status) {
				const todayDate = moment().format("YYYY-MM-DD");
				const shiftStartDateTime = driver?.shift?.startTime
					? moment(`${todayDate} ${driver.shift.startTime}`)
					: null;

				if (status === "idle") {
					const afterShiftStart =
						shiftStartDateTime &&
						moment().isAfter(shiftStartDateTime);
					const noAttendanceStatus =
						!driver?.attendance?.status?.length;

					if (
						isToday(driver.attendance?.date || null) ||
						(afterShiftStart && !noAttendanceStatus)
					) {
						return null;
					}
				} else if (status === "unmarked") {
					const afterShiftStart =
						shiftStartDateTime &&
						moment().isAfter(shiftStartDateTime);

					if (
						isToday(driver.attendance?.date || null) ||
						!afterShiftStart
					) {
						return null;
					}
				} else {
					if (
						!isToday(driver.attendance?.date || null) ||
						driver?.attendance?.status !== status
					) {
						return null;
					}
				}
			}
			const getStatus = () => {
				const isTodayAttendance = isToday(
					driver.attendance?.date || null
				);
				const attendanceStatus = driver?.attendance?.status;
				const shiftStartTime = driver?.shift?.startTime;
				const currentTime = moment();
				const shiftTimeInKolkata = moment(
					`${moment().format("YYYY-MM-DD")} ${shiftStartTime}`
				).tz("Asia/Kolkata");

				let status;

				if (
					isTodayAttendance &&
					attendanceStatus === AttendanceStatus.Present
				) {
					status = AttendanceStatus.Present;
				} else if (
					isTodayAttendance &&
					attendanceStatus === AttendanceStatus.Absent
				) {
					status = AttendanceStatus.Absent;
				} else if (
					!isTodayAttendance &&
					shiftStartTime &&
					currentTime.isAfter(shiftTimeInKolkata)
				) {
					status = AttendanceStatus.Unmarked;
				} else if (
					(!isTodayAttendance &&
						shiftStartTime &&
						!currentTime.isAfter(shiftTimeInKolkata)) ||
					!attendanceStatus?.length
				) {
					status = AttendanceStatus.Idle;
				} else if (
					isTodayAttendance &&
					attendanceStatus === AttendanceStatus.Late
				) {
					status = AttendanceStatus.Late;
				} else {
					status = null;
				}

				return status;
			};
			const getStatusButton = () => {
				return getStatus() === AttendanceStatus.Present ? (
					<>
						<HoverCard
							shadow="md"
							arrowPosition={"center"}
							withArrow
							position="bottom-end"
						>
							<HoverCard.Target>
								<div>
									<button
										onClick={() => {
											if (
												moment()
													.startOf("minutes")
													.isSameOrBefore(
														moment(
															`${moment().format(
																"YYYY-MM-DD"
															)} ${
																driver.shift
																	.endTime
															}`
														).tz("Asia/Kolkata")
													)
											)
												openStatusModal(driver._id);
										}}
										className={
											driver?.shift?.startTime &&
											moment()
												.startOf("minutes")
												.isSameOrBefore(
													moment(
														`${moment().format(
															"YYYY-MM-DD"
														)} ${
															driver.shift.endTime
														}`
													).tz("Asia/Kolkata")
												)
												? "present_button"
												: "present_button disabled"
										}
									>
										Present
									</button>
								</div>
							</HoverCard.Target>
							{!moment()
								.startOf("minutes")
								.isSameOrBefore(
									moment(
										`${moment().format("YYYY-MM-DD")} ${
											driver.shift.endTime
										}`
									).tz("Asia/Kolkata")
								) && (
								<HoverCard.Dropdown maw={200} bg={"#0E2227"}>
									<Text size="sm" c={"#fff"} fz={12}>
										Shift is over.
									</Text>
								</HoverCard.Dropdown>
							)}
						</HoverCard>

						<Modal
							opened={statusModals[driver._id]}
							onClose={() => closeStatusModal(driver._id)}
							centered
							withCloseButton={false}
							size={
								!driver.attendance?.picture?.length
									? "sm"
									: "lg"
							}
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<PresentModal
								driver={driver}
								close={() => closeStatusModal(driver._id)}
								value={driver?.attendance?.status}
							/>
						</Modal>
					</>
				) : isToday(driver.attendance?.date || null) &&
				  getStatus() === AttendanceStatus.Absent ? (
					<div className="absent_button">
						<HoverCard
							shadow="md"
							arrowPosition={"center"}
							withArrow
							position="bottom-end"
						>
							<HoverCard.Target>
								<div>
									<Select
										size="xs"
										value="absent"
										onChange={(e) => {
											if (
												moment()
													.startOf("minutes")
													.isSameOrBefore(
														moment(
															`${moment().format(
																"YYYY-MM-DD"
															)} ${
																driver.shift
																	.endTime
															}`
														).tz("Asia/Kolkata")
													)
											)
												if (
													driver?.attendance
														?.status !== e
												) {
													openStatusModal(driver._id);
												}
										}}
										defaultValue="absent"
										styles={{
											rightSection: {
												pointerEvents: "none",
											},
											input: {
												borderRadius: "10px",
												fontSize: "12px",
											},
										}}
										rightSection={
											<svg
												width="8"
												height="8"
												viewBox="0 0 13 8"
												fill="none"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
													fill={"white"}
												/>
											</svg>
										}
										className={
											moment()
												.startOf("minutes")
												.isSameOrBefore(
													moment(
														`${moment().format(
															"YYYY-MM-DD"
														)} ${
															driver.shift.endTime
														}`
													).tz("Asia/Kolkata")
												)
												? ""
												: "disabled"
										}
										placeholder="Absent"
										data={[
											// { value: "Late", label: "Late" },
											...(moment()
												.startOf("minutes")
												.isSameOrBefore(
													moment(
														`${moment().format(
															"YYYY-MM-DD"
														)} ${
															driver.shift.endTime
														}`
													).tz("Asia/Kolkata")
												)
												? [
														{
															value: "present",
															label: "Present",
														},
														{
															value: "absent",
															label: "Absent",
														},
												  ]
												: []),
										]}
									/>
								</div>
							</HoverCard.Target>
							{!moment()
								.startOf("minutes")
								.isSameOrBefore(
									moment(
										`${moment().format("YYYY-MM-DD")} ${
											driver.shift.endTime
										}`
									).tz("Asia/Kolkata")
								) && (
								<HoverCard.Dropdown maw={200} bg={"#0E2227"}>
									<Text size="sm" c={"#fff"} fz={12}>
										Shift is over.
									</Text>
								</HoverCard.Dropdown>
							)}
						</HoverCard>
						<Modal
							opened={statusModals[driver._id]}
							onClose={() => closeStatusModal(driver._id)}
							centered
							withCloseButton={false}
							size="sm"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<StatusConfirmationModal
								driver={driver}
								setStatusModal={() =>
									closeStatusModal(driver._id)
								}
								onSuccess={() => markPresent(driver)}
							/>
						</Modal>
					</div>
				) : getStatus() == AttendanceStatus.Unmarked ? (
					<>
						<HoverCard
							shadow="md"
							arrowPosition={"center"}
							withArrow
							position="bottom-end"
						>
							<HoverCard.Target>
								<button
									onClick={() => {
										if (
											moment()
												.startOf("minutes")
												.isSameOrBefore(
													moment(
														`${moment().format(
															"YYYY-MM-DD"
														)} ${
															driver.shift.endTime
														}`
													).tz("Asia/Kolkata")
												)
										)
											return openStatusModal(driver._id);
									}}
									className="unmarked_button"
								>
									Unmarked
								</button>
							</HoverCard.Target>
							<HoverCard.Dropdown maw={200} bg={"#0E2227"}>
								{moment()
									.startOf("minutes")
									.isSameOrBefore(
										moment(
											`${moment().format("YYYY-MM-DD")} ${
												driver.shift.endTime
											}`
										).tz("Asia/Kolkata")
									) ? (
									<Text size="sm" c={"#fff"} fz={12}>
										Change from Unmarked to Present or
										Absent
									</Text>
								) : (
									<Text size="sm" c={"#fff"} fz={12}>
										Shift is over.
									</Text>
								)}
							</HoverCard.Dropdown>
						</HoverCard>
						<Modal
							opened={statusModals[driver._id]}
							onClose={() => closeStatusModal(driver._id)}
							centered
							withCloseButton={false}
							size="sm"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<PresentModal
								close={() => closeStatusModal(driver._id)}
								value={"unmarked"}
								driver={driver}
							/>
						</Modal>
					</>
				) : getStatus() == AttendanceStatus.Idle ? (
					<HoverCard
						shadow="md"
						arrowPosition={"center"}
						withArrow
						position="bottom-end"
					>
						<HoverCard.Target>
							<button className="idle_button">Idle</button>
						</HoverCard.Target>
						<HoverCard.Dropdown maw={200} bg={"#0E2227"}>
							<Text size="sm" c={"#fff"} fz={12}>
								Change Shift timings to change driver status
							</Text>
						</HoverCard.Dropdown>
					</HoverCard>
				) : getStatus() === AttendanceStatus.Late ? (
					<>
						<HoverCard
							shadow="md"
							arrowPosition={"center"}
							withArrow
							position="bottom-end"
						>
							<HoverCard.Target>
								<div>
									<button
										onClick={() => {
											if (
												moment()
													.startOf("minutes")
													.isSameOrBefore(
														moment(
															`${moment().format(
																"YYYY-MM-DD"
															)} ${
																driver.shift
																	.endTime
															}`
														).tz("Asia/Kolkata")
													)
											)
												openStatusModal(driver._id);
										}}
										className={
											driver?.shift?.startTime &&
											moment()
												.startOf("minutes")
												.isSameOrBefore(
													moment(
														`${moment().format(
															"YYYY-MM-DD"
														)} ${
															driver.shift.endTime
														}`
													).tz("Asia/Kolkata")
												)
												? "late_button"
												: "late_button disabled"
										}
									>
										Late
									</button>
								</div>
							</HoverCard.Target>
							{!moment()
								.startOf("minutes")
								.isSameOrBefore(
									moment(
										`${moment().format("YYYY-MM-DD")} ${
											driver.shift.endTime
										}`
									).tz("Asia/Kolkata")
								) && (
								<HoverCard.Dropdown maw={200} bg={"#0E2227"}>
									<Text size="sm" c={"#fff"} fz={12}>
										Shift is over.
									</Text>
								</HoverCard.Dropdown>
							)}
						</HoverCard>

						<Modal
							opened={statusModals[driver._id]}
							onClose={() => closeStatusModal(driver._id)}
							centered
							withCloseButton={false}
							size={
								!driver.attendance?.picture?.length
									? "sm"
									: "lg"
							}
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<PresentModal
								driver={driver}
								close={() => closeStatusModal(driver._id)}
								value={driver?.attendance?.status}
							/>
						</Modal>
					</>
				) : (
					<></>
				);
			};
			return (
				<tr key={id}>
					<td>
						<img
							src={driver?.picture}
							alt="driver_image"
							className={"driver_image " + getStatus()}
							style={{ cursor: "pointer" }}
							onClick={() =>
								navigate(
									`/dashboard/driverdetails/${driver._id}`
								)
							}
						/>
					</td>
					<td>
						<Text maw={180}>{driver?.name}</Text>
						<span className="username">{driver?.username}</span>
					</td>
					<td>{driver?.hub?.name}</td>
					<td>{driver?.recruiter?.name}</td>
					<td style={{ maxWidth: "150px" }}>
						{driver?.vehicle?.vehicle_no}
					</td>
					<td>
						{`${driver?.shift?.startTime} to ${driver?.shift?.endTime}`}
						<br />
						{/* use red_text classname for red color text and green_text classname for green color text */}
						<span className="green_text">
							{isToday(driver.attendance?.date || null) &&
							driver?.attendance?.status !==
								AttendanceStatus.Absent
								? `Punched in at ${driver?.attendance?.checkIn}`
								: ""}
						</span>
					</td>
					<td>{getStatusButton()}</td>
				</tr>
			);
		})
		.filter((item) => item !== null);
	return (
		<div className="table_box">
			<Table>
				<thead className="table_thead">
					<tr>
						<th style={{ width: "70px" }}></th>
						<th>Name</th>
						<th>Hub</th>
						<th>Recruiter</th>
						<th>Vehicle</th>
						<th>Shift</th>
						<th>Attendance</th>
					</tr>
				</thead>
				{loading ? (
					<tbody>
						<tr>
							<td colSpan="7">
								<Loading />
							</td>
						</tr>
					</tbody>
				) : rows.length ? (
					<tbody>{rows}</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan="7">
								<ZeroScreens />
							</td>
						</tr>
					</tbody>
				)}
			</Table>
		</div>
	);
};

export default TableBox;
