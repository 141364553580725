import { Grid, Highlight, TextInput } from "@mantine/core";
import "./Login.css";
import image from "../../assets/illustrations/7606729_3693140 1.svg";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/reducers/userReducer";
import { onChangeFormValue } from "../../utils/functions";
import { useEffect } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { toast } from "react-toastify";

const initialState = {
	formData: {
		username: "",
		password: "",
	},
};

const Login = () => {
	const notify = (info, error) => {
		if (info) {
			toast.info(info, {
				autoClose: 1000,
				theme: "dark",
			});
		} else if (error) {
			toast.error(error, {
				autoClose: 1000,
				theme: "dark",
			});
		}
	};
	// hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// states
	const [formValue, setFormValue] = useState(initialState.formData);

	// redux states
	const isAuthenticated = useSelector((state) => state.user?.isAuthenticated);
	const isLoading = useSelector((state) => state.user?.loading);

	// functions
	function onClickLogin() {
		login.prototype.onError = (e) => {
			notify(null, e?.response?.data?.error);
		};
		dispatch(
			login({
				data: formValue,
			})
		);
	}

	async function handleIsAuthenticated() {
		// console.log("handleIsAuthenticated");
		if (isAuthenticated) {
			navigate("/dashboard");
		}
	}

	// effects
	useEffect(() => {
		handleIsAuthenticated();
	}, [isAuthenticated]);

	const [isPasswordVisible, setPasswordVisibility] = useState(false);

	const togglePasswordVisibility = () => {
		setPasswordVisibility(!isPasswordVisible);
	};

	return (
		<Grid m={0} align="center" justify="center" h={"100vh"}>
			<Grid.Col span={6} px={80}>
				<Highlight
					style={{
						textAlign: "center",
						fontSize: "22px",
						fontWeight: 500,
					}}
					highlight="Zevo"
					highlightStyles={() => ({
						color: "#07DD92",
						WebkitBackgroundClip: "text",
						fontWeight: 600,
					})}
				>
					Welcome to Zevo Admin Portal - Where Efficiency Meets
					Excellence!
				</Highlight>

				<img
					className="login_illustration"
					src={image}
					alt="login_illustration"
				/>
			</Grid.Col>
			<Grid.Col span={6} px={80}>
				<div className="login_form_box">
					<div className="zevo_logo">
						<img src={logo} alt="zevo_logo" />
						<svg
							width="100%"
							height="2"
							viewBox="0 0 392 2"
							fill="none"
						>
							<line
								y1="0.908203"
								x2="392"
								y2="0.908203"
								stroke="url(#paint0_linear_398_4328)"
								strokeOpacity="0.19"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_398_4328"
									x1="0"
									y1="1.4082"
									x2="392"
									y2="1.4082"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopOpacity="0" />
									<stop offset="0.49664" />
									<stop offset="1" stopOpacity="0" />
								</linearGradient>
							</defs>
						</svg>
					</div>

					<div>
						<TextInput
							styles={() => ({
								input: {
									padding: "25px 10px",
									borderRadius: "10px",
									border: "1px solid #000",
									boxShadow:
										"0px 4px 24px 0px rgba(14, 34, 39, 0.16)",
								},
								label: {
									marginBottom: "10px",
									fontSize: 18,
								},
							})}
							label="Login Id"
							placeholder="User Name"
							name="username"
							onChange={(e) =>
								onChangeFormValue(
									"username",
									e.target.value,
									setFormValue
								)
							}
						/>
						<TextInput
							mt={40}
							styles={() => ({
								input: {
									padding: "25px 10px",
									paddingRight: "40px",
									borderRadius: "10px",
									border: "1px solid #000",
									boxShadow:
										"0px 4px 24px 0px rgba(14, 34, 39, 0.16)",
								},
								label: {
									marginBottom: "10px",
									fontSize: 18,
								},
							})}
							label="Password"
							placeholder="Enter User Password"
							name="password"
							onChange={(e) =>
								onChangeFormValue(
									"password",
									e.target.value,
									setFormValue
								)
							}
							type={isPasswordVisible ? "text" : "password"}
							rightSection={
								<div
									style={{
										position: "absolute",
										right: "10px",
										cursor: "pointer",
									}}
									onClick={togglePasswordVisibility}
								>
									{isPasswordVisible ? (
										<FiEyeOff
											size={24}
											style={{ verticalAlign: "middle" }}
										/>
									) : (
										<FiEye
											size={24}
											style={{ verticalAlign: "middle" }}
										/>
									)}
								</div>
							}
						/>
					</div>
					<div style={{ display: "flex" }}>
						<button
							className="black_button_1"
							onClick={onClickLogin}
						>
							Login
						</button>
						{isLoading ? (
							<div
								className="loader"
								style={{ margin: "auto 0 0 20px" }}
							></div>
						) : null}
					</div>
				</div>
			</Grid.Col>
		</Grid>
	);
};

export default Login;
