import jwt_decode from "jwt-decode";
import store from "../redux/store";
import { dateFormat } from "./constants";
import moment from "moment";

export async function isTokenExpired() {
	const token = store.getState()?.user?.token;
	if (!token?.length) return true;
	const decodedToken = await jwt_decode(token);
	const tokenExpired = decodedToken?.exp < Date.now() / 1000;
	// console.log("isTokenExpired", tokenExpired, decodedToken);
	return tokenExpired;
}

/**
 *
 * @param {Event} e
 * @param {Function} setFormValues
 * @returns {String} updated key
 */
export function onChangeFormValue(name, value, setFormValue) {
	if (!name || !value || !setFormValue) {
		return;
	}
	setFormValue((prev) => ({ ...prev, [name]: value }));
	return name;
}

export function getErrorMessage(error) {
	return error?.response?.data?.message || error?.message;
}

Array.prototype.assign = assignArray;
export function assignArray(key, sources, sourceKey, idKey = "_id") {
	if (!Array.isArray(this) && !Array.isArray(sources)) {
		return;
	}

	return this?.map((t) => {
		t[key] = sources?.filter((s) => s[sourceKey] === t[idKey]);
	});
}

export const isObject = (obj) =>
	obj && typeof obj === "object" && !Array.isArray(obj);

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const getFormattedDate = (isoDate) => {
	if (!isoDate) return dateFormat;
	return moment(isoDate).format(dateFormat);
};
export const getFormattedTime = () => {
	return moment().format("HH:mm");
};

export const getDayFromDate = (dateStr) => {
	const [day, month, year] = dateStr.split("/").map(Number);
	const date = new Date(year, month - 1, day);
	const weekday = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	return weekday[date.getDay()];
};

export function createQueryString(params) {
	const searchParams = new URLSearchParams();

	function appendParams(obj, prefix = "") {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];

				if (value !== undefined && value !== null && value !== false) {
					const updatedKey = prefix ? `${prefix}.${key}` : key;

					if (Array.isArray(value)) {
						for (const item of value) {
							if (
								item !== undefined &&
								item !== null &&
								item !== false
							) {
								searchParams.append(
									updatedKey,
									item.toString()
								);
							}
						}
					} else if (typeof value === "object" && value !== null) {
						appendParams(value, updatedKey);
					} else {
						searchParams.append(updatedKey, value.toString());
					}
				}
			}
		}
	}

	if (params) appendParams(params);

	const queryString = searchParams.toString();
	return queryString ? `?${queryString}` : "";
}

export function isToday(dateString) {
	if (!dateString || dateString.length == 0) return false;
	const inputDate = new Date(dateString.split("/").reverse().join("-"));
	const today = new Date();

	inputDate.setHours(0, 0, 0, 0);
	today.setHours(0, 0, 0, 0);

	return inputDate.getTime() === today.getTime();
}

export function sortSelectOptions(selectOption) {
	selectOption.sort((a, b) => {
		if (a.label < b.label) {
			return -1;
		}
		if (a.label > b.label) {
			return 1;
		}
		return 0;
	});
}

export function checkURLType(url) {
	if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
		return "image";
	} else if (url.match(/\.pdf$/) != null) {
		return "pdf";
	} else {
		return null;
	}
}
