import { io } from "socket.io-client";
import config from "../config";
import { EventName } from "../utils/constants";

const socket = io(config.backend.host);

export async function connectUser(userId) {
  if (!userId) {
    console.error("Provide userId to establish socket conection");
    return {
      error: true,
      message: "Provide userId to establish socket conection",
    };
  }

  return await new Promise(async (resolve) => {
    socket.emit(EventName.ConnectUser, { userId }, (response) => {
      if (response) {
        resolve(response);
      } else {
        resolve(false);
      }
    });
  });
}

export default socket;
