import { TextInput } from "@mantine/core";
import "./Monitor.css";
import PendingApprovalTabs from "./PendingApprovalTabs";
import { useState } from "react";

const Monitor = () => {
	const [searchQuery, changeSearch] = useState("");

	return (
		<div className="monitor">
			<TextInput
				maw={500}
				styles={() => ({
					input: {
						padding: "22px 10px",
						borderRadius: "10px",
						border: "1px solid #9DB2B8",
						boxShadow: "0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
					},
				})}
				placeholder="Enter driver name, Employee ID"
				rightSection={
					<svg width="22" height="20" viewBox="0 0 27 28" fill="none">
						<ellipse
							cx="10.3418"
							cy="10.5395"
							rx="8.34184"
							ry="8.65079"
							stroke="#222222"
							strokeWidth="3"
						/>
						<path
							d="M19.75 20.2969L25.3557 26.1102"
							stroke="#222222"
							strokeWidth="3"
							strokeLinecap="round"
						/>
					</svg>
				}
				onChange={(e) => {
					changeSearch(e.target.value);
				}}
			/>

			<PendingApprovalTabs searchQuery={searchQuery} />
		</div>
	);
};

export default Monitor;
