import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import appReducer from "./appReducer";
import driverReducer from "./driverReducer";
import holidayReducer from "./holidayReducer";
import shiftReducer from "./shiftReducer";
import supervisorReducer from "./supervisorReducer";
import hubReducer from "./hubReducer";
import recruiterReducer from "./recruiterReducer";
import attendanceReducer from "./attendanceReducer";
import clientReducer from "./clientReducer";
import payoutCycleReducer from "./payoutCycleReducer";
import payoutReducer from "./payoutReducer";
import notificationReducer from "./notificationReducer";
import vehicleReducer from "./vehicleReducer";
import vehiclesTypeReducer from "./vehiclesTypeReducer";
import challanReducer from "./challanReducer";

const rootReducer = combineReducers({
	user: userReducer,
	app: appReducer,
	driver: driverReducer,
	attendance: attendanceReducer,
	holiday: holidayReducer,
	shift: shiftReducer,
	supervisor: supervisorReducer,
	hub: hubReducer,
	recruiter: recruiterReducer,
	client: clientReducer,
	payoutCycle: payoutCycleReducer,
	payout: payoutReducer,
	notification: notificationReducer,
	vehicle: vehicleReducer,
	vehiclesType: vehiclesTypeReducer,
	challan: challanReducer,
});

export default rootReducer;
