import "./DriverDetailsHeader.css";
import { Divider, Flex, HoverCard, Modal, Select, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import AttendanceHistoryModal from "../../../../components/AttendanceHistoryModal/AttendanceHistoryModal";
import PhotoUpload from "../../../../components/PhotoUpload/PhotoUpload";
import { useEffect, useState } from "react";
import PresentModal from "../../../../components/PresentModal/PresentModal";
import StatusConfirmationModal from "../../../../components/StatusConfirmationModal/StatusConfirmationModal";
import { useNavigate } from "react-router-dom";
import {
	getFormattedDate,
	getFormattedTime,
} from "../../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { updateDriverPicture } from "../../../../redux/reducers/driverReducer";
import { FileName } from "../../../../utils/constants";
import { updateAttendanceByDriverId } from "../../../../redux/reducers/attendanceReducer";
import moment from "moment";
import { toast } from "react-toastify";
import OrderHistoryModal from "../../../../components/OrderHistoryModal/OrderHistoryModal";
import { Badge } from "@mantine/core";
import ChallanListModal from "../../ChallanManagement/ChallanListModal/ChallanListModal";

function isToday(dateString) {
	if (!dateString || dateString.length == 0) return false;
	const inputDate = new Date(dateString.split("/").reverse().join("-"));
	const today = new Date();

	inputDate.setHours(0, 0, 0, 0);
	today.setHours(0, 0, 0, 0);

	return inputDate.getTime() === today.getTime();
}

const DriverDetailsHeader = (data) => {
	const driver = data.driver || {};
	const [opened, { open, close }] = useDisclosure(false);
	const [
		openedOrderHistory,
		{ open: openOrderHistory, close: closeOrderHistory },
	] = useDisclosure(false);
	const [lateModal, setLateModal] = useState(false);
	const [presentModal, setPresentModal] = useState(false);
	const [statusModal, setStatusModal] = useState(false);

	const todayDate = moment().format("YYYY-MM-DD");
	const shiftStartDateTime = driver?.shift?.startTime
		? moment(`${todayDate} ${driver.shift.startTime}`)
		: null;

	const [value, setValue] = useState(
		isToday(driver.attendance?.date || null)
			? driver.attendance?.status
			: moment().isAfter(shiftStartDateTime)
			? "unmarked"
			: "idle"
	);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [unmarkedModal, setUnmarkedModal] = useState(false);
	const [vehicle_no, setVehicleNo] = useState("");

	const challans = useSelector((state) => state?.challan?.data);

	function markPresent(driver, setUnmark = false) {
		updateAttendanceByDriverId.prototype.onSuccess = () => {
			toast.success("Attendance Marked.", {
				autoClose: 2000,
				theme: "dark",
			});
		};
		dispatch(
			updateAttendanceByDriverId({
				id: setUnmark ? null : driver.attendance?._id,
				data: {
					user: driver?._id,
					checkIn: getFormattedTime(),
					date: getFormattedDate(moment()),
					hub: driver?.hub?._id,
					status: "present",
					mode: "online",
				},
			})
		);
		setValue("present");
		return true;
	}

	const uploadProfile = (file) => {
		if (file) {
			updateDriverPicture.prototype.onSuccess = () => {
				toast.success("Image updated successfully!", {
					autoClose: 2000,
					theme: "dark",
				});
			};
			dispatch(
				updateDriverPicture({
					id: driver._id,
					file,
					query: { fileName: FileName.Picture },
				})
			);
		}
	};

	return (
		<div className="DriverDetailsHeader">
			<div
				style={{ cursor: "pointer", marginBottom: "10px" }}
				onClick={() => navigate(-1)}
			>
				<svg width="25" viewBox="0 0 31 24" fill="none">
					<path
						d="M2.90991 10.752H28.5484C28.9338 10.752 29.3033 10.8778 29.5758 11.1017C29.8482 11.3256 30.0013 11.6293 30.0013 11.946C30.0013 12.2627 29.8482 12.5665 29.5758 12.7904C29.3033 13.0143 28.9338 13.1401 28.5484 13.1401H2.90991C2.52458 13.1401 2.15504 13.0143 1.88257 12.7904C1.6101 12.5665 1.45703 12.2627 1.45703 11.946C1.45703 11.6293 1.6101 11.3256 1.88257 11.1017C2.15504 10.8778 2.52458 10.752 2.90991 10.752Z"
						fill="black"
					/>
					<path
						d="M3.50984 11.9424L15.56 21.8438C15.8328 22.068 15.986 22.3721 15.986 22.6892C15.986 23.0063 15.8328 23.3104 15.56 23.5346C15.2872 23.7588 14.9172 23.8848 14.5313 23.8848C14.1455 23.8848 13.7755 23.7588 13.5027 23.5346L0.426836 12.7878C0.291535 12.6769 0.184189 12.5451 0.110945 12.4C0.0377017 12.255 0 12.0995 0 11.9424C0 11.7853 0.0377017 11.6298 0.110945 11.4847C0.184189 11.3397 0.291535 11.2079 0.426836 11.097L13.5027 0.350182C13.7755 0.125964 14.1455 0 14.5313 0C14.9172 0 15.2872 0.125964 15.56 0.350182C15.8328 0.5744 15.986 0.878505 15.986 1.1956C15.986 1.51269 15.8328 1.81679 15.56 2.04101L3.50984 11.9424Z"
						fill="black"
					/>
				</svg>
			</div>

			<div className="DriverDetailsHeader_body">
				<div className="driver_info">
					<div className="driver_details_image">
						<PhotoUpload
							onImageUpload={uploadProfile}
							picture={driver.picture}
						/>
					</div>
					<div className="driver_info_details">
						<h1>{driver?.name}</h1>
						<Badge variant="filled" color="yellow">
							Driver
						</Badge>
						<div>
							<svg
								width="15"
								height="15"
								viewBox="0 0 32 32"
								fill="none"
							>
								<circle
									cx="16"
									cy="12.666"
									r="5"
									stroke="black"
									strokeLinecap="round"
									strokeWidth="2"
								/>
								<circle
									cx="16"
									cy="16"
									r="15"
									strokeLinecap="round"
									strokeWidth="2"
								/>
								<path
									d="M26 27.1765C25.4102 25.4046 24.1104 23.8388 22.3024 22.7221C20.4943 21.6053 18.279 21 16 21C13.721 21 11.5057 21.6053 9.69764 22.7221C7.88959 23.8388 6.58985 25.4046 6 27.1765"
									stroke="black"
									strokeLinecap="round"
									strokeWidth="2"
								/>
							</svg>
							{driver?.username}
						</div>
						<div>
							<svg
								width="15"
								height="15"
								viewBox="0 0 15 15"
								fill="none"
							>
								<path
									d="M11.596 9.48488L13.4985 11.3874C13.7755 11.6644 13.7755 12.1134 13.4985 12.3904C12.0011 13.8878 9.63054 14.0562 7.93643 12.7857L7.07487 12.1395C5.47751 10.9415 4.05853 9.52249 2.86051 7.92512L2.21434 7.06356C0.943761 5.36946 1.11223 2.99888 2.60962 1.50149C2.88659 1.22452 3.33563 1.22452 3.6126 1.50149L5.51512 3.404C5.90564 3.79453 5.90564 4.42769 5.51512 4.81822L4.87802 5.45531C4.7518 5.58153 4.72051 5.77436 4.80034 5.93401C5.7233 7.77993 7.22007 9.2767 9.06599 10.1997C9.22564 10.2795 9.41847 10.2482 9.54469 10.122L10.1818 9.48488C10.5723 9.09436 11.2055 9.09436 11.596 9.48488Z"
									stroke="#222222"
								/>
							</svg>
							{driver.phone}
						</div>
						{driver.email && (
							<div>
								<svg
									width="15"
									height="12"
									viewBox="0 0 15 12"
									fill="none"
								>
									<rect
										x="1"
										y="1"
										width="13"
										height="9.75"
										rx="2"
										stroke="#222222"
									/>
									<path
										d="M1 3.4375L6.60557 6.24029C7.16863 6.52181 7.83137 6.52181 8.39443 6.24029L14 3.4375"
										stroke="#222222"
									/>
								</svg>
								{driver.email}
							</div>
						)}
						{driver.state && (
							<div>
								<svg
									width="15"
									height="20"
									viewBox="0 0 18 18"
									fill="none"
								>
									<path
										d="M9 10.125C8.44374 10.125 7.89997 9.96005 7.43746 9.65101C6.97495 9.34197 6.61446 8.90272 6.40159 8.3888C6.18872 7.87488 6.13302 7.30938 6.24154 6.76381C6.35006 6.21824 6.61793 5.7171 7.01126 5.32376C7.4046 4.93043 7.90574 4.66256 8.45131 4.55404C8.99688 4.44552 9.56238 4.50122 10.0763 4.71409C10.5902 4.92696 11.0295 5.28745 11.3385 5.74996C11.6476 6.21247 11.8125 6.75624 11.8125 7.3125C11.8116 8.05815 11.515 8.773 10.9878 9.30025C10.4605 9.82751 9.74565 10.1241 9 10.125ZM9 5.625C8.66625 5.625 8.33998 5.72397 8.06248 5.9094C7.78497 6.09482 7.56868 6.35837 7.44095 6.66672C7.31323 6.97507 7.27981 7.31437 7.34493 7.64172C7.41004 7.96906 7.57076 8.26974 7.80676 8.50574C8.04276 8.74175 8.34344 8.90246 8.67079 8.96758C8.99813 9.03269 9.33743 8.99927 9.64578 8.87155C9.95413 8.74383 10.2177 8.52753 10.4031 8.25003C10.5885 7.97252 10.6875 7.64626 10.6875 7.3125C10.6871 6.86509 10.5091 6.43612 10.1928 6.11975C9.87638 5.80338 9.44742 5.62545 9 5.625Z"
										fill="black"
									/>
									<path
										d="M9.00001 16.875L4.25476 11.2787C4.18882 11.1947 4.12357 11.1101 4.05901 11.025C3.24843 9.95725 2.81054 8.65306 2.81251 7.3125C2.81251 5.67147 3.4644 4.09766 4.62478 2.93728C5.78516 1.7769 7.35898 1.125 9.00001 1.125C10.641 1.125 12.2148 1.7769 13.3752 2.93728C14.5356 4.09766 15.1875 5.67147 15.1875 7.3125C15.1895 8.65245 14.7518 9.95605 13.9416 11.0233L13.941 11.025C13.941 11.025 13.7723 11.2466 13.7469 11.2764L9.00001 16.875ZM4.95676 10.3472C4.95788 10.3472 5.08838 10.5204 5.11819 10.5576L9.00001 15.1358L12.8869 10.5514C12.9116 10.5204 13.0433 10.3461 13.0438 10.3455C13.706 9.47313 14.0638 8.4077 14.0625 7.3125C14.0625 5.96984 13.5291 4.68217 12.5797 3.73277C11.6303 2.78337 10.3427 2.25 9.00001 2.25C7.65735 2.25 6.36968 2.78337 5.42028 3.73277C4.47088 4.68217 3.93751 5.96984 3.93751 7.3125C3.93633 8.40838 4.29401 9.47442 4.95676 10.3472Z"
										fill="black"
									/>
								</svg>
								{driver.city}, {driver.state}
							</div>
						)}
						{/* <button
              // onClick={() =>
              //   navigate(`/dashboard/editdriverdetails/${driver._id}`)
              // }
            >
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                <g clipPath="url(#clip0_304_2458)">
                  <path
                    d="M14.413 4.78298L5.4149 13.5591L2.31731 13.8933C1.41956 13.9902 0.655445 13.2516 0.754815 12.3694L1.09747 9.34817L10.0955 0.572038C10.8802 -0.193283 12.148 -0.193283 12.9293 0.572038L14.4096 2.01579C15.1942 2.78111 15.1942 4.021 14.413 4.78298ZM11.0276 5.81566L9.03675 3.87395L2.67025 10.0868L2.42011 12.2691L4.65764 12.0251L11.0276 5.81566ZM13.248 3.15207L11.7677 1.70832C11.6272 1.5713 11.3976 1.5713 11.2606 1.70832L10.2018 2.74101L12.1926 4.68272L13.2514 3.65003C13.3884 3.50967 13.3885 3.2891 13.248 3.15207Z"
                    fill="#0E2227"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_304_2458">
                    <rect width="15" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Edit
            </button> */}
					</div>
				</div>
				<div className="driver_info2">
					<div className="btn">
						<button
							className="Attendance_btn Challan_btn"
							onClick={() => {
								setVehicleNo(driver?.vehicle?.vehicle_no);
							}}
						>
							<span>
								Total Challan (
								{challans?.find(
									(a) =>
										a?.vehicle_no ===
										driver?.vehicle?.vehicle_no
								)?.count || 0}
								)
							</span>
							{(challans?.find(
								(a) =>
									a?.vehicle_no ===
									driver?.vehicle?.vehicle_no
							)?.count || 0) > 0 ? (
								<div></div>
							) : (
								<></>
							)}
						</button>
						<button className="Attendance_btn" onClick={open}>
							Attendance History
						</button>
						<Modal
							opened={vehicle_no?.length > 0}
							onClose={() => setVehicleNo("")}
							centered
							withCloseButton={false}
							size="90vw"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<ChallanListModal
								onClose={() => setVehicleNo("")}
								vehicle_no={vehicle_no}
							/>
						</Modal>

						<Modal
							opened={opened}
							onClose={close}
							centered
							withCloseButton={false}
							size="md"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<AttendanceHistoryModal
								close={close}
								driverId={driver._id}
							/>
						</Modal>
					</div>

					<div className="joining_info">
						<Flex justify={"space-between"} miw={230}>
							<div style={{ marginTop: "10px" }}>
								<h5>Joining Date</h5>
								<h5 style={{ marginTop: "15px" }}>
									Today’s Status
								</h5>
							</div>
							<div style={{ marginTop: "10px" }}>
								<h6>{getFormattedDate(driver.createdAt)}</h6>
								<div style={{ marginTop: "12px" }}>
									{value == "present" ? (
										<>
											<button
												onClick={() =>
													setPresentModal(true)
												}
												className="present_button"
											>
												Present
											</button>

											<Modal
												opened={presentModal}
												onClose={() =>
													setPresentModal(false)
												}
												centered
												withCloseButton={false}
												size={
													isToday(
														driver?.attendance
															?.date || null
													) &&
													!driver.attendance?.picture
														?.length
														? "sm"
														: "lg"
												}
												padding={0}
												radius={10}
												overlayProps={{
													blur: 5,
													color: "#fff",
													backgroundopacity: 1,
												}}
											>
												<PresentModal
													driver={driver}
													close={() =>
														setPresentModal(false)
													}
													setValue={setValue}
													value={value}
												/>
											</Modal>
										</>
									) : value == "absent" ? (
										<div className="absent_button">
											<Select
												size="xs"
												value={value}
												onChange={(e) => {
													if (value !== e) {
														setStatusModal(true);
													}
												}}
												defaultValue="absent"
												styles={{
													input: {
														borderRadius: "10px",
														fontSize: "12px",
													},
												}}
												rightSection={
													<svg
														width="8"
														height="8"
														viewBox="0 0 13 8"
														fill="none"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
															fill="#0E2227"
														/>
													</svg>
												}
												placeholder="Absent"
												data={[
													// {
													// 	value: "Late",
													// 	label: "Late",
													// },
													{
														value: "present",
														label: "Present",
													},
													{
														value: "absent",
														label: "Absent",
													},
												]}
											/>
											<Modal
												opened={statusModal}
												onClose={() =>
													setStatusModal(false)
												}
												centered
												withCloseButton={false}
												size="sm"
												padding={0}
												radius={10}
												overlayProps={{
													blur: 5,
													color: "#fff",
													backgroundopacity: 1,
												}}
											>
												<StatusConfirmationModal
													driver={driver}
													setStatusModal={
														setUnmarkedModal
													}
													onSuccess={() =>
														markPresent(driver)
													}
												/>
											</Modal>
										</div>
									) : value == "idle" ? (
										<HoverCard
											shadow="md"
											arrowPosition={"center"}
											withArrow
											position="bottom-end"
										>
											<HoverCard.Target>
												<button className="idle_button">
													Idle
												</button>
											</HoverCard.Target>
											<HoverCard.Dropdown
												maw={200}
												bg={"#0E2227"}
											>
												<Text
													size="sm"
													c={"#fff"}
													fz={12}
												>
													Change Shift timings to
													change driver status
												</Text>
											</HoverCard.Dropdown>
										</HoverCard>
									) : value == "idle" ? (
										<HoverCard
											shadow="md"
											arrowPosition={"center"}
											withArrow
											position="bottom-end"
										>
											<HoverCard.Target>
												<button className="idle_button">
													Idle
												</button>
											</HoverCard.Target>
											<HoverCard.Dropdown
												maw={200}
												bg={"#0E2227"}
											>
												<Text
													size="sm"
													c={"#fff"}
													fz={12}
												>
													{isToday(
														driver?.attendance
															?.date || null
													) &&
													!driver.attendance?.picture
														?.length
														? "sm"
														: "lg"}
													Change Shift timings to
													change driver status
												</Text>
											</HoverCard.Dropdown>
										</HoverCard>
									) : value == "idle" ? (
										<HoverCard
											shadow="md"
											arrowPosition={"center"}
											withArrow
											position="bottom-end"
										>
											<HoverCard.Target>
												<button className="idle_button">
													Idle
												</button>
											</HoverCard.Target>
											<HoverCard.Dropdown
												maw={200}
												bg={"#0E2227"}
											>
												<Text
													size="sm"
													c={"#fff"}
													fz={12}
												>
													Change Shift timings to
													change driver status
												</Text>
											</HoverCard.Dropdown>
										</HoverCard>
									) : value == "unmarked" ? (
										<>
											<button
												onClick={() =>
													setUnmarkedModal(true)
												}
												className="unmarked_button"
											>
												Unmarked
											</button>

											<Modal
												opened={unmarkedModal}
												onClose={() =>
													setUnmarkedModal(false)
												}
												centered
												withCloseButton={false}
												size="sm"
												padding={0}
												radius={10}
												overlayProps={{
													blur: 5,
													color: "#fff",
													backgroundopacity: 1,
												}}
											>
												<PresentModal
													driver={driver}
													close={() =>
														setUnmarkedModal(false)
													}
													setValue={setValue}
													value={"unmarked"}
												/>
												{/* <StatusConfirmationModal
													driver={driver}
													setStatusModal={
														setUnmarkedModal
													}
													onSuccess={() =>
														markPresent(
															driver,
															true
														)
													}
													setUnmarked={true}
												/> */}
											</Modal>
										</>
									) : value == "late" ? (
										<>
											<button
												className="late_button"
												onClick={() =>
													setLateModal(true)
												}
											>
												Late
											</button>

											<Modal
												opened={lateModal}
												onClose={() =>
													setLateModal(false)
												}
												centered
												withCloseButton={false}
												size={
													isToday(
														driver?.attendance
															?.date || null
													) &&
													!driver.attendance?.picture
														?.length
														? "sm"
														: "lg"
												}
												padding={0}
												radius={10}
												overlayProps={{
													blur: 5,
													color: "#fff",
													backgroundopacity: 1,
												}}
											>
												<PresentModal
													close={() =>
														setLateModal(false)
													}
													driver={driver}
													value={"late"}
												/>
											</Modal>
										</>
									) : null}
								</div>
							</div>
						</Flex>
					</div>

					<div style={{ textAlign: "center" }}>
						<p className="order_history" onClick={openOrderHistory}>
							View Order History
						</p>

						<Modal
							opened={openedOrderHistory}
							onClose={closeOrderHistory}
							centered
							withCloseButton={false}
							size="md"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<OrderHistoryModal
								close={closeOrderHistory}
								driverId={driver._id}
							/>
						</Modal>
					</div>
				</div>
			</div>

			<Divider color="#C2D0D6" mt={20} />
		</div>
	);
};

export default DriverDetailsHeader;
