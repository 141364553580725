import { Stepper } from "@mantine/core";

const StyledStepperSupervisor = (props) => {
	return (
		<Stepper
			allowNextStepsSelect={false}
			size="xs"
			color="#03ab7033"
			w={"60%"}
			styles={{
				stepBody: {
					position: "absolute",
					top: "125px",
				},
				stepDescription: {
					color: "#000",
					fontSize: "13px",
					width: "130px",
					textAlign: "center",
					position: "absolute",
					left: "-18px",
				},

				step: {
					padding: 0,
					borderRadius: "100%",
				},

				stepIcon: {
					borderWidth: "4px",
					borderColor: "#03ab7033",
				},
				stepCompletedIcon: {
					background:
						"linear-gradient(132deg, #03AB70 24.38%, #029AC9 75.69%)",
					borderRadius: "100%",
					borderColor: "red",
				},
				separator: {
					marginLeft: "0px",
					marginRight: "0px",
					height: "6px",
				},
			}}
			{...props}
		/>
	);
};

export default StyledStepperSupervisor;
