import React, { Component } from "react";
import logo from "../../../assets/images/logo.svg";
import "./PrivacyPolicy.css";

class PrivacyPolicy extends Component {
	render() {
		return (
			<div
				style={{
					textAlign: "left",
					display: "flex",
					flexDirection: "column",
					height: "100vh",
				}}
				className="privacy_policy"
			>
				<div style={{ marginTop: "27px", textAlign: "center" }}>
					<img style={{ width: 71.12, height: 31 }} src={logo} />
				</div>
				<div
					style={{
						padding: "56px 32px",
					}}
				>
					<h1
						style={{
							color: "#0E2227",
							fontSize: 24,
							fontFamily: "Roboto",
							fontWeight: "600",
							wordWrap: "break-word",
						}}
					>
						Privacy Policy
					</h1>
					<div>
						<p>
							Welcome to Zevo! This Privacy Policy outlines how we
							collect, use, disclose, and safeguard your personal
							information. By using the Zevo app, you consent to
							the practices described in this policy.
						</p>
						<ol type="1">
							<li>
								<span>Information We Collect:</span>
								<p>
									We may collect the following types of
									information:
								</p>
								<ul style={{ marginLeft: 10 }}>
									<li>
										Personal Information: This includes your
										name, contact information,
										identification documents, and other
										details provided during account
										registration or document submission.
									</li>
									<li>
										Usage Data: We collect information about
										how you interact with the app, such as
										attendance records, transaction history,
										and interactions with our support team.
									</li>
									<li>
										Device Information: We may collect
										device-specific information, including
										your device model, operating system
										version, unique device identifiers, and
										mobile network information.
									</li>
								</ul>
							</li>
							<li>
								<span>How We Use Your Information:</span>
								<p>
									We use your information for the following
									purposes:
								</p>
								<ul style={{ marginLeft: 10 }}>
									<li>
										Application Processing: To facilitate
										the driver application process,
										including document verification and
										approval.
									</li>
									<li>
										Attendance and Salary Management: To
										enable drivers to mark attendance, check
										salary status, and receive notifications
										related to payments.
									</li>
									<li>
										Communication: To provide support, send
										important notices, and facilitate
										communication between drivers and
										supervisors.
									</li>
									<li>
										Communication: To provide support, send
										important notices, and facilitate
										communication between drivers and
										supervisors.
									</li>
								</ul>
							</li>
							<li>
								<span>Information Sharing:</span>
								<p>
									We may share your information in the
									following circumstances:
								</p>
								<ul style={{ marginLeft: 10 }}>
									<li>
										With Admins: Driver information may be
										shared with administrators for
										application processing, approvals, and
										communication purposes.
									</li>
									<li>
										Legal Compliance: We may disclose
										information if required by law or in
										response to valid legal requests.
									</li>
								</ul>
							</li>
							<li>
								<span>Security Measures:</span>
								<p>
									We implement industry-standard security
									measures to protect your information from
									unauthorized access, alteration, disclosure,
									or destruction.
								</p>
							</li>
							<li>
								<span>Your Choices:</span>
								<p>
									You can review, edit, or delete your
									personal information within the app. You may
									also contact us if you have any
									privacy-related concerns.
								</p>
							</li>
							<li>
								<span>Changes to this Policy:</span>
								<p>
									We may update this policy from time to time.
									You will be notified of any significant
									changes.
								</p>
							</li>
							<li>
								<span>Contact Us:</span>
								<p>
									For any questions or concerns regarding this
									policy, please contact us at&nbsp;
									<a href="mailto:contact@zevoindia.in">
										contact@zevoindia.in
									</a>
								</p>
							</li>
						</ol>
					</div>
				</div>
			</div>
		);
	}
}

export default PrivacyPolicy;
