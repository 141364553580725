export const EventName = {
	Emit: "emit",
	ConnectUser: "connect_user",

	// cruds update
	DriversUpdate: "drivers_update",
	RecruitersUpdate: "recruiters_update",
	SupervisorsUpdate: "supervisors_update",
	HolidaysUpdate: "holidays_update",
	HubsUpdate: "hubs_update",
	ShiftsUpdate: "shifts_update",
	PayoutCyclesUpdate: "payout_cycles_update",
	PayoutsUpdate: "payouts_update",
	ClientsUpdate: "clients_update",
	ClientsUpdate: "clients_update",
	VehiclesUpdate: "vehicles_update",
	VehiclesTypeUpdate: "vehicles_type_update",

	// admin notifications
	LateAttendance: "late_attendance",
	PhoneChanged: "phone_changed",
};
export const ApprovalStatus = {
	Approved: "approved",
	Pending: "pending",
	Rejected: "rejected",
};
export const Role = {
	Admin: "admin",
	Supervisor: "supervisor",
	Recruiter: "recruiter",
	Driver: "driver",
};
export const dateFormat = "DD/MM/YYYY";
export const AttendanceStatus = {
	Present: "present",
	Late: "late",
	Absent: "absent",
	Unmarked: "unmarked",
	Idle: "idle",
};
export const FileName = {
	Picture: "picture",
	AadhaarCardFront: "aadhaarCardFront",
	AadhaarCardBack: "aadhaarCardBack",
	PanCard: "panCard",
	DrivingLicense: "drivingLicense",
};
export const VehicleFileNameKey = {
	RCImage: "rc_image",
	InsuranceFile: "insurance_file",
};
export const SalaryStatus = {
	Paid: "paid",
	Unpaid: "unpaid",
	Declined: "declined",
};

export const rupeeSymbol = "₹";
