import { useSelector } from "react-redux";
import "./SupervisorManagement.css";
import SupervisorManagementHeader from "./SupervisorManagementHeader/SupervisorManagementHeader";
import SupervisorManagementTable from "./SupervisorManagementTable/SupervisorManagementTable";
import SupervisorsTable from "./SupervisorsTable/SupervisorsTable";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SupervisorManagement = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const supervisorId = queryParams.get("supervisor");

	const drivers = useSelector((state) => state.driver?.drivers?.data);
	const [table, setTable] = useState([]);
	const [search, setSearch] = useState("");
	const [supervisor, setSupervisor] = useState("");
	const [hub, setHub] = useState("");
	useEffect(() => {
		if (drivers?.length > 0) {
			let sortDrivers = [];
			if (supervisor?.length || supervisorId?.length || hub?.length) {
				sortDrivers = drivers;
			}
			if (search?.length) {
				sortDrivers = sortDrivers.filter(
					(d) =>
						d?.name
							?.toLowerCase()
							.includes(search?.toLowerCase()) ||
						d?.reference_no
							?.toLowerCase()
							.includes(search?.toLowerCase())
				);
			}
			if (supervisor?.length || supervisorId?.length) {
				if (supervisor?.length)
					sortDrivers = sortDrivers.filter(
						(d) => d?.supervisor?._id === supervisor
					);
				else if (supervisorId?.length)
					sortDrivers = sortDrivers.filter(
						(d) => d?.supervisor?._id === supervisorId
					);
			}
			if (hub?.length) {
				sortDrivers = sortDrivers.filter((d) => d?.hub?._id === hub);
			}

			setTable(sortDrivers);
		}
	}, [drivers, search, supervisor, hub, supervisorId]);

	return (
		<div className="supervisor_management_container">
			<div className="supervisor_management_body">
				<div className="supervisor_management_left">
					<SupervisorManagementHeader
						drivers={drivers}
						setSearch={(value) => setSearch(value)}
						setSupervisor={(value) => setSupervisor(value)}
						setHub={(value) => setHub(value)}
						supervisorId={supervisorId}
						table={table}
					/>
					<div>
						<SupervisorManagementTable table={table} />
					</div>
				</div>
				<div className="supervisor_management_right supervisor_list">
					<h2>Supervisors</h2>
					<SupervisorsTable />
				</div>
			</div>
		</div>
	);
};

export default SupervisorManagement;
