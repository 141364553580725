import React, { useEffect, useRef, useState } from "react";
import "./VehicleRegModal.css";
import { Box, Menu, Modal, Select, Table, TextInput } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import ViewDocumentModal from "../ViewDocumentModal/ViewDocumentModal";
import {
	createBulkVehicles,
	createVehicle,
	deleteVehicle,
	updateVehicle,
} from "../../redux/reducers/vehicleReducer";
import { toast } from "react-toastify";
import SelectWithSearch from "../SelectWithSearch/SelectWithSearch";
import {
	createVehicleType,
	deleteVehicleType,
} from "../../redux/reducers/vehiclesTypeReducer";
import { checkURLType } from "../../utils/functions";

const initialForm = {
	vehicleNo: null,
	rcImage: null,
	insuranceFile: null,
	vehicleType: null,
};

const VehicleRegModal = ({ close }) => {
	const dispatch = useDispatch();
	const [formData, setFormData] = useState(initialForm);
	const [addData, setAddData] = useState(false);
	const [editingId, setEditingId] = useState(null);
	const [editingData, seteditingData] = useState(false);
	// redux
	const vehicles = useSelector((state) => state.vehicle?.data);
	const sortedVehicles = [...vehicles].sort((a, b) => {
		if (a.vehicle_no.toLowerCase() < b.vehicle_no.toLowerCase()) {
			return -1;
		}
		if (a.vehicle_no.toLowerCase() > b.vehicle_no.toLowerCase()) {
			return 1;
		}
		return 0;
	});
	const vehiclesType = useSelector((state) => state.vehiclesType.data);
	const [vehicleTypeById, setVehicleTypeById] = useState({});

	const [opened, setOpened] = useState(false);
	const [documentImage, setDocumentImage] = useState(null);
	const [documentName, setDocumentName] = useState(null);
	const fileRCInputRef = useRef(null);
	const fileInsuranceInputRef = useRef(null);
	const fileBulkUploadRef = useRef(null);
	const [search, setSearch] = useState("");

	useEffect(() => {
		if (vehiclesType?.length) {
			setVehicleTypeById(
				vehiclesType.reduce((acc, current, index) => {
					acc[current?._id] = current;
					return acc;
				}, {})
			);
		}
	}, [vehiclesType]);

	const getVehicle = (id) => {
		if (id) {
			return vehicles.find((v) => v._id === id);
		}
		return {};
	};

	const open = (image, imageName) => {
		setDocumentImage(image);
		setDocumentName(imageName);
		setOpened(true);
	};

	const close1 = () => {
		setOpened(false);
	};

	const onClickDelete = (id) => {
		dispatch(deleteVehicle({ id: id }));
	};

	const onClickAdd = () => {
		if (!formData.vehicleNo) {
			toast.error("Enter Vehicle Number");
			return;
		}
		dispatch(createVehicle({ data: formData }));
		seteditingData(true);
		setEditingId(null);
		setAddData(false);
		setFormData(initialForm);
	};

	const onClickEdit = () => {
		if (!formData.vehicleNo) {
			toast.error("Enter Vehicle Number");
			return;
		}
		console.log(formData);
		dispatch(updateVehicle({ id: editingId, data: formData }));
		seteditingData(true);
		setEditingId(null);
		setAddData(false);
		setFormData(initialForm);
	};
	createVehicle.prototype.onSuccess = () => {
		toast.success("Vehicle Created", {
			autoClose: 2000,
			theme: "dark",
		});
	};

	updateVehicle.prototype.onSuccess = () => {
		toast.success("Vehicle Updated", {
			autoClose: 2000,
			theme: "dark",
		});
	};

	deleteVehicle.prototype.onSuccess = () => {
		toast.success("Vehicle Deleted", {
			autoClose: 2000,
			theme: "dark",
		});
	};

	createBulkVehicles.prototype.onSuccess = () => {
		toast.success("Vehicles Imported", {
			autoClose: 2000,
			theme: "dark",
		});
	};

	createBulkVehicles.prototype.onError = () => {
		toast.error("Error importing vehicles", {
			autoClose: 2000,
			theme: "dark",
		});
	};

	const handleRCButtonClick = () => {
		fileRCInputRef.current.click();
	};

	const handleRCFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const fileType = file.type;
			const validImageTypes = [
				"image/jpeg",
				"image/png",
				"image/jpg",
				"application/pdf",
			];

			if (validImageTypes.includes(fileType)) {
				setFormData({
					...formData,
					rcImage: file,
				});
			} else {
				toast.error(
					"Invalid file type. Please upload an image in JPG, JPEG, or PNG format."
				);
			}
		}
		e.target.value = "";
	};

	const handleInsuranceButtonClick = () => {
		fileInsuranceInputRef.current.click();
	};

	const handleInsuranceFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const fileType = file.type;
			const validImageTypes = [
				"image/jpeg",
				"image/png",
				"image/jpg",
				"application/pdf",
			];

			if (validImageTypes.includes(fileType)) {
				setFormData({
					...formData,
					insuranceFile: file,
				});
			} else {
				toast.error(
					"Invalid file type. Please upload a file in PDF format."
				);
			}
		}
		e.target.value = "";
	};

	const handleBulkUploadClick = () => {
		fileBulkUploadRef.current.click();
	};

	const handleBulkUploadChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const fileType = file.type;
			const validImageTypes = ["text/csv"];

			if (validImageTypes.includes(fileType)) {
				dispatch(createBulkVehicles({ csvFile: file }));
				toast.info("Upload Start");
			} else {
				toast.error(
					"Invalid file type. Please upload a file in CSV format."
				);
			}
		}
		e.target.value = "";
	};

	const rows = sortedVehicles
		?.filter((d) =>
			d?.vehicle_no?.toLowerCase().includes(search.toLowerCase())
		)
		?.map((vehicle, id) => (
			<tr
				key={id}
				style={
					editingData && vehicle._id === editingId
						? { backgroundColor: "#1E424B" }
						: {}
				}
			>
				<td style={{ paddingLeft: "32px" }} className="first">
					{vehicle.vehicle_no}
				</td>
				<td>
					<div
						className="view_button"
						onClick={() =>
							checkURLType(vehicle?.rc_image) === "image"
								? open(vehicle?.rc_image, "RC")
								: window.open(vehicle?.rc_image, "_blank")
						}
					>
						<span>View</span>
					</div>
				</td>
				<td>
					<div
						className="view_button"
						onClick={() =>
							checkURLType(vehicle?.insurance_file) === "image"
								? open(vehicle?.insurance_file, "Insurance")
								: window.open(vehicle?.insurance_file, "_blank")
						}
					>
						<span>View</span>
					</div>
				</td>
				<td>
					{(vehicleTypeById[vehicle.vehicle_type] || {})
						.vehicle_type || ""}
				</td>
				<td
					className="holiday_btns"
					style={{ paddingRight: "32px", textAlign: "end" }}
				>
					<Menu>
						<Menu.Target>
							<svg
								cursor={"pointer"}
								fill="#ffffff"
								height="20px"
								width="20px"
								version="1.1"
								id="Capa_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								viewBox="0 0 32.055 32.055"
								xml:space="preserve"
							>
								<g>
									<path
										d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967
		C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967
		s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967
		c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"
									/>
								</g>
							</svg>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								p={5}
								onClick={() => {
									setAddData(true);
									seteditingData(true);
									setEditingId(vehicle._id);
									const curVehicle = getVehicle(vehicle?._id);
									setFormData({
										vehicleNo: curVehicle.vehicle_no,
									});
								}}
							>
								<svg
									width="13"
									height="12"
									viewBox="0 0 13 12"
									fill="none"
								>
									<g clipPath="url(#clip0_485_4714)">
										<path
											d="M12.4931 4.09942L4.69474 11.6218L2.01016 11.9083C1.23211 11.9913 0.569875 11.3583 0.655996 10.602L0.952962 8.01244L8.7513 0.490039C9.43135 -0.165951 10.5301 -0.165951 11.2072 0.490039L12.4901 1.72754C13.1702 2.38353 13.1702 3.44629 12.4931 4.09942ZM9.55905 4.98457L7.83367 3.32025L2.31604 8.64551L2.09925 10.5161L4.03844 10.307L9.55905 4.98457ZM11.4834 2.7015L10.2005 1.464C10.0787 1.34655 9.87977 1.34655 9.76098 1.464L8.84336 2.34915L10.5687 4.01348L11.4864 3.12832C11.6051 3.00801 11.6051 2.81895 11.4834 2.7015Z"
											fill="#06B9F0"
										/>
									</g>
									<defs>
										<clipPath id="clip0_485_4714">
											<rect
												width="13"
												height="12"
												fill="white"
											/>
										</clipPath>
									</defs>
								</svg>
								<span>Edit</span>
							</Menu.Item>
							<Menu.Item
								p={5}
								onClick={() => onClickDelete(vehicle._id)}
							>
								<svg
									width="12"
									height="12"
									viewBox="0 0 12 12"
									fill="none"
								>
									<circle
										cx="6"
										cy="6"
										r="5.5"
										stroke="#F97373"
									/>
									<path
										d="M4.12561 8.37732L5.94222 6.56071L7.77607 8.40163C7.94831 8.57453 8.13966 8.66308 8.30968 8.49306L8.47802 8.32471C8.64804 8.15469 8.56003 7.9628 8.38779 7.7899L6.55394 5.94899L8.37055 4.13238C8.54056 3.96236 8.62481 3.77064 8.45257 3.59774L8.27994 3.42444C8.1077 3.25154 7.91619 3.33558 7.74617 3.5056L5.92956 5.32221L4.09571 3.48129C3.92347 3.30839 3.73212 3.21984 3.5621 3.38986L3.39376 3.55821C3.22374 3.72823 3.31175 3.92011 3.48398 4.09301L5.31784 5.93393L3.50123 7.75054C3.33122 7.92056 3.24697 8.11228 3.41921 8.28518L3.59184 8.45848C3.76407 8.63138 3.95559 8.54733 4.12561 8.37732Z"
										fill="#F97373"
									/>
								</svg>
								<span>Delete</span>
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</td>
			</tr>
		));

	return (
		<div>
			<div
				className="present_modal_header"
				style={{ paddingBottom: "10px", paddingTop: "20px" }}
			>
				<TextInput
					w={300}
					styles={() => ({
						input: {
							padding: "10px",
							borderRadius: "10px",
							border: "1px solid #9DB2B8",
							boxShadow: "0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
						},
					})}
					placeholder="Search by vehicle number"
					rightSection={
						<svg
							width="22"
							height="20"
							viewBox="0 0 27 28"
							fill="none"
						>
							<ellipse
								cx="10.3418"
								cy="10.5395"
								rx="8.34184"
								ry="8.65079"
								stroke="#222222"
								strokeWidth="3"
							/>
							<path
								d="M19.75 20.2969L25.3557 26.1102"
								stroke="#222222"
								strokeWidth="3"
								strokeLinecap="round"
							/>
						</svg>
					}
					onChange={(e) => {
						if (setSearch) setSearch(e?.target?.value);
					}}
				/>
				<div className="vehicle_count_container">
					Total Vehicle: {vehicles?.length}
				</div>
				<svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>
			<div className="holiday_list_table">
				<div className="table_content">
					<Table
						verticalSpacing="xs"
						horizontalSpacing="lg"
						className="vehicle_table"
					>
						<thead className="vehicle_thead">
							<tr>
								<th
									style={{ paddingLeft: "32px" }}
									className="first"
								>
									Vehicle No
								</th>
								<th>RC</th>
								<th>Insurance</th>
								<th>Vehicle Type</th>
								<th style={{ paddingRight: "32px" }}>&nbsp;</th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
						<Modal
							opened={opened}
							onClose={close1}
							centered
							withCloseButton={false}
							size="sm"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<ViewDocumentModal
								close={close1}
								image={documentImage}
								documentName={documentName}
							/>
						</Modal>
					</Table>
				</div>
				{addData && (
					<div
						className="vehicle_reg_time_add_box"
						style={{
							flexShrink: 0,
							padding: "20px 0",
							border: "1px solid #264147",
						}}
					>
						<Box
							style={{
								paddingRight: "32px",
								paddingLeft: "32px",
								paddingBottom: "20px",
							}}
						>
							<div style={{ width: "100%", display: "flex" }}>
								<div
									style={{
										flex: "1 auto",
										marginRight: "30px",
									}}
								>
									<p className="vg_label">Vehicle No</p>
									<input
										className="vg_input"
										value={formData.vehicleNo}
										onChange={(e) => {
											setFormData({
												...formData,
												vehicleNo: e?.target?.value,
											});
										}}
									/>
								</div>
								<div
									style={{
										flex: "1 auto",
										marginRight: "17px",
									}}
								>
									<p className="vg_label text_center">RC</p>
									{/* uploaded */}
									<input
										type="file"
										style={{ display: "none" }}
										ref={fileRCInputRef}
										onChange={handleRCFileChange}
										accept="image/jpeg,image/png,image/jpg,application/pdf"
									/>

									<button
										className={
											formData.rcImage
												? "vg_button uploaded"
												: "vg_button"
										}
										onClick={handleRCButtonClick}
									>
										{formData.rcImage
											? "Uploaded"
											: "Upload"}
									</button>
								</div>
								<div style={{ flex: "1 auto" }}>
									<p className="vg_label text_center">
										Insurance
									</p>
									<input
										type="file"
										style={{ display: "none" }}
										ref={fileInsuranceInputRef}
										onChange={handleInsuranceFileChange}
										accept="image/jpeg,image/png,image/jpg,application/pdf"
									/>

									<button
										className={
											formData.insuranceFile
												? "vg_button uploaded"
												: "vg_button"
										}
										onClick={handleInsuranceButtonClick}
									>
										{formData.insuranceFile
											? "Uploaded"
											: "Upload"}
									</button>
								</div>
							</div>
							<div>
								<div
									style={{
										flex: "1 auto",
										marginRight: "30px",
										marginTop: "20px",
									}}
								>
									<p className="vg_label">
										Choose Vehicle Type
									</p>
									<SelectWithSearch
										data={vehiclesType?.map((vt) => {
											return {
												key: vt._id,
												name: vt.vehicle_type,
											};
										})}
										value=""
										placeholder={"Eg. 2 Wheeler"}
										searchPlaceholder={"Add new type"}
										searchEnterable={true}
										rightIcon={
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="19"
												height="19"
												viewBox="0 0 19 19"
												fill="none"
											>
												<path
													d="M15.8333 3.95508C16.0433 3.95508 16.2447 4.03849 16.3931 4.18695C16.5416 4.33542 16.625 4.53678 16.625 4.74674C16.625 4.95671 16.5416 5.15807 16.3931 5.30654C16.2447 5.455 16.0433 5.53841 15.8333 5.53841H15.0417L15.0393 5.59462L14.3007 15.9425C14.2722 16.342 14.0935 16.7158 13.8004 16.9887C13.5074 17.2617 13.1218 17.4134 12.7213 17.4134H6.27792C5.87744 17.4134 5.49184 17.2617 5.19878 16.9887C4.90572 16.7158 4.72698 16.342 4.69854 15.9425L3.95992 5.59541C3.95872 5.57644 3.95819 5.55742 3.95833 5.53841H3.16667C2.9567 5.53841 2.75534 5.455 2.60687 5.30654C2.45841 5.15807 2.375 4.95671 2.375 4.74674C2.375 4.53678 2.45841 4.33542 2.60687 4.18695C2.75534 4.03849 2.9567 3.95508 3.16667 3.95508H15.8333ZM13.456 5.53841H5.54404L6.27871 15.8301H12.7213L13.456 5.53841ZM11.0833 1.58008C11.2933 1.58008 11.4947 1.66349 11.6431 1.81195C11.7916 1.96042 11.875 2.16178 11.875 2.37174C11.875 2.58171 11.7916 2.78307 11.6431 2.93154C11.4947 3.08 11.2933 3.16341 11.0833 3.16341H7.91667C7.7067 3.16341 7.50534 3.08 7.35687 2.93154C7.20841 2.78307 7.125 2.58171 7.125 2.37174C7.125 2.16178 7.20841 1.96042 7.35687 1.81195C7.50534 1.66349 7.7067 1.58008 7.91667 1.58008H11.0833Z"
													fill="#FF5151"
												/>
											</svg>
										}
										getValue={(v) => {
											setFormData({
												...formData,
												vehicleType: v.key,
											});
										}}
										searchOnEnter={(s) => {
											dispatch(
												createVehicleType({
													data: { vehicle_type: s },
												})
											);
										}}
										onRightIconClick={(e) => {
											dispatch(
												deleteVehicleType({
													id: e.key,
												})
											);
										}}
									/>
								</div>
							</div>
						</Box>
						<Box ta={"center"}>
							<button
								className="cancel_button"
								onClick={() => {
									setAddData(false);
									seteditingData(false);
									setEditingId(null);
								}}
							>
								Cancel
							</button>
							<button
								className="save_button"
								onClick={editingData ? onClickEdit : onClickAdd}
							>
								Save
							</button>
						</Box>
					</div>
				)}
				{!addData && (
					<div
						className="vehicle_reg_time_add_box flex"
						style={{
							flexShrink: 0,
							padding: "20px 0",
							margin: "auto",
						}}
					>
						<input
							type="file"
							style={{ display: "none" }}
							ref={fileBulkUploadRef}
							onChange={handleBulkUploadChange}
							accept="text/csv"
						/>
						<button
							className="holiday_add_btn"
							style={{ width: "unset", marginRight: "25px" }}
							onClick={handleBulkUploadClick}
						>
							<svg width="12" viewBox="0 0 12 12" fill="none">
								<path
									d="M6.4339 11.296V7.18254L10.6935 7.19054C11.0935 7.19129 11.418 7.0749 11.418 6.68992L11.418 6.30872C11.418 5.92374 11.0935 5.80613 10.6935 5.80538L6.4339 5.79739V1.68394C6.4339 1.29895 6.30933 0.986507 5.90927 0.985756L5.50829 0.985003C5.10823 0.984253 4.98365 1.29623 4.98365 1.68121V5.79466L0.724085 5.78667C0.324025 5.78592 -0.000414185 5.90231 -0.000414185 6.28729V6.66849C-0.000414185 7.05347 0.324025 7.17108 0.724085 7.17183L4.98365 7.17982V11.2933C4.98365 11.6783 5.10823 11.9907 5.50829 11.9915L5.90927 11.9922C6.30933 11.993 6.4339 11.681 6.4339 11.296Z"
									fill="url(#paint0_linear_430_6205)"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_430_6205"
										x1="11.418"
										y1="6.06648"
										x2="6.14298"
										y2="12.2052"
										gradientUnits="userSpaceOnUse"
									>
										<stop stopColor="#07DD92" />
										<stop offset="1" stopColor="#06B9F0" />
									</linearGradient>
								</defs>
							</svg>
							Bulk Upload
						</button>
						<div className="vg_save_button_modal">
							<button
								onClick={() => setAddData(true)}
								style={{ width: "unset" }}
							>
								Single Upload
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default VehicleRegModal;
