import "./AccountDeleteRequests.css";
import { Table } from "@mantine/core";
import { useSelector } from "react-redux";
import moment from "moment";
import api from "../../api";
import { toast } from "react-toastify";

const AccountDeleteRequests = ({ close }) => {
	// redux
	const requests = useSelector((state) => state.driver?.dltReqs?.data);

	const handleSubmitButton = (id, status) => {
		if (status) {
			api.get(`/user/delete/${id}/${status}`)
				.then((response) => {
					if (status == "accept") toast.success("Driver Deleted");
					else toast.success("Request Rejected");
					close();
				})
				.catch((error) => {
					toast.error(error?.response?.data?.error);
					close();
				});
		}
	};

	const sortedReqs = [...requests].sort((a, b) => {
		if (a?.user?.name.toLowerCase() < b?.user?.name.toLowerCase()) {
			return -1;
		}
		if (a?.user?.name.toLowerCase() > b?.user?.name.toLowerCase()) {
			return 1;
		}
		return 0;
	});

	const rows = sortedReqs?.map((request, id) => (
		<tr key={id}>
			<td style={{ paddingLeft: "32px" }} className="first">
				{request?.user?.name}
			</td>
			<td>{moment(request?.reqTime).format("D/M/y")}</td>
			<td>
				<svg
					style={{ cursor: "pointer" }}
					xmlns="http://www.w3.org/2000/svg"
					width="22"
					viewBox="0 0 27 27"
					fill="none"
					onClick={() => {
						handleSubmitButton(request?._id, "reject");
					}}
				>
					<path
						d="M14.5826 14L19 18.4238L18.4238 19L14 14.5826L9.57618 19L9 18.4238L13.4174 14L9 9.57618L9.57618 9L14 13.4174L18.4238 9L19 9.57618L14.5826 14Z"
						fill="white"
					/>
					<circle cx="13.5" cy="13.5" r="13" stroke="white" />
				</svg>
			</td>
			<td>
				<svg
					style={{ cursor: "pointer" }}
					xmlns="http://www.w3.org/2000/svg"
					width="22"
					viewBox="0 0 27 27"
					fill="none"
					onClick={() => {
						handleSubmitButton(request?._id, "accept");
					}}
				>
					<circle cx="13.5" cy="13.5" r="13" stroke="#FF4040" />
					<path
						d="M10.0625 20C9.68438 20 9.36056 19.8584 9.09106 19.5753C8.82156 19.2922 8.68704 18.9523 8.6875 18.5556V9.16667H8V7.72222H11.4375V7H15.5625V7.72222H19V9.16667H18.3125V18.5556C18.3125 18.9528 18.1778 19.2929 17.9083 19.5761C17.6388 19.8592 17.3152 20.0005 16.9375 20H10.0625ZM16.9375 9.16667H10.0625V18.5556H16.9375V9.16667ZM11.4375 17.1111H12.8125V10.6111H11.4375V17.1111ZM14.1875 17.1111H15.5625V10.6111H14.1875V17.1111Z"
						fill="#FF3B3B"
					/>
				</svg>
			</td>
			<td>
				<a
					style={{
						cursor: "pointer",
						textAlign: "center",
						color: "#06B9F0",
						fontSize: 13,
						fontFamily: "Inter",
						fontWeight: "500",
						wordWrap: "break-word",
						textDecoration: "none",
					}}
					href={"/dashboard/driverdetails/" + request?.user?._id}
					target="_blank"
				>
					View Profile
				</a>
			</td>
		</tr>
	));

	return (
		<div>
			<div className="present_modal_header">
				<div>
					Account Delete Requests
					<span
						style={{
							color: "#FF4040",
							fontSize: 16,
							fontFamily: "Roboto",
							fontWeight: "500",
							wordWrap: "break-word",
							marginLeft: 19,
						}}
					>
						({requests.length})
					</span>
				</div>
				<svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>
			<div className="holiday_list_table">
				<div className="table_content">
					<Table
						verticalSpacing="xs"
						horizontalSpacing="lg"
						className="vehicle_table"
					>
						<thead className="vehicle_thead">
							<tr>
								<th
									style={{
										background: "#000",
										paddingLeft: "32px",
									}}
									className="first"
								>
									Driver Name
								</th>
								<th style={{ background: "#000" }}>Sent On</th>
								<th style={{ background: "#000" }}>
									Cancel Request
								</th>
								<th style={{ background: "#000" }}>
									Delete Account
								</th>
								<th
									style={{
										paddingRight: "32px",
										background: "#000",
									}}
								>
									Profile
								</th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default AccountDeleteRequests;
