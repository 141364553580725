import { call, put, select, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
  createClient,
  createClientFulfilled,
  createClientRejected,
  deleteClient,
  deleteClientFulfilled,
  deleteClientRejected,
  getClients,
  getClientsFulfilled,
  getClientsRejected,
  updateClient,
  updateClientFulfilled,
  updateClientRejected,
} from "../reducers/clientReducer";
import { getErrorMessage } from "../../utils/functions";

function* createClientSaga(action) {
  try {
    const client = yield select((state) => state.client);
    const formData = action.payload?.data || client?.formData;
    const res = yield call(api.post, "/client", formData);
    console.log("createClientSaga", res?.data);
    if (createClient.prototype.onSuccess) {
      createClient.prototype.onSuccess();
    }
    yield put(createClientFulfilled(res?.data));
  } catch (err) {
    if (createClient.prototype.onReject) {
      createClient.prototype.onReject();
    }
    yield put(createClientRejected(getErrorMessage(err)));
  }
}

function* getClientsSaga() {
  try {
    const res = yield call(api.get, "/client");
    if (getClients.prototype.onSuccess) {
      getClients.prototype.onSuccess();
    }
    yield put(getClientsFulfilled(res?.data));
  } catch (err) {
    if (getClients.prototype.onReject) {
      getClients.prototype.onReject();
    }
    yield put(getClientsRejected(getErrorMessage(err)));
  }
}

function* updateClientSaga(action) {
  try {
    const client = yield select((state) => state.Client);
    const id = action.payload?.id || client?.id;
    const data = action.payload?.data || client?.formData;
    const res = yield call(api.patch, `/client/${id}`, data);
    // console.log("updateClientSaga", res?.data);
    if (updateClient.prototype.onSuccess) {
      updateClient.prototype.onSuccess();
    }
    yield put(updateClientFulfilled(res?.data));
  } catch (err) {
    if (updateClient.prototype.onReject) {
      updateClient.prototype.onReject();
    }
    yield put(updateClientRejected(getErrorMessage(err)));
  }
}

function* deleteClientSaga(action) {
  try {
    const id = action.payload?.id;
    const res = yield call(api.delete, `/client/${id}`);
    // console.log("updateClientSaga", res?.data);
    if (deleteClient.prototype.onSuccess) {
      deleteClient.prototype.onSuccess();
    }
    yield put(deleteClientFulfilled(res?.data));
  } catch (err) {
    if (deleteClient.prototype.onReject) {
      deleteClient.prototype.onReject();
    }
    yield put(deleteClientRejected(getErrorMessage(err)));
  }
}

export function* clientSaga() {
  yield takeLatest(createClient.type, createClientSaga);
  yield takeLatest(getClients.type, getClientsSaga);
  yield takeLatest(updateClient.type, updateClientSaga);
  yield takeLatest(deleteClient.type, deleteClientSaga);
}
