import { Modal, Table, TextInput } from "@mantine/core";
import "./SupervisorsTable.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import ApprovalList from "../../../../components/ApprovalList/ApprovalList";
import { useNavigate } from "react-router-dom";

const SupervisorsTable = () => {
	const [opened, { open, close }] = useDisclosure(false);
	// redux
	const supervisors = useSelector((state) => state.supervisor?.data);
	const pendingApprovalSupervisorsCount = useSelector(
		(state) =>
			state.supervisor?.pendingApprovalSupervisors?.data?.length || 0
	);
	const [search, setSearch] = useState("");
	const [filteredSupervisor, setFilteredSupervisor] = useState([]);
	useEffect(() => {
		if (supervisors?.length > 0) {
			setFilteredSupervisor(
				supervisors?.filter((s) =>
					search?.length
						? s?.name
								?.toLowerCase()
								?.includes(search?.toLowerCase())
						: true
				)
			);
		}
	}, [supervisors, search]);
	const navigate = useNavigate();

	const rows = filteredSupervisor
		.filter((a) => a.name?.length)
		.sort((a, b) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}
			return 0;
		})
		?.map((supervisor, id) => (
			<tr key={id}>
				<td
					style={{
						fontSize: "12px",
						padding: "5px",
					}}
				>
					{supervisor?.name}
				</td>

				<td style={{ fontSize: "12px", textAlign: "right" }}>
					<button
						onClick={() => {
							navigate(
								`/dashboard/supervisordetails/${supervisor?._id}`
							);
						}}
						className="holiday_btns_edit"
					>
						View Profile
					</button>
				</td>
			</tr>
		));

	return (
		<div
			style={{ display: "flex", flexDirection: "column", height: "100%" }}
		>
			<TextInput
				mt={15}
				styles={() => ({
					input: {
						padding: "10px",
						borderRadius: "10px",
						border: "1px solid #9DB2B8",
						boxShadow: "0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
					},
				})}
				placeholder="Search supervisor"
				rightSection={
					<svg width="22" height="20" viewBox="0 0 27 28" fill="none">
						<ellipse
							cx="10.3418"
							cy="10.5395"
							rx="8.34184"
							ry="8.65079"
							stroke="#222222"
							strokeWidth="3"
						/>
						<path
							d="M19.75 20.2969L25.3557 26.1102"
							stroke="#222222"
							strokeWidth="3"
							strokeLinecap="round"
						/>
					</svg>
				}
				onChange={(e) => {
					if (setSearch) setSearch(e?.target?.value);
				}}
			/>
			<div className="pending_approvals" onClick={open}>
				Pending Approvals
				<div className="approval_number_badge">
					{pendingApprovalSupervisorsCount}
				</div>
			</div>
			<Modal
				opened={opened}
				onClose={close}
				centered
				withCloseButton={false}
				size="xl"
				padding={0}
				radius={10}
				overlayProps={{
					blur: 5,
					color: "#fff",
					backgroundopacity: 1,
				}}
			>
				<ApprovalList close={close} defaultScreen="supervisor" />
			</Modal>
			<div className="supervisorTable">
				<Table verticalSpacing={"sm"}>
					<tbody>{rows}</tbody>
				</Table>
			</div>
		</div>
	);
};

export default SupervisorsTable;
