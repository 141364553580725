import { useSelector } from "react-redux";
import { Select } from "@mantine/core";
import "./RecruiterDeleteModal.css";
import { useState } from "react";
import { toast } from "react-toastify";

const RecruiterDeleteModal = ({ close, onClickDelete, id }) => {
	const recruiters = useSelector((state) => state.recruiter?.data);
	const drivers = useSelector((state) => state.driver.drivers.data);
	const hasDrivers = drivers
		? drivers.some((driver) => driver?.recruiter?._id === id)
		: true;
    // console.log("id: " ,id )
    // console.log("hasDrivers " ,hasDrivers )
	const [recruiter, selectRecruiter] = useState(null);
	const selectRecruiterData = [];
	recruiters
		?.filter((a) => a?._id != id)
		.map((recruiter) =>
			selectRecruiterData.push({
				value: recruiter?._id,
				label: recruiter?.name,
			})
		);
	const onClickDeleteHandler = () => {
		if (hasDrivers && !recruiter) {
			toast.error("Select Recruiter");
			return;
		}
		onClickDelete(id, recruiter, hasDrivers);
    

	};

	return (
		<div className="delete_modal_recruiter">
			<div className="present_modal_header">
				<div>Delete Recruiter</div>
				<svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>

			{hasDrivers ? (
				<div className="migrate_box">
					<h2>Migrate all the drivers before deleting Recruiter.</h2>
					<label>Select Recruiter</label>
					<Select
						w={150}
						size="xs"
						styles={{
							rightSection: { pointerEvents: "none" },
							input: {
								borderRadius: "10px",
								fontSize: "12px",
								background: "#0E2227",
								border: "1px solid #8399A3",
								color: "#fff",
							},
						}}
						rightSection={
							<svg
								width="8"
								height="8"
								viewBox="0 0 13 8"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
									fill="#ffffff"
								/>
							</svg>
						}
						placeholder="Choose Recruiter"
						data={selectRecruiterData}
						onChange={selectRecruiter}
					/>
					<div className="delete_modal_buttons">
						<button className="close_button3" onClick={close}>
							Close
						</button>
						<button
							className="migrate_button"
							onClick={onClickDeleteHandler}
						>
							Migrate
						</button>
					</div>
				</div>
			) : (
				<div className="migrate_box">
					<h2>Are you sure you want to delete this recruiter?</h2>
					<div
						className="status_btn_group"
						style={{ paddingBottom: "20px" }}
					>
						<button className="no_button" onClick={close}>
							No
						</button>
						<button
							className="yes_button"
							onClick={onClickDeleteHandler}
						>
							Yes
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default RecruiterDeleteModal;


