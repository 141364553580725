import { Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import "./UploadedDocuments.css";
import DocumentVerifyModal from "../../../../components/DocumentVerifyModal/DocumentVerifyModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ApprovalStatus } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { deleteDriver } from "../../../../redux/reducers/driverReducer";

const UploadedDocuments = ({ setActiveTab }) => {
	const navigate = useNavigate();
	const [opened, { open, close }] = useDisclosure(false);
	const [image, setImage] = useState({});
	const [imageType, setimageType] = useState("");
	const [disbale, setDisable] = useState(false);
	const [btnclose, setBtnClose] = useState(false);
	const dispatch = useDispatch();

	const driverFormData = useSelector(
		(state) => state?.driver?.fetchedDriver?.data
	);

	const checkApprovals = () => {
		if (
			driverFormData?.approval?.aadhaarCardFront !=
				ApprovalStatus.Rejected &&
			driverFormData?.approval?.aadhaarCardBack !=
				ApprovalStatus.Rejected &&
			driverFormData?.approval?.drivingLicense !=
				ApprovalStatus.Rejected &&
			driverFormData?.approval?.panCard != ApprovalStatus.Rejected &&
			driverFormData?.approval?.aadhaarCardFront ==
				ApprovalStatus.Approved &&
			driverFormData?.approval?.aadhaarCardBack ==
				ApprovalStatus.Approved &&
			driverFormData?.approval?.drivingLicense ==
				ApprovalStatus.Approved &&
			(driverFormData?.panCard?.length
				? driverFormData?.approval?.panCard === ApprovalStatus.Approved
				: true)
		) {
			setActiveTab(1);
		} else {
			alert("All documents must be approved");
		}
	};
	useEffect(() => {
		if (
			driverFormData?.approval?.aadhaarCardFront !=
				ApprovalStatus.Rejected &&
			driverFormData?.approval?.aadhaarCardBack !=
				ApprovalStatus.Rejected &&
			driverFormData?.approval?.drivingLicense !=
				ApprovalStatus.Rejected &&
			driverFormData?.approval?.panCard != ApprovalStatus.Rejected &&
			driverFormData?.approval?.aadhaarCardFront ==
				ApprovalStatus.Approved &&
			driverFormData?.approval?.aadhaarCardBack ==
				ApprovalStatus.Approved &&
			driverFormData?.approval?.drivingLicense ==
				ApprovalStatus.Approved &&
			(driverFormData?.panCard?.length
				? driverFormData?.approval?.panCard === ApprovalStatus.Approved
				: true)
		) {
			setDisable(false);
		} else if (
			driverFormData?.approval?.aadhaarCardFront ==
				ApprovalStatus.Rejected ||
			driverFormData?.approval?.aadhaarCardBack ==
				ApprovalStatus.Rejected ||
			driverFormData?.approval?.drivingLicense ==
				ApprovalStatus.Rejected ||
			driverFormData?.approval?.panCard == ApprovalStatus.Rejected
		) {
			setBtnClose(true);
			setDisable(true);
		} else {
			setDisable(true);
		}
	}, [driverFormData]);
	return (
		<div className="tab_contents">
			{/* <h3 className="upload_doc_header">Uploaded Documents</h3> */}
			<div className="document_image_container">
				{driverFormData?.aadhaarCardFront ? (
					<div style={{ marginRight: "20px" }}>
						<h3>
							{driverFormData?.approval.aadhaarCardFront ==
							ApprovalStatus.Approved ? (
								<span className="approved_tag">
									<svg
										width="14"
										viewBox="0 0 16 16"
										fill="none"
									>
										<rect
											width="16"
											height="16"
											rx="8"
											fill="#12B466"
										/>
										<path
											d="M4.26673 9.42998L6.60007 11.734L11.7334 5.33398"
											stroke="white"
											strokeWidth="1.70667"
											strokeLinecap="round"
										/>
									</svg>
									Approved
								</span>
							) : driverFormData?.approval.aadhaarCardFront ==
							  ApprovalStatus.Pending ? (
								<span className="pending_tag">
									<svg
										width="14"
										viewBox="0 0 15 15"
										fill="none"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#FEA826"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
									Pending
								</span>
							) : driverFormData?.approval.aadhaarCardFront ==
							  ApprovalStatus.Rejected ? (
								<span className="rejected_tag">
									<svg
										width="14"
										viewBox="0 0 15 15"
										fill="none"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#F97373"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
									Rejected
								</span>
							) : null}
						</h3>
						<div
							className="document_box"
							onClick={() => {
								open();
								setImage(driverFormData?.aadhaarCardFront);
								setimageType("aadhaarCardFront");
							}}
						>
							<img
								src={driverFormData?.aadhaarCardFront}
								alt="document_image"
							/>
							<div className="overlay">
								<p>View</p>
							</div>
						</div>
						<h6>Aadhaar Card (Front)</h6>
					</div>
				) : (
					"No Aadhaar Card (Front)!"
				)}
				{driverFormData?.aadhaarCardBack ? (
					<div style={{ marginRight: "20px" }}>
						<h3>
							{driverFormData?.approval.aadhaarCardBack ==
							ApprovalStatus.Approved ? (
								<span className="approved_tag">
									<svg
										width="14"
										viewBox="0 0 16 16"
										fill="none"
									>
										<rect
											width="16"
											height="16"
											rx="8"
											fill="#12B466"
										/>
										<path
											d="M4.26673 9.42998L6.60007 11.734L11.7334 5.33398"
											stroke="white"
											strokeWidth="1.70667"
											strokeLinecap="round"
										/>
									</svg>
									Approved
								</span>
							) : driverFormData?.approval.aadhaarCardBack ==
							  ApprovalStatus.Pending ? (
								<span className="pending_tag">
									<svg
										width="14"
										viewBox="0 0 15 15"
										fill="none"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#FEA826"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
									Pending
								</span>
							) : driverFormData?.approval.aadhaarCardBack ==
							  ApprovalStatus.Rejected ? (
								<span className="rejected_tag">
									<svg
										width="14"
										viewBox="0 0 15 15"
										fill="none"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#F97373"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
									Rejected
								</span>
							) : null}
						</h3>
						<div
							className="document_box"
							onClick={() => {
								open();
								setImage(driverFormData?.aadhaarCardBack);
								setimageType("aadhaarCardBack");
							}}
						>
							<img
								src={driverFormData?.aadhaarCardBack}
								alt="document_image"
							/>
							<div className="overlay">
								<p>View</p>
							</div>
						</div>
						<h6>Aadhaar Card (Back)</h6>
					</div>
				) : (
					"No Aadhaar Card (Back)!"
				)}
				{driverFormData?.drivingLicense ? (
					<div style={{ marginRight: "20px" }}>
						<h3>
							{driverFormData?.approval.drivingLicense ==
							ApprovalStatus.Approved ? (
								<span className="approved_tag">
									<svg
										width="14"
										viewBox="0 0 16 16"
										fill="none"
									>
										<rect
											width="16"
											height="16"
											rx="8"
											fill="#12B466"
										/>
										<path
											d="M4.26673 9.42998L6.60007 11.734L11.7334 5.33398"
											stroke="white"
											strokeWidth="1.70667"
											strokeLinecap="round"
										/>
									</svg>
									Approved
								</span>
							) : driverFormData?.approval.drivingLicense ==
							  ApprovalStatus.Pending ? (
								<span className="pending_tag">
									<svg
										width="14"
										viewBox="0 0 15 15"
										fill="none"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#FEA826"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
									Pending
								</span>
							) : driverFormData?.approval.drivingLicense ==
							  ApprovalStatus.Rejected ? (
								<span className="rejected_tag">
									<svg
										width="14"
										viewBox="0 0 15 15"
										fill="none"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#F97373"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
									Rejected
								</span>
							) : null}
						</h3>
						<div
							className="document_box"
							onClick={() => {
								open();
								setImage(driverFormData?.drivingLicense);
								setimageType("drivingLicense");
							}}
						>
							<img
								src={driverFormData?.drivingLicense}
								alt="document_image"
							/>
							<div className="overlay">
								<p>View</p>
							</div>
						</div>
						<h6>Driving License</h6>
					</div>
				) : (
					"No Driving License!"
				)}
				{driverFormData?.panCard ? (
					<div>
						<h3>
							{driverFormData?.approval.panCard ==
							ApprovalStatus.Approved ? (
								<span className="approved_tag">
									<svg
										width="14"
										viewBox="0 0 16 16"
										fill="none"
									>
										<rect
											width="16"
											height="16"
											rx="8"
											fill="#12B466"
										/>
										<path
											d="M4.26673 9.42998L6.60007 11.734L11.7334 5.33398"
											stroke="white"
											strokeWidth="1.70667"
											strokeLinecap="round"
										/>
									</svg>
									Approved
								</span>
							) : driverFormData?.approval.panCard ==
							  ApprovalStatus.Pending ? (
								<span className="pending_tag">
									<svg
										width="14"
										viewBox="0 0 15 15"
										fill="none"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#FEA826"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
									Pending
								</span>
							) : driverFormData?.approval.panCard ==
							  ApprovalStatus.Rejected ? (
								<span className="rejected_tag">
									<svg
										width="14"
										viewBox="0 0 15 15"
										fill="none"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#F97373"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
									Rejected
								</span>
							) : null}
						</h3>
						<div
							className="document_box"
							onClick={() => {
								open();
								setImage(driverFormData?.panCard);
								setimageType("panCard");
							}}
						>
							<img
								src={driverFormData?.panCard}
								alt="document_image"
							/>
							<div className="overlay">
								<p>View</p>
							</div>
						</div>
						<h6>Pan Card</h6>
					</div>
				) : null}
			</div>

			<div className="stepper_btn_grp3 fl-row">
				<div>
					<span
						className="reject_button"
						onClick={() => {
							dispatch(
								deleteDriver({
									id: driverFormData._id,
								})
							);
							deleteDriver.prototype.onSuccess = () => {
								navigate("/dashboard/monitor");
							};
						}}
					>
						Reject Application
					</span>
				</div>
				<div>
					{btnclose && (
						<span
							className="close_button2"
							onClick={() => navigate("/dashboard/monitor")}
						>
							Go Back
						</span>
					)}
					<Tooltip
						withArrow
						disabled={!disbale}
						multiline={true}
						label="Approve all documents first"
					>
						<button
							disabled={disbale}
							className="next_button"
							onClick={checkApprovals}
						>
							Next
						</button>
					</Tooltip>
				</div>
			</div>

			<Modal
				opened={opened}
				onClose={close}
				centered
				withCloseButton={false}
				size="lg"
				padding={0}
				radius={10}
			>
				<DocumentVerifyModal
					close={close}
					image={image}
					driverFormData={driverFormData}
					imageType={imageType}
				/>
			</Modal>
		</div>
	);
};

export default UploadedDocuments;
