import { Divider, Grid, Select } from "@mantine/core";
import "./PresentModal.css";
import image from "../../assets/images/Group 68.png";
import { updateAttendanceByDriverId } from "../../redux/reducers/attendanceReducer";
import {
	getFormattedDate,
	getFormattedTime,
	isToday,
} from "../../utils/functions";
import { useDispatch } from "react-redux";
import moment from "moment";
const PresentModal = ({ driver, close, value }) => {
	const PRESENT_STATUS = value == "late" ? "late" : "present";
	const dispatch = useDispatch();

	const updateAttendence = (attendence) => {
		if (attendence !== null) {
			updateAttendanceByDriverId.prototype.onSuccess = () => {
				close();
			};
			dispatch(
				updateAttendanceByDriverId({
					id: driver?.attendance?._id,
					data: {
						user: driver?._id,
						checkIn:
							attendence == PRESENT_STATUS
								? getFormattedTime()
								: null,
						date: getFormattedDate(moment()),
						hub: driver?.hub?._id,
						status: attendence,
						mode: "online",
						shift: driver?.shift?._id,
					},
				})
			);
		}
	};
	const TimeDifference = (driver) => {
		const checkIn = driver?.attendance?.checkIn;
		const shiftStart = driver?.shift?.startTime;
		if (!checkIn || !shiftStart) return "";
		const checkInMoment = moment(checkIn, "HH:mm");
		const shiftTime = moment(shiftStart, "HH:mm");

		let diffInMinutes = checkInMoment.diff(shiftTime, "minutes");

		const convertToHrMin = (minutes) => {
			const hrs = Math.floor(minutes / 60);
			const mins = minutes % 60;
			return `${hrs} hr ${mins} min`;
		};

		return diffInMinutes > 59
			? convertToHrMin(diffInMinutes)
			: `${diffInMinutes} min`;
	};
	return (
		<div className="PresentModal">
			<div className="present_modal_header">
				<div>Attendence</div>
				<svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>
			<Grid m={0} bg={"#0E2227"}>
				{isToday(driver?.attendance?.date || null) &&
					driver?.attendance?.picture?.length && (
						<Grid.Col span={6} p={0}>
							<img
								src={driver?.attendance?.picture || image}
								alt="present_modal_image"
								className="present_modal_image"
							/>
						</Grid.Col>
					)}
				<Grid.Col
					span={
						isToday(driver?.attendance?.date || null) &&
						driver?.attendance?.picture?.length
							? 6
							: 12
					}
					p={0}
				>
					<div
						className="present_modal_content"
						style={{ marginTop: "20px" }}
					>
						<div>
							<div style={{ marginRight: "20px" }}>
								<h1>Name</h1>
								<h2>{driver?.name}</h2>
							</div>
							<div>
								<h1>ID Number</h1>
								<h2>{driver?.username}</h2>
							</div>
						</div>
						<div>
							<div style={{ marginRight: "20px" }}>
								<h1>Supervisor</h1>
								<h2>{driver?.supervisor?.name}</h2>
							</div>
							<div>
								<h1>Hub</h1>
								<h2>{driver?.hub?.name}</h2>
							</div>
						</div>
					</div>
					<Divider color="#264147" />
					<div
						className="present_modal_content"
						style={{ marginTop: "20px" }}
					>
						<div style={{ marginRight: "10px" }}>
							<h1>Mode</h1>
							<h2>{driver?.attendance?.mode}</h2>
						</div>
						<div>
							<h1>Shift</h1>
							<h2>{`${driver?.shift?.startTime} to ${driver?.shift?.endTime}`}</h2>
						</div>
					</div>
					{value == "late" && driver?.attendance?.checkIn && (
						<div className="late_text">
							<svg
								width="14"
								height="14"
								viewBox="0 0 14 14"
								fill="none"
							>
								<circle
									cx="7"
									cy="7"
									r="6.36364"
									fill="#0E2227"
									stroke="#F97373"
									strokeWidth="1.27273"
								/>
								<path
									d="M7 7L10.1818 8.90909"
									stroke="#F97373"
									strokeWidth="1.27273"
									strokeLinecap="round"
								/>
								<path
									d="M7 3.91406L7 6.65985"
									stroke="#F97373"
									strokeWidth="1.27273"
									strokeLinecap="round"
								/>
							</svg>
							Attendance late by {TimeDifference(driver)}
						</div>
					)}
					<div className="present_modal_select">
						<Select
							value={value}
							onChange={updateAttendence}
							size="xs"
							defaultValue="absent"
							styles={{
								input: {
									color:
										value == PRESENT_STATUS
											? "#fff"
											: // : value == "late"
											  // ? "#fff"
											  "#000",
									border: "none",
									borderRadius: "10px",
									fontSize: "12px",
									background:
										value == PRESENT_STATUS
											? PRESENT_STATUS == "present"
												? "#03AB70"
												: "#029AC9"
											: // : value == "late"
											  // ? "#029AC9"
											  "#fff",
								},
							}}
							rightSection={
								<svg
									width="8"
									height="8"
									viewBox="0 0 13 8"
									fill="none"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
										fill={
											value == PRESENT_STATUS
												? "#fff"
												: // : value == "late"
												  // ? "#fff"
												  "#000"
										}
									/>
								</svg>
							}
							placeholder="Absent"
							data={[
								...[
									{
										value: PRESENT_STATUS,
										label:
											PRESENT_STATUS == "late"
												? "Late"
												: "Present",
									},
									{ value: "absent", label: "Absent" },

									// { value: "late", label: "Late" },
								],
								...[
									value == "unmarked"
										? {
												value: "unmarked",
												label: "Unmarked",
												disabled: true,
										  }
										: null,
								],
							].filter((a) => a != null)}
						/>
					</div>
				</Grid.Col>
			</Grid>
		</div>
	);
};

export default PresentModal;
