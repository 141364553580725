import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const shiftReducer = createSlice({
  name: "shift",
  initialState,
  reducers: {
    createShift: (state) => {
      state.loading = true;
      state.error = null;
    },
    createShiftFulfilled: (state) => {
      state.loading = false;
    },
    createShiftRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getShifts: (state) => {
      state.loading = true;
      state.error = null;
    },
    getShiftsFulfilled: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) state.data = action.payload;
    },
    getShiftsRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateShift: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateShiftFulfilled: (state) => {
      state.loading = false;
    },
    updateShiftRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteShift: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteShiftFulfilled: (state) => {
      state.loading = false;
    },
    deleteShiftRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createShift,
  createShiftFulfilled,
  createShiftRejected,
  getShifts,
  getShiftsFulfilled,
  getShiftsRejected,
  updateShift,
  updateShiftFulfilled,
  updateShiftRejected,
  deleteShift,
  deleteShiftFulfilled,
  deleteShiftRejected,
} = shiftReducer.actions;

export default shiftReducer.reducer;
