// vehicleSaga.js

import { call, put, takeLatest } from "redux-saga/effects";
import {
	fetchAllVehicles,
	fetchAllVehiclesFulfilled,
	fetchAllVehiclesRejected,
	createBulkVehicles,
	createBulkVehiclesFulfilled,
	createBulkVehiclesRejected,
	createVehicle,
	createVehicleFulfilled,
	createVehicleRejected,
	updateVehicle,
	updateVehicleFulfilled,
	updateVehicleRejected,
	deleteVehicle,
	deleteVehicleFulfilled,
	deleteVehicleRejected,
} from "../reducers/vehicleReducer";
import { getErrorMessage } from "../../utils/functions";
import api from "../../api";
import { VehicleFileNameKey } from "../../utils/constants";

function* fetchAllVehiclesSaga(action) {
	try {
		const res = yield call(api.get, "/vehicle");
		const vehicles = res?.data;

		if (vehicles) {
			if (fetchAllVehicles.prototype.onSuccess) {
				fetchAllVehicles.prototype.onSuccess();
			}
			yield put(fetchAllVehiclesFulfilled(vehicles));
		}
	} catch (error) {
		if (fetchAllVehicles.prototype.onError) {
			fetchAllVehicles.prototype.onError(error);
		}
		yield put(fetchAllVehiclesRejected(getErrorMessage(error)));
	}
}

function* createBulkVehiclesSaga(action) {
	try {
		const csvFile = action.payload?.csvFile;
		const formData = new FormData();
		formData.append("csvFile", csvFile);

		const res = yield call(api.put, "/vehicle/bulk-upload", formData);
		const vehicle = res?.data;

		if (createBulkVehicles.prototype.onSuccess) {
			createBulkVehicles.prototype.onSuccess();
		}
		yield put(createBulkVehiclesFulfilled(vehicle));
	} catch (error) {
		console.log(error);
		if (createBulkVehicles.prototype.onError) {
			createBulkVehicles.prototype.onError(error);
		}
		yield put(createBulkVehiclesRejected(getErrorMessage(error)));
	}
}

function* createVehicleSaga(action) {
	try {
		const vehicleNo = action.payload?.data?.vehicleNo;
		const rcImage = action.payload?.data?.rcImage;
		const insuranceFile = action.payload?.data?.insuranceFile;
		const vehicleType = action.payload?.data?.vehicleType;
		const formData = new FormData();
		formData.append("vehicle_no", vehicleNo);
		formData.append(VehicleFileNameKey.RCImage, rcImage);
		formData.append(VehicleFileNameKey.InsuranceFile, insuranceFile);
		formData.append("vehicle_type", vehicleType);

		const res = yield call(api.post, "/vehicle", formData);
		const vehicle = res?.data;

		if (createVehicle.prototype.onSuccess) {
			createVehicle.prototype.onSuccess();
		}
		yield put(createVehicleFulfilled(vehicle));
	} catch (error) {
		console.log(error);
		if (createVehicle.prototype.onError) {
			createVehicle.prototype.onError(error);
		}
		yield put(createVehicleRejected(getErrorMessage(error)));
	}
}

function* updateVehicleSaga(action) {
	try {
		const id = action.payload?.id;
		const vehicleNo = action.payload?.data?.vehicleNo;
		const rcImage = action.payload?.data?.rcImage;
		const insuranceFile = action.payload?.data?.insuranceFile;
		const vehicleType = action.payload?.data?.vehicleType;
		const formData = new FormData();
		formData.append("vehicle_no", vehicleNo);
		formData.append("vehicle_type", vehicleType);
		if (rcImage) formData.append(VehicleFileNameKey.RCImage, rcImage);
		if (insuranceFile)
			formData.append(VehicleFileNameKey.InsuranceFile, insuranceFile);

		const response = yield call(api.patch, `/vehicle/${id}`, formData);
		if (updateVehicle.prototype.onSuccess) {
			updateVehicle.prototype.onSuccess();
		}
		yield put(updateVehicleFulfilled(response.data));
	} catch (err) {
		if (updateVehicle.prototype.onError) {
			updateVehicle.prototype.onError();
		}
		yield put(updateVehicleRejected(getErrorMessage(err)));
	}
}

function* deleteVehicleSaga(action) {
	try {
		const id = action.payload?.id;
		const response = yield call(api.delete, `/vehicle/${id}`);
		if (deleteVehicle.prototype.onSuccess) {
			deleteVehicle.prototype.onSuccess();
		}
		yield put(deleteVehicleFulfilled(response?.data));
	} catch (error) {
		if (deleteVehicle.prototype.onError) {
			deleteVehicle.prototype.onError();
		}
		yield put(deleteVehicleRejected(getErrorMessage(error)));
	}
}

export function* vehicleSaga() {
	yield takeLatest(fetchAllVehicles.type, fetchAllVehiclesSaga);
	yield takeLatest(createBulkVehicles.type, createBulkVehiclesSaga);
	yield takeLatest(createVehicle.type, createVehicleSaga);
	yield takeLatest(updateVehicle.type, updateVehicleSaga);
	yield takeLatest(deleteVehicle.type, deleteVehicleSaga);
}
