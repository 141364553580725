import { Input, TextInput } from "@mantine/core";
import "./DriverDetailsForm.css";
import { useId } from "@mantine/hooks";
import { IMaskInput } from "react-imask";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { onChangeFormValue } from "../../../../utils/functions";
import {
	getDriverById,
	updateDriver,
} from "../../../../redux/reducers/driverReducer";
import { useParams } from "react-router-dom";
const DriverDetailsForm = ({ setActiveTab }) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [formValue, setFormValue] = useState({});
	const driverFormData = useSelector(
		(state) => state?.driver?.fetchedDriver?.data
	);

	useEffect(() => {
		setFormValue(driverFormData);
	}, [driverFormData]);
	updateDriver.prototype.onSuccess = () => {
		dispatch(getDriverById({ id: id }));
	};
	getDriverById.prototype.onSuccess = () => {
		setActiveTab(2);
	};

	const onClickNext = () => {
		if (formValue.name && formValue.phone) {
			dispatch(
				updateDriver({
					id: driverFormData._id,
					data: {
						email: formValue.email,
					},
				})
			);
		}
	};

	return (
		<div className="tab_contents">
			<div className="DriverDetailsForm_box">
				<div className="DriverDetailsForm_row">
					<div style={{ paddingTop: "5px" }}>Name</div>
					<div>Email Address</div>
					<div style={{ paddingBottom: "5px" }}>Phone Number</div>
					{/* <div>State,City</div> */}
				</div>
				<div className="DriverDetailsForm_row">
					<div style={{ display: "flex" }}>
						<TextInput
							value={formValue?.name}
							w={200}
							readOnly
							mr={25}
							placeholder="Your name"
							radius="md"
							size="sm"
							name="name"
							// onChange={(e) =>
							//   onChangeFormValue("name", e.target.value, setFormValue)
							// }
						/>
					</div>
					<div>
						<TextInput
							value={formValue?.email}
							name="email"
							onChange={(e) =>
								onChangeFormValue(
									"email",
									e.target.value,
									setFormValue
								)
							}
							w={250}
							placeholder="Your Email"
							radius="md"
							size="sm"
						/>
					</div>
					<div>
						<Input
							value={`+91${formValue?.phone?.toString()}`}
							name="phone"
							readOnly
							radius="md"
							w={220}
							size="sm"
							component={IMaskInput}
							mask="+91 0000000000"
							id={useId()}
							placeholder="Your phone"
						/>
					</div>
				</div>
			</div>
			<div className="stepper_btn_grp">
				<button className="prev_btn" onClick={() => setActiveTab(0)}>
					Previous
				</button>
				<button className="black_button" onClick={onClickNext}>
					Next
				</button>
			</div>
		</div>
	);
};

export default DriverDetailsForm;
