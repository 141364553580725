import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  id: null,
  formData: {
    startDate: undefined,
    endDate: undefined,
    disbursementDate: undefined,
  },
};

const payoutCycleReducer = createSlice({
  name: "payoutCycle",
  initialState,
  reducers: {
    setPayoutCycleId: (state, action) => {
      if (typeof action.payload === "string") {
        state.id = action.payload;
      }
    },
    setPayoutCycleFormData: (state, action) => {
      if (typeof action.payload === "object") {
        Object.assign(state.formData, action.payload);
      }
    },
    createPayoutCycle: (state) => {
      state.loading = true;
      state.error = null;
    },
    createPayoutCycleFulfilled: (state) => {
      state.loading = false;
      state.formData = initialState.formData;
    },
    createPayoutCycleRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPayoutCycles: (state) => {
      state.loading = true;
      state.error = null;
    },
    getPayoutCyclesFulfilled: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) state.data = action.payload;
    },
    getPayoutCyclesRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updatePayoutCycle: (state) => {
      state.loading = true;
      state.error = null;
    },
    updatePayoutCycleFulfilled: (state) => {
      state.loading = false;
      state.id = initialState.id;
      state.formData = initialState.formData;
    },
    updatePayoutCycleRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deletePayoutCycle: (state) => {
      state.loading = true;
      state.error = null;
    },
    deletePayoutCycleFulfilled: (state) => {
      state.loading = false;
    },
    deletePayoutCycleRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setPayoutCycleId,
  setPayoutCycleFormData,
  createPayoutCycle,
  createPayoutCycleFulfilled,
  createPayoutCycleRejected,
  getPayoutCycles,
  getPayoutCyclesFulfilled,
  getPayoutCyclesRejected,
  updatePayoutCycle,
  updatePayoutCycleFulfilled,
  updatePayoutCycleRejected,
  deletePayoutCycle,
  deletePayoutCycleFulfilled,
  deletePayoutCycleRejected,
} = payoutCycleReducer.actions;

export default payoutCycleReducer.reducer;
