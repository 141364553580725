import { Navigate } from "react-router";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/functions";

export default function ProtectedRoute({ element }) {
  const isAuthenticated = useSelector((state) => state.user?.isAuthenticated);

  return isAuthenticated ? element : <Navigate to="/login" replace />;
}
