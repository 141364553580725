import { useEffect, useState } from "react";
import "./ShiftTimingsModal.css";
import { Box, Divider, Flex, Select, Table } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import {
	createShift,
	deleteShift,
	updateShift,
} from "../../redux/reducers/shiftReducer";
import moment from "moment";
const hourdata = [
	{ value: "00", label: "00" },
	{ value: "01", label: "01" },
	{ value: "02", label: "02" },
	{ value: "03", label: "03" },
	{ value: "04", label: "04" },
	{ value: "05", label: "05" },
	{ value: "06", label: "06" },
	{ value: "07", label: "07" },
	{ value: "08", label: "08" },
	{ value: "09", label: "09" },
	{ value: "10", label: "10" },
	{ value: "11", label: "11" },
	{ value: "12", label: "12" },
	{ value: "13", label: "13" },
	{ value: "14", label: "14" },
	{ value: "15", label: "15" },
	{ value: "16", label: "16" },
	{ value: "17", label: "17" },
	{ value: "18", label: "18" },
	{ value: "19", label: "19" },
	{ value: "20", label: "20" },
	{ value: "21", label: "21" },
	{ value: "22", label: "22" },
	{ value: "23", label: "23" },
];
const minutedata = [
	{ value: "00", label: "00" },
	{ value: "01", label: "01" },
	{ value: "02", label: "02" },
	{ value: "03", label: "03" },
	{ value: "04", label: "04" },
	{ value: "05", label: "05" },
	{ value: "06", label: "06" },
	{ value: "07", label: "07" },
	{ value: "08", label: "08" },
	{ value: "09", label: "09" },
	{ value: "10", label: "10" },
	{ value: "11", label: "11" },
	{ value: "12", label: "12" },
	{ value: "13", label: "13" },
	{ value: "14", label: "14" },
	{ value: "15", label: "15" },
	{ value: "16", label: "16" },
	{ value: "17", label: "17" },
	{ value: "18", label: "18" },
	{ value: "19", label: "19" },
	{ value: "20", label: "20" },
	{ value: "21", label: "21" },
	{ value: "22", label: "22" },
	{ value: "23", label: "23" },
	{ value: "24", label: "24" },
	{ value: "25", label: "25" },
	{ value: "26", label: "26" },
	{ value: "27", label: "27" },
	{ value: "28", label: "28" },
	{ value: "29", label: "29" },
	{ value: "30", label: "30" },
	{ value: "31", label: "31" },
	{ value: "32", label: "32" },
	{ value: "33", label: "33" },
	{ value: "34", label: "34" },
	{ value: "35", label: "35" },
	{ value: "36", label: "36" },
	{ value: "37", label: "37" },
	{ value: "38", label: "38" },
	{ value: "39", label: "39" },
	{ value: "40", label: "40" },
	{ value: "41", label: "41" },
	{ value: "42", label: "42" },
	{ value: "43", label: "43" },
	{ value: "44", label: "44" },
	{ value: "45", label: "45" },
	{ value: "46", label: "46" },
	{ value: "47", label: "47" },
	{ value: "48", label: "48" },
	{ value: "49", label: "49" },
	{ value: "50", label: "50" },
	{ value: "51", label: "51" },
	{ value: "52", label: "52" },
	{ value: "53", label: "53" },
	{ value: "54", label: "54" },
	{ value: "55", label: "55" },
	{ value: "56", label: "56" },
	{ value: "57", label: "57" },
	{ value: "58", label: "58" },
	{ value: "59", label: "59" },
];
const ShiftTimingsModal = ({ close }) => {
	const dispatch = useDispatch();
	const [addData, setAddData] = useState(false);
	const [editingIndex, seteditingIndex] = useState("");
	const [startTimeHours, setstartTimeHours] = useState("");
	const [startTimeMins, setstartTimeMins] = useState("00");
	const [endTimeHours, setendTimeHours] = useState("");
	const [endTimeMins, setendTimeMins] = useState("00");
	const [editingData, seteditingData] = useState(false);
	const [isStartSelected, setStartSelected] = useState(false);
	// redux
	const shifts = useSelector((state) => state?.shift?.data);

	const onClickDelete = (id) => {
		dispatch(deleteShift({ id: id }));
	};
	const onClickAdd = () => {
		dispatch(
			createShift({
				data: {
					startTime: `${startTimeHours}:${startTimeMins}`,
					endTime: `${endTimeHours}:${endTimeMins}`,
				},
			})
		);
		setAddData(false);
		seteditingIndex(null);
		seteditingData(false);
	};
	const onClickEdit = () => {
		dispatch(
			updateShift({
				id: editingIndex,
				data: {
					startTime: `${startTimeHours}:${startTimeMins}`,
					endTime: `${endTimeHours}:${endTimeMins}`,
				},
			})
		);
		setAddData(false);
		seteditingIndex(null);
		seteditingData(false);
	};

	useEffect(() => {
		const shift = shifts?.find((shift) => shift._id == editingIndex);
		if (shift) {
			const startHour = moment(shift.startTime, "HH:mm").format("HH");
			const startMinute = moment(shift.startTime, "HH:mm").format("mm");
			const endHour = moment(shift.endTime, "HH:mm").format("HH");
			const endMinute = moment(shift.endTime, "HH:mm").format("mm");

			setstartTimeHours(startHour);
			setstartTimeMins(startMinute);
			setendTimeHours(endHour);
			setendTimeMins(endMinute);
		}
	}, [editingIndex]);

	const generateOptions = (start, end) => {
		let options = [];
		for (let i = start; i <= end; i++) {
			let val = i.toString().padStart(2, "0");
			options.push({ value: val, label: val });
		}
		return options;
	};

	const minStart =
		parseInt(endTimeHours, 10) > parseInt(startTimeHours, 10)
			? 0
			: parseInt(startTimeMins, 10);

	const rows = shifts?.map((shift, id) => (
		<tr
			key={id}
			style={
				editingData && shift._id === editingIndex
					? { backgroundColor: "#1E424B" }
					: {}
			}
		>
			<td>From : {shift?.startTime}</td>
			<td>To : {shift?.endTime}</td>
			<td className="holiday_btns">
				<button
					className="holiday_btns_edit"
					onClick={() => {
						setAddData(true);
						seteditingData(true);
						seteditingIndex(shift._id);
					}}
					style={{ marginRight: "10px" }}
				>
					<svg width="13" height="12" viewBox="0 0 13 12" fill="none">
						<g clipPath="url(#clip0_485_4714)">
							<path
								d="M12.4931 4.09942L4.69474 11.6218L2.01016 11.9083C1.23211 11.9913 0.569875 11.3583 0.655996 10.602L0.952962 8.01244L8.7513 0.490039C9.43135 -0.165951 10.5301 -0.165951 11.2072 0.490039L12.4901 1.72754C13.1702 2.38353 13.1702 3.44629 12.4931 4.09942ZM9.55905 4.98457L7.83367 3.32025L2.31604 8.64551L2.09925 10.5161L4.03844 10.307L9.55905 4.98457ZM11.4834 2.7015L10.2005 1.464C10.0787 1.34655 9.87977 1.34655 9.76098 1.464L8.84336 2.34915L10.5687 4.01348L11.4864 3.12832C11.6051 3.00801 11.6051 2.81895 11.4834 2.7015Z"
								fill="#06B9F0"
							/>
						</g>
						<defs>
							<clipPath id="clip0_485_4714">
								<rect width="13" height="12" fill="white" />
							</clipPath>
						</defs>
					</svg>
					Edit
				</button>
				<button
					className="holiday_btns_delete"
					onClick={() => onClickDelete(shift._id)}
				>
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none">
						<circle
							cx="6"
							cy="6"
							r="5.5"
							fill="#0E2227"
							stroke="#F97373"
						/>
						<path
							d="M4.12561 8.37732L5.94222 6.56071L7.77607 8.40163C7.94831 8.57453 8.13966 8.66308 8.30968 8.49306L8.47802 8.32471C8.64804 8.15469 8.56003 7.9628 8.38779 7.7899L6.55394 5.94899L8.37055 4.13238C8.54056 3.96236 8.62481 3.77064 8.45257 3.59774L8.27994 3.42444C8.1077 3.25154 7.91619 3.33558 7.74617 3.5056L5.92956 5.32221L4.09571 3.48129C3.92347 3.30839 3.73212 3.21984 3.5621 3.38986L3.39376 3.55821C3.22374 3.72823 3.31175 3.92011 3.48398 4.09301L5.31784 5.93393L3.50123 7.75054C3.33122 7.92056 3.24697 8.11228 3.41921 8.28518L3.59184 8.45848C3.76407 8.63138 3.95559 8.54733 4.12561 8.37732Z"
							fill="#F97373"
						/>
					</svg>
					Delete
				</button>
			</td>
		</tr>
	));

	return (
		<div>
			<div className="present_modal_header">
				<div>Shift Timings</div>
				<svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>
			<div className="holiday_list_table">
				<div className="table_content">
					<Table verticalSpacing="xs" horizontalSpacing="lg">
						<tbody>{rows}</tbody>
					</Table>
				</div>
				{addData && (
					<div className="shift_time_add_box">
						<Flex align={"center"}>
							<label>From :</label>
							<Flex ml={20} align={"center"}>
								<Select
									value={startTimeHours}
									onChange={(e) => {
										setStartSelected(e && startTimeMins);
										setstartTimeHours(e);
									}}
									maw={100}
									size="xs"
									styles={{
										rightSection: { pointerEvents: "none" },
										input: {
											borderRadius: "10px",
											fontSize: "12px",
											border: "1px solid #8399A3",
											background: "#264147",
											color: "#fff",
										},
									}}
									rightSection={
										<svg
											width="10"
											height="8"
											viewBox="0 0 13 8"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
												fill="#fff"
											/>
										</svg>
									}
									placeholder="hours"
									data={hourdata}
								/>
								<label style={{ marginLeft: "5px" }}>hrs</label>
							</Flex>
							<Flex ml={20} align={"center"}>
								<Select
									value={startTimeMins}
									onChange={(e) => {
										setStartSelected(e && startTimeHours);
										setstartTimeMins(e);
									}}
									maw={100}
									size="xs"
									styles={{
										rightSection: { pointerEvents: "none" },
										input: {
											borderRadius: "10px",
											fontSize: "12px",
											border: "1px solid #8399A3",
											background: "#264147",
											color: "#fff",
										},
									}}
									rightSection={
										<svg
											width="10"
											height="8"
											viewBox="0 0 13 8"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
												fill="#fff"
											/>
										</svg>
									}
									placeholder="mins"
									data={minutedata}
								/>
								<label style={{ marginLeft: "5px" }}>
									mins
								</label>
							</Flex>
						</Flex>
						<Flex align={"left"} mt={30}>
							<label
								style={{
									width: "10%",
									textAlign: "right",
								}}
							>
								To :
							</label>
							<Flex ml={20} align={"center"}>
								<Select
									readOnly={!isStartSelected}
									value={endTimeHours}
									onChange={setendTimeHours}
									maw={100}
									size="xs"
									styles={{
										rightSection: { pointerEvents: "none" },
										input: {
											borderRadius: "10px",
											fontSize: "12px",
											border: "1px solid #8399A3",
											background: "#264147",
											color: "#fff",
										},
									}}
									rightSection={
										<svg
											width="10"
											height="8"
											viewBox="0 0 13 8"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
												fill="#fff"
											/>
										</svg>
									}
									placeholder="hours"
									data={generateOptions(
										parseInt(startTimeHours, 10),
										23
									)}
								/>
								<label style={{ marginLeft: "5px" }}>hrs</label>
							</Flex>
							<Flex ml={20} align={"center"}>
								<Select
									readOnly={!isStartSelected}
									value={endTimeMins}
									onChange={setendTimeMins}
									maw={100}
									size="xs"
									styles={{
										rightSection: { pointerEvents: "none" },
										input: {
											borderRadius: "10px",
											fontSize: "12px",
											border: "1px solid #8399A3",
											background: "#264147",
											color: "#fff",
										},
									}}
									rightSection={
										<svg
											width="10"
											height="8"
											viewBox="0 0 13 8"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
												fill="#fff"
											/>
										</svg>
									}
									placeholder="mins"
									data={generateOptions(minStart, 59)}
								/>
								<label style={{ marginLeft: "5px" }}>
									mins
								</label>
							</Flex>
						</Flex>
						<Divider color="#264147" mt={15} />

						<Box ta={"center"}>
							<button
								className="cancel_button"
								onClick={() => {
									seteditingData(false);
									seteditingIndex(null);
									setAddData(false);
								}}
							>
								Cancel
							</button>
							<button
								className="save_button"
								onClick={editingData ? onClickEdit : onClickAdd}
							>
								Save
							</button>
						</Box>
					</div>
				)}
				{!addData && (
					<Box
						ta={"center"}
						w={"100%"}
						style={{ flexShrink: 0, padding: "20px 0" }}
					>
						<button
							onClick={() => setAddData(true)}
							className="holiday_add_btn"
						>
							<svg width="12" viewBox="0 0 12 12" fill="none">
								<path
									d="M6.4339 11.296V7.18254L10.6935 7.19054C11.0935 7.19129 11.418 7.0749 11.418 6.68992L11.418 6.30872C11.418 5.92374 11.0935 5.80613 10.6935 5.80538L6.4339 5.79739V1.68394C6.4339 1.29895 6.30933 0.986507 5.90927 0.985756L5.50829 0.985003C5.10823 0.984253 4.98365 1.29623 4.98365 1.68121V5.79466L0.724085 5.78667C0.324025 5.78592 -0.000414185 5.90231 -0.000414185 6.28729V6.66849C-0.000414185 7.05347 0.324025 7.17108 0.724085 7.17183L4.98365 7.17982V11.2933C4.98365 11.6783 5.10823 11.9907 5.50829 11.9915L5.90927 11.9922C6.30933 11.993 6.4339 11.681 6.4339 11.296Z"
									fill="url(#paint0_linear_430_6205)"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_430_6205"
										x1="11.418"
										y1="6.06648"
										x2="6.14298"
										y2="12.2052"
										gradientUnits="userSpaceOnUse"
									>
										<stop stopColor="#07DD92" />
										<stop offset="1" stopColor="#06B9F0" />
									</linearGradient>
								</defs>
							</svg>
							Add
						</button>
					</Box>
				)}
			</div>
		</div>
	);
};

export default ShiftTimingsModal;
