import React, { useEffect, useState } from "react";
import "./VehicleRegModalSelectable.css";
import { Modal, Table, TextInput } from "@mantine/core";
import { useSelector } from "react-redux";
import ViewDocumentModal from "../ViewDocumentModal/ViewDocumentModal";
import { checkURLType } from "../../utils/functions";

const VehicleRegModalSelectable = ({ close }) => {
	// redux
	const vehicles = useSelector((state) => state.vehicle?.data);
	const sortedVehicles = [...vehicles].sort((a, b) => {
		if (a.vehicle_no.toLowerCase() < b.vehicle_no.toLowerCase()) {
			return -1;
		}
		if (a.vehicle_no.toLowerCase() > b.vehicle_no.toLowerCase()) {
			return 1;
		}
		return 0;
	});
	const vehiclesType = useSelector((state) => state.vehiclesType.data);
	const [vehicleTypeById, setVehicleTypeById] = useState({});

	const [opened, setOpened] = useState(false);
	const [documentImage, setDocumentImage] = useState(null);
	const [documentName, setDocumentName] = useState(null);
	const [search, setSearch] = useState("");

	useEffect(() => {
		if (vehiclesType?.length) {
			setVehicleTypeById(
				vehiclesType.reduce((acc, current, index) => {
					acc[current?._id] = current;
					return acc;
				}, {})
			);
		}
	}, [vehiclesType]);

	const open = (image, imageName) => {
		setDocumentImage(image);
		setDocumentName(imageName);
		setOpened(true);
	};

	const close1 = () => {
		setOpened(false);
	};

	const rows = sortedVehicles
		?.filter((d) =>
			d?.vehicle_no?.toLowerCase().includes(search.toLowerCase())
		)
		?.map((vehicle, id) => (
			<tr key={id}>
				<td style={{ paddingLeft: "32px" }} className="first">
					{vehicle.vehicle_no}
				</td>
				<td>
					<div
						className="view_button_select nosave"
						onClick={() =>
							checkURLType(vehicle?.rc_image) === "image"
								? open(vehicle?.rc_image, "RC")
								: window.open(vehicle?.rc_image, "_blank")
						}
					>
						<span>View</span>
					</div>
				</td>
				<td>
					<div
						className="view_button_select nosave"
						onClick={() =>
							checkURLType(vehicle?.insurance_file) === "image"
								? open(vehicle?.insurance_file, "Insurance")
								: window.open(vehicle?.insurance_file, "_blank")
						}
					>
						<span>View</span>
					</div>
				</td>
				<td>
					{(vehicleTypeById[vehicle.vehicle_type] || {})
						.vehicle_type || ""}
				</td>
				<td
					className="holiday_btns"
					style={{ paddingRight: "32px", textAlign: "end" }}
				>
					<div
						className="view_button_select save"
						onClick={() => close(vehicle)}
					>
						<span>Select</span>
					</div>
				</td>
			</tr>
		));

	return (
		<div>
			<div
				className="present_modal_header"
				style={{ paddingBottom: "10px", paddingTop: "20px" }}
			>
				<TextInput
					w={300}
					styles={() => ({
						input: {
							padding: "10px",
							borderRadius: "10px",
							border: "1px solid #9DB2B8",
							boxShadow: "0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
						},
					})}
					placeholder="Search by vehicle number"
					rightSection={
						<svg
							width="22"
							height="20"
							viewBox="0 0 27 28"
							fill="none"
						>
							<ellipse
								cx="10.3418"
								cy="10.5395"
								rx="8.34184"
								ry="8.65079"
								stroke="#222222"
								strokeWidth="3"
							/>
							<path
								d="M19.75 20.2969L25.3557 26.1102"
								stroke="#222222"
								strokeWidth="3"
								strokeLinecap="round"
							/>
						</svg>
					}
					onChange={(e) => {
						if (setSearch) setSearch(e?.target?.value);
					}}
				/>
			</div>
			<div className="holiday_list_table">
				<div className="table_content">
					<Table
						verticalSpacing="xs"
						horizontalSpacing="lg"
						className="vehicle_table"
					>
						<thead className="vehicle_thead">
							<tr>
								<th
									style={{ paddingLeft: "32px" }}
									className="first"
								>
									Vehicle No
								</th>
								<th>RC</th>
								<th>Insurance</th>
								<th>Vehicle Type</th>
								<th style={{ paddingRight: "32px" }}>&nbsp;</th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
						<Modal
							opened={opened}
							onClose={close1}
							centered
							withCloseButton={false}
							size="sm"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<ViewDocumentModal
								close={close1}
								image={documentImage}
								documentName={documentName}
							/>
						</Modal>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default VehicleRegModalSelectable;
