import { Table, Tabs, createStyles } from "@mantine/core";
import "./ApprovalList.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { dateFormat } from "../../utils/constants";
import { useSelector } from "react-redux";
import ZeroScreens from "../ZeroScreens/ZeroScreens";

const useStyles = createStyles(() => ({
  tab: {
    minWidth: "150px",
    padding: "8px 5px",
    color: "#658A92",
    backgroundColor: "#0E2228",
    cursor: "pointer",
    marginRight: "5px",
    fontSize: "13px",
    border: "1px solid #25454D",
    borderRadius: " 10px 10px 0px 0px",
    "&[data-active]": {
      backgroundColor: "#fff",
      border: "none",
      color: "#0E2227",
    },
    "&:last-of-type": {
      marginRight: "0px",
    },
  },
  tabsList: {
    display: "flex",
    marginLeft: "30px",
  },
}));

const ApprovalList = ({ close, defaultScreen = "driver" }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  // redux
  const pendingApprovalDrivers = useSelector(
    (state) => state.driver?.pendingApprovalDrivers?.data
  );
  const pendingApprovalSupervisor = useSelector(
    (state) => state.supervisor?.pendingApprovalSupervisors?.data
  );
  const goToPRofile = (driver) => {
    close();
    navigate(`/dashboard/driverapproval/${driver._id}`);
  };
  const goToPRofileSupervisor = (driver) => {
    close();
    navigate(`/dashboard/supervisorapproval/${driver._id}`);
  };
  const pendingApprovalDriversSorted = [...(pendingApprovalDrivers || [])].sort(
    (a, b) => {
      //   if (a.name.toLowerCase() < b.name.toLowerCase()) {
      //     return -1;
      //   }
      //   if (a.name.toLowerCase() > b.name.toLowerCase()) {
      //     return 1;
      //   }
      //   return 0;
      const dateA = moment(a.createdAt);
      const dateB = moment(b.createdAt);

      // Sort in descending order (new date to old date)
      return dateB - dateA;
    }
  );
  const pendingApprovalSupervisorSorted = [
    ...(pendingApprovalSupervisor || []),
  ].sort((a, b) => {
    // if (a.name.toLowerCase() < b.name.toLowerCase()) {
    //   return -1;
    // }
    // if (a.name.toLowerCase() > b.name.toLowerCase()) {
    //   return 1;
    // }
    // return 0;
    const dateA = moment(a.createdAt);
    const dateB = moment(b.createdAt);

    // Sort in descending order (new date to old date)
    return dateB - dateA;
  });
  const rows = pendingApprovalDriversSorted?.map((driver, id) => (
    <tr key={id}>
      <td style={{ paddingLeft: "30px" }}>{driver?.name}</td>
      <td>{driver?.email}</td>
      <td>
        {driver?.createdAt && moment(driver?.createdAt).format(dateFormat)}
      </td>
      <td className="view_application_btn">
        <span onClick={() => goToPRofile(driver)}>View Application</span>
      </td>
    </tr>
  ));
  const rowsSupervisors = pendingApprovalSupervisorSorted?.map((driver, id) => (
    <tr key={id}>
      <td style={{ paddingLeft: "30px" }}>{driver?.name}</td>
      <td>{driver?.email}</td>
      <td>
        {driver?.createdAt && moment(driver?.createdAt).format(dateFormat)}
      </td>
      <td className="view_application_btn">
        <span onClick={() => goToPRofileSupervisor(driver)}>
          View Application
        </span>
      </td>
    </tr>
  ));

  return (
    <div className="approvalList">
      <div className="present_modal_header" style={{ paddingBottom: "30px" }}>
        <div
          style={{
            paddingLeft: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Pending Applications
        </div>
        <svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
          <path
            d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="approvalList_table">
        <Tabs
          mx={"auto"}
          defaultValue={defaultScreen}
          classNames={classes}
          unstyled
        >
          <Tabs.List>
            <Tabs.Tab value="driver">
              <span className="topic">
                <span className="title">Driver</span>
                <span className="count">{pendingApprovalDrivers?.length}</span>
              </span>
            </Tabs.Tab>
            <Tabs.Tab value="supervisor">
              <span className="topic">
                <span className="title">Supervisor</span>
                <span className="count">
                  {pendingApprovalSupervisor?.length}
                </span>
              </span>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="driver">
            <Table verticalSpacing="md" horizontalSpacing="xl">
              <thead className="approvalList_table_header">
                <tr>
                  <th style={{ paddingLeft: "30px" }}>Name</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {rows?.length <= 0 ? (
                  <tr>
                    <td colSpan="4">
                      <ZeroScreens modal={true} />
                    </td>
                  </tr>
                ) : (
                  rows
                )}
              </tbody>
            </Table>
          </Tabs.Panel>
          <Tabs.Panel value="supervisor">
            <Table verticalSpacing="md" horizontalSpacing="xl">
              <thead className="approvalList_table_header">
                <tr>
                  <th style={{ paddingLeft: "30px" }}>Name</th>
                  <th>Email</th>
                  <th>Date & Time</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {rowsSupervisors?.length <= 0 ? (
                  <tr>
                    <td colSpan="4">
                      <ZeroScreens modal={true} />
                    </td>
                  </tr>
                ) : (
                  rowsSupervisors
                )}
              </tbody>
            </Table>
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
};

export default ApprovalList;
