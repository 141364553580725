import "./DriverCard.css";
import { Flex, Text } from "@mantine/core";
import userimg from "../../assets/images/Group 68.png";
import { useNavigate } from "react-router-dom";
const DriverCard = (data) => {
  const driver = data.item || {};
  const navigate = useNavigate();
  return (
    <Flex
      className="flex_item"
      onClick={() => navigate(`/dashboard/driverdetails/${driver._id}`)}
      direction={"column"}
      bg={"#fff"}
    >
      <div className="image_outer_box">
        <img alt="team_member" src={driver.picture || userimg} />
      </div>
      <Text
        w={"100%"}
        px={20}
        ta={"center"}
        c={"#0E2227"}
        fz={13}
        fw={600}
        pb={2}
        truncate
      >
        {driver.name}
      </Text>
      <Text ta={"center"} c={"#9DB2B8"} fz={10} fw={600}>
        {driver.username}
      </Text>
      {driver.shift && (
        <Text ta={"center"} pb={10} c={"#9DB2B8"} fz={10} fw={600}>
          {`${driver.shift?.startTime} to ${driver.shift?.endTime}`}
        </Text>
      )}
    </Flex>
  );
};

export default DriverCard;
