import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  notifications: {
    loading: false,
    data: [],
    error: null,
  },
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // add
    addNotification: (state, action) => {
      if (action.payload) {
        const notifications = [action.payload, ...state.notifications.data];
        state.notifications.data = notifications;
      }
    },
    // read
    getNotifications: (state) => {
      state.notifications.loading = true;
    },
    getNotificationsFulfilled: (state, action) => {
      state.notifications.loading = false;
      if (Array.isArray(action.payload)) {
        state.notifications.data = action.payload;
      }
    },
    getNotificationsRejected: (state, action) => {
      state.notifications.loading = false;
      state.notifications.error = action.payload;
    },

    // update
    seenNotifications: (state) => {
      state.loading = true;
      state.updated = false;
    },
    seenNotificationsFulfilled: (state, action) => {
      state.loading = false;
      state.updated = true;
    },
    seenNotificationsRejected: (state, action) => {
      state.loading = false;
      state.updated = false;
      state.error = action.payload;
    },
  },
});

export const {
  // add
  addNotification,

  // read
  getNotifications,
  getNotificationsFulfilled,
  getNotificationsRejected,
  // update
  seenNotifications,
  seenNotificationsFulfilled,
  seenNotificationsRejected,
} = notificationSlice.actions;

export default notificationSlice.reducer;
