import { ActionIcon, Menu, Modal, Table } from "@mantine/core";
import "./SupervisorManagementTable.css";
import { useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import ZeroScreens from "../../../../components/ZeroScreens/ZeroScreens";
import menu from "../../../../assets/images/menu.svg";
import { useNavigate } from "react-router-dom";
import MigrateDriverModal from "../../../../components/MigrateDriverModal/MigrateDriverModal";
import { toast } from "react-toastify";
import { isToday } from "../../../../utils/functions";

const SupervisorManagementTable = ({ table }) => {
	const [loading, setLoading] = useState(false);
	const [driver, setDriver] = useState({});
	const navigate = useNavigate();
	const [deletedrivermodal, setmigratedrivermodal] = useState(false);

	const rows = table?.map((element, id) => {
		return (
			<tr key={id}>
				<td style={{ width: "70px" }}>
					<img
						src={element?.picture}
						alt="driver_image"
						className="driver_image"
					/>
				</td>
				<td>
					<span style={{ color: "#0E2227", fontSize: "13px" }}>
						{element?.name}
					</span>
					<br />
					{element?.username}
				</td>
				<td>
					Active
					<br />
					{element?.shift?.startTime}-{element?.shift?.endTime}
				</td>
				<td>
					{element.attendance?.date &&
					isToday(element.attendance?.date)
						? element.attendance?.no_of_orders
						: 0}
				</td>
				<td>{element?.hub?.name}</td>
				<td>
					<Menu
						transitionProps={{ transition: "pop" }}
						withArrow
						position="bottom-end"
						withinPortal
					>
						<Menu.Target>
							<ActionIcon variant="subtle" color="gray">
								<img src={menu} />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								onClick={() =>
									navigate(
										`/dashboard/driverdetails/${element?._id}`
									)
								}
							>
								View Profile
							</Menu.Item>
							<Menu.Item
								onClick={() => {
									setDriver(element);
									setmigratedrivermodal(true);
								}}
							>
								Migrate Driver
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</td>
			</tr>
		);
	});
	return (
		<div className="supervisor_table_box">
			<Table horizontalSpacing={"xl"}>
				<thead className="table_thead">
					<tr>
						<th style={{ width: "70px" }}></th>
						<th>Driver</th>
						<th>Shift</th>
						<th>Total No. of orders</th>
						<th>Hub Name</th>
						<th></th>
					</tr>
				</thead>
				{loading ? (
					<Loading />
				) : rows?.length ? (
					<tbody>{rows}</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan="6" className="zero_screen">
								<ZeroScreens />
							</td>
						</tr>
					</tbody>
				)}
			</Table>

			<Modal
				opened={deletedrivermodal}
				onClose={() => setmigratedrivermodal(false)}
				centered
				withCloseButton={false}
				size="sm"
				padding={0}
				radius={10}
				overlayProps={{
					blur: 1,
					color: "#333",
					backgroundopacity: 1,
				}}
			>
				<MigrateDriverModal
					setmigratedrivermodal={setmigratedrivermodal}
					selectedDriverid={driver?._id}
					supervisorId={driver?.supervisor?._id}
					onMigrate={() => {
						toast.success("Driver Migrated");
						setmigratedrivermodal(false);
					}}
				/>
			</Modal>
		</div>
	);
};

export default SupervisorManagementTable;
