import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
  createRecruiter,
  createRecruiterFulfilled,
  createRecruiterRejected,
  deleteRecruiter,
  deleteRecruiterFulfilled,
  deleteRecruiterRejected,
  getRecruiters,
  getRecruitersFulfilled,
  getRecruitersRejected,
  updateRecruiter,
  updateRecruiterFulfilled,
  updateRecruiterRejected,
  updateRecruiterSalary,
  updateRecruiterSalaryFulfilled,
  updateRecruiterSalaryRejected,
} from "../reducers/recruiterReducer";
import { getErrorMessage } from "../../utils/functions";

function* createRecruiterSaga(action) {
  try {
    const res = yield call(api.post, "/user/recruiter", action.payload?.data);
    // console.log("createRecruiterSaga", res?.data);
    if (createRecruiter.prototype.onSuccess) {
      createRecruiter.prototype.onSuccess();
    }
    yield put(createRecruiterFulfilled(res?.data));
  } catch (err) {
    if (createRecruiter.prototype.onReject) {
      createRecruiter.prototype.onReject();
    }
    yield put(createRecruiterRejected(getErrorMessage(err)));
  }
}

function* getRecruitersSaga() {
  try {
    const res = yield call(api.get, "/user/recruiter");
    // console.log("getRecruitersSaga", res?.data);
    if (getRecruiters.prototype.onSuccess) {
      getRecruiters.prototype.onSuccess();
    }
    yield put(getRecruitersFulfilled(res?.data));
  } catch (err) {
    if (getRecruiters.prototype.onReject) {
      getRecruiters.prototype.onReject();
    }
    yield put(getRecruitersRejected(getErrorMessage(err)));
  }
}

function* updateRecruiterSaga(action) {
  try {
    const id = action.payload?.id;
    const data = action.payload?.data;
    const res = yield call(api.patch, `/user/recruiter/${id}`, data);
    // console.log("updateRecruiterSaga", res?.data);
    if (updateRecruiter.prototype.onSuccess) {
      updateRecruiter.prototype.onSuccess();
    }
    yield put(updateRecruiterFulfilled(res?.data));
  } catch (err) {
    if (updateRecruiter.prototype.onReject) {
      updateRecruiter.prototype.onReject();
    }
    yield put(updateRecruiterRejected(getErrorMessage(err)));
  }
}

function* updateRecruiterSalarySaga(action) {
  try {
    const id = action.payload?.id;
    const data = action.payload?.data;
    const res = yield call(api.patch, `/user/recruiter/${id}/salary`, data);
    // console.log("updateRecruiterSalarySaga", res?.data);
    if (updateRecruiterSalary.prototype.onSuccess) {
      updateRecruiterSalary.prototype.onSuccess();
    }
    yield put(updateRecruiterSalaryFulfilled(res?.data));
  } catch (err) {
    if (updateRecruiterSalary.prototype.onReject) {
      updateRecruiterSalary.prototype.onReject();
    }
    yield put(updateRecruiterSalaryRejected(getErrorMessage(err)));
  }
}

function* deleteRecruiterSaga(action) {
  
  try {
    const id = action.payload?.id;
    const newId = action.payload?.newId;
    // console.log("id",id);
    // console.log("newid",newId);
    const res = yield call(api.delete, `/user/recruiter/${id}/${newId}`);
    // console.log("updateSupervisorSaga", res?.data);
    if (deleteRecruiter.prototype.onSuccess) {
      deleteRecruiter.prototype.onSuccess();
    }
    yield put(deleteRecruiterFulfilled(res?.data));
  } catch (err) {
    if (deleteRecruiter.prototype.onReject) {
      deleteRecruiter.prototype.onReject();
    }
    yield put(deleteRecruiterRejected(getErrorMessage(err)));
  }


}

export function* recruiterSaga() {
  yield takeLatest(createRecruiter.type, createRecruiterSaga);
  yield takeLatest(getRecruiters.type, getRecruitersSaga);
  yield takeLatest(updateRecruiter.type, updateRecruiterSaga);
  yield takeLatest(updateRecruiterSalary.type, updateRecruiterSalarySaga);
  yield takeLatest(deleteRecruiter.type, deleteRecruiterSaga);
}
