import { call, put, takeLatest } from "redux-saga/effects";
import {
	fetchAllVehiclesType,
	fetchAllVehiclesTypeFulfilled,
	fetchAllVehiclesTypeRejected,
	createVehicleType,
	createVehicleTypeFulfilled,
	createVehicleTypeRejected,
	deleteVehicleType,
	deleteVehicleTypeFulfilled,
	deleteVehicleTypeRejected,
} from "../reducers/vehiclesTypeReducer";
import { getErrorMessage } from "../../utils/functions";
import api from "../../api";

function* fetchAllVehiclesTypeSaga(action) {
	try {
		const res = yield call(api.get, "/vehicle_type");
		const vehicles = res?.data;

		if (vehicles) {
			if (fetchAllVehiclesType.prototype.onSuccess) {
				fetchAllVehiclesType.prototype.onSuccess();
			}
			yield put(fetchAllVehiclesTypeFulfilled(vehicles));
		}
	} catch (error) {
		if (fetchAllVehiclesType.prototype.onError) {
			fetchAllVehiclesType.prototype.onError(error);
		}
		yield put(fetchAllVehiclesTypeRejected(getErrorMessage(error)));
	}
}

function* createVehicleTypeSaga(action) {
	try {
		const data = action.payload?.data;
		console.log(data);
		const res = yield call(api.post, "/vehicle_type", data);
		console.log(res);
		const vehicle = res?.data;

		if (createVehicleType.prototype.onSuccess) {
			createVehicleType.prototype.onSuccess();
		}
		yield put(createVehicleTypeFulfilled(vehicle));
	} catch (error) {
		console.log(error);
		if (createVehicleType.prototype.onError) {
			createVehicleType.prototype.onError(error);
		}
		yield put(createVehicleTypeRejected(getErrorMessage(error)));
	}
}

function* deleteVehicleTypeSaga(action) {
	try {
		const id = action.payload?.id;
		const response = yield call(api.delete, `/vehicle_type/${id}`);
		if (deleteVehicleType.prototype.onSuccess) {
			deleteVehicleType.prototype.onSuccess();
		}
		yield put(deleteVehicleTypeFulfilled(response?.data));
	} catch (error) {
		if (deleteVehicleType.prototype.onError) {
			deleteVehicleType.prototype.onError();
		}
		yield put(deleteVehicleTypeRejected(getErrorMessage(error)));
	}
}

export function* vehiclesTypeSaga() {
	yield takeLatest(fetchAllVehiclesType.type, fetchAllVehiclesTypeSaga);
	yield takeLatest(createVehicleType.type, createVehicleTypeSaga);
	yield takeLatest(deleteVehicleType.type, deleteVehicleTypeSaga);
}
