import { Divider, Modal } from "@mantine/core";
import "./SupervisorDetails.css";
import SupervisorDetailsHeader from "./SupervisorDetailsHeader/SupervisorDetailsHeader";
import ViewDocumentModal from "../../../components/ViewDocumentModal/ViewDocumentModal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import { getSupervisorById } from "../../../redux/reducers/supervisorReducer";
import DeactivateSupervisorModal from "../../../components/DeactivateSupervisorModal/DeactivateSupervisorModal";
import { toast } from "react-toastify";

const SupervisorDetails = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const supervisorInfo = useSelector(
		(state) => state.supervisor.fetchedSupervisor
	);
	const loading = useSelector(
		(state) => state.supervisor.fetchedSupervisor.loading
	);

	const supervisor = supervisorInfo.data || {};

	useEffect(() => {
		if (id) {
			dispatch(getSupervisorById({ id }));
		}
	}, [id, dispatch]);

	const [opened, setOpened] = useState(false);
	const [documentImage, setDocumentImage] = useState(null);
	const [documentName, setDocumentName] = useState(null);

	const open = (image, imageName) => {
		setDocumentImage(image);
		setDocumentName(imageName);
		setOpened(true);
	};

	const close = () => {
		setOpened(false);
	};

	const [deletesupervisormodal, setdeletesupervisormodal] = useState(false);
	return loading ? (
		<Loading />
	) : (
		<div className="supervisor_details_container">
			<SupervisorDetailsHeader key={id} supervisor={supervisor} />

			<div className="supervisor_document_ribbon">
				<h5>Documents:</h5>
				<div>
					<h6>Aadhaar Card (Front)</h6>
					<button
						onClick={() =>
							open(
								supervisor.aadhaarCardFront || null,
								"Aadhaar Card (Front)"
							)
						}
					>
						view
					</button>
				</div>
				<div>
					<h6>Aadhaar Card (Back)</h6>
					<button
						onClick={() =>
							open(
								supervisor.aadhaarCardBack || null,
								"Aadhaar Card (Back)"
							)
						}
					>
						view
					</button>
				</div>
				<div>
					<h6>Pan Card</h6>
					<button
						onClick={() =>
							open(supervisor.panCard || null, "Pan Card")
						}
					>
						view
					</button>
				</div>
			</div>
			<Modal
				opened={opened}
				onClose={close}
				centered
				withCloseButton={false}
				size="lg"
				padding={0}
				radius={10}
				overlayProps={{
					blur: 5,
					color: "#fff",
					backgroundopacity: 1,
				}}
			>
				<ViewDocumentModal
					close={close}
					image={documentImage}
					documentName={documentName}
				/>
			</Modal>
			<Divider color="#C2D0D6" mx={20} />

			<div className="supervisor_down_box">
				<div className="supervisor_down_box_1">
					<div className="supervisor_info_container">
						<div className="supervisor_info_box">
							<h5>Hubs</h5>
							<div className="supervisor_details_div">
								{(supervisor.hubs || []).map((hub, i) => (
									<div className="supervisor_details" key={i}>
										<p>{hub?.name || "Name"}</p>
										<p>
											{hub?.address || "Address"},{" "}
											{hub?.landmark || "Landmark"},{" "}
											{hub?.city || "City"},{" "}
											{hub?.state || "State"}-
											{hub?.pincode || "Pincode"}
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="accountBtns">
					<button
						className="Deactivate_button"
						onClick={() => setdeletesupervisormodal(true)}
					>
						Account Deactivate
					</button>
					<button
						className="black_button"
						onClick={() => {
							navigate("/dashboard/editsupervisordetails/" + id);
						}}
					>
						Edit Details
					</button>
				</div>
			</div>
			<Modal
				opened={deletesupervisormodal}
				onClose={() => setdeletesupervisormodal(false)}
				centered
				withCloseButton={false}
				size="sm"
				padding={0}
				radius={10}
				overlayProps={{
					blur: 5,
					color: "#fff",
					backgroundopacity: 1,
				}}
			>
				<DeactivateSupervisorModal
					setdeletesupervisormodal={setdeletesupervisormodal}
					selectedSupervisorid={supervisor?._id}
					hasDrivers={true}
					onDelete={() => {
						toast.success("Supervisor Deactivated");
						setdeletesupervisormodal(false);
					}}
				/>
			</Modal>
		</div>
	);
};

export default SupervisorDetails;
