import HubHeader from "./HubHeader/HubHeader";
import HubTable from "./HubTable/HubTable";
import { useNavigate } from "react-router-dom";
import "./HubsandClients.css";
import { useState } from "react";
import ClientsTable from "./ClientsTable/ClientsTable";
const HubsandClients = () => {
	const navigate = useNavigate();
	const [table, setTable] = useState([]);
	return (
		<div className="HubsandClients">
			<div className="hub_left">
				<HubHeader getTableData={(value) => setTable(value)} />
				<div>
					<HubTable table={table} />
				</div>
			</div>
			<div className="hub_right">
				<div
					className="create_new_hub_button"
					onClick={() => navigate("/dashboard/createnewmap")}
				>
					<span>Create New Hub</span>
					<svg width="16" viewBox="0 0 20 20" fill="none">
						<path
							d="M1.26877 11.6195L8.35005 11.6195L8.33629 18.7818C8.335 19.4545 8.53537 20 9.19811 20L9.85434 20C10.5171 20 10.7195 19.4545 10.7208 18.7818L10.7346 11.6195L17.8159 11.6195C18.4786 11.6195 19.0165 11.41 19.0178 10.7373L19.0191 10.0631C19.0204 9.39042 18.4833 9.18095 17.8206 9.18095L10.7393 9.18095L10.753 2.01866C10.7543 1.34598 10.554 0.800444 9.89123 0.800444L9.235 0.800444C8.57225 0.800444 8.36979 1.34598 8.3685 2.01866L8.35474 9.18095L1.27346 9.18095C0.610712 9.18095 0.0728354 9.39042 0.0715431 10.0631L0.0702477 10.7373C0.0689553 11.41 0.606027 11.6195 1.26877 11.6195Z"
							fill="white"
						/>
					</svg>
				</div>

				<div className="supervisor_list" style={{ marginTop: "30px" }}>
					{/* <h2>Supervisors</h2>
					<SupervisorTable /> */}

					<h2>Clients</h2>
					<ClientsTable />
				</div>
			</div>
		</div>
	);
};

export default HubsandClients;
