import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: [],
	allData: [],
	fetchedChallan: {
		data: null,
		loading: false,
		error: null,
	},
	loading: false,
	error: null,
};

const challanReducer = createSlice({
	name: "challan",
	initialState,
	reducers: {
		getAllChallans: (state) => {
			state.loading = true;
			state.error = null;
		},
		getAllChallansFulfilled: (state, action) => {
			state.loading = false;
			if (Array.isArray(action.payload)) state.allData = action.payload;
		},
		getAllChallansRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getChallans: (state) => {
			state.loading = true;
			state.error = null;
		},
		getChallansFulfilled: (state, action) => {
			state.loading = false;
			if (Array.isArray(action.payload)) state.data = action.payload;
		},
		getChallansRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getChallanByVehicle: (state) => {
			state.fetchedChallan.data = null;
			state.fetchedChallan.loading = true;
			state.fetchedChallan.error = null;
		},
		getChallanByVehicleFulfilled: (state, action) => {
			state.fetchedChallan.loading = false;
			if (Array.isArray(action.payload))
				state.fetchedChallan.data = action.payload;
		},
		getChallanByVehicleRejected: (state, action) => {
			state.fetchedChallan.loading = false;
			state.fetchedChallan.error = action.payload;
		},
	},
});

export const {
	getAllChallans,
	getAllChallansFulfilled,
	getAllChallansRejected,
	getChallans,
	getChallansFulfilled,
	getChallansRejected,
	getChallanByVehicle,
	getChallanByVehicleFulfilled,
	getChallanByVehicleRejected,
} = challanReducer.actions;

export default challanReducer.reducer;
