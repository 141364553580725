import { Flex, HoverCard, Modal, Select, Text } from "@mantine/core";
import "./SalaryManagementHeader.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { updateRecruiterSalary } from "../../../../redux/reducers/recruiterReducer";
import { SalaryStatus } from "../../../../utils/constants";
import { CSVLink } from "react-csv";

const SalaryManagementHeader = ({
	getTableData,
	attendanceWithId,
	csvHeader,
	csvData,
}) => {
	const [payoutActive, setPayoutActive] = useState(false);
	const recruiters = useSelector((state) => state.recruiter?.data);
	const [id, setId] = useState("");
	const [data, setData] = useState();
	const startYear = 2023;
	const currentYear = new Date().getFullYear();
	const [years, setYears] = useState([]);
	const selectRecruitersData = [];
	const dispatch = useDispatch();
	const [selectedMonth, changeMonthState] = useState(null);
	const [selectedYear, changeYearState] = useState(null);
	const payoutCycleData = useSelector((state) => state.payoutCycle?.data);
	const payoutCycle = payoutCycleData[0];

	const clearSelects = () => {
		changeMonthState(null);
		changeYearState(null);
		getTableData(data?.drivers);
	};

	const checkDate = (xDate, selectedMonth, selectedYear) => {
		selectedMonth = parseInt(selectedMonth);
		selectedYear = parseInt(selectedYear);
		const today = moment().startOf("day");
		const xMoment = moment(xDate, "DD");

		const lastDayCurrentMonth = moment().endOf("month");
		const lastMonthMoment = moment().subtract(1, "months");
		if (
			selectedMonth !== lastMonthMoment.month() + 1 ||
			selectedYear !== lastMonthMoment.year()
		) {
			return false;
		}

		return today.isBetween(xMoment, lastDayCurrentMonth, null, "[]");
	};

	recruiters?.forEach((hub) =>
		selectRecruitersData.push({ value: hub?._id, label: hub?.name })
	);
	useEffect(() => {
		const yearsArray = [];
		for (let year = startYear; year <= currentYear; year++) {
			yearsArray.push(year);
		}
		const yearsData = [];
		yearsArray?.forEach((year) =>
			yearsData.push({ value: year.toString(), label: year })
		);
		setYears(yearsData);
	}, []);

	// useEffect(() => {
	// 	const recruitersData = recruiters?.find((item) => item._id == id);
	// 	setData(recruitersData);
	// 	getTableData(recruitersData?.drivers);

	// 	const hasUnpaidDriver = recruitersData?.drivers.some(
	// 		(driver) => driver?.attendance?.salaryStatus === "unpaid"
	// 	);
	// }, [recruiters]);

	useEffect(() => {
		changeMonthState(null);
		changeYearState(null);
		setPayoutActive(false);
		const recruitersData = recruiters?.find((item) => item._id == id);
		const filtered = recruitersData?.drivers;
		setData({
			drivers: recruitersData?.drivers,
			payout: filtered
				?.filter((f) => f.salary)
				.reduce(
					(acc, curr) =>
						acc +
						curr.salary *
							((attendanceWithId || {})[curr?._id]?.filter(
								(a) => a?.status?.toLowerCase() !== "absent"
							)?.length || 0),
					0
				),
		});
		getTableData(filtered);
	}, [id]);

	// useEffect(() => {
	// 	const recruitersData = recruiters?.find((item) => item._id == id);
	// 	setData(recruitersData);
	// 	getTableData(recruitersData?.drivers);

	// 	// Code for updating payoutActive based on the selected month
	// 	if (id && selectedMonth) {
	// 		const hasUnpaidDriver = recruitersData?.drivers.some(
	// 			(driver) =>
	// 				moment(driver?.createdAt).format("MM") === selectedMonth &&
	// 				driver?.attendance?.salaryStatus === "unpaid"
	// 		);
	// 		setPayoutActive(hasUnpaidDriver);
	// 	} else {
	// 		setPayoutActive(false);
	// 	}
	// }, [id, recruiters, selectedMonth]);

	// useEffect(() => {
	// 	const currentDate = new Date();
	// 	const previousMonth = new Date(currentDate);
	// 	previousMonth.setMonth(currentDate.getMonth() - 1);

	// 	// Get the default month as the previous month in MM format
	// 	const defaultMonth = (previousMonth.getMonth() + 1)
	// 		.toString()
	// 		.padStart(2, "0");

	// 	changeMonthState(defaultMonth);
	// }, []);

	const onProceedPayment = () => {
		if (!payoutActive) return;
		updateRecruiterSalary.prototype.onSuccess = () => {
			setPayoutActive(false);
			toast.success("All drivers Paid");
		};
		dispatch(
			updateRecruiterSalary({
				id: data?._id,
				data: {
					salaryStatus: SalaryStatus.Paid,
				},
			})
		);
	};
	const getYearData = (value) => {
		const filtered = data?.drivers?.filter(
			(date) => moment(date?.createdAt).format("YYYY") == value.toString()
		);
		setData({
			...data,
			payout: filtered
				?.filter((f) => f.salary)
				.reduce(
					(acc, curr) =>
						acc +
						curr.salary *
							((attendanceWithId || {})[curr?._id]?.filter(
								(a) => a?.status?.toLowerCase() !== "absent"
							)?.length || 0),
					0
				),
		});
		getTableData(filtered);
		const hasUnpaidDriver = filtered?.some(
			(driver) => driver?.attendance?.salaryStatus === "unpaid"
		);
		if (
			id &&
			hasUnpaidDriver &&
			checkDate(payoutCycle?.disbursementDate, selectedMonth, value)
		) {
			setPayoutActive(hasUnpaidDriver);
		}
	};
	const getMonthData = (value) => {
		const filtered = data?.drivers?.filter(
			(date) => moment(date?.createdAt).format("MM") == value
		);
		setData({
			...data,
			payout: filtered
				?.filter((f) => f.salary)
				.reduce(
					(acc, curr) =>
						acc +
						curr.salary *
							((attendanceWithId || {})[curr?._id]?.filter(
								(a) => a?.status?.toLowerCase() !== "absent"
							)?.length || 0),
					0
				),
		});
		getTableData(filtered);
		const hasUnpaidDriver = filtered?.some(
			(driver) => driver?.attendance?.salaryStatus === "unpaid"
		);
		if (
			id &&
			hasUnpaidDriver &&
			checkDate(payoutCycle?.disbursementDate, value, selectedYear)
		) {
			setPayoutActive(hasUnpaidDriver);
		}
	};

	return (
		<div className="SalaryManagementHeader">
			<div>
				<Select
					onChange={(value) => setId(value)}
					w={300}
					size="sm"
					styles={{
						rightSection: { pointerEvents: "none" },
						input: {
							borderRadius: "10px",
							fontSize: "14px",
						},
					}}
					rightSection={
						<svg
							width="8"
							height="8"
							viewBox="0 0 13 8"
							fill="none"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
								fill="#0E2227"
							/>
						</svg>
					}
					placeholder="Choose Recruiter"
					data={selectRecruitersData}
				/>

				<Flex mt={15}>
					<Select
						disabled={!id.length}
						onChange={(value) => {
							changeMonthState(value);
							return getMonthData(value);
						}}
						mr={20}
						maw={150}
						size="xs"
						styles={{
							rightSection: { pointerEvents: "none" },
							input: {
								borderRadius: "10px",
								fontSize: "12px",
								background: "#EFF6F7",
								borderColor: "#9DB2B8",
							},
						}}
						rightSection={
							<svg
								width="8"
								height="8"
								viewBox="0 0 13 8"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
									fill="#0E2227"
								/>
							</svg>
						}
						placeholder="Month"
						data={[
							{ value: "01", label: "Jan" },
							{ value: "02", label: "Feb" },
							{ value: "03", label: "Mar" },
							{ value: "04", label: "Apr" },
							{ value: "05", label: "May" },
							{ value: "06", label: "Jun" },
							{ value: "07", label: "Jul" },
							{ value: "08", label: "Aug" },
							{ value: "09", label: "Sep" },
							{ value: "10", label: "Oct" },
							{ value: "11", label: "Nov" },
							{ value: "12", label: "Dec" },
						]}
						value={selectedMonth}
					/>
					<Select
						disabled={!id.length}
						onChange={(value) => {
							changeYearState(value);
							return getYearData(value);
						}}
						maw={100}
						size="xs"
						styles={{
							rightSection: { pointerEvents: "none" },
							input: {
								borderRadius: "10px",
								fontSize: "12px",
								background: "#EFF6F7",
								borderColor: "#9DB2B8",
							},
						}}
						rightSection={
							<svg
								width="8"
								height="8"
								viewBox="0 0 13 8"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
									fill="#0E2227"
								/>
							</svg>
						}
						placeholder="Year"
						data={years}
						value={selectedYear}
					/>
					{(selectedMonth || selectedYear) && id ? (
						<div className="clear_box">
							<p
								onClick={clearSelects}
								style={{
									textAlign: "left",
									cursor: "pointer",
									fontWeight: 400,
									fontSize: "11px",
									color: "#24A0ED",
									textDecoration: "underline",
									margin: "0px",
								}}
							>
								<span>Clear All</span>
							</p>
						</div>
					) : null}
				</Flex>
			</div>
			{id && (
				<div className="payout_box">
					<p>Total Payout</p>
					<h5>{data?.payout ? data?.payout : "00"}</h5>
					<HoverCard
						shadow="md"
						arrowPosition={"center"}
						withArrow
						position="bottom-end"
					>
						<HoverCard.Target>
							<button
								onClick={onProceedPayment}
								className={
									payoutActive
										? "payout_btn active"
										: "payout_btn"
								}
							>
								<svg
									width="12"
									height="18"
									viewBox="0 0 12 18"
									fill="none"
								>
									<path
										d="M7.42843 11.1445L4.57129 14.0017L7.42843 16.8588"
										stroke={
											payoutActive ? "#fff" : "#C2D0D6"
										}
										strokeWidth="1.02857"
									/>
									<path
										d="M10.3301 6.50195C10.769 7.26205 11 8.12427 11 9.00195C11 9.87964 10.769 10.7419 10.3301 11.502C9.89129 12.262 9.2601 12.8932 8.5 13.3321C7.7399 13.7709 6.87768 14.002 6 14.002"
										stroke={
											payoutActive ? "#fff" : "#C2D0D6"
										}
										strokeWidth="1.02857"
										strokeLinecap="round"
									/>
									<path
										d="M4.57157 6.85938L7.42871 4.00223L4.57157 1.14509"
										stroke={
											payoutActive ? "#fff" : "#C2D0D6"
										}
										strokeWidth="1.02857"
									/>
									<path
										d="M1.66987 11.502C1.23103 10.7419 1 9.87964 1 9.00195C1 8.12427 1.23103 7.26205 1.66987 6.50195C2.10871 5.74186 2.7399 5.11067 3.5 4.67183C4.2601 4.23298 5.12232 4.00195 6 4.00195"
										stroke={
											payoutActive ? "#fff" : "#C2D0D6"
										}
										strokeWidth="1.02857"
										strokeLinecap="round"
									/>
								</svg>
								Process Payment
							</button>
						</HoverCard.Target>
						{!payoutActive ? (
							<HoverCard.Dropdown maw={200} bg={"#0E2227"}>
								<Text size="sm" c={"#fff"} fz={12}>
									Process Payment will unable on disbursement
									date
								</Text>
							</HoverCard.Dropdown>
						) : (
							<></>
						)}
					</HoverCard>
					<CSVLink data={csvData} headers={csvHeader}>
						<button className="csv_btn">Download CSV</button>
					</CSVLink>
					{/* <Modal
            opened={statusModal}
            onClose={() => setStatusModal(false)}
            centered
            withCloseButton={false}
            size="sm"
            padding={0}
            radius={10}
            overlayProps={{
              blur: 5,
              color: "#fff",
              backgroundopacity: 1,
            }}
          >
            <PaymentConfimationModal
              name={data?.name}
              id={data?._id}
              payout={data?.payout}
              drivercount={data?.drivers?.length}
              setStatusModal={setStatusModal}
              setPayoutActive={setPayoutActive}
            />
          </Modal> */}
				</div>
			)}
		</div>
	);
};

export default SalaryManagementHeader;
