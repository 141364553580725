import { createSlice } from "@reduxjs/toolkit";
import { isEmpty, isObject } from "../../utils/functions";

const initialState = {
	loading: false,
	error: null,
	data: [],
	driverAttendances: {
		loading: false,
		data: [],
		error: null,
	},
	updateAttendance: {
		loading: false,
		updated: false,
		error: null,
	},
};

const attendanceSlice = createSlice({
	name: "attendance",
	initialState,
	reducers: {
		// read
		getAllAttendances: (state) => {
			state.loading = true;
		},
		getAllAttendancesFulfilled: (state, action) => {
			state.loading = false;
			if (Array.isArray(action.payload)) {
				state.data = action.payload;
			}
		},
		getAllAttendancesRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getAttendanceByDriverId: (state) => {
			state.driverAttendances.loading = true;
		},
		getAttendanceByDriverIdFulfilled: (state, action) => {
			state.driverAttendances.loading = false;
			if (Array.isArray(action.payload)) {
				state.driverAttendances.data = action.payload;
			}
		},
		getAttendanceByDriverIdRejected: (state, action) => {
			state.driverAttendances.loading = false;
			state.driverAttendances.error = action.payload;
		},

		// update
		updateAttendanceByDriverId: (state) => {
			state.updateAttendance.loading = true;
			state.updateAttendance.updated = false;
		},
		updateAttendanceByDriverIdFulfilled: (state, action) => {
			state.updateAttendance.loading = false;
			state.updateAttendance.updated = true;
		},
		updateAttendanceByDriverIdRejected: (state, action) => {
			state.updateAttendance.loading = false;
			state.updateAttendance.updated = false;
			state.updateAttendance.error = action.payload;
		},
	},
});

export const {
	// read
	getAllAttendances,
	getAllAttendancesFulfilled,
	getAllAttendancesRejected,
	getAttendanceByDriverId,
	getAttendanceByDriverIdFulfilled,
	getAttendanceByDriverIdRejected,
	// update
	updateAttendanceByDriverId,
	updateAttendanceByDriverIdFulfilled,
	updateAttendanceByDriverIdRejected,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
