import "./ViewDocumentModal.css";
import img from "../../assets/images/no data.jpg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const ViewDocumentModal = ({ close, image, documentName, large = false }) => {
  return (
    <div>
      <div className="present_modal_header">
        <div>{documentName || "Document Name"} </div>
        <svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
          <path
            d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="ViewDocumentModal">
        <TransformWrapper>
          <TransformComponent>
            <img
              src={image || img}
              alt="document_image"
              className={large ? "large" : ""}
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>
  );
};

export default ViewDocumentModal;
