import {
	useState,
	useCallback,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from "react";
import {
	useJsApiLoader,
	GoogleMap,
	Marker,
	Circle,
} from "@react-google-maps/api";
import { Box } from "@mantine/core";

const libraries = ["maps", "places"];
const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const GoogleMapsComponent = forwardRef(
	({ setLocation, setLocationObj, popup, hubLocation, radius }, ref) => {
		const { mapRef, inputRef, radiusInputRef } = ref;
		const { isLoaded } = useJsApiLoader({
			googleMapsApiKey: GOOGLE_API_KEY,
			libraries: libraries,
		});

		const [map, setMap] = useState(null);
		const [radius1, setRadius] = useState(radius || 50);
		const [markerPosition, setMarkerPosition] = useState({
			lat: 0,
			lng: 0,
		});

		useEffect(() => {
			if (
				window.google &&
				window.google.maps &&
				window.google.maps.places &&
				inputRef.current
			) {
				const searchBox = new window.google.maps.places.SearchBox(
					inputRef.current
				);
				searchBox.addListener("places_changed", () => {
					const places = searchBox.getPlaces();
					const place = places[0];
					if (place != null) {
						const newCenter = {
							lat: place.geometry.location.lat(),
							lng: place.geometry.location.lng(),
						};
						setMarkerPosition(newCenter);
						fetchLocation(newCenter);
						setZoom(map);
					}
				});
			}
		}, [inputRef.current]);

		const fetchCurrentLocation = (force = false) => {
			if (popup && hubLocation && !force) {
				setMarkerPosition(hubLocation);
				fetchLocation(hubLocation);
				setZoom(map);
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition((position) => {
						const lat = position.coords.latitude;
						const lng = position.coords.longitude;
						setMarkerPosition({ lat, lng });
						fetchLocation({ lat, lng });
						setZoom(map);
					});
				}
			}
		};
		useImperativeHandle(mapRef, () => ({
			fetchCurrentLocation,
		}));

		if (popup) {
			useEffect(() => {
				fetchCurrentLocation();
			}, [hubLocation]);
		} else {
			useEffect(() => {
				fetchCurrentLocation();
			}, [navigator.geolocation]);
		}

		async function handleDragEnd(e) {
			const newCoordinates = { lat: e.latLng.lat(), lng: e.latLng.lng() };

			fetchLocation(newCoordinates);
			setMarkerPosition(newCoordinates);
			setLocation(newCoordinates);
			setZoom(map);
		}

		const setZoom = (map) => {
			if (map) {
				map.setZoom(19);
			}
		};

		const onLoad = useCallback(function callback(map) {
			setMap(map);
			setZoom(map);
		}, []);

		const onUnmount = useCallback(function callback(map) {
			setMap(null);
		}, []);

		useEffect(() => {
			const handleInputChange = () => {
				if (radiusInputRef.current) {
					setRadius(
						Math.max(
							10,
							parseInt(radiusInputRef.current.value || 0)
						)
					);
				}
			};

			if (radiusInputRef.current) {
				radiusInputRef.current.addEventListener(
					"input",
					handleInputChange
				);
			}

			return () => {
				if (radiusInputRef.current) {
					radiusInputRef.current.removeEventListener(
						"input",
						handleInputChange
					);
				}
			};
		}, [radiusInputRef]);

		const fetchLocation = (newCoordinates) => {
			const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newCoordinates?.lat},${newCoordinates?.lng}&key=${GOOGLE_API_KEY}`;

			fetch(geocodingUrl)
				.then((response) => response.json())
				.then((data) => {
					if (data.status === "OK") {
						const results = data.results[0];
						let city = null,
							state = null,
							postalCode = null,
							streetNumber = null,
							route = null;
						let isSublocalitySet = false;
						for (const component of results.address_components) {
							if (
								component.types.includes("sublocality_level_1")
							) {
								city = component.long_name;
								isSublocalitySet = true;
							} else if (
								!isSublocalitySet &&
								component.types.includes("locality")
							) {
								city = component.long_name;
							} else if (
								component.types.includes(
									"administrative_area_level_1"
								)
							) {
								state = component.long_name;
							} else if (
								component.types.includes("postal_code")
							) {
								postalCode = component.long_name;
							} else if (
								component.types.includes("street_number")
							) {
								streetNumber = component.long_name;
							} else if (component.types.includes("route")) {
								route = component.long_name;
							}
						}

						const address = [streetNumber, route]
							.filter((a) => a != null)
							.join(", ");

						setLocationObj({
							city,
							state,
							postalCode,
							address,
							radius: radius1,
						});
						setLocation(newCoordinates);
					} else {
						console.log(
							"Geocoding request failed. Check your coordinates and API key."
						);
					}
				})
				.catch((error) => {
					console.error("An error occurred while geocoding:", error);
				});
		};

		if (!isLoaded) {
			return <>loading...</>;
		}
		return (
			<Box>
				<GoogleMap
					map
					center={markerPosition}
					zoom={15}
					mapContainerStyle={{
						width: "100%",
						height: popup ? "80vh" : "100vh",
					}}
					options={{
						zoomControl: false,
						streetViewControl: false,
						mapTypeControl: false,
						fullscreenControl: false,
					}}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					<Marker
						position={markerPosition}
						draggable
						onDragEnd={handleDragEnd}
					/>
					<Circle
						center={markerPosition}
						radius={radius1}
						options={{
							fillColor: "#06b9f0",
							strokeColor: "#06b9f0",
						}}
					/>
				</GoogleMap>
			</Box>
		);
	}
);

export default GoogleMapsComponent;
