import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
  createHoliday,
  createHolidayFulfilled,
  createHolidayRejected,
  deleteHoliday,
  deleteHolidayFulfilled,
  deleteHolidayRejected,
  getHolidays,
  getHolidaysFulfilled,
  getHolidaysRejected,
  updateHoliday,
  updateHolidayFulfilled,
  updateHolidayRejected,
} from "../reducers/holidayReducer";
import { getErrorMessage } from "../../utils/functions";

function* createHolidaySaga(action) {
  try {
    const res = yield call(api.post, "/holiday", action.payload?.data);
    // console.log("createHolidaySaga", res?.data);
    if (createHoliday.prototype.onSuccess) {
      createHoliday.prototype.onSuccess();
    }
    yield put(createHolidayFulfilled(res?.data));
  } catch (err) {
    if (createHoliday.prototype.onReject) {
      createHoliday.prototype.onReject();
    }
    yield put(createHolidayRejected(getErrorMessage(err)));
  }
}

function* getHolidaysSaga() {
  try {
    const res = yield call(api.get, "/holiday");
    // console.log("getHolidaysSaga", res?.data);
    if (getHolidays.prototype.onSuccess) {
      getHolidays.prototype.onSuccess();
    }
    yield put(getHolidaysFulfilled(res?.data));
  } catch (err) {
    if (getHolidays.prototype.onReject) {
      getHolidays.prototype.onReject();
    }
    yield put(getHolidaysRejected(getErrorMessage(err)));
  }
}

function* updateHolidaySaga(action) {
  try {
    const id = action.payload?.id;
    const data = action.payload?.data;
    const res = yield call(api.patch, `/holiday/${id}`, data);
    // console.log("updateHolidaySaga", res?.data);
    if (updateHoliday.prototype.onSuccess) {
      updateHoliday.prototype.onSuccess();
    }
    yield put(updateHolidayFulfilled(res?.data));
  } catch (err) {
    if (updateHoliday.prototype.onReject) {
      updateHoliday.prototype.onReject();
    }
    yield put(updateHolidayRejected(getErrorMessage(err)));
  }
}

function* deleteHolidaySaga(action) {
  try {
    const id = action.payload?.id;
    const res = yield call(api.delete, `/holiday/${id}`);
    // console.log("updateHolidaySaga", res?.data);
    if (deleteHoliday.prototype.onSuccess) {
      deleteHoliday.prototype.onSuccess();
    }
    yield put(deleteHolidayFulfilled(res?.data));
  } catch (err) {
    if (deleteHoliday.prototype.onReject) {
      deleteHoliday.prototype.onReject();
    }
    yield put(deleteHolidayRejected(getErrorMessage(err)));
  }
}

export function* holidaySaga() {
  yield takeLatest(createHoliday.type, createHolidaySaga);
  yield takeLatest(getHolidays.type, getHolidaysSaga);
  yield takeLatest(updateHoliday.type, updateHolidaySaga);
  yield takeLatest(deleteHoliday.type, deleteHolidaySaga);
}
