import { createSlice } from "@reduxjs/toolkit";
import { isEmpty, isObject } from "../../utils/functions";

const initialState = {
	data: [],
	loading: false,
	error: null,
	fetchedSupervisor: {
		data: {},
		loading: false,
		error: null,
	},
	pendingApprovalSupervisors: { data: [], loading: false, error: null },
	supervisorDrivers: { data: [], loading: false, error: null },
};

const supervisorReducer = createSlice({
	name: "supervisor",
	initialState,
	reducers: {
		createSupervisor: (state) => {
			state.loading = true;
			state.error = null;
		},
		createSupervisorFulfilled: (state) => {
			state.loading = false;
		},
		createSupervisorRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getSupervisors: (state) => {
			state.loading = true;
			state.error = null;
		},
		getSupervisorsFulfilled: (state, action) => {
			state.loading = false;
			if (Array.isArray(action.payload)) state.data = action.payload;
		},
		getSupervisorsRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		fetchSupervisors: (state) => {
			state.loading = true;
			state.error = null;
		},
		fetchSupervisorsFulfilled: (state) => {
			state.loading = false;
		},
		fetchSupervisorsRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		fetchSupervisorDrivers: (state) => {
			state.supervisorDrivers.loading = true;
			state.supervisorDrivers.error = null;
		},
		fetchSupervisorDriversFulfilled: (state, action) => {
			state.supervisorDrivers.loading = false;
			if (Array.isArray(action.payload))
				state.supervisorDrivers.data = action.payload;
		},
		fetchSupervisorDriversRejected: (state, action) => {
			state.supervisorDrivers.loading = false;
			state.supervisorDrivers.error = action.payload;
		},
		getPendingApprovalSupervisors: (state) => {
			state.pendingApprovalSupervisors.loading = true;
			state.pendingApprovalSupervisors.null;
		},
		getPendingApprovalSupervisorsFulfilled: (state, action) => {
			state.pendingApprovalSupervisors.loading = false;
			if (Array.isArray(action.payload))
				state.pendingApprovalSupervisors.data = action.payload;
		},
		getPendingApprovalSupervisorsRejected: (state, action) => {
			state.pendingApprovalSupervisors.loading = false;
			state.pendingApprovalSupervisors.error = action.payload;
		},
		getSupervisorById: (state) => {
			state.fetchedSupervisor.loading = true;
			state.fetchedSupervisor.error = null;
		},
		getSupervisorByIdFulfilled: (state, action) => {
			state.fetchedSupervisor.loading = false;
			if (isObject(action.payload) && !isEmpty(action.payload))
				state.fetchedSupervisor.data = action.payload;
			state.fetchedSupervisor.error = null;
		},
		getSupervisorByIdRejected: (state, action) => {
			state.fetchedSupervisor.loading = false;
			state.fetchedSupervisor.error = action.payload;
		},
		updateSupervisor: (state) => {
			state.loading = true;
			state.error = null;
		},
		updateSupervisorFulfilled: (state) => {
			state.loading = false;
		},
		updateSupervisorRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		checkSupervisorStatus: (state) => {
			state.loading = true;
			state.error = null;
		},
		checkSupervisorStatusFulfilled: (state) => {
			state.loading = false;
		},
		checkSupervisorStatusRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		updateSupervisorApproval: (state) => {
			state.loading = true;
			state.error = null;
		},
		updateSupervisorApprovalFulfilled: (state) => {
			state.loading = false;
		},
		updateSupervisorApprovalRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		updateSupervisorPicture: (state) => {
			state.loading = true;
			state.error = null;
		},
		updateSupervisorPictureFulfilled: (state) => {
			state.loading = false;
		},
		updateSupervisorPictureRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deleteSupervisor: (state) => {
			state.loading = true;
			state.error = null;
		},
		deleteSupervisorFulfilled: (state) => {
			state.loading = false;
		},
		deleteSupervisorRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		assignSupervisor: (state) => {
			state.loading = true;
			state.error = null;
		},
		assignSupervisorFulfilled: (state) => {
			state.loading = false;
		},
		assignSupervisorRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const {
	createSupervisor,
	createSupervisorFulfilled,
	createSupervisorRejected,
	getSupervisors,
	getSupervisorsFulfilled,
	getSupervisorsRejected,
	fetchSupervisors,
	fetchSupervisorsFulfilled,
	fetchSupervisorsRejected,
	fetchSupervisorDrivers,
	fetchSupervisorDriversFulfilled,
	fetchSupervisorDriversRejected,
	getPendingApprovalSupervisors,
	getPendingApprovalSupervisorsFulfilled,
	getPendingApprovalSupervisorsRejected,
	getSupervisorById,
	getSupervisorByIdFulfilled,
	getSupervisorByIdRejected,
	updateSupervisor,
	updateSupervisorFulfilled,
	updateSupervisorRejected,
	checkSupervisorStatus,
	checkSupervisorStatusFulfilled,
	checkSupervisorStatusRejected,
	updateSupervisorApproval,
	updateSupervisorApprovalFulfilled,
	updateSupervisorApprovalRejected,
	updateSupervisorPicture,
	updateSupervisorPictureFulfilled,
	updateSupervisorPictureRejected,
	deleteSupervisor,
	deleteSupervisorFulfilled,
	deleteSupervisorRejected,
	assignSupervisor,
	assignSupervisorFulfilled,
	assignSupervisorRejected,
} = supervisorReducer.actions;

export default supervisorReducer.reducer;
