const config = {
  backend: {
    host: import.meta.env.VITE_BACKEND_HOST,
  },
  tokenHeader: import.meta.env.VITE_TOKEN_HEADER,
};

// console.log("config", config);

export default config;
