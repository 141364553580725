import { Modal, TextInput } from "@mantine/core";
import GoogleMapsComponent from "../../../../components/GoogleMapsComponent/GoogleMapsComponent";
import "./Map.css";
import { useRef, useState } from "react";
import CreateNewHubMmodal from "../../../../components/CreateNewHubMmodal/CreateNewHubMmodal";
import HubSavedConfirmationModal from "../../../../components/CreateNewHubMmodal/HubSavedConfirmationModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Map = ({ popup, close, hubLocation, radius }) => {
	const [statusModal, setStatusModal] = useState(false);
	const [createHub, setcreateHub] = useState(false);
	const [location, setLocation] = useState();
	const [hubName, onHubSave] = useState();
	const [locationObj, setLocationObj] = useState({});
	const [radius1, setRadius] = useState(radius);
	const mapRef = useRef(null);
	const inputRef = useRef();
	const radiusInputRef = useRef();
	const navigator = useNavigate();
	const handleFetchCurrentLocation = () => {
		if (mapRef.current) {
			toast.info("Fetching current location", {
				autoClose: 2000,
				theme: "dark",
			});
			mapRef.current.fetchCurrentLocation(true);
		}
	};
	const handleRadiusChange = (e) => {
		setLocationObj({
			...locationObj,
			radius: Math.max(10, parseInt(e.target.value || 0)),
		});
		setRadius(Math.max(10, parseInt(e.target.value || 0)));
	};
	return (
		<div>
			{popup && (
				<div
					className="present_modal_header"
					style={{ paddingBottom: "10px" }}
				>
					<div style={{ paddingLeft: "8px" }}>
						Select location on map
					</div>
					<svg
						onClick={() => close()}
						width="30"
						viewBox="0 0 36 36"
						fill="none"
					>
						<path
							d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
							fill="white"
						/>
					</svg>
				</div>
			)}
			<div className="maps_box">
				<GoogleMapsComponent
					setLocation={setLocation}
					setLocationObj={setLocationObj}
					ref={{
						mapRef: mapRef,
						inputRef: inputRef,
						radiusInputRef: radiusInputRef,
					}}
					popup={popup}
					hubLocation={hubLocation}
					radius={radius1}
				/>
				<div className="map_ovarlay_input">
					<div
						className="input_box"
						onClick={() => {
							navigator(-1);
						}}
					>
						<svg width="20" viewBox="0 0 30 26" fill="none">
							<path
								d="M2.90991 11.6523H28.5484C28.9338 11.6523 29.3033 11.7887 29.5758 12.0314C29.8482 12.2741 30.0013 12.6032 30.0013 12.9464C30.0013 13.2896 29.8482 13.6188 29.5758 13.8615C29.3033 14.1042 28.9338 14.2405 28.5484 14.2405H2.90991C2.52458 14.2405 2.15504 14.1042 1.88257 13.8615C1.6101 13.6188 1.45703 13.2896 1.45703 12.9464C1.45703 12.6032 1.6101 12.2741 1.88257 12.0314C2.15504 11.7887 2.52458 11.6523 2.90991 11.6523Z"
								fill="black"
							/>
							<path
								d="M3.50984 12.9424L15.56 23.6729C15.8328 23.9159 15.986 24.2454 15.986 24.5891C15.986 24.9327 15.8328 25.2623 15.56 25.5053C15.2872 25.7483 14.9172 25.8848 14.5313 25.8848C14.1455 25.8848 13.7755 25.7483 13.5027 25.5053L0.426836 13.8586C0.291535 13.7384 0.184189 13.5956 0.110945 13.4384C0.0377017 13.2812 0 13.1126 0 12.9424C0 12.7722 0.0377017 12.6036 0.110945 12.4464C0.184189 12.2892 0.291535 12.1464 0.426836 12.0262L13.5027 0.379505C13.7755 0.136512 14.1455 0 14.5313 0C14.9172 0 15.2872 0.136512 15.56 0.379505C15.8328 0.622497 15.986 0.952067 15.986 1.29571C15.986 1.63935 15.8328 1.96892 15.56 2.21192L3.50984 12.9424Z"
								fill="black"
							/>
						</svg>
					</div>
					<TextInput
						ref={inputRef}
						w={400}
						size="sm"
						styles={() => ({
							input: {
								padding: "0 10px",
								borderRadius: "7px",
								border: "1px solid #9DB2B8",
								boxShadow:
									"0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
							},
						})}
						placeholder="Search Place"
						rightSection={
							<svg width="18" viewBox="0 0 27 28" fill="none">
								<ellipse
									cx="10.3418"
									cy="10.5395"
									rx="8.34184"
									ry="8.65079"
									stroke="#222222"
									strokeWidth="3"
								/>
								<path
									d="M19.75 20.2969L25.3557 26.1102"
									stroke="#222222"
									strokeWidth="3"
									strokeLinecap="round"
								/>
							</svg>
						}
					/>
					<div
						className="input_box"
						onClick={handleFetchCurrentLocation}
					>
						<svg width="20" viewBox="0 0 32 32" fill="none">
							<circle
								cx="15.9993"
								cy="15.9993"
								r="10.8333"
								stroke="#029AC9"
								strokeWidth="2"
							/>
							<circle cx="16" cy="16" r="7" fill="#029AC9" />
							<path
								d="M16 5.16667V1"
								stroke="#029AC9"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M26.8333 16L31 16"
								stroke="#029AC9"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M16 31.0007L16 26.834"
								stroke="#029AC9"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M0.999349 16H5.16602"
								stroke="#029AC9"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
					</div>
				</div>

				<div
					className="map_ovarlay_button"
					style={{
						...(popup ? { bottom: "20px" } : {}),
						display: "flex",
						height: 35,
					}}
				>
					<div
						style={{
							display: "flex",
							background: "#EFF5F7",
							boxShadow: "1px 1px 12px rgba(14, 34, 39, 0.50)",
							borderRadius: 10,
							padding: "5px 6px 5px 15px",
							alignItems: "center",
							marginRight: 40,
						}}
					>
						<span style={{ marginRight: 12, fontSize: "14px" }}>
							Hub radius in meters px
						</span>
						<TextInput
							defaultValue={radius1 || 50}
							ref={radiusInputRef}
							onChange={handleRadiusChange}
							className="radius_input"
						/>
					</div>
					<button
						onClick={() => {
							if (popup) {
								close(location, locationObj);
								return;
							}
							setcreateHub(true);
						}}
						className="black_button"
					>
						Confirm
					</button>
				</div>

				<Modal
					opened={createHub}
					onClose={() => setcreateHub(false)}
					centered
					withCloseButton={false}
					size="sm"
					padding={0}
					radius={10}
					overlayProps={{
						blur: 5,
						color: "#fff",
						backgroundopacity: 1,
					}}
				>
					<CreateNewHubMmodal
						loc={location}
						locationObject={locationObj}
						setStatusModal={setStatusModal}
						setcreateHub={setcreateHub}
						onSave={onHubSave}
					/>
				</Modal>
				<Modal
					opened={statusModal}
					onClose={() => setStatusModal(false)}
					closeOnClickOutside={false}
					centered
					withCloseButton={false}
					size="xs"
					padding={0}
					radius={10}
					overlayProps={{
						blur: 5,
						color: "#fff",
						backgroundopacity: 1,
					}}
				>
					<HubSavedConfirmationModal
						setStatusModal={setStatusModal}
						hubName={hubName}
					/>
				</Modal>
			</div>
		</div>
	);
};

export default Map;
