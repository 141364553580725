"use client";
import "./style.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { getChallanByVehicle } from "../../../../redux/reducers/challanReducer";
import { CSVLink } from "react-csv";

const dateFormat = "DD-MM-YYYY HH:mm:ss";

const RowValue = ({ name, value, url, className, read_more }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const textRef = useRef(null);
	const [isClamped, setIsClamped] = useState(false);

	const handleToggle = () => {
		setIsExpanded(!isExpanded);
	};
	useEffect(() => {
		if (!textRef?.current || !read_more) return;
		const element = textRef.current;
		if (element.scrollHeight > element.clientHeight) {
			setIsClamped(true);
		} else {
			setIsClamped(false);
		}
	}, [value, textRef]);
	return (
		<div className={`challan_row_value ${className}`}>
			<p title={name}>{name}</p>
			{url === true ? (
				<a href={value} target="_blank">
					Open
				</a>
			) : (
				<p className={isExpanded ? "read_full" : ""}>
					{typeof value === "object" ? (
						<ol
							ref={textRef}
							className={isExpanded ? "read_full" : ""}
						>
							{value?.map((a, i) => (
								<li title={a} key={i}>
									{a}
								</li>
							))}
						</ol>
					) : (
						<p title={value} ref={textRef}>
							{value}
						</p>
					)}
					{read_more === true && isClamped ? (
						<span onClick={handleToggle}>
							{!isExpanded ? "Read More" : "Read Less"}
						</span>
					) : (
						<></>
					)}
				</p>
			)}
		</div>
	);
};

const ChallanRow = ({ data }) => {
	return (
		<div className="challan_row">
			{/* <div className="row">
				<RowValue name="Date" value={data?.date} />
			</div> */}
			<div className="row flex_wrap grid_row">
				<RowValue
					name="Owner Name"
					value={data?.owner_name}
					className="w-10"
				/>
				<RowValue
					name="Vehicle no"
					value={data?.vehicle_no}
					className="w-10"
				/>
				<RowValue
					name="Challan no"
					value={data?.challan_no}
					className="w-20"
				/>
				<RowValue
					name="Offense name"
					value={data?.offense_name}
					className="w-25"
					read_more={true}
				/>
				<RowValue
					name="State Code"
					value={data?.state_code}
					className="w-5"
				/>
				<RowValue
					name="Vehicle Impound"
					value={data?.vehicle_impound}
					className="w-10"
				/>
				<RowValue name="Amount" value={data?.amount} className="w-10" />
				{/* <RowValue
					name="Challan Doc"
					value={data?.challan_doc}
					url={true}
					className="w-10"
				/> */}
				<RowValue name="Status" value={data?.status} className="w-10" />
			</div>
		</div>
	);
};

export default function ChallanListModal({ onClose, vehicle_no }) {
	const [value, onChange] = useState([]);
	const [calenderOpen, setCalenderOpen] = useState(false);
	const [csvData, setCSVData] = useState([]);
	const challan_list = useSelector(
		(state) => state?.challan?.fetchedChallan?.data
	);
	useEffect(() => {
		if (challan_list?.length) {
			setCSVData([
				[
					"Driver Name",
					"Vehicle Number",
					"Challan no",
					"Offense name",
					"State Code",
					"Date",
					"Amount",
					"Status",
				],
				...challan_list
					.map((a) => [
						a?.owner_name,
						a?.vehicle_no,
						a?.challan_no,
						a?.offense_name,
						a?.state_code,
						a?.date,
						a?.amount,
						a?.status,
					])
					.sort((a, b) => a[1].localeCompare(b[1])),
			]);
		}
	}, [challan_list]);

	const dispatch = useDispatch();

	const loader = (
		<div className="loading">
			<svg
				className="loader"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				width="35"
			>
				<circle
					style={{ opacity: "25%" }}
					cx="12"
					cy="12"
					r="10"
					stroke="#0E2227"
					strokeWidth="4"
				></circle>
				<path
					style={{ opacity: "75%" }}
					fill="#0E2227"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				></path>
			</svg>
		</div>
	);
	const no_challan_found = (
		<div className="no_challan_found">
			<p>No Challan Found</p>
		</div>
	);

	useEffect(() => {
		if (vehicle_no?.length) {
			dispatch(getChallanByVehicle({ vehicle_no }));
		}
	}, [vehicle_no]);
	return (
		<div className="challan_modal">
			<div className="header">
				<p>
					Challan Details (
					{
						// ?.filter((a) => {
						// 	const date = moment(a?.date, dateFormat).tz(
						// 		"Asia/Kolkata",
						// 	);

						// 	if (value?.length === 2) {
						// 		return (
						// 			date >= moment(value[0]) &&
						// 			date <= moment(value[1])
						// 		);
						// 	} else return true;
						// })
						challan_list?.length || 0
					}
					)
				</p>
				<div className="action_right">
					{/* <div className="date_select">
						<DateRangePicker
							onChange={onChange}
							value={value}
							isOpen={calenderOpen}
							showLeadingZeros={true}
							onCalendarClose={() => setCalenderOpen(false)}
							format={"y-MM-dd"}
							maxDate={new Date()}
						/>
						<div
							className="date_select_inner"
							onClick={() => setCalenderOpen(true)}
						>
							<p>
								{value[0]
									? value[0]?.toDateString()
									: "YYYY-MM-DD"}
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
							>
								<path
									d="M13.6422 9.31563L11.0797 6.06563C11.033 6.00629 10.9734 5.95832 10.9055 5.92531C10.8375 5.89229 10.763 5.87509 10.6875 5.875H9.675C9.57031 5.875 9.5125 5.99531 9.57656 6.07812L11.8313 8.9375H2.375C2.30625 8.9375 2.25 8.99375 2.25 9.0625V10C2.25 10.0687 2.30625 10.125 2.375 10.125H13.2484C13.6672 10.125 13.9 9.64375 13.6422 9.31563Z"
									fill="#707070"
								/>
							</svg>
							<p>
								{value[1]
									? value[1]?.toDateString()
									: "YYYY-MM-DD"}
							</p>
						</div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							onClick={() => onChange([])}
						>
							<path
								d="M4.40771 10.4831L7.00906 7.88677L9.63511 10.5178C9.88175 10.7649 10.1558 10.8915 10.3992 10.6485L10.6403 10.4079C10.8838 10.1649 10.7577 9.89066 10.5111 9.64355L7.88503 7.01248L10.4864 4.41616C10.7298 4.17317 10.8505 3.89916 10.6038 3.65205L10.3566 3.40437C10.11 3.15726 9.83575 3.27737 9.59229 3.52036L6.99094 6.11668L4.36489 3.48562C4.11825 3.2385 3.84424 3.11195 3.60078 3.35494L3.35971 3.59555C3.11625 3.83854 3.24228 4.11279 3.48892 4.3599L6.11497 6.99097L3.51362 9.58729C3.27015 9.83028 3.14951 10.1043 3.39615 10.3514L3.64336 10.5991C3.89 10.8462 4.16425 10.7261 4.40771 10.4831Z"
								fill="black"
							/>
						</svg>
					</div> */}
					<CSVLink
						data={csvData}
						className="download_btn"
						filename={`${vehicle_no}_challan`}
					>
						Download CSV
					</CSVLink>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="17"
						height="17"
						viewBox="0 0 17 17"
						fill="none"
						onClick={onClose}
					>
						<path
							d="M2.59453 16.3628L8.25545 10.8225L13.9701 16.4369C14.5068 16.9643 15.1031 17.2343 15.6329 16.7158L16.1575 16.2024C16.6873 15.6838 16.4131 15.0986 15.8764 14.5713L10.1617 8.95682L15.8226 3.41648C16.3524 2.89796 16.615 2.31324 16.0782 1.78592L15.5403 1.2574C15.0035 0.730085 14.4067 0.986393 13.8769 1.50492L8.21602 7.04525L2.50135 1.43078C1.96463 0.903463 1.36834 0.633405 0.838531 1.15193L0.313929 1.66536C-0.215882 2.18388 0.0583763 2.76911 0.595101 3.29642L6.30977 8.9109L0.648857 14.4512C0.119045 14.9698 -0.143487 15.5545 0.393237 16.0818L0.931195 16.6103C1.46792 17.1376 2.06472 16.8813 2.59453 16.3628Z"
							fill="#F0F0F0"
						/>
					</svg>
				</div>
			</div>
			<div className="challan_list_container">
				{challan_list === null
					? loader
					: !(
							// ?.filter((a) => {
							// 			const date = moment(a?.date, dateFormat).tz(
							// 				"Asia/Kolkata",
							// 			);

							// 			if (value?.length === 2) {
							// 				return (
							// 					date >= moment(value[0]) &&
							// 					date <= moment(value[1])
							// 				);
							// 			} else return true;
							// 	  })
							challan_list?.length
					  )
					? no_challan_found
					: // ?.filter((a) => {
					  // 	const date = moment(a?.date, dateFormat).tz(
					  // 		"Asia/Kolkata",
					  // 	);

					  // 	if (value?.length === 2) {
					  // 		return (
					  // 			date >= moment(value[0]) &&
					  // 			date <= moment(value[1])
					  // 		);
					  // 	} else return true;
					  // })
					  challan_list?.map((a, i) => (
							<ChallanRow key={i} data={a} />
					  ))}
			</div>
		</div>
	);
}
