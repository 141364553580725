import socket from ".";
import { addNotification } from "../redux/reducers/notificationReducer";
import { getClients } from "../redux/reducers/clientReducer";
import {
	fetchDrivers,
	getDriversDltReq,
} from "../redux/reducers/driverReducer";
import { getHolidays } from "../redux/reducers/holidayReducer";
import { getHubs } from "../redux/reducers/hubReducer";
import { getPayoutCycles } from "../redux/reducers/payoutCycleReducer";
import { getPayouts } from "../redux/reducers/payoutReducer";
import { getRecruiters } from "../redux/reducers/recruiterReducer";
import { getShifts } from "../redux/reducers/shiftReducer";
import {
	fetchSupervisors,
	getSupervisors,
} from "../redux/reducers/supervisorReducer";
import store from "../redux/store";
import { EventName } from "../utils/constants";
import { fetchAllVehicles } from "../redux/reducers/vehicleReducer";
import { fetchAllVehiclesType } from "../redux/reducers/vehiclesTypeReducer";

export function listenEvents() {
	Object.values(EventName).forEach((eventName) => {
		socket.on(eventName, (body) => {
			console.log("eventName:", eventName, "data:", body);

			// call actions to fetch and update states
			switch (eventName) {
				case EventName.DriversUpdate:
					store.dispatch(fetchDrivers()); // fecth all drivers
					store.dispatch(getDriversDltReq());
					break;
				case EventName.HolidaysUpdate:
					store.dispatch(getHolidays());
					break;
				case EventName.HubsUpdate:
					store.dispatch(getHubs());
					break;
				case EventName.RecruitersUpdate:
					store.dispatch(getRecruiters());
					break;
				case EventName.ShiftsUpdate:
					store.dispatch(getShifts());
					break;
				case EventName.SupervisorsUpdate:
					store.dispatch(fetchSupervisors());
					store.dispatch(getSupervisors());
					break;
				case EventName.PayoutCyclesUpdate:
					store.dispatch(getPayoutCycles());
					break;
				case EventName.PayoutsUpdate:
					store.dispatch(getPayouts());
					break;
				case EventName.ClientsUpdate:
					store.dispatch(getClients());
					break;
				case EventName.VehiclesUpdate:
					store.dispatch(fetchAllVehicles());
					break;
				case EventName.VehiclesTypeUpdate:
					store.dispatch(fetchAllVehiclesType(body));
					break;
				case EventName.LateAttendance:
					store.dispatch(addNotification(body));
					break;
				case EventName.PhoneChanged:
					store.dispatch(addNotification(body));
					break;
			}
		});
	});
}
