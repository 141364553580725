import { Flex, Modal, Select, TextInput } from "@mantine/core";
import "./SupervisorManagementHeader.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchSupervisorDrivers } from "../../../../redux/reducers/supervisorReducer";
import AssignDriversSupervisor from "../../../../components/AssignDriversSupervisor/AssignDriversSupervisor";
import { toast } from "react-toastify";
import { sortSelectOptions } from "../../../../utils/functions";

const SupervisorManagementHeader = ({
	drivers,
	setSearch,
	setSupervisor,
	setHub,
	supervisorId,
	table,
}) => {
	const [selectedSupervisor, setSelectedSupervisor] = useState(supervisorId);
	const [selectedHub, setSelectedHub] = useState("");
	const [searchVal, setSearchVal] = useState("");
	const [hubSelect, setHubSelect] = useState([]);
	const [selectedSupervisorMain, setSupervisorMain] = useState({});
	const supervisors = useSelector((state) => state.supervisor?.data);
	const dispatch = useDispatch();
	const [supervisorSelect, setSupervisorSelect] = useState([]);
	sortSelectOptions(supervisorSelect);
	sortSelectOptions(hubSelect);
	useEffect(() => {
		if (supervisors?.length) {
			setSupervisorSelect(
				supervisors?.map((supervisor) => ({
					label: supervisor.name || "",
					value: supervisor._id,
				}))
			);
		}
	}, [supervisors]);
	const supervisorDrivers = useSelector(
		(state) => state.supervisor.supervisorDrivers.data
	);
	const [opened, setOpened] = useState(false);
	const [hubDrivers, setDrivers] = useState([]);

	const open = () => {
		setOpened(true);
	};

	const close = () => {
		setOpened(false);
	};

	useEffect(() => {
		if (selectedSupervisor?.length) {
			dispatch(
				fetchSupervisorDrivers({
					id: selectedSupervisor,
				})
			);
			setSupervisorMain(
				supervisors?.find(
					(supervisor) => supervisor?._id == selectedSupervisor
				)
			);
		}
	}, [selectedSupervisor]);
	useEffect(() => {
		if (supervisorDrivers?.length) {
			const uniqueLabels = new Set();
			const uniqueSupervisors = supervisorDrivers
				?.filter((supervisor) => {
					const label = supervisor?.hub.name;
					if (uniqueLabels.has(label)) {
						return false;
					} else {
						uniqueLabels.add(label);
						return true;
					}
				})
				.map((supervisor) => ({
					label: supervisor?.hub.name,
					value: supervisor?.hub._id,
				}));
			setHubSelect(uniqueSupervisors);
		}
	}, [supervisorDrivers]);

	const clearSelects = () => {
		setSearch("");
		setSupervisor(null);
		setSelectedSupervisor(null);
		setHub(null);
		setSelectedHub(null);
		setSearchVal("");
		setHubSelect([]);
	};
	return (
		<div className="SupervisorManagementHeader">
			<div className="head">
				<p>Find Drivers</p>
				<Flex mt={15}>
					<Select
						disabled={false}
						onChange={(e) => {
							setSelectedSupervisor(e);
							setSupervisor(e);
						}}
						mr={20}
						size="xs"
						styles={{
							rightSection: { pointerEvents: "none" },
							input: {
								borderRadius: "10px",
								fontSize: "12px",
								background: "#EFF6F7",
								borderColor: "#9DB2B8",
							},
						}}
						rightSection={
							<svg
								width="8"
								height="8"
								viewBox="0 0 13 8"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
									fill="#0E2227"
								/>
							</svg>
						}
						placeholder="Select Supervisor"
						data={supervisorSelect}
						value={selectedSupervisor}
						searchable
					/>
					<Select
						disabled={false}
						onChange={(e) => {
							setSelectedHub(e);
							setHub(e);
						}}
						size="xs"
						styles={{
							rightSection: { pointerEvents: "none" },
							input: {
								borderRadius: "10px",
								fontSize: "12px",
								background: "#EFF6F7",
								borderColor: "#9DB2B8",
							},
						}}
						rightSection={
							<svg
								width="8"
								height="8"
								viewBox="0 0 13 8"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
									fill="#0E2227"
								/>
							</svg>
						}
						placeholder="Select Hub"
						data={selectedSupervisor?.length ? hubSelect : []}
						value={selectedHub}
						searchable={selectedSupervisor?.length}
						onClick={() => {
							if (!selectedSupervisor?.length) {
								toast.error("Select Supervisor first.");
							}
						}}
					/>
				</Flex>
				<Flex align={"center"} mt={30}>
					<TextInput
						w={300}
						styles={() => ({
							input: {
								padding: "10px",
								borderRadius: "10px",
								border: "1px solid #9DB2B8",
								boxShadow:
									"0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
							},
						})}
						placeholder="Search Driver name, Ref ID, etc"
						rightSection={
							<svg
								width="22"
								height="20"
								viewBox="0 0 27 28"
								fill="none"
							>
								<ellipse
									cx="10.3418"
									cy="10.5395"
									rx="8.34184"
									ry="8.65079"
									stroke="#222222"
									strokeWidth="3"
								/>
								<path
									d="M19.75 20.2969L25.3557 26.1102"
									stroke="#222222"
									strokeWidth="3"
									strokeLinecap="round"
								/>
							</svg>
						}
						onChange={(e) => {
							setSearchVal(e?.target?.value);
							if (setSearch) setSearch(e?.target?.value);
						}}
						value={searchVal}
					/>
					{selectedSupervisor?.length ||
					selectedHub?.length ||
					searchVal?.length ? (
						<div className="clear_box">
							<p
								onClick={clearSelects}
								style={{
									textAlign: "left",
									cursor: "pointer",
									fontWeight: 400,
									fontSize: "11px",
									color: "#24A0ED",
									textDecoration: "underline",
									margin: "0px",
								}}
							>
								<span>Clear All</span>
							</p>
						</div>
					) : (
						<></>
					)}
				</Flex>
			</div>
			<div className="driver_box">
				<p>Drivers</p>
				<h5>{table?.length || 0}</h5>
				{selectedSupervisor && (
					<div className="button" onClick={() => open(true)}>
						<p>Add Driver</p>
					</div>
				)}
				<Modal
					opened={opened}
					onClose={close}
					centered
					withCloseButton={false}
					size="xl"
					padding={0}
					radius={10}
					overlayProps={{
						blur: 5,
						color: "#fff",
						backgroundopacity: 1,
					}}
				>
					<AssignDriversSupervisor
						hubs={selectedSupervisorMain?.hubs?.map((h) => h?._id)}
						close={close}
						setParentDrivers={setDrivers}
						supervisorId={selectedSupervisorMain?._id}
					/>
				</Modal>
			</div>
		</div>
	);
};

export default SupervisorManagementHeader;
