import {
	Divider,
	Input,
	Modal,
	Select,
	TextInput,
	Tooltip,
} from "@mantine/core";
import { useId } from "@mantine/hooks";
import { IMaskInput } from "react-imask";
import "./EditSupervisorDetails.css";
import Loading from "../../../components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
	assignSupervisor,
	getSupervisorById,
} from "../../../redux/reducers/supervisorReducer";
import { updateSupervisor } from "../../../redux/reducers/supervisorReducer";
import { toast } from "react-toastify";
import AssignDriversSupervisor from "../../../components/AssignDriversSupervisor/AssignDriversSupervisor";
import HubSelectorSupervisor from "../../../components/HubSelectorSupervisor/HubSelectorSupervisor";

const EditSupervisorDetails = () => {
	const inputId = useId();
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const supervisorInfo = useSelector(
		(state) => state.supervisor.fetchedSupervisor
	);
	const loading = useSelector(
		(state) => state.supervisor.fetchedSupervisor.loading
	);
	const error = useSelector(
		(state) => state.supervisor.fetchedSupervisor.error
	);

	const [hubs, setHubs] = useState([]);

	const [opened, setOpened] = useState(false);
	const [drivers, setDrivers] = useState([]);
	const [driversObj, setDriversObj] = useState({});

	const driverList = useSelector((state) => state.driver.drivers.data);

	useEffect(() => {
		if (driverList?.length) {
			setDrivers(
				driverList
					?.filter((d) => hubs.includes(d?.hub?._id))
					?.filter((d) => d?.supervisor?._id === id)
					.map((d) => d._id)
			);
			setDriversObj(
				driverList
					?.filter((d) => hubs.includes(d?.hub?._id))
					?.filter((d) => d?.supervisor?._id === id)
					.reduce((drivers, driver, i) => {
						if (!drivers[driver?.hub?._id]?.length) {
							drivers[driver?.hub?._id] = [];
						}
						drivers[driver?.hub?._id].push(driver);
						return drivers;
					}, {})
			);
		}
	}, [driverList, hubs]);

	const open = () => {
		setOpened(true);
	};

	const close = () => {
		setOpened(false);
	};

	const supervisor = supervisorInfo.data || {};
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	const notify = () => {
		toast.success("Profile Saved", {
			onClose: () => navigate("/dashboard/fleet"),
			autoClose: 500,
		});
	};
	const saveProfile = () => {
		const originalObject = {
			name: name,
			email: email,
			phone: phone.replace(/^\+91\s?/, ""),
			hubs: hubs,
		};
		const data = Object.fromEntries(
			Object.entries(originalObject).filter(([, value]) => value !== "")
		);

		updateSupervisor.prototype.onSuccess = () => {
			dispatch(
				assignSupervisor({
					id: id,
					data: { ids: drivers },
				})
			);
			assignSupervisor.prototype.onSuccess = () => {
				notify();
			};
		};

		updateSupervisor.prototype.onError = () => {
			alert(`Unable to update profile. Error: ${error}`);
		};

		dispatch(updateSupervisor({ id: id, data: data }));
	};

	const handleNameChange = (e) => {
		setName(e.target.value);
	};
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};
	const handlePhoneChange = (e) => {
		setPhone(e.target.value);
	};

	useEffect(() => {
		setName(supervisor.name || "");
	}, [supervisor.name]);
	useEffect(() => {
		setEmail(supervisor.email || "");
	}, [supervisor.email]);
	useEffect(() => {
		setPhone(supervisor.phone?.toString() || "");
	}, [supervisor.phone]);
	useEffect(() => {
		setHubs(supervisor?.hubs || []);
	}, [supervisor?.hubs]);

	useEffect(() => {
		if (id) {
			dispatch(getSupervisorById({ id }));
		}
	}, [id]);

	return loading ? (
		<Loading />
	) : (
		<div className="edit_supervisor_details_container">
			<div
				style={{ cursor: "pointer", marginBottom: "10px" }}
				onClick={() => navigate("/dashboard/supervisormanagement")}
			>
				<svg width="20" viewBox="0 0 31 24" fill="none">
					<path
						d="M2.90991 10.752H28.5484C28.9338 10.752 29.3033 10.8778 29.5758 11.1017C29.8482 11.3256 30.0013 11.6293 30.0013 11.946C30.0013 12.2627 29.8482 12.5665 29.5758 12.7904C29.3033 13.0143 28.9338 13.1401 28.5484 13.1401H2.90991C2.52458 13.1401 2.15504 13.0143 1.88257 12.7904C1.6101 12.5665 1.45703 12.2627 1.45703 11.946C1.45703 11.6293 1.6101 11.3256 1.88257 11.1017C2.15504 10.8778 2.52458 10.752 2.90991 10.752Z"
						fill="black"
					/>
					<path
						d="M3.50984 11.9424L15.56 21.8438C15.8328 22.068 15.986 22.3721 15.986 22.6892C15.986 23.0063 15.8328 23.3104 15.56 23.5346C15.2872 23.7588 14.9172 23.8848 14.5313 23.8848C14.1455 23.8848 13.7755 23.7588 13.5027 23.5346L0.426836 12.7878C0.291535 12.6769 0.184189 12.5451 0.110945 12.4C0.0377017 12.255 0 12.0995 0 11.9424C0 11.7853 0.0377017 11.6298 0.110945 11.4847C0.184189 11.3397 0.291535 11.2079 0.426836 11.097L13.5027 0.350182C13.7755 0.125964 14.1455 0 14.5313 0C14.9172 0 15.2872 0.125964 15.56 0.350182C15.8328 0.5744 15.986 0.878505 15.986 1.1956C15.986 1.51269 15.8328 1.81679 15.56 2.04101L3.50984 11.9424Z"
						fill="black"
					/>
				</svg>{" "}
				<span>Edit Supervisor Details</span>
			</div>
			<Divider color="#C2D0D6" w={"100%"} />
			<div className="editDetailsForm_box">
				{/* Name */}
				<div className="row">
					<p className="title" style={{ minWidth: "15vw" }}>
						Name
					</p>
					<div style={{ display: "grid" }}>
						<TextInput
							w={250}
							placeholder="Your Name"
							radius="md"
							size="sm"
							value={name}
							onChange={handleNameChange}
						/>
					</div>
				</div>

				{/* Email Address */}
				<div className="row">
					<p className="title" style={{ minWidth: "15vw" }}>
						Email Address
					</p>
					<div style={{ display: "grid" }}>
						<TextInput
							w={250}
							placeholder="Your Email"
							radius="md"
							size="sm"
							value={email}
							onChange={handleEmailChange}
						/>
					</div>
				</div>

				{/* Phone Number */}
				<div className="row">
					<p className="title" style={{ minWidth: "15vw" }}>
						Phone Number
					</p>
					<div style={{ display: "grid" }}>
						<Input
							radius="md"
							w={220}
							size="sm"
							component={IMaskInput}
							mask="+91 0000000000"
							id={inputId}
							placeholder="Your phone"
							value={phone}
							onChange={handlePhoneChange}
						/>
					</div>
				</div>
				{/* Hub dropdown */}
				<div className="row">
					<p className="title" style={{ minWidth: "15vw" }}>
						Assign Hub
					</p>
					<div style={{ display: "grid" }}>
						<HubSelectorSupervisor
							getValue={(value) => {
								setHubs(value);
							}}
							value={hubs}
							supervisor={id}
						/>
					</div>
				</div>
				{/* Driver Container */}
				<div>
					<p className="title">Add Driver(s)</p>
					<div className="add_driver_div">
						<span className="flex">
							{drivers?.length > 0 && (
								<span className="count">
									{drivers?.length} drivers added
								</span>
							)}
							<p>
								{drivers?.length ? (
									<>add more from assigned hubs</>
								) : (
									<>
										Add drivers who are assigned to your
										selected hubs
									</>
								)}
							</p>
						</span>
						<Tooltip
							withArrow
							disabled={hubs?.length}
							label="Select Hubs"
						>
							<div
								className="assign_driver"
								onClick={() => {
									if (hubs?.length) open();
								}}
							>
								<span>Assign Drivers</span>
							</div>
						</Tooltip>
						<Modal
							opened={opened}
							onClose={close}
							centered
							withCloseButton={false}
							size="xl"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<AssignDriversSupervisor
								hubs={hubs}
								close={close}
								setParentDrivers={setDrivers}
								supervisorId={id}
								driversObj={driversObj}
							/>
						</Modal>
					</div>
				</div>
			</div>
			<div className="stepper_btn_grp4">
				<button className="black_button" onClick={saveProfile}>
					Save
				</button>
			</div>
		</div>
	);
};

export default EditSupervisorDetails;
