import { call, put, select, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
  createPayout,
  createPayoutFulfilled,
  createPayoutRejected,
  deletePayout,
  deletePayoutFulfilled,
  deletePayoutRejected,
  getPayouts,
  getPayoutsFulfilled,
  getPayoutsRejected,
  updatePayout,
  updatePayoutFulfilled,
  updatePayoutRejected,
} from "../reducers/payoutReducer";
import { getErrorMessage } from "../../utils/functions";

function* createPayoutSaga(action) {
  try {
    const payout = yield select((state) => state.Payout);
    const formData = action.payload?.data || payout?.formData;
    const res = yield call(api.post, "/payout", formData);
    // console.log("createPayoutSaga", res?.data);
    if (createPayout.prototype.onSuccess) {
      createPayout.prototype.onSuccess();
    }
    yield put(createPayoutFulfilled(res?.data));
  } catch (err) {
    if (createPayout.prototype.onReject) {
      createPayout.prototype.onReject();
    }
    yield put(createPayoutRejected(getErrorMessage(err)));
  }
}

function* getPayoutsSaga() {
  try {
    const res = yield call(api.get, "/payout");
    // console.log("getPayoutsSaga", res?.data);
    if (getPayouts.prototype.onSuccess) {
      getPayouts.prototype.onSuccess();
    }
    yield put(getPayoutsFulfilled(res?.data));
  } catch (err) {
    if (getPayouts.prototype.onReject) {
      getPayouts.prototype.onReject();
    }
    yield put(getPayoutsRejected(getErrorMessage(err)));
  }
}

function* updatePayoutSaga(action) {
  try {
    const payout = yield select((state) => state.Payout);
    const id = action.payload?.id || payout?.id;
    const data = action.payload?.data || payout?.formData;
    const res = yield call(api.patch, `/payout/${id}`, data);
    // console.log("updatePayoutSaga", res?.data);
    if (updatePayout.prototype.onSuccess) {
      updatePayout.prototype.onSuccess();
    }
    yield put(updatePayoutFulfilled(res?.data));
  } catch (err) {
    if (updatePayout.prototype.onReject) {
      updatePayout.prototype.onReject();
    }
    yield put(updatePayoutRejected(getErrorMessage(err)));
  }
}

function* deletePayoutSaga(action) {
  try {
    const id = action.payload?.id;
    const res = yield call(api.delete, `/payout/${id}`);
    // console.log("updatePayoutSaga", res?.data);
    if (deletePayout.prototype.onSuccess) {
      deletePayout.prototype.onSuccess();
    }
    yield put(deletePayoutFulfilled(res?.data));
  } catch (err) {
    if (deletePayout.prototype.onReject) {
      deletePayout.prototype.onReject();
    }
    yield put(deletePayoutRejected(getErrorMessage(err)));
  }
}

export function* payoutSaga() {
  yield takeLatest(createPayout.type, createPayoutSaga);
  yield takeLatest(getPayouts.type, getPayoutsSaga);
  yield takeLatest(updatePayout.type, updatePayoutSaga);
  yield takeLatest(deletePayout.type, deletePayoutSaga);
}
