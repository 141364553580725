import { call, put, takeLatest } from "redux-saga/effects";
import {
  getNotifications,
  getNotificationsFulfilled,
  getNotificationsRejected,
  seenNotifications,
  seenNotificationsFulfilled,
  seenNotificationsRejected,
} from "../reducers/notificationReducer";
import api from "../../api";
import { getErrorMessage } from "../../utils/functions";

// <------------------------READ----------------------------------->
function* getNotificationsSaga() {
  try {
    const res = yield call(api.get, `/notifications`);
    // console.log("getNotificationsSaga", res?.data);
    if (getNotifications.prototype.onSuccess) {
      getNotifications.prototype.onSuccess();
    }
    yield put(getNotificationsFulfilled(res?.data));
  } catch (err) {
    if (getNotifications.prototype.onReject) {
      getNotifications.prototype.onReject();
    }
    yield put(getNotificationsRejected(getErrorMessage(err)));
  }
}

// <------------------------UPDATE----------------------------------->
function* seenNotificationsSaga() {
  try {
    const res = yield call(api.post, `/notifications/seen`);
    // console.log("seenNotificationsSaga", res?.data);
    if (seenNotifications.prototype.onSuccess) {
      seenNotifications.prototype.onSuccess();
    }
    yield put(seenNotificationsFulfilled(res?.data));
  } catch (err) {
    if (seenNotifications.prototype.onReject) {
      seenNotifications.prototype.onReject();
    }
    yield put(seenNotificationsRejected(getErrorMessage(err)));
  }
}

// <<<<<<<<<<<<<<<< MAIN SAGA >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export function* notificationSaga() {
  // read
  yield takeLatest(getNotifications.type, getNotificationsSaga);

  // update
  yield takeLatest(
    seenNotifications.type,
    seenNotificationsSaga
  );
}
