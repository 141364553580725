import { all, put, takeLatest } from "redux-saga/effects";
import {
	fetchData,
	fetchDataFulFilled,
	fetchDataRejected,
} from "../reducers/appReducer";
import {
	getDeactivatedDrivers,
	getDrivers,
	getDriversDltReq,
	getPendingApprovalDrivers,
	getRejectedApprovalDrivers,
} from "../reducers/driverReducer";
import { getHolidays } from "../reducers/holidayReducer";
import { getShifts } from "../reducers/shiftReducer";
import { fetchSupervisors } from "../reducers/supervisorReducer";
import { getHubs } from "../reducers/hubReducer";
import { getRecruiters } from "../reducers/recruiterReducer";
import { getClients } from "../reducers/clientReducer";
import { getPayoutCycles } from "../reducers/payoutCycleReducer";
import { getPayouts } from "../reducers/payoutReducer";
import { getNotifications } from "../reducers/notificationReducer";
import { fetchAllVehicles } from "../reducers/vehicleReducer";
import { getAllAttendances } from "../reducers/attendanceReducer";
import { fetchAllVehiclesType } from "../reducers/vehiclesTypeReducer";
import { getAllChallans, getChallans } from "../reducers/challanReducer";

function* fetchDataSaga() {
	try {
		yield all([
			put(getAllAttendances()),
			put(getDrivers()),
			put(getChallans()),
			put(getAllChallans()),
			put(getDriversDltReq()),
			put(getPendingApprovalDrivers()),
			put(getRejectedApprovalDrivers()),
			put(getDeactivatedDrivers()),
			put(getHolidays()),
			put(getShifts()),
			put(fetchSupervisors()),
			put(getHubs()),
			put(getRecruiters()),
			put(getClients()),
			put(getPayoutCycles()),
			put(getPayouts()),
			put(getNotifications()),
			put(fetchAllVehicles()),
			put(fetchAllVehiclesType()),
		]);

		yield put(fetchDataFulFilled());
	} catch (err) {
		yield put(fetchDataRejected(err?.message));
	}
}

export default function* appSaga() {
	yield takeLatest(fetchData.type, fetchDataSaga);
}
