import { Tabs, createStyles } from "@mantine/core";
import PendingApprovalHeader from "./PendingApprovalHeader";
import "./PendingApprovalTabs.css";
import TableBox from "../../../components/Table/TableBox";
import { useState } from "react";
const useStyles = createStyles(() => ({
  tab: {
    minWidth: "100px",
    padding: "5px",
    backgroundColor: "#fff",
    cursor: "pointer",
    marginRight: "5px",
    fontSize: "14px",
    border: "1px solid #C2D0D6",
    borderRadius: " 15px 15px 0px 0px",
    "&[data-active]": {
      backgroundColor: "#0E2228",
      border: "none",
      color: "#fff",
    },
    "&:last-of-type": {
      marginRight: "0px",
    },
  },
  tabsList: {
    display: "flex",
    marginLeft: "30px",
  },
}));
const PendingApprovalTabs = ({ searchQuery }) => {
  const { classes } = useStyles();
  const [state, selectState] = useState(null);
  const [city, selectCity] = useState(null);
  const [hubId, selectHubId] = useState(null);
  const [shiftId, selectShiftId] = useState(null);
  return (
    <div className="PendingApprovalTabs_box">
      <PendingApprovalHeader
        onStateSelected={selectState}
        onCitySelected={selectCity}
        onHubSelected={selectHubId}
        onShiftSelected={selectShiftId}
        searchQuery={searchQuery}
      />

      <Tabs mx={"auto"} classNames={classes} defaultValue="all" unstyled>
        <Tabs.List>
          <Tabs.Tab value="all">All</Tabs.Tab>
          <Tabs.Tab value="Present">Present</Tabs.Tab>
          <Tabs.Tab value="Absent">Absent</Tabs.Tab>
          <Tabs.Tab value="Unmarked">Unmarked</Tabs.Tab>
          <Tabs.Tab value="Late">Late</Tabs.Tab>
          <Tabs.Tab value="idle">Idle</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="all">
          <TableBox
            state={state}
            city={city}
            hubId={hubId}
            shiftId={shiftId}
            searchQuery={searchQuery}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Present">
          <TableBox
            state={state}
            city={city}
            hubId={hubId}
            shiftId={shiftId}
            searchQuery={searchQuery}
            status="present"
          />
        </Tabs.Panel>
        <Tabs.Panel value="Absent">
          <TableBox
            state={state}
            city={city}
            hubId={hubId}
            shiftId={shiftId}
            searchQuery={searchQuery}
            status="absent"
          />
        </Tabs.Panel>
        <Tabs.Panel value="Unmarked">
          <TableBox
            state={state}
            city={city}
            hubId={hubId}
            shiftId={shiftId}
            searchQuery={searchQuery}
            status="unmarked"
          />
        </Tabs.Panel>
        <Tabs.Panel value="Late">
          <TableBox
            state={state}
            city={city}
            hubId={hubId}
            shiftId={shiftId}
            searchQuery={searchQuery}
            status="late"
          />
        </Tabs.Panel>
        <Tabs.Panel value="idle">
          <TableBox
            state={state}
            city={city}
            hubId={hubId}
            shiftId={shiftId}
            searchQuery={searchQuery}
            status="idle"
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default PendingApprovalTabs;
