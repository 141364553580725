const LogoutModal = ({ setStatusModal, onClickLogout }) => {
  return (
    <div className="StatusConfirmationModal">
      <h1>Logout</h1>
      <h2>Are you sure you want to logout?</h2>

      <div className="status_btn_group">
        <button className="no_button" onClick={() => setStatusModal(false)}>
          No
        </button>
        <button className="yes_button" onClick={onClickLogout}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default LogoutModal;
