import { createSlice } from "@reduxjs/toolkit";
import { Role } from "../../utils/constants";
import { isEmpty, isObject } from "../../utils/functions";

const initialState = {
	updated: false,
	loading: false,
	error: null,
	driverId: null,
	queryParams: {},
	formData: {
		username: undefined,
		roles: [Role.Driver],
		name: undefined,
		email: undefined,
		phone: undefined,
		state: undefined,
		city: undefined,
		pincode: undefined,
		address: undefined,
		shift: undefined,
		hub: undefined,
		recruiter: undefined,
		supervisor: undefined,
		clients: undefined,
		vehicle: undefined, //{ models: [], number: "" },
		salary: undefined,
		approval: undefined, // {panCard, aadhaarCardFront, aadhaarCardBack, drivingLicense, status}
		active: undefined, // boolean
	},
	dltReqs: {
		loading: false,
		data: {},
		error: null,
	},
	fetchedDriver: {
		loading: false,
		data: {},
		error: null,
	},
	driverPicture: {
		updated: false,
	},
	drivers: {
		// active: true, approval.status: approved
		loading: false,
		data: [],
		error: null,
	},
	pendingApprovalDrivers: {
		loading: false,
		data: [],
		error: null,
	},
	rejectedApprovalDrivers: {
		loading: false,
		data: [],
		error: null,
	},
	deactivatedDrivers: {
		loading: false,
		data: [],
		error: null,
	},
};

const driverSlice = createSlice({
	name: "driver",
	initialState,
	reducers: {
		// create
		createDriver: (state) => {
			state.loading = true;
			state.error = null;
		},
		createDriverFulfilled: (state) => {
			state.loading = false;
		},
		createDriverRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},

		// read
		getDriverById: (state) => {
			state.fetchedDriver.loading = true;
		},
		getDriverByIdFulfilled: (state, action) => {
			state.fetchedDriver.loading = false;
			if (isObject(action.payload) && !isEmpty(action.payload)) {
				state.fetchedDriver.data = action.payload;
			}
		},
		getDriverByIdRejected: (state, action) => {
			state.fetchedDriver.loading = false;
			state.fetchedDriver.error = action.payload;
		},
		fetchDrivers: (state) => {
			// action for getting all drivers: rejected, approved, deactivated, etc...
			state.loading = true;
			state.error = null;
		},
		fetchDriversFulfilled: (state) => {
			state.loading = false;
		},
		fetchDriversRejected: (state, action) => {
			state.drivers.loading = false;
			state.drivers.error = action.payload;
		},
		getDrivers: (state) => {
			state.drivers.loading = true;
			state.drivers.null;
		},
		getDriversFulfilled: (state, action) => {
			state.drivers.loading = false;
			if (Array.isArray(action.payload))
				state.drivers.data = action.payload;
		},
		getDriversRejected: (state, action) => {
			state.drivers.loading = false;
			state.drivers.error = action.payload;
		},
		getDriversDltReq: (state) => {
			state.dltReqs.loading = true;
			state.dltReqs.null;
		},
		getDriversDltReqFulfilled: (state, action) => {
			state.dltReqs.loading = false;
			if (Array.isArray(action.payload))
				state.dltReqs.data = action.payload;
		},
		getDriversDltReqRejected: (state, action) => {
			state.dltReqs.loading = false;
			state.dltReqs.error = action.payload;
		},
		getPendingApprovalDrivers: (state) => {
			//   console.log("getPendingApprovalDrivers");
			state.pendingApprovalDrivers.loading = true;
			state.pendingApprovalDrivers.null;
		},
		getPendingApprovalDriversFulfilled: (state, action) => {
			state.pendingApprovalDrivers.loading = false;
			if (Array.isArray(action.payload))
				state.pendingApprovalDrivers.data = action.payload;
		},
		getPendingApprovalDriversRejected: (state, action) => {
			state.pendingApprovalDrivers.loading = false;
			state.pendingApprovalDrivers.error = action.payload;
		},
		getRejectedApprovalDrivers: (state) => {
			state.rejectedApprovalDrivers.loading = true;
			state.rejectedApprovalDrivers.null;
		},
		getRejectedApprovalDriversFulfilled: (state, action) => {
			state.rejectedApprovalDrivers.loading = false;
			if (Array.isArray(action.payload))
				state.rejectedApprovalDrivers.data = action.payload;
		},
		getRejectedApprovalDriversRejected: (state, action) => {
			state.rejectedApprovalDrivers.loading = false;
			state.rejectedApprovalDrivers.error = action.payload;
		},
		getDeactivatedDrivers: (state) => {
			state.deactivatedDrivers.loading = true;
			state.deactivatedDrivers.null;
		},
		getDeactivatedDriversFulfilled: (state, action) => {
			state.deactivatedDrivers.loading = false;
			if (Array.isArray(action.payload))
				state.deactivatedDrivers.data = action.payload;
		},
		getDeactivatedDriversRejected: (state, action) => {
			state.deactivatedDrivers.loading = false;
			state.deactivatedDrivers.error = action.payload;
		},

		// update
		updateDriver: (state) => {
			state.loading = true;
			state.error = null;
			state.updated = false;
		},
		updateDriverFulfilled: (state) => {
			state.loading = false;
			state.updated = true;
		},
		updateDriverRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.updated = false;
		},
		checkDriverStatus: (state) => {
			state.loading = true;
			state.error = null;
		},
		checkDriverStatusFulfilled: (state) => {
			state.loading = false;
		},
		checkDriverStatusRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		updateDriverApproval: (state) => {
			state.loading = true;
			state.error = null;
		},
		updateDriverApprovalFulfilled: (state) => {
			state.loading = false;
			state.formData.approval = initialState.formData.approval;
		},
		updateDriverApprovalRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		updateDriverPicture: (state) => {
			state.loading = true;
			state.error = null;
		},
		updateDriverPictureFulfilled: (state) => {
			state.loading = false;
			state.driverPicture.updated = true;
		},
		updateDriverPictureRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},

		// delete
		deleteDriver: (state) => {
			state.loading = true;
			state.error = null;
		},
		deleteDriverFulfilled: (state) => {
			state.loading = false;
		},
		deleteDriverRejected: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const {
	// create
	createDriver,
	createDriverFulfilled,
	createDriverRejected,
	// read
	getDriverById,
	getDriverByIdFulfilled,
	getDriverByIdRejected,
	fetchDrivers,
	fetchDriversFulfilled,
	fetchDriversRejected,
	getDrivers,
	getDriversFulfilled,
	getDriversRejected,
	getDriversDltReq,
	getDriversDltReqFulfilled,
	getDriversDltReqRejected,
	getPendingApprovalDrivers,
	getPendingApprovalDriversFulfilled,
	getPendingApprovalDriversRejected,
	getRejectedApprovalDrivers,
	getRejectedApprovalDriversFulfilled,
	getRejectedApprovalDriversRejected,
	getDeactivatedDrivers,
	getDeactivatedDriversFulfilled,
	getDeactivatedDriversRejected,
	// update
	updateDriver,
	updateDriverFulfilled,
	updateDriverRejected,
	checkDriverStatus,
	checkDriverStatusFulfilled,
	checkDriverStatusRejected,
	updateDriverApproval,
	updateDriverApprovalFulfilled,
	updateDriverApprovalRejected,
	updateDriverPicture,
	updateDriverPictureFulfilled,
	updateDriverPictureRejected,
	// delete
	deleteDriver,
	deleteDriverFulfilled,
	deleteDriverRejected,
} = driverSlice.actions;

export default driverSlice.reducer;
