import { Flex, Modal, TextInput } from "@mantine/core";
import "./CreateNewHubMmodal.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createHub, updateHub } from "../../redux/reducers/hubReducer";
import { useEffect } from "react";
import Map from "../../pages/Dashboard/HubsandClients/Map/Map";
const initialState = {
	data: [],
	loading: false,
	error: null,
	hubId: null,
	formData: {
		name: undefined,
		location: {
			lat: undefined,
			lng: undefined,
		},
		supervisor: undefined,
		state: undefined,
		city: undefined,
		pincode: undefined,
		address: undefined,
		landmark: undefined,
		radius: undefined,
	},
};
const CreateNewHubMmodal = ({
	setcreateHub,
	setStatusModal,
	loc,
	selectedHubid,
	onSave,
	locationObject,
}) => {
	const [openMap, setOpenMap] = useState(false);
	const [location, setLoc] = useState({});
	const [radius, setRadius] = useState((locationObject || {}).radius || 50);
	const [locationObj, setLocObj] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [formData, setFormData] = useState(initialState.formData);
	const dispatch = useDispatch();
	const hubs = useSelector((state) => state.hub?.data);
	const handleInputChange = (event) => {
		setIsSubmitted(false);
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	useEffect(() => {
		if (locationObject) {
			setLocObj(locationObject);
		}
	}, [locationObject]);

	useEffect(() => {
		if (loc) {
			setLoc(loc);
		}
	}, [loc]);

	useEffect(() => {
		setFormData({
			...formData,
			address: locationObj?.address,
			state: locationObj?.state,
			city: locationObj?.city,
			pincode: locationObj?.postalCode,
			radius: locationObj?.radius || 50,
		});
	}, [locationObj]);
	const onSubmit = () => {
		setIsSubmitted(true);
		if (
			!formData.name ||
			!formData.address ||
			!formData.state ||
			!formData.city ||
			!formData.radius ||
			!formData.pincode
		)
			return;
		setFormData({
			...formData,
			location: location,
		});
		createHub.prototype.onSuccess = () => {
			setStatusModal(true);
			setcreateHub(false);
			onSave(formData.name);
		};
		dispatch(
			createHub({
				data: {
					...formData,
					location: location,
				},
			})
		);
	};
	const onUpdate = () => {
		setFormData({
			...formData,
			location: location,
		});
		console.log("update", {
			...formData,
			location: location,
		});
		updateHub.prototype.onSuccess = () => {
			setStatusModal(true);
			setcreateHub(false);
			onSave(formData.name);
		};
		dispatch(
			updateHub({
				id: selectedHubid,
				data: {
					...formData,
					location: location,
				},
			})
		);
	};

	useEffect(() => {
		if (selectedHubid) {
			setFormData(hubs?.find((hub) => hub._id == selectedHubid));
			const location1 = hubs?.find((hub) => hub._id == selectedHubid);
			let loc = {
				...location1.location,
			};
			if (loc) {
				delete loc._id;
			}
			setLoc(loc);
			setRadius((location1 || {}).radius || 50);
		}
	}, [selectedHubid]);

	return (
		<div>
			<div
				className="present_modal_header"
				style={{ paddingBottom: "10px" }}
			>
				<div style={{ paddingLeft: "8px" }}>
					{selectedHubid ? "Edit Hub" : "Create New Hub"}
				</div>
				<svg
					onClick={() => setcreateHub(false)}
					width="30"
					viewBox="0 0 36 36"
					fill="none"
				>
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				className="CreateNewHubMmodal"
				style={{ position: "relative" }}
			>
				{selectedHubid && (
					<>
						<label
							style={{
								right: 20,
								top: 10,
								position: "absolute",
								cursor: "pointer",
								textDecoration: "underline",
							}}
							onClick={setOpenMap}
						>
							Select on Map
						</label>
						<Modal
							opened={openMap}
							onClose={() => setOpenMap(false)}
							centered
							withCloseButton={false}
							size="90vw"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<Map
								style={{ overflow: "hidden" }}
								popup={true}
								hubLocation={location}
								radius={radius || 50}
								close={(loc, locObj) => {
									setLoc(loc);
									setLocObj(locObj);
									if (locObj)
										setRadius((locObj || {}).radius || 50);
									setOpenMap(false);
								}}
							/>
						</Modal>
					</>
				)}

				<div>
					<label htmlFor="name">Hub Name</label>
					<TextInput
						value={formData?.name}
						styles={{
							input: {
								background: "#0E2227",
								borderColor:
									isSubmitted && !formData?.name
										? "red"
										: "#8399A3",
								color: "#fff",
							},
						}}
						name="name"
						onChange={handleInputChange}
						placeholder="Enter Hub Name"
						radius="md"
						size="sm"
					/>
				</div>
				<div>
					<label htmlFor="address">Address Details</label>
					<TextInput
						value={formData?.address}
						styles={{
							input: {
								background: "#0E2227",
								borderColor:
									isSubmitted && !formData?.address
										? "red"
										: "#8399A3",
								color: "#fff",
							},
						}}
						name="address"
						onChange={handleInputChange}
						placeholder="Plot No., Street No"
						radius="md"
						size="sm"
					/>
				</div>
				<div>
					<label htmlFor="landmark">Landmark</label>
					<TextInput
						value={formData?.landmark}
						styles={{
							input: {
								background: "#0E2227",
								borderColor: "#8399A3",
								color: "#fff",
							},
						}}
						name="landmark"
						onChange={handleInputChange}
						placeholder="Eg: Opposite supermarket"
						radius="md"
						size="sm"
					/>
				</div>

				<label style={{ marginTop: "10px" }}>
					Location (Auto Fetch)
				</label>
				<div>
					<Flex>
						<div>
							<label htmlFor="State">State</label>
							<TextInput
								value={formData?.state}
								mr={20}
								styles={{
									input: {
										background: "#0E2227",
										borderColor:
											isSubmitted && !formData?.state
												? "red"
												: "#8399A3",
										color: "#fff",
									},
								}}
								name="state"
								onChange={handleInputChange}
								maw={140}
								placeholder="State"
								radius="md"
								size="sm"
							/>
						</div>
						<div>
							<label htmlFor="City">City</label>
							<TextInput
								value={formData?.city}
								styles={{
									input: {
										background: "#0E2227",
										borderColor:
											isSubmitted && !formData?.city
												? "red"
												: "#8399A3",
										color: "#fff",
									},
								}}
								name="city"
								onChange={handleInputChange}
								maw={140}
								placeholder="City"
								radius="md"
								size="sm"
							/>
						</div>
					</Flex>
				</div>
				<div>
					<label htmlFor="pincode">Pincode</label>
					<TextInput
						value={formData?.pincode}
						styles={{
							input: {
								background: "#0E2227",
								borderColor:
									isSubmitted && !formData?.pincode
										? "red"
										: "#8399A3",
								color: "#fff",
							},
						}}
						name="pincode"
						onChange={handleInputChange}
						maw={140}
						placeholder="Pincode"
						radius="md"
						size="sm"
					/>
				</div>

				<div className="save_hub_btn">
					<button onClick={selectedHubid ? onUpdate : onSubmit}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};

export default CreateNewHubMmodal;
