import "./StatusConfirmationModal.css";
const StatusConfirmationModal = ({
	driver,
	setStatusModal,
	onSuccess,
	setUnmarked = false,
}) => {
	return (
		<div className="StatusConfirmationModal">
			<h1>{driver.name || "Driver Name"}</h1>
			<h2>
				{setUnmarked ? (
					"Mark Attendance?"
				) : (
					<>
						Change status from <span>Absent</span> to{" "}
						<span>Present</span>?
					</>
				)}
			</h2>

			<div className="status_btn_group">
				<button
					className="no_button"
					onClick={() => setStatusModal(false)}
				>
					No
				</button>
				<button
					className="yes_button"
					onClick={() => onSuccess() && setStatusModal(false)}
				>
					Yes
				</button>
			</div>
		</div>
	);
};

export default StatusConfirmationModal;
