import { Select, TextInput } from "@mantine/core";
import "./FleetManagementHeader.css";
import { useSelector } from "react-redux";
import { useState } from "react";
const FleetManagementHeader = ({
  onStateSelected,
  onCitySelected,
  onHubSelected,
  onShiftSelected,
  onSearch,
}) => {
  // redux
  const drivers = useSelector((state) => state.driver?.drivers?.data);
  const shifts = useSelector((state) => state.shift?.data);
  const states = {
    all: "All States",
    ...(drivers ?? [])
      .map((driver) => driver?.hub?.state || "")
      .filter((state) => state !== "")
      .reduce((acc, state) => {
        acc[state] = state;
        return acc;
      }, {}),
  };
  const city = (drivers ?? []).reduce((acc, driver) => {
    const state = driver?.hub?.state;
    const city = driver?.hub?.city;

    if (state && city) {
      if (!acc[state]) {
        acc[state] = { all: "All Cities" };
      }
      if (!Object.keys(acc[state]).includes(city)) {
        acc[state][city] = city;
      }
    }

    return acc;
  }, {});
  const hubs = (drivers ?? []).reduce((acc, driver) => {
    const state = driver?.hub?.state;
    const city = driver?.hub?.city;

    if (state && city) {
      if (!acc[state]) {
        acc[state] = {};
      }
      if (!acc[state][city]) {
        acc[state][city] = { all: { name: "All Hubs" } };
      }
      if (!Object.keys(acc[state][city]).includes(driver?.hub?._id)) {
        acc[state][city][driver?.hub?._id] = driver?.hub;
      }
    }

    return acc;
  }, {});
  const [selectedState, changeStateState] = useState("all");
  const [selectedCity, changeCityState] = useState(null);
  const [selectedHub, changeHubState] = useState(null);
  const [selectedShift, changeShiftState] = useState(null);

  const clearSelects = () => {
    changeStateState("all");
    changeCityState(null);
    changeHubState(null);
    changeShiftState(null);
    if (onStateSelected) {
      onStateSelected("");
    }
    if (onCitySelected) {
      onCitySelected("");
    }
    if (onHubSelected) {
      onHubSelected("");
    }
    if (onShiftSelected) {
      onShiftSelected("");
    }
  };

  const changeState = (state) => {
    if (state) {
      changeStateState(state);
      changeCityState("all");
      changeHubState(null);
    }
    if (onStateSelected) {
      onStateSelected(state);
    }
    if (onCitySelected) {
      onCitySelected("");
    }
    if (onHubSelected) {
      onHubSelected("");
    }
  };

  const changeCity = (city) => {
    if (city) {
      changeCityState(city);
      changeHubState("all");
    }
    if (onCitySelected) {
      onCitySelected(city);
    }
    if (onHubSelected) {
      onHubSelected("");
    }
  };

  const changeHub = (hubId) => {
    if (onHubSelected) {
      onHubSelected(hubId);
    }
    if (hubId) {
      changeHubState(hubId);
    }
  };

  const changeShift = (shiftId) => {
    if (onShiftSelected) {
      onShiftSelected(shiftId);
    }
    if (shiftId) {
      changeShiftState(shiftId);
    }
  };

  const changeSearch = (searchQuery) => {
    if (onSearch) {
      onSearch(searchQuery);
    }
  };
  return (
    <div className="PendingApprovalHeader">
      <div className="PendingApprovalHeader_number">
        <TextInput
          w={300}
          styles={() => ({
            input: {
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #9DB2B8",
              boxShadow: "0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
            },
          })}
          placeholder="Search driver profile"
          rightSection={
            <svg width="22" height="20" viewBox="0 0 27 28" fill="none">
              <ellipse
                cx="10.3418"
                cy="10.5395"
                rx="8.34184"
                ry="8.65079"
                stroke="#222222"
                strokeWidth="3"
              />
              <path
                d="M19.75 20.2969L25.3557 26.1102"
                stroke="#222222"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          }
          onChange={(e) => {
            changeSearch(e.target.value);
          }}
        />
      </div>
      <div className="filter_box">
        <div className="select_box"></div>

        <div className="select_box">
          <Select
            size="xs"
            styles={{
              rightSection: { pointerEvents: "none" },
              input: {
                borderRadius: "10px",
                fontSize: "12px",
              },
            }}
            rightSection={
              <svg width="10" height="8" viewBox="0 0 13 8" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
                  fill="#0E2227"
                />
              </svg>
            }
            placeholder="State"
            data={Object.keys(states).map((key) => {
              return {
                label: states[key],
                value: key,
              };
            })}
            onChange={changeState}
            value={selectedState}
            disabled={!Object.keys(states).length}
          />
        </div>

        <div className="select_box">
          <Select
            size="xs"
            styles={{
              rightSection: { pointerEvents: "none" },
              input: { borderRadius: "10px", fontSize: "12px" },
            }}
            rightSection={
              <svg width="10" height="8" viewBox="0 0 13 8" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
                  fill="#0E2227"
                />
              </svg>
            }
            placeholder="Select City"
            data={Object.keys(city[selectedState] || {}).map((key) => {
              return {
                label: city[selectedState][key],
                value: key,
              };
            })}
            onChange={changeCity}
            value={selectedCity}
            disabled={!Object.keys(city[selectedState] || {}).length}
          />
        </div>

        {(selectedState && selectedState != "all") ||
        (selectedCity && selectedCity != "all") ||
        (selectedHub && selectedHub != "all") ||
        selectedShift ? (
          <div className="clear_box">
            <p
              onClick={clearSelects}
              style={{
                textAlign: "right",
                cursor: "pointer",
                fontWeight: 400,
                fontSize: "11px",
                color: "#24A0ED",
                textDecoration: "underline",
              }}
            >
              <span style={{ marginLeft: "5px" }}>Clear All</span>
            </p>
          </div>
        ) : (
          <div className="dummy_box" />
        )}

        <div className="select_box">
          <Select
            size="xs"
            styles={{
              rightSection: { pointerEvents: "none" },
              input: { borderRadius: "10px", fontSize: "12px" },
            }}
            rightSection={
              <svg width="10" height="8" viewBox="0 0 13 8" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
                  fill="#0E2227"
                />
              </svg>
            }
            placeholder="Select Shift"
            data={[
              // ...[{ value: "all", label: "All Shifts" }],
              ...shifts?.map((shift) => ({
                value: shift._id,
                label: `${shift.startTime} to ${shift.endTime}`,
              })),
            ]}
            onChange={changeShift}
            value={selectedShift}
            disabled={!shifts.length}
          />
        </div>

        <div className="select_box">
          <Select
            size="xs"
            styles={{
              rightSection: { pointerEvents: "none" },
              input: { borderRadius: "10px", fontSize: "12px" },
            }}
            rightSection={
              <svg width="10" viewBox="0 0 13 8" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
                  fill="#0E2227"
                />
              </svg>
            }
            placeholder="Select Hub"
            data={
              selectedState &&
              selectedCity &&
              selectedState != "all" &&
              selectedCity != "all"
                ? Object.keys(hubs[selectedState][selectedCity] || {}).map(
                    (key) => {
                      return {
                        label:
                          hubs[selectedState][selectedCity][key]["name"] || key,
                        value: key,
                      };
                    }
                  )
                : []
            }
            onChange={changeHub}
            value={selectedHub}
            disabled={
              !(
                selectedState &&
                selectedCity &&
                selectedState != "all" &&
                selectedCity != "all"
                  ? Object.keys(hubs[selectedState][selectedCity] || {})
                  : []
              ).length
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FleetManagementHeader;
