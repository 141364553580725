import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  id: null,
  formData: {
    user: undefined,
    amount: undefined,
    payoutCycle: undefined,
    status: undefined,
    data: undefined,
  },
};

const payoutReducer = createSlice({
  name: "payout",
  initialState,
  reducers: {
    setPayoutId: (state, action) => {
      if (typeof action.payload === "string") {
        state.id = action.payload;
      }
    },
    setPayoutFormData: (state, action) => {
      if (typeof action.payload === "object") {
        Object.assign(state.formData, action.payload);
      }
    },
    createPayout: (state) => {
      state.loading = true;
      state.error = null;
    },
    createPayoutFulfilled: (state) => {
      state.loading = false;
      state.formData = initialState.formData;
    },
    createPayoutRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPayouts: (state) => {
      state.loading = true;
      state.error = null;
    },
    getPayoutsFulfilled: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) state.data = action.payload;
    },
    getPayoutsRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updatePayout: (state) => {
      state.loading = true;
      state.error = null;
    },
    updatePayoutFulfilled: (state) => {
      state.loading = false;
      state.id = initialState.id;
      state.formData = initialState.formData;
    },
    updatePayoutRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deletePayout: (state) => {
      state.loading = true;
      state.error = null;
    },
    deletePayoutFulfilled: (state) => {
      state.loading = false;
    },
    deletePayoutRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setPayoutId,
  setPayoutFormData,
  createPayout,
  createPayoutFulfilled,
  createPayoutRejected,
  getPayouts,
  getPayoutsFulfilled,
  getPayoutsRejected,
  updatePayout,
  updatePayoutFulfilled,
  updatePayoutRejected,
  deletePayout,
  deletePayoutFulfilled,
  deletePayoutRejected,
} = payoutReducer.actions;

export default payoutReducer.reducer;
