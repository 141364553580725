import { useDispatch } from "react-redux";
import { deleteDriver } from "../../redux/reducers/driverReducer";

const DeleteAccModal = ({ setdeletehubmodal, driver, onchange }) => {
	const dispatch = useDispatch();
	return (
		<div className="StatusConfirmationModal">
			<h1>Delete Driver</h1>
			<h2>Are you sure you want to Delete this driver?</h2>

			<div className="status_btn_group">
				<button
					className="no_button"
					onClick={() => setdeletehubmodal(false)}
				>
					No
				</button>
				<button
					className="yes_button"
					onClick={() => {
						dispatch(
							deleteDriver({
								id: driver._id,
							})
						);
						onchange();
						return setdeletehubmodal(false);
					}}
				>
					Yes
				</button>
			</div>
		</div>
	);
};

export default DeleteAccModal;
