import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
	getChallanByVehicle,
	getChallanByVehicleFulfilled,
	getChallanByVehicleRejected,
	getChallans,
	getChallansFulfilled,
	getChallansRejected,
	getAllChallans,
	getAllChallansFulfilled,
	getAllChallansRejected,
} from "../reducers/challanReducer";
import { getErrorMessage } from "../../utils/functions";

function* getAllChallansSaga() {
	try {
		const res = yield call(api.get, "/challan/all");
		if (getAllChallans.prototype.onSuccess) {
			getAllChallans.prototype.onSuccess();
		}
		yield put(getAllChallansFulfilled(res?.data));
	} catch (err) {
		if (getAllChallans.prototype.onReject) {
			getAllChallans.prototype.onReject();
		}
		yield put(getAllChallansRejected(getErrorMessage(err)));
	}
}

function* getChallansSaga() {
	try {
		const res = yield call(api.get, "/challan");
		if (getChallans.prototype.onSuccess) {
			getChallans.prototype.onSuccess();
		}
		yield put(getChallansFulfilled(res?.data));
	} catch (err) {
		if (getChallans.prototype.onReject) {
			getChallans.prototype.onReject();
		}
		yield put(getChallansRejected(getErrorMessage(err)));
	}
}

function* getChallanByVehicleSaga(action) {
	try {
		const id = action.payload?.vehicle_no;
		const res = yield call(api.get, `/challan/${id}`);
		if (getChallanByVehicle.prototype.onSuccess) {
			getChallanByVehicle.prototype.onSuccess();
		}
		yield put(getChallanByVehicleFulfilled(res?.data));
	} catch (err) {
		if (getChallanByVehicle.prototype.onReject) {
			getChallanByVehicle.prototype.onReject();
		}
		yield put(getChallanByVehicleRejected(getErrorMessage(err)));
	}
}

export function* challanSaga() {
	yield takeLatest(getAllChallans.type, getAllChallansSaga);
	yield takeLatest(getChallans.type, getChallansSaga);
	yield takeLatest(getChallanByVehicle.type, getChallanByVehicleSaga);
}
