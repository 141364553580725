import { call, put, takeLatest } from "redux-saga/effects";
import {
	createDriver,
	createDriverFulfilled,
	createDriverRejected,
	deleteDriver,
	deleteDriverFulfilled,
	deleteDriverRejected,
	getDriverById,
	getDriverByIdFulfilled,
	getDriverByIdRejected,
	fetchDrivers,
	fetchDriversFulfilled,
	fetchDriversRejected,
	getDeactivatedDrivers,
	getDeactivatedDriversFulfilled,
	getDeactivatedDriversRejected,
	getDrivers,
	getDriversFulfilled,
	getDriversRejected,
	getDriversDltReq,
	getDriversDltReqFulfilled,
	getDriversDltReqRejected,
	getPendingApprovalDrivers,
	getPendingApprovalDriversFulfilled,
	getPendingApprovalDriversRejected,
	getRejectedApprovalDrivers,
	getRejectedApprovalDriversFulfilled,
	getRejectedApprovalDriversRejected,
	checkDriverStatus,
	checkDriverStatusFulfilled,
	checkDriverStatusRejected,
	updateDriverApproval,
	updateDriverApprovalFulfilled,
	updateDriverApprovalRejected,
	updateDriverPicture,
	updateDriverPictureFulfilled,
	updateDriverPictureRejected,
	updateDriver,
	updateDriverFulfilled,
	updateDriverRejected,
} from "../reducers/driverReducer";
import api from "../../api";
import { ApprovalStatus, Role } from "../../utils/constants";
import { createQueryString, getErrorMessage } from "../../utils/functions";

//<--------------CREATE------------------------------->
function* createDriverSaga(action) {
	try {
		const res = yield call(api.post, "/user/driver", action.payload?.data);

		if (createDriver.prototype.onSuccess) {
			createDriver.prototype.onSuccess();
		}
		yield put(createDriverFulfilled(res?.data));
	} catch (err) {
		if (createDriver.prototype.onReject) {
			createDriver.prototype.onReject();
		}
		yield put(createDriverRejected(getErrorMessage(err)));
	}
}

// <------------------------READ----------------------------------->
function* getDriverSaga(action) {
	try {
		const id = action.payload?.id;
		const res = yield call(api.get, `/user/driver/${id}`);
		// console.log("getDriverSaga", res?.data);
		if (getDriverById.prototype.onSuccess) {
			getDriverById.prototype.onSuccess();
		}
		yield put(getDriverByIdFulfilled(res?.data));
	} catch (err) {
		if (getDriverById.prototype.onReject) {
			getDriverById.prototype.onReject();
		}
		yield put(getDriverByIdRejected(getErrorMessage(err)));
	}
}

function* fetchDriversSaga() {
	try {
		yield put(getDrivers());
		yield put(getPendingApprovalDrivers());
		yield put(getRejectedApprovalDrivers());
		yield put(getDeactivatedDrivers());

		yield put(fetchDriversFulfilled());
	} catch (err) {
		yield put(fetchDriversRejected(getErrorMessage(err)));
	}
}

function* getDriversSaga() {
	try {
		const res = yield call(api.post, "/user/findMany", {
			roles: Role.Driver,
			"approval.status": ApprovalStatus.Approved,
			active: true,
		});
		// console.log("getDriversSaga", res?.data);
		if (getDrivers.prototype.onSuccess) {
			getDrivers.prototype.onSuccess();
		}
		yield put(getDriversFulfilled(res?.data));
	} catch (err) {
		if (getDrivers.prototype.onReject) {
			getDrivers.prototype.onReject();
		}
		yield put(getDriversRejected(getErrorMessage(err)));
	}
}

function* getDriversDltReqSaga() {
	try {
		const res = yield call(api.get, "/user/delete/requests");
		// console.log("getDriversDltReqSaga", res?.data);
		if (getDriversDltReq.prototype.onSuccess) {
			getDriversDltReq.prototype.onSuccess();
		}
		yield put(getDriversDltReqFulfilled(res?.data));
	} catch (err) {
		if (getDriversDltReq.prototype.onReject) {
			getDriversDltReq.prototype.onReject();
		}
		yield put(getDriversDltReqRejected(getErrorMessage(err)));
	}
}

function* getPendingApprovalDriversSaga() {
	try {
		const res = yield call(api.post, "/user/findMany", {
			roles: Role.Driver,
			"approval.status": ApprovalStatus.Pending,
			active: true,
		});
		// console.log("getPendingApprovalDriversSaga", res?.data);
		if (getPendingApprovalDrivers.prototype.onSuccess) {
			getPendingApprovalDrivers.prototype.onSuccess();
		}
		yield put(
			getPendingApprovalDriversFulfilled(
				res?.data.filter((d) => d.picture != null)
			)
		);
	} catch (err) {
		if (getPendingApprovalDrivers.prototype.onReject) {
			getPendingApprovalDrivers.prototype.onReject();
		}
		yield put(getPendingApprovalDriversRejected(getErrorMessage(err)));
	}
}

function* getRejectedApprovalDriversSaga() {
	try {
		const res = yield call(api.post, "/user/findMany", {
			roles: Role.Driver,
			"approval.status": ApprovalStatus.Rejected,
			active: true,
		});
		if (getRejectedApprovalDrivers.prototype.onSuccess) {
			getRejectedApprovalDrivers.prototype.onSuccess();
		}
		yield put(getRejectedApprovalDriversFulfilled(res?.data));
	} catch (err) {
		if (getRejectedApprovalDrivers.prototype.onReject) {
			getRejectedApprovalDrivers.prototype.onReject();
		}
		yield put(getRejectedApprovalDriversRejected(getErrorMessage(err)));
	}
}

function* getDeactivatedDriversSaga() {
	try {
		const res = yield call(api.post, "/user/findMany", {
			active: false,
		});
		if (getDeactivatedDrivers.prototype.onSuccess) {
			getDeactivatedDrivers.prototype.onSuccess();
		}
		yield put(getDeactivatedDriversFulfilled(res?.data));
	} catch (err) {
		if (getDeactivatedDrivers.prototype.onReject) {
			getDeactivatedDrivers.prototype.onReject();
		}
		yield put(getDeactivatedDriversRejected(getErrorMessage(err)));
	}
}

// <--------------UPDATE------------------------>

function* updateDriverPictureSaga(action) {
	try {
		const id = action.payload?.id;
		const file = action.payload?.file;
		const formData = new FormData();
		formData.append("file", file);
		const queryParams = action.payload?.query;
		const query = createQueryString(queryParams);
		const res = yield call(api.put, `/user/${id}${query}`, formData);
		// console.log("updateDriverPictureSaga", res?.data);
		if (updateDriverPicture.prototype.onSuccess) {
			updateDriverPicture.prototype.onSuccess();
		}
		yield put(updateDriverPictureFulfilled(res?.data));
	} catch (err) {
		if (updateDriverPicture.prototype.onReject) {
			updateDriverPicture.prototype.onReject();
		}
		yield put(updateDriverPictureRejected(getErrorMessage(err)));
	}
}

function* checkDriverStatusSaga(action) {
	try {
		const id = action.payload?.id;
		const res = yield call(api.get, `/user/driver/checkStatus/${id}`);
		console.log("checkDriverStatusSaga", res?.data);
		if (checkDriverStatus.prototype.onSuccess) {
			checkDriverStatus.prototype.onSuccess();
		}
		yield put(checkDriverStatusFulfilled(res?.data));
	} catch (err) {
		console.log(err);
		if (checkDriverStatus.prototype.onReject) {
			checkDriverStatus.prototype.onReject();
		}
		yield put(checkDriverStatusRejected(getErrorMessage(err)));
	}
}

function* updateDriverApprovalSaga(action) {
	try {
		const id = action.payload?.id;
		const data = action.payload?.data;
		const res = yield call(api.patch, `/user/driver/${id}/approval`, data);
		console.log("updateDriverApprovalSaga", res?.data);
		if (updateDriverApproval.prototype.onSuccess) {
			updateDriverApproval.prototype.onSuccess();
		}
		yield put(updateDriverApprovalFulfilled(res?.data));
	} catch (err) {
		if (updateDriverApproval.prototype.onReject) {
			updateDriverApproval.prototype.onReject();
		}
		yield put(updateDriverApprovalRejected(getErrorMessage(err)));
	}
}

function* updateDriverSaga(action) {
	try {
		const id = action.payload?.id;
		const data = action.payload?.data;
		// console.log(id, data);
		const res = yield call(api.patch, `/user/driver/${id}`, data);
		console.log("updateDriverSaga", res?.data);
		if (updateDriver.prototype.onSuccess) {
			updateDriver.prototype.onSuccess();
		}
		yield put(updateDriverFulfilled(res?.data));
	} catch (err) {
		if (updateDriver.prototype.onReject) {
			updateDriver.prototype.onReject();
		}
		yield put(updateDriverRejected(getErrorMessage(err)));
	}
}

// <--------------DELETE------------------------>
function* deleteDriverSaga(action) {
	try {
		const id = action.payload?.id;
		const res = yield call(api.delete, `/user/driver/${id}`);
		// console.log("updateDriverSaga", res?.data);
		if (deleteDriver.prototype.onSuccess) {
			deleteDriver.prototype.onSuccess();
		}
		yield put(deleteDriverFulfilled(res?.data));
	} catch (err) {
		if (deleteDriver.prototype.onReject) {
			deleteDriver.prototype.onReject();
		}
		yield put(deleteDriverRejected(getErrorMessage(err)));
	}
}

// <<<<<<<<<<<<<<<< MAIN SAGA >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export function* driverSaga() {
	// create
	yield takeLatest(createDriver.type, createDriverSaga);

	// read
	yield takeLatest(getDriverById.type, getDriverSaga);
	yield takeLatest(fetchDrivers.type, fetchDriversSaga);
	yield takeLatest(getDrivers.type, getDriversSaga);
	yield takeLatest(getDriversDltReq.type, getDriversDltReqSaga);
	yield takeLatest(
		getPendingApprovalDrivers.type,
		getPendingApprovalDriversSaga
	);
	yield takeLatest(
		getRejectedApprovalDrivers.type,
		getRejectedApprovalDriversSaga
	);
	yield takeLatest(getDeactivatedDrivers.type, getDeactivatedDriversSaga);

	// update
	yield takeLatest(updateDriver.type, updateDriverSaga);
	yield takeLatest(checkDriverStatus.type, checkDriverStatusSaga);
	yield takeLatest(updateDriverPicture.type, updateDriverPictureSaga);
	yield takeLatest(updateDriverApproval.type, updateDriverApprovalSaga);

	// delete
	yield takeLatest(deleteDriver.type, deleteDriverSaga);
}
