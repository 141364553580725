import { Grid, Modal } from "@mantine/core";
import "./DocumentVerifyModal.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	checkDriverStatus,
	getDriverById,
	updateDriverApproval,
} from "../../redux/reducers/driverReducer";
import { ApprovalStatus } from "../../utils/constants";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ViewDocumentModal from "../ViewDocumentModal/ViewDocumentModal";
const DocumentVerifyModal = ({ close, image, driverFormData, imageType }) => {
	const { id } = useParams();
	const [rejected, setRejected] = useState(false);
	const [formValue, setFormValue] = useState(driverFormData);
	const dispatch = useDispatch();

	useEffect(() => {
		updateDriverApproval.prototype.onSuccess = () => {
			dispatch(checkDriverStatus({ id: id }));
		};
		checkDriverStatus.prototype.onSuccess = () => {
			dispatch(getDriverById({ id: id }));
		};
		getDriverById.prototype.onSuccess = () => {
			close();
		};
	}, [formValue]);

	const onSubmit = (value) => {
		setFormValue({
			...formValue,
			approval: {
				...formValue.approval,
				[imageType]: value,
			},
		});
		dispatch(
			updateDriverApproval({
				id: driverFormData._id,
				data: {
					[imageType]: value,
				},
			})
		);
	};
	const [opened, setOpened] = useState(false);
	const [documentImage, setDocumentImage] = useState(null);

	const openModal = () => {
		setDocumentImage(image);
		setOpened(true);
	};

	const closeModal = () => {
		setOpened(false);
	};
	return (
		<div className="DocumentVerifyModal">
			<div className="present_modal_header">
				<div>Document Name</div>
				<svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>

			<Grid m={0}>
				<Grid.Col span={6}>
					<img
						src={image}
						alt=""
						className="modal_document_image"
						onClick={openModal}
					/>
					<Modal
						opened={opened}
						onClose={closeModal}
						centered
						withCloseButton={false}
						size="auto"
						padding={0}
						radius={10}
						overlayProps={{
							blur: 5,
							color: "#fff",
							backgroundopacity: 1,
						}}
					>
						<ViewDocumentModal
							close={closeModal}
							image={documentImage}
							large={true}
						/>
					</Modal>
				</Grid.Col>
				<Grid.Col span={6}>
					<div className="modal_document_buttons">
						{rejected ? (
							<h6>
								<svg
									width="15"
									height="15"
									viewBox="0 0 15 15"
									fill="none"
								>
									<rect
										width="15"
										height="15"
										rx="7.5"
										fill="#06B9F0"
									/>
									<path
										d="M4 8.84L6.1875 11L11 5"
										stroke="#0E2227"
										strokeWidth="1.6"
										strokeLinecap="round"
									/>
								</svg>
								Request Sent
							</h6>
						) : (
							<button
								className="request_btn"
								onClick={() => {
									onSubmit(ApprovalStatus.Rejected);
									setRejected(true);
								}}
							>
								Request Upload
							</button>
						)}
						<button
							onClick={() => {
								onSubmit(ApprovalStatus.Approved);
							}}
							className="approve_btn"
							disabled={rejected}
						>
							Approve
						</button>
					</div>
				</Grid.Col>
			</Grid>
		</div>
	);
};

export default DocumentVerifyModal;
