import { Divider, Input, Modal, Select, TextInput } from "@mantine/core";
import { useId } from "@mantine/hooks";
import { IMaskInput } from "react-imask";
import "./EditDriverDetails.css";
import SingleSelectDropdown from "../../../components/SingleSelectDropdown/SingleSelectDropdown";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Loading from "../../../components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getDriverById } from "../../../redux/reducers/driverReducer";
import { getSupervisorById } from "../../../redux/reducers/supervisorReducer";
import { updateDriver } from "../../../redux/reducers/driverReducer";
import { toast } from "react-toastify";
import ViewDocumentModal from "../../../components/ViewDocumentModal/ViewDocumentModal";
import { checkURLType, sortSelectOptions } from "../../../utils/functions";
import VehicleSelect from "../../../components/VehicleSelect/VehicleSelect";
import ShiftSelect from "../../../components/ShiftSelect/ShiftSelect";
import HubSelect from "../../../components/HubSelect/HubSelect";
import SupervisorSelect from "../../../components/SupervisorSelect/SupervisorSelect";
import RecruiterSelect from "../../../components/RecruiterSelect/RecruiterSelect";

const EditDriverDetails = () => {
	const inputId = useId();
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const driverInfo = useSelector((state) => state.driver.fetchedDriver);
	const loading = useSelector((state) => state.driver.fetchedDriver.loading);
	const error = useSelector((state) => state.driver.fetchedDriver.error);

	const driver = driverInfo.data || {};
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [hub, setHub] = useState("");
	const [shift, setShift] = useState("");
	const [recruiter, setRecruiter] = useState("");
	const [supervisor, setSupervisor] = useState("");
	const [filteredSupervisor, setFilteredSupervisor] = useState([]);
	const [supervisorPhone, setSupervisorPhone] = useState("");
	const [clients, setClients] = useState([]);
	const [vehicle, setVehicle] = useState(null);
	const [salary, setSalaryDetails] = useState("");
	const hubs = useSelector((state) => state.hub?.data);
	const shifts = useSelector((state) => state.shift?.data);
	const vehicles = useSelector((state) => state.vehicle?.data);
	const supervisorInfo = useSelector(
		(state) => state.supervisor.fetchedSupervisor
	);
	const supervisors = useSelector((state) => state.supervisor?.data);
	const [vehicleInfo, setVehicleInfo] = useState({});
	useEffect(() => {
		if (vehicle && vehicles?.length)
			setVehicleInfo(vehicles?.find((veh) => veh?._id == vehicle));
	}, [vehicle, vehicles]);
	const [opened, setOpened] = useState(false);
	const [documentImage, setDocumentImage] = useState(null);
	const [documentName, setDocumentName] = useState(null);
	const notify = () => {
		toast.success("Profile Saved", {
			onClose: () => navigate("/dashboard/fleet"),
			autoClose: 500,
		});
	};
	const saveProfile = () => {
		const originalObject = {
			name: name,
			email: email,
			phone: phone.replace(/^\+91\s?/, ""),
			hub: hub,
			shift: shift,
			recruiter: recruiter,
			supervisor: supervisor,
			clients: clients,
			vehicle: vehicle,
			salary: salary,
		};
		const data = Object.fromEntries(
			Object.entries(originalObject).filter(
				([key, value]) => value !== ""
			)
		);

		updateDriver.prototype.onSuccess = () => {
			notify();
		};

		updateDriver.prototype.onError = () => {
			alert(`Unable to update profile. Error: ${error}`);
		};

		dispatch(updateDriver({ id: id, data: data }));
	};

	useEffect(() => {
		const supervisorDetails = supervisorInfo.data || {};
		setSupervisorPhone(supervisorDetails.phone?.toString());
	}, [supervisorInfo]);
	const handleNameChange = (e) => {
		setName(e.target.value);
	};
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};
	const handlePhoneChange = (e) => {
		setPhone(e.target.value);
	};
	const handleShiftChange = (e) => {
		setShift(e);
	};
	const handleHubChange = (e) => {
		setFilteredSupervisor(
			supervisors?.filter((supervisor) => {
				return supervisor.hubs?.some(
					(supervisorHub) => supervisorHub._id === e
				);
			})
		);
		console.log(filteredSupervisor);
		setHub(e);
	};
	const handleRecruiterChange = (e) => {
		setRecruiter(e);
	};
	const handleSupervisorChange = (e) => {
		setSupervisor(e);
		if (e) dispatch(getSupervisorById({ id: e }));
		else setSupervisorPhone("");
	};
	const handleClientChange = (e) => {
		setClients(e);
	};
	const handleVehicleChange = (e) => {
		setVehicle(e?._id);
	};
	const handleSalaryDetailsChange = (e) => {
		if (/^\d*\.?\d*$/.test(e.target.value) || e.target.value === "") {
			setSalaryDetails(e.target.value);
		}
	};

	useEffect(() => {
		setName(driver.name || "");
	}, [driver.name]);
	useEffect(() => {
		setEmail(driver.email || "");
	}, [driver.email]);
	useEffect(() => {
		setPhone(driver.phone?.toString() || "");
	}, [driver.phone]);
	useEffect(() => {
		setShift(driver.shift?._id || "");
	}, [driver.shift]);
	useEffect(() => {
		setHub(driver.hub?._id || "");
	}, [driver.hub]);
	useEffect(() => {
		setSupervisor(driver.supervisor?._id || "");
		setSupervisorPhone(driver.supervisor?.phone?.toString() || "");
	}, [driver.supervisor]);
	useEffect(() => {
		setRecruiter(driver.recruiter?._id || "");
	}, [driver.recruiter]);
	useEffect(() => {
		setClients(driver.clients?.map((client) => client) || []);
	}, [driver.clients]);
	useEffect(() => {
		setVehicle(driver.vehicle?._id || "");
	}, [driver.vehicle]);
	useEffect(() => {
		setSalaryDetails(driver.salary || "");
	}, [driver.salary]);

	useEffect(() => {
		if (id) {
			dispatch(getDriverById({ id }));
		}
	}, [id]);

	// variables
	const selectHubData = [];
	const selectShiftData = [];
	const selectVehicleData = [];
	hubs?.forEach((hub) =>
		selectHubData.push({ value: hub?._id, label: hub?.name })
	);
	shifts?.forEach((shift) =>
		selectShiftData.push({
			value: shift?._id,
			label: `${shift?.startTime} - ${shift?.endTime}`,
		})
	);

	vehicles.forEach((vehicle) =>
		selectVehicleData.push({
			value: vehicle?._id,
			label: vehicle.vehicle_no,
		})
	);

	sortSelectOptions(selectHubData);

	const open = (image, imageName) => {
		setDocumentImage(image);
		setDocumentName(imageName);
		setOpened(true);
	};

	const close = () => {
		setOpened(false);
	};

	return loading ? (
		<Loading />
	) : (
		<div className="edit_driver_details_container">
			<div
				style={{ cursor: "pointer", marginBottom: "10px" }}
				onClick={() => navigate("/dashboard/fleet")}
			>
				<svg width="20" height="auto" viewBox="0 0 31 24" fill="none">
					<path
						d="M2.90991 10.752H28.5484C28.9338 10.752 29.3033 10.8778 29.5758 11.1017C29.8482 11.3256 30.0013 11.6293 30.0013 11.946C30.0013 12.2627 29.8482 12.5665 29.5758 12.7904C29.3033 13.0143 28.9338 13.1401 28.5484 13.1401H2.90991C2.52458 13.1401 2.15504 13.0143 1.88257 12.7904C1.6101 12.5665 1.45703 12.2627 1.45703 11.946C1.45703 11.6293 1.6101 11.3256 1.88257 11.1017C2.15504 10.8778 2.52458 10.752 2.90991 10.752Z"
						fill="black"
					/>
					<path
						d="M3.50984 11.9424L15.56 21.8438C15.8328 22.068 15.986 22.3721 15.986 22.6892C15.986 23.0063 15.8328 23.3104 15.56 23.5346C15.2872 23.7588 14.9172 23.8848 14.5313 23.8848C14.1455 23.8848 13.7755 23.7588 13.5027 23.5346L0.426836 12.7878C0.291535 12.6769 0.184189 12.5451 0.110945 12.4C0.0377017 12.255 0 12.0995 0 11.9424C0 11.7853 0.0377017 11.6298 0.110945 11.4847C0.184189 11.3397 0.291535 11.2079 0.426836 11.097L13.5027 0.350182C13.7755 0.125964 14.1455 0 14.5313 0C14.9172 0 15.2872 0.125964 15.56 0.350182C15.8328 0.5744 15.986 0.878505 15.986 1.1956C15.986 1.51269 15.8328 1.81679 15.56 2.04101L3.50984 11.9424Z"
						fill="black"
					/>
				</svg>{" "}
				<span>Edit Driver Details</span>
			</div>
			<Divider color="#C2D0D6" w={"100%"} />
			<div
				className="editDetailsForm_box"
				style={{ display: "block", width: "100%" }}
			>
				<table>
					<tbody>
						{/* Name dropdown */}
						<tr>
							<td>Name</td>
							<td>
								<div>
									<TextInput
										w={250}
										placeholder="Your Name"
										radius="md"
										size="sm"
										value={name}
										onChange={handleNameChange}
									/>
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* Email dropdown */}
						<tr>
							<td>Email Address</td>
							<td>
								<div>
									<TextInput
										w={250}
										placeholder="Your Email"
										radius="md"
										size="sm"
										value={email}
										onChange={handleEmailChange}
									/>
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* Phone Number dropdown */}
						<tr>
							<td>Phone Number</td>
							<td>
								<div>
									<Input
										radius="md"
										w={220}
										size="sm"
										component={IMaskInput}
										mask="+91 0000000000"
										id={inputId}
										placeholder="Your phone"
										value={phone}
										onChange={handlePhoneChange}
									/>
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* Shift dropdown */}
						<tr>
							<td>Shift</td>
							<td>
								<div>
									<ShiftSelect
										getValue={handleShiftChange}
										value={shift}
									/>
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* Hub dropdown */}
						<tr>
							<td>Assign Hub</td>
							<td>
								<div>
									<HubSelect
										getValue={handleHubChange}
										value={hub}
									/>
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* Supervisor dropdown */}
						<tr>
							<td>Assign Supervisor</td>
							<td>
								<div className="flex">
									<SupervisorSelect
										getValue={handleSupervisorChange}
										value={
											supervisors?.find(
												(s) => s._id === supervisor
											)
												? supervisor
												: ""
										}
										hub={hub}
									/>
									{supervisors?.find(
										(s) => s._id === supervisor
									) && (
										<Input
											radius="md"
											w={220}
											size="sm"
											value={supervisorPhone}
											readOnly
										/>
									)}
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* Recruiter dropdown */}
						<tr>
							<td>Assign Recruiter</td>
							<td>
								<div>
									<RecruiterSelect
										getValue={handleRecruiterChange}
										value={recruiter}
									/>
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* Clients dropdown */}
						<tr>
							<td>Assign Client(s)</td>
							<td>
								<div>
									<MultiSelectDropdown
										getValue={handleClientChange}
										value={clients}
									/>
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* assign vehicle dropdown */}
						<tr>
							<td>Assign Vehicle</td>
							<td>
								<div className="flex">
									<VehicleSelect
										getValue={handleVehicleChange}
										value={vehicleInfo}
									/>
									{/* <Select
									value={vehicle}
									onChange={handleVehicleChange}
									maw={200}
									size="sm"
									radius="md"
									styles={{
										input: { fontSize: "12px" },
									}}
									className="mr-48"
									rightSection={
										<svg
											width="10"
											height="8"
											viewBox="0 0 13 8"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
												fill="#0E2227"
											/>
										</svg>
									}
									placeholder="Assign Vehicle"
									data={selectVehicleData}
								/> */}
									{vehicle && (
										<div className="flex m-auto">
											<div className="flex mr-40">
												<span className="mr-8">RC</span>
												<button
													className="black_button oval_button"
													onClick={() =>
														checkURLType(
															vehicleInfo?.rc_image
														) === "image"
															? open(
																	vehicleInfo?.rc_image,
																	"RC"
															  )
															: window.open(
																	vehicleInfo?.rc_image,
																	"_blank"
															  )
													}
												>
													View
												</button>
											</div>
											<div className="flex">
												<span className="mr-8">
													Insurance
												</span>
												<button
													className="black_button oval_button"
													onClick={() =>
														checkURLType(
															vehicleInfo?.insurance_file
														) === "image"
															? open(
																	vehicleInfo?.insurance_file,
																	"Insurance"
															  )
															: window.open(
																	vehicleInfo?.insurance_file,
																	"_blank"
															  )
													}
												>
													View
												</button>
											</div>
											<Modal
												opened={opened}
												onClose={close}
												centered
												withCloseButton={false}
												size="lg"
												padding={0}
												radius={10}
												overlayProps={{
													blur: 5,
													color: "#fff",
													backgroundopacity: 1,
												}}
											>
												<ViewDocumentModal
													close={close}
													image={documentImage}
													documentName={documentName}
												/>
											</Modal>
										</div>
									)}
								</div>
							</td>
						</tr>
						<tr className="spacer"></tr>
						{/* Salary Input */}
						<tr>
							<td>Salary Details</td>
							<td>
								<div className="flex">
									<TextInput
										maw={200}
										placeholder="Daily Wage"
										radius="md"
										size="xs"
										value={salary}
										onChange={handleSalaryDetailsChange}
										maxLength={4}
									/>
									<span
										className="daily_text"
										style={{ alignSelf: "center" }}
									>
										Daily
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="stepper_btn_grp4">
				<button className="black_button" onClick={saveProfile}>
					Save
				</button>
			</div>
		</div>
	);
};

export default EditDriverDetails;
