// userSaga.js

import { call, put, takeLatest } from "redux-saga/effects";
import {
  getUserFulfilled,
  getUser,
  getUserRejected,
  loginFulfilled,
  login,
  loginRejected,
  verifyToken,
  logout,
  verifyTokenFulfilled,
  updateUserFulfilled,
  updateUserRejected,
  updateUser,
} from "../reducers/userReducer";
import { getErrorMessage } from "../../utils/functions";
import api from "../../api";
import config from "../../config";

function* loginSaga(action) {
  try {
    const res = yield call(api.post, "/auth/login", action.payload.data);
    console.log("loginSaga", res);
    const token = res?.headers?.get(config.tokenHeader);
    const user = res?.data;

    if (user && token) {
      if (login.prototype.onSuccess) {
        login.prototype.onSuccess();
      }
      yield put(loginFulfilled({ token, user }));
    }
  } catch (error) {
    if (login.prototype.onError) {
      login.prototype.onError(error);
    }
    yield put(loginRejected(getErrorMessage(error)));
  }
}

function* verifyTokenSaga() {
  try {
    // console.log("verifyToken");
    const response = yield call(api.get, "/auth/verifyToken");
    yield put(verifyTokenFulfilled(response.data));
  } catch (err) {
    yield put(logout(getErrorMessage(err)));
  }
}

function* getUserSaga() {
  try {
    const response = yield call(api.get, "/user/self");
    yield put(getUserFulfilled(response?.data));
  } catch (error) {
    yield put(getUserRejected(getErrorMessage(error)));
  }
}

function* updateUserSaga(action) {
  try {
    const res = yield call(api.patch, "/user/self", action.payload.data);
    yield put(updateUserFulfilled(res.data));
  } catch (error) {
    yield put(updateUserRejected(getErrorMessage(error)));
  }
}

function* logoutSaga() {
  console.log("logoutSaga");
  if (logout.prototype.onSuccess) {
    // logout.prototype.onSuccess();
    location.reload();
  }
}

export function* userSaga() {
  yield takeLatest(login.type, loginSaga);
  yield takeLatest(getUser.type, getUserSaga);
  yield takeLatest(verifyToken.type, verifyTokenSaga);
  yield takeLatest(updateUser.type, updateUserSaga);
  yield takeLatest(logout.type, logoutSaga);
}
