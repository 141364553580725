import { Modal, TextInput } from "@mantine/core";
import "./ChallanManagement.css";
import { useEffect, useState } from "react";
import ChallanListModal from "./ChallanListModal/ChallanListModal";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";

const ChallanManagement = () => {
	const selectOptions = {
		latest: "Latest",
		oldest: "Oldest",
	};
	const [value, onChange] = useState([]);
	const [search, changeSearch] = useState("");
	const [calenderOpen, setCalenderOpen] = useState(false);
	const [selectedSort, setSelectedSort] = useState("latest");
	const [vehicle_no, setVehicleNo] = useState("");
	const [csvData, setCSVData] = useState([]);

	const challans = useSelector((state) => state?.challan?.data);
	const allChallans = useSelector((state) => state?.challan?.allData);

	useEffect(() => {
		if (allChallans?.length) {
			setCSVData([
				[
					"Driver Name",
					"Vehicle Number",
					"Challan no",
					"Offense name",
					"State Code",
					"Date",
					"Amount",
					"Status",
				],
				...allChallans
					.map((a) => [
						a?.owner_name,
						a?.vehicle_no,
						a?.challan_no,
						a?.offense_name,
						a?.state_code,
						a?.date,
						a?.amount,
						a?.status,
					])
					.sort((a, b) => a[1].localeCompare(b[1])),
			]);
		}
	}, [allChallans]);

	return (
		<div className="challan_management_container">
			<div className="searchbar">
				<TextInput
					w={500}
					styles={() => ({
						input: {
							padding: "22px 10px",
							borderRadius: "10px",
							border: "1px solid #9DB2B8",
							boxShadow: "0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
						},
					})}
					placeholder="Search by Driver name or Vehicle no"
					rightSection={
						<svg
							width="22"
							height="20"
							viewBox="0 0 27 28"
							fill="none"
						>
							<ellipse
								cx="10.3418"
								cy="10.5395"
								rx="8.34184"
								ry="8.65079"
								stroke="#222222"
								strokeWidth="3"
							/>
							<path
								d="M19.75 20.2969L25.3557 26.1102"
								stroke="#222222"
								strokeWidth="3"
								strokeLinecap="round"
							/>
						</svg>
					}
					onChange={(e) => {
						changeSearch(e.target.value);
					}}
				/>
				<CSVLink
					data={csvData}
					className="download_btn"
					filename="all_challans"
				>
					Download CSV
				</CSVLink>
			</div>
			{/* <div className="date_select">
				<DateRangePicker
					onChange={onChange}
					value={value}
					isOpen={calenderOpen}
					showLeadingZeros={true}
					onCalendarClose={() => setCalenderOpen(false)}
					format={"y-MM-dd"}
					maxDate={new Date()}
				/>
				<div
					className="date_select_inner"
					onClick={() => setCalenderOpen(true)}
				>
					<p>{value[0] ? value[0]?.toDateString() : "YYYY-MM-DD"}</p>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
					>
						<path
							d="M13.6422 9.31563L11.0797 6.06563C11.033 6.00629 10.9734 5.95832 10.9055 5.92531C10.8375 5.89229 10.763 5.87509 10.6875 5.875H9.675C9.57031 5.875 9.5125 5.99531 9.57656 6.07812L11.8313 8.9375H2.375C2.30625 8.9375 2.25 8.99375 2.25 9.0625V10C2.25 10.0687 2.30625 10.125 2.375 10.125H13.2484C13.6672 10.125 13.9 9.64375 13.6422 9.31563Z"
							fill="#707070"
						/>
					</svg>
					<p>{value[1] ? value[1]?.toDateString() : "YYYY-MM-DD"}</p>
				</div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="14"
					height="14"
					viewBox="0 0 14 14"
					fill="none"
					onClick={() => onChange([])}
				>
					<path
						d="M4.40771 10.4831L7.00906 7.88677L9.63511 10.5178C9.88175 10.7649 10.1558 10.8915 10.3992 10.6485L10.6403 10.4079C10.8838 10.1649 10.7577 9.89066 10.5111 9.64355L7.88503 7.01248L10.4864 4.41616C10.7298 4.17317 10.8505 3.89916 10.6038 3.65205L10.3566 3.40437C10.11 3.15726 9.83575 3.27737 9.59229 3.52036L6.99094 6.11668L4.36489 3.48562C4.11825 3.2385 3.84424 3.11195 3.60078 3.35494L3.35971 3.59555C3.11625 3.83854 3.24228 4.11279 3.48892 4.3599L6.11497 6.99097L3.51362 9.58729C3.27015 9.83028 3.14951 10.1043 3.39615 10.3514L3.64336 10.5991C3.89 10.8462 4.16425 10.7261 4.40771 10.4831Z"
						fill="black"
					/>
				</svg>
			</div> */}
			<div className="challan_table">
				<div className="challan_count_line">
					<p>
						Total Challan <span>({challans?.length || 0})</span>
					</p>
					{/* <div className="select_box">
					<p>Sort</p>
					<Select
						size="xs"
						styles={{
							rightSection: { pointerEvents: "none" },
							input: {
								borderRadius: "5px",
								fontSize: "12px",
								width: "100px",
							},
						}}
						rightSection={
							<svg
								width="10"
								height="8"
								viewBox="0 0 13 8"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
									fill="#0E2227"
								/>
							</svg>
						}
						placeholder="Sort"
						data={Object.keys(selectOptions).map((key) => {
							return {
								label: selectOptions[key],
								value: key,
							};
						})}
						onChange={setSelectedSort}
						value={selectedSort}
						disabled={!Object.keys(selectOptions).length}
					/>
				</div> */}
				</div>
				<div className="challan_table_head">
					<p>Driver Name</p>
					{/* <p>Date</p> */}
					<p>Vehicle No</p>
					<p>Total Challan</p>
				</div>
				<div className="challan_table_body">
					{challans
						?.filter((a) =>
							search?.length > 0
								? a?.vehicle_no
										?.toLowerCase()
										?.includes(search?.toLowerCase()) ||
								  a?.user?.name
										?.toLowerCase()
										?.includes(search?.toLowerCase())
								: true
						)
						?.map((a, i) => (
							<div
								key={i}
								onClick={() => {
									setVehicleNo(a?.vehicle_no);
								}}
							>
								<p>
									<div>
										<img
											src={
												a?.user?.photo ||
												"/src/assets/images/user-placeholder.webp"
											}
										/>
									</div>
									<span>{a?.user?.name}</span>
								</p>
								{/* <p>01-05-2024</p> */}
								<p>{a?.vehicle_no}</p>
								<p>{a?.count || 0}</p>
							</div>
						))}
				</div>
			</div>
			<Modal
				opened={vehicle_no?.length > 0}
				onClose={() => setVehicleNo("")}
				centered
				withCloseButton={false}
				size="90vw"
				padding={0}
				radius={10}
				overlayProps={{
					blur: 5,
					color: "#fff",
					backgroundopacity: 1,
				}}
			>
				<ChallanListModal
					onClose={() => setVehicleNo("")}
					vehicle_no={vehicle_no}
				/>
			</Modal>
		</div>
	);
};

export default ChallanManagement;
