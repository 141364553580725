import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  id: null,
  formData: {
    name: undefined,
    location: {
      lat: undefined,
      lng: undefined,
    },
    supervisor: undefined,
    state: undefined,
    city: undefined,
    pincode: undefined,
    address: undefined,
    landmark: undefined,
  },
};

const hubReducer = createSlice({
  name: "hub",
  initialState,
  reducers: {
    setHubId: (state, action) => {
      if (typeof action.payload === "string") {
        state.id = action.payload;
      }
    },
    setHubFormData: (state, action) => {
      if (typeof action.payload === "object") {
        Object.assign(state.formData, action.payload);
      }
    },
    createHub: (state) => {
      state.loading = true;
      state.error = null;
    },
    createHubFulfilled: (state) => {
      state.loading = false;
      state.formData = initialState.formData;
    },
    createHubRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getHubs: (state) => {
      state.loading = true;
      state.error = null;
    },
    getHubsFulfilled: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) state.data = action.payload;
    },
    getHubsRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateHub: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateHubFulfilled: (state) => {
      state.loading = false;
      state.id = initialState.id;
      state.formData = initialState.formData;
    },
    updateHubRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteHub: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteHubFulfilled: (state) => {
      state.loading = false;
    },
    deleteHubRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setHubId,
  setHubFormData,
  createHub,
  createHubFulfilled,
  createHubRejected,
  getHubs,
  getHubsFulfilled,
  getHubsRejected,
  updateHub,
  updateHubFulfilled,
  updateHubRejected,
  deleteHub,
  deleteHubFulfilled,
  deleteHubRejected,
} = hubReducer.actions;

export default hubReducer.reducer;
