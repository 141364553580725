import React from "react";
import "./Checkbox.css";

const Checkbox = ({ value, onSelect, selected, disabled }) => {
	return (
		<div
			className={
				"checkbox" +
				(disabled || selected ? " selected" : "") +
				(disabled ? " disabled" : "")
			}
			onClick={() => {
				if (disabled) return false;
				return onSelect(!selected, value);
			}}
		>
			<span></span>
		</div>
	);
};

export default Checkbox;
