import { Divider } from "@mantine/core";
import "./AttendanceHistoryModal.css";
import { forwardRef, useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import { useSelector, useDispatch } from "react-redux";
import { getAttendanceByDriverId } from "../../redux/reducers/attendanceReducer";
import { getDayFromDate } from "../../utils/functions";
import moment from "moment";
import DatePicker from "react-datepicker";

// const ClearButton = ({ clearDates }) => (
// 	<button onClick={clearDates}>Clear</button>
//   );

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
	<button className="example-custom-input" onClick={onClick} ref={ref}>
		<svg width="16" height="16" viewBox="0 0 19 19" fill="none">
			<path
				d="M16.6361 3.49219H15.0087V4.49369H16.5109V15.5102H2.48991V4.49369H3.99216V3.49219H2.36472C2.24767 3.49414 2.13214 3.51914 2.02475 3.56575C1.91735 3.61236 1.82019 3.67967 1.73882 3.76384C1.65744 3.84801 1.59344 3.94738 1.55048 4.05628C1.50751 4.16519 1.48642 4.28149 1.48841 4.39855V15.6053C1.48642 15.7224 1.50751 15.8387 1.55048 15.9476C1.59344 16.0565 1.65744 16.1559 1.73882 16.2401C1.82019 16.3242 1.91735 16.3915 2.02475 16.4381C2.13214 16.4848 2.24767 16.5097 2.36472 16.5117H16.6361C16.7532 16.5097 16.8687 16.4848 16.9761 16.4381C17.0835 16.3915 17.1807 16.3242 17.262 16.2401C17.3434 16.1559 17.4074 16.0565 17.4504 15.9476C17.4933 15.8387 17.5144 15.7224 17.5124 15.6053V4.39855C17.5144 4.28149 17.4933 4.16519 17.4504 4.05628C17.4074 3.94738 17.3434 3.84801 17.262 3.76384C17.1807 3.67967 17.0835 3.61236 16.9761 3.56575C16.8687 3.51914 16.7532 3.49414 16.6361 3.49219Z"
				fill="#07DD92"
			/>
			<path
				d="M4.49219 7.49805H5.49369V8.49955H4.49219V7.49805Z"
				fill="#07DD92"
			/>
			<path
				d="M7.49805 7.49805H8.49955V8.49955H7.49805V7.49805Z"
				fill="#07DD92"
			/>
			<path
				d="M10.502 7.49805H11.5035V8.49955H10.502V7.49805Z"
				fill="#07DD92"
			/>
			<path
				d="M13.5059 7.49805H14.5074V8.49955H13.5059V7.49805Z"
				fill="#07DD92"
			/>
			<path d="M4.49219 10H5.49369V11.0015H4.49219V10Z" fill="#07DD92" />
			<path d="M7.49805 10H8.49955V11.0015H7.49805V10Z" fill="#07DD92" />
			<path d="M10.502 10H11.5035V11.0015H10.502V10Z" fill="#07DD92" />
			<path d="M13.5059 10H14.5074V11.0015H13.5059V10Z" fill="#07DD92" />
			<path
				d="M4.49219 12.5059H5.49369V13.5074H4.49219V12.5059Z"
				fill="#07DD92"
			/>
			<path
				d="M7.49805 12.5059H8.49955V13.5074H7.49805V12.5059Z"
				fill="#07DD92"
			/>
			<path
				d="M10.502 12.5059H11.5035V13.5074H10.502V12.5059Z"
				fill="#07DD92"
			/>
			<path
				d="M13.5059 12.5059H14.5074V13.5074H13.5059V12.5059Z"
				fill="#07DD92"
			/>
			<path
				d="M5.49489 5.49429C5.6277 5.49429 5.75507 5.44153 5.84898 5.34762C5.94288 5.25371 5.99564 5.12634 5.99564 4.99354V1.98903C5.99564 1.85622 5.94288 1.72886 5.84898 1.63495C5.75507 1.54104 5.6277 1.48828 5.49489 1.48828C5.36208 1.48828 5.23472 1.54104 5.14081 1.63495C5.0469 1.72886 4.99414 1.85622 4.99414 1.98903V4.99354C4.99414 5.12634 5.0469 5.25371 5.14081 5.34762C5.23472 5.44153 5.36208 5.49429 5.49489 5.49429Z"
				fill="#07DD92"
			/>
			<path
				d="M13.5066 5.49429C13.6394 5.49429 13.7668 5.44153 13.8607 5.34762C13.9546 5.25371 14.0074 5.12634 14.0074 4.99354V1.98903C14.0074 1.85622 13.9546 1.72886 13.8607 1.63495C13.7668 1.54104 13.6394 1.48828 13.5066 1.48828C13.3738 1.48828 13.2464 1.54104 13.1525 1.63495C13.0586 1.72886 13.0059 1.85622 13.0059 1.98903V4.99354C13.0059 5.12634 13.0586 5.25371 13.1525 5.34762C13.2464 5.44153 13.3738 5.49429 13.5066 5.49429Z"
				fill="#07DD92"
			/>
			<path
				d="M6.99609 3.49219H12.0036V4.49369H6.99609V3.49219Z"
				fill="#07DD92"
			/>
		</svg>

		{value ? (
			<span>{moment(value).format("l")}</span>
		) : (
			<span>Select Date</span>
		)}
	</button>
));

const AttendanceHistoryModal = ({ close, driverId }) => {
	const dispatch = useDispatch();
	const [attendances, setAttendance] = useState([]);
	const [absent, setAbsent] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [workingDays, setWorkingDays] = useState(attendances.length);

	// const clearDates = () => {
	// 	setStartDate(null);
	// 	setEndDate(null);
	//   };
	const clearSelects = () => {
		setStartDate(null);
		setEndDate(null);
		setWorkingDays(0);
	};

	const attendancesInfo = useSelector(
		(state) => state.attendance.driverAttendances
	);
	const loading = useSelector(
		(state) => state.attendance.driverAttendances.loading
	);

	const isAnyDateSelected = startDate || endDate;

	useEffect(() => {
		getAttendanceByDriverId.prototype.onSuccess = () => {
			setAttendance(attendancesInfo.data || []);
		};
		if (driverId) {
			dispatch(getAttendanceByDriverId({ id: driverId }));
		}
	}, [driverId]);

	useEffect(() => {
		if (startDate && endDate) {
			const startingDate = moment({
				year: moment(startDate).format("Y"),
				month: moment(startDate).format("M") - 1,
				day: moment(startDate).format("D"),
			});
			const endingDate = moment({
				year: moment(endDate).format("Y"),
				month: moment(endDate).format("M") - 1,
				day: moment(endDate).format("D"),
			});
			setAttendance(
				(attendancesInfo.data || [])
					.filter((item) => {
						const itemDate = moment(item.createdAt);
						return itemDate.isBetween(
							startingDate,
							endingDate,
							"day",
							"[]"
						);
					})
					.sort(
						(a, b) => new Date(a.createdAt) - new Date(b.createdAt)
					)
			);
		} else {
			setAttendance(attendancesInfo.data || []);
		}
	}, [startDate, endDate]);

	useEffect(() => {}, [attendances]);

	return loading ? (
		<Loading />
	) : (
		<div>
			<div className="present_modal_header">
				<div>Attendance History</div>
				<svg onClick={close} width="30" viewBox="0 0 36 36" fill="none">
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>
			<div className="AttendanceHistoryModal">
				<table
					style={{
						borderCollapse: "collapse",
						border: 0,
						width: "100%",
					}}
				>
					<tbody>
						<tr>
							<td style={{ border: "none", width: "50px" }}>
								<h2>From:</h2>
							</td>
							<td style={{ border: "none", textAlign: "start" }}>
								<DatePicker
									selected={startDate}
									onChange={(date) => setStartDate(date)}
									selectsStart
									startDate={startDate}
									endDate={endDate}
									customInput={<ExampleCustomInput />}
									calendarClassName="rasta-stripes"
									portalId="root-portal"
								/>
							</td>
						</tr>
						<tr>
							<td style={{ border: "none" }}>
								<h2>To:</h2>
							</td>
							<td style={{ border: "none", textAlign: "start" }}>
								<DatePicker
									selected={endDate}
									onChange={(date) => setEndDate(date)}
									selectsEnd
									startDate={startDate}
									endDate={endDate}
									minDate={startDate}
									customInput={<ExampleCustomInput />}
									calendarClassName="rasta-stripes"
									portalId="root-portal1"
								/>
							</td>
						</tr>
						{/* <tr>
            <td colSpan="2">
              <ClearButton clearDates={clearDates} />
            </td>
          </tr> */}
						{isAnyDateSelected && (
							<tr>
								<td colSpan="2">
									<div className="clear_box">
										<p
											onClick={clearSelects}
											style={{
												textAlign: "right",
												cursor: "pointer",
												fontWeight: 400,
												fontSize: "11px",
												color: "#24A0ED",
												textDecoration: "underline",
											}}
										>
											<span style={{ marginLeft: "5px" }}>
												Clear All
											</span>
										</p>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{/* <Flex align={"center"}>
          <label style={{ marginRight: "20px" }}>From :</label>
          <Select
            maxDropdownHeight={150}
            maw={100}
            size="xs"
            styles={{
              input: {
                borderRadius: "10px",
                fontSize: "12px",
                border: "1px solid #8399A3",
                background: "#264147",
                color: "#fff",
              },
            }}
            rightSection={
              <svg width="10" height="8" viewBox="0 0 13 8" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
                  fill="#fff"
                />
              </svg>
            }
            placeholder="Month"
            data={Array.from({ length: 12 }, (_, i) => {
              const monthName = moment().month(i).format("MMMM");
              return { value: i, label: monthName };
            })}
            onChange={(v) => console.log(typeof v)}
          />

          <Flex ml={20} align={"center"}>
            <Select
              maxDropdownHeight={150}
              maw={100}
              size="xs"
              styles={{
                input: {
                  borderRadius: "10px",
                  fontSize: "12px",
                  border: "1px solid #8399A3",
                  background: "#264147",
                  color: "#fff",
                },
              }}
              rightSection={
                <svg width="10" height="8" viewBox="0 0 13 8" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
                    fill="#fff"
                  />
                </svg>
              }
              placeholder="Year"
              data={Array.from(
                { length: new Date().getFullYear() - 1999 },
                (_, i) => {
                  const year = (2000 + i).toString();
                  return { value: year, label: year };
                }
              ).reverse()}
              onChange={setFromYear}
            />
          </Flex>
        </Flex> */}
				{/* <Flex mt={20} align={"center"}>
          <label style={{ marginRight: "20px" }}>To :</label>
          <Select
            ml={20}
            maxDropdownHeight={150}
            maw={100}
            size="xs"
            styles={{
              input: {
                borderRadius: "10px",
                fontSize: "12px",
                border: "1px solid #8399A3",
                background: "#264147",
                color: "#fff",
              },
            }}
            rightSection={
              <svg width="10" height="8" viewBox="0 0 13 8" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
                  fill="#fff"
                />
              </svg>
            }
            placeholder="Month"
            data={Array.from({ length: 12 }, (_, i) => {
              const monthName = moment().month(i).format("MMMM");
              return { value: i, label: monthName };
            })}
            onChange={(v) => console.log(typeof v)}
          />

          <Flex ml={20} align={"center"}>
            <Select
              maxDropdownHeight={150}
              maw={100}
              size="xs"
              styles={{
                input: {
                  borderRadius: "10px",
                  fontSize: "12px",
                  border: "1px solid #8399A3",
                  background: "#264147",
                  color: "#fff",
                },
              }}
              rightSection={
                <svg width="10" height="8" viewBox="0 0 13 8" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
                    fill="#fff"
                  />
                </svg>
              }
              placeholder="Year"
              data={Array.from(
                { length: new Date().getFullYear() - 1999 },
                (_, i) => {
                  const year = (2000 + i).toString();
                  return { value: year, label: year };
                }
              ).reverse()}
              onChange={(v) => console.log(typeof v)}
            />
          </Flex>
        </Flex> */}

				<Divider color="#264147" mt={20} />

				<div className="AttendanceHistoryModal_bottom">
					<div>
						<label>Number of Working Days</label>
						<p>{attendances.length}</p>
					</div>
					<div>
						<label>Present</label>
						<p>
							{
								attendances.filter(
									(attendance) =>
										attendance.status == "present" ||
										attendance.status == "late"
								).length
							}
						</p>
					</div>
					<div
						className={absent ? "absent_box_colored" : "absent_box"}
						onClick={() => setAbsent(!absent)}
					>
						<label>Absent</label>
						<p>
							{
								attendances.filter(
									(attendance) =>
										attendance.status == "absent"
								).length
							}
						</p>
					</div>
				</div>

				{absent && (
					<>
						<Divider color="#264147" mt={20} />
						{attendances
							.filter(
								(attendance) => attendance.status == "absent"
							)
							.map((attendance, idx) => (
								<div className="absent_days_box" key={idx}>
									<p>{attendance.date}</p>
									<p>{getDayFromDate(attendance.date)}</p>
								</div>
							))}
					</>
				)}
			</div>
		</div>
	);
};

export default AttendanceHistoryModal;
