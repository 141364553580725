import { Divider, Flex, Modal } from "@mantine/core";
import "./DriverDetails.css";
import DriverDetailsHeader from "./DriverDetailsHeader/DriverDetailsHeader";
import ViewDocumentModal from "../../../components/ViewDocumentModal/ViewDocumentModal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import { getDriverById } from "../../../redux/reducers/driverReducer";
import { rupeeSymbol } from "../../../utils/constants";
import moment from "moment/moment";
import DeleteAccModal from "../../../components/DeleteAccModal/DeleteAccModal";
import { checkURLType } from "../../../utils/functions";

const DriverDetails = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const driverInfo = useSelector((state) => state.driver.fetchedDriver);
	const loading = useSelector((state) => state.driver.fetchedDriver.loading);
	const totalDays = moment().endOf("month").date();

	const driver = driverInfo.data || {};

	useEffect(() => {
		if (id) {
			dispatch(getDriverById({ id }));
		}
	}, [id, dispatch]);

	const [opened, setOpened] = useState(false);
	const [documentImage, setDocumentImage] = useState(null);
	const [documentName, setDocumentName] = useState(null);

	const open = (image, imageName) => {
		setDocumentImage(image);
		setDocumentName(imageName);
		setOpened(true);
	};

	const close = () => {
		setOpened(false);
	};

	const [deletehubmodal, setdeletehubmodal] = useState(false);
	const navigate = useNavigate();
	return loading ? (
		<Loading />
	) : (
		<div style={{ position: "relative", margin: "40px 40px 0 40px" }}>
			<div className="driver_details_container">
				<DriverDetailsHeader key={id} driver={driver} />

				<div className="driver_document_ribbon">
					<h5>Documents:</h5>
					<div>
						<h6>Aadhaar Card (Front)</h6>
						<button
							onClick={() =>
								open(
									driver.aadhaarCardFront || null,
									"Aadhaar Card (Front)"
								)
							}
						>
							view
						</button>
					</div>
					<div>
						<h6>Aadhaar Card (Back)</h6>
						<button
							onClick={() =>
								open(
									driver.aadhaarCardBack || null,
									"Aadhaar Card (Back)"
								)
							}
						>
							view
						</button>
					</div>
					<div>
						<h6>Driver’s License</h6>
						<button
							onClick={() =>
								open(
									driver.drivingLicense || null,
									"Driver’s License"
								)
							}
						>
							view
						</button>
					</div>
					<div>
						<h6>Pan Card</h6>
						<button
							onClick={() =>
								open(driver.panCard || null, "Pan Card")
							}
						>
							view
						</button>
					</div>
				</div>
				<Modal
					opened={opened}
					onClose={close}
					centered
					withCloseButton={false}
					size="auto"
					padding={0}
					radius={10}
					overlayProps={{
						blur: 5,
						color: "#fff",
						backgroundopacity: 1,
					}}
				>
					<ViewDocumentModal
						close={close}
						image={documentImage}
						documentName={documentName}
						large={true}
					/>
				</Modal>
				<Divider color="#C2D0D6" mx={20} />

				<div className="driver_down_box">
					<div className="driver_info_container">
						<div className="driver_info_box">
							<h5>Assigned Vehicle</h5>
							<p>{driver?.vehicle?.vehicle_no}</p>
							<p>
								Doc:
								<a
									className="vehicle_a"
									onClick={() =>
										checkURLType(
											driver?.vehicle?.insurance_file
										) === "image"
											? open(
													driver?.vehicle
														?.insurance_file,
													"Insurance"
											  )
											: window.open(
													driver?.vehicle
														?.insurance_file,
													"_blank"
											  )
									}
								>
									Insurance
								</a>
								&nbsp;&nbsp;
								<a
									className="vehicle_a"
									onClick={() =>
										checkURLType(
											driver?.vehicle?.rc_image
										) === "image"
											? open(
													driver?.vehicle?.rc_image,
													"RC"
											  )
											: window.open(
													driver?.vehicle?.rc_image,
													"_blank"
											  )
									}
								>
									RC
								</a>
							</p>
						</div>
						<div className="driver_info_box">
							<h5>Salary</h5>
							<Flex>
								<p>Daily&nbsp;</p>
								<p>
									{rupeeSymbol}
									{driver?.salary}/-
								</p>
							</Flex>
							<Flex>
								<p>Monthly&nbsp;</p>
								<p>
									{rupeeSymbol}
									{totalDays * driver?.salary}/-
								</p>
							</Flex>
						</div>
						<div className="driver_info_box">
							<h5>Clients</h5>
							<p>
								{(driver.clients || [])
									.map((client) => client.name)
									.join(", ")}
							</p>
						</div>
						<div className="driver_info_box">
							<h5>Hub</h5>
							<p>{driver.hub?.name}</p>
							<p>
								{driver.hub?.address}, {driver.hub?.landmark},{" "}
								{driver.hub?.city}, {driver.hub?.state}-
								{driver.hub?.pincode}
							</p>
						</div>
						<div className="driver_info_box">
							<h5>Recruiter</h5>
							<p>{driver.recruiter?.name}</p>
						</div>
						<div className="driver_info_box">
							<h5>Supervisor</h5>
							<p>{driver.supervisor?.name}</p>
						</div>
						<div className="driver_info_box">
							<h5>Shift Timings</h5>
							<p>
								{driver.shift?.startTime} to{" "}
								{driver.shift?.endTime}
							</p>
						</div>
					</div>
					<div className="accountBtns">
						<button
							className="Deactivate_button"
							onClick={() => setdeletehubmodal(true)}
						>
							Delete Account
						</button>
						<button
							className="black_button"
							onClick={() =>
								navigate(
									`/dashboard/editdriverdetails/${driver._id}`
								)
							}
						>
							Edit Details
						</button>
					</div>
				</div>
				<Modal
					opened={deletehubmodal}
					onClose={() => setdeletehubmodal(false)}
					centered
					withCloseButton={false}
					size="sm"
					padding={0}
					radius={10}
					overlayProps={{
						blur: 5,
						color: "#fff",
						backgroundopacity: 1,
					}}
				>
					<DeleteAccModal
						setdeletehubmodal={setdeletehubmodal}
						driver={driver}
						onchange={() => {
							navigate(`/dashboard/fleet`);
						}}
					/>
				</Modal>
			</div>
		</div>
	);
};

export default DriverDetails;
