// App.js
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home.jsx";
import Login from "./pages/Login/Login.jsx";
import Dashboard from "./pages/Dashboard/Dashboard.jsx";
import Monitor from "./pages/Dashboard/Monitor/Monitor.jsx";
import FleetManagement from "./pages/Dashboard/FleetManagement/FleetManagement.jsx";
import SalaryManagement from "./pages/Dashboard/SalaryManagement/SalaryManagement.jsx";
import HubsandClients from "./pages/Dashboard/HubsandClients/HubsandClients.jsx";
import DriverApproval from "./pages/Dashboard/DriverApproval/DriverApproval.jsx";
import DriverDetails from "./pages/Dashboard/DriverDetails/DriverDetails.jsx";
import Map from "./pages/Dashboard/HubsandClients/Map/Map.jsx";
import EditDriverDetails from "./pages/Dashboard/EditDriverDetails/EditDriverDetails.jsx";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoute from "./wrappers/ProtectedRoute.jsx";
import { verifyToken } from "./redux/reducers/userReducer.js";
import { connectUser } from "./socket/index.js";
import { listenEvents } from "./socket/notification.js";
import { fetchData } from "./redux/reducers/appReducer.js";
import DeleteDriver from "./pages/public/DeleteDriver/DeleteDriver.jsx";
import PrivacyPolicy from "./pages/public/PrivacyPolicy/PrivacyPolicy.jsx";
import SupervisorManagement from "./pages/Dashboard/SupervisorManagement/SupervisorManagement.jsx";
import SupervisorApproval from "./pages/Dashboard/SupervisorApproval/SupervisorApproval.jsx";
import SupervisorDetails from "./pages/Dashboard/SupervisorDetails/SupervisorDetails.jsx";
import EditSupervisorDetails from "./pages/Dashboard/EditSupervisorDetails/EditSupervisorDetails.jsx";
import ChallanManagement from "./pages/Dashboard/ChallanManagement/ChallanManagement.jsx";

const App = () => {
	// hooks
	const dispatch = useDispatch();

	// redux states
	const token = useSelector((state) => state.user?.token);
	const isAuthenticated = useSelector((state) => state.user?.isAuthenticated);
	const user = useSelector((state) => state.user?.data);

	// functions
	async function handleToken() {
		if (token) {
			dispatch(verifyToken());
		}
	}

	async function handleIsAuthenticated() {
		// console.log("handleUser isAuthenticated", isAuthenticated);
		if (isAuthenticated) {
			dispatch(fetchData());
			const res = await connectUser(user._id);
			if (res?.success) {
				// console.log(res.message);
				listenEvents();
			}
		}
	}

	// effects
	useEffect(() => {
		handleToken();
	}, [token]);

	useEffect(() => {
		handleIsAuthenticated();
	}, [isAuthenticated]);

	return (
		<Routes>
			<Route path="/" element={<Login />} />
			<Route path="/login" element={<Login />} />
			<Route path="/deleteUser" element={<DeleteDriver />} />
			<Route path="/privacyPolicy" element={<PrivacyPolicy />} />

			<Route
				path="/home"
				element={<ProtectedRoute element={<Home />} />}
			/>

			<Route path="dashboard" element={<Dashboard />}>
				<Route
					index
					element={<ProtectedRoute element={<Monitor />} />}
				/>
				<Route
					path="monitor"
					element={<ProtectedRoute element={<Monitor />} />}
				/>
				<Route
					path="fleet"
					element={<ProtectedRoute element={<FleetManagement />} />}
				/>
				<Route
					path="salarymanagement"
					element={<ProtectedRoute element={<SalaryManagement />} />}
				/>
				<Route
					path="hubsandclients"
					element={<ProtectedRoute element={<HubsandClients />} />}
				/>
				<Route
					path="driverapproval/:id"
					element={<ProtectedRoute element={<DriverApproval />} />}
				/>
				<Route
					path="driverdetails/:id"
					element={<ProtectedRoute element={<DriverDetails />} />}
				/>
				<Route
					path="editdriverdetails/:id"
					element={<ProtectedRoute element={<EditDriverDetails />} />}
				/>
				<Route
					path="createnewmap"
					element={<ProtectedRoute element={<Map />} />}
				/>
				<Route
					path="supervisormanagement"
					element={
						<ProtectedRoute element={<SupervisorManagement />} />
					}
				/>
				<Route
					path="supervisorapproval/:id"
					element={
						<ProtectedRoute element={<SupervisorApproval />} />
					}
				/>
				<Route
					path="supervisordetails/:id"
					element={<ProtectedRoute element={<SupervisorDetails />} />}
				/>
				<Route
					path="editsupervisordetails/:id"
					element={
						<ProtectedRoute element={<EditSupervisorDetails />} />
					}
				/>
				<Route
					path="challanmanagement"
					element={<ProtectedRoute element={<ChallanManagement />} />}
				/>
			</Route>
		</Routes>
	);
};

export default App;
