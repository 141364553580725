import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
  createShift,
  createShiftFulfilled,
  createShiftRejected,
  deleteShift,
  deleteShiftFulfilled,
  deleteShiftRejected,
  getShifts,
  getShiftsFulfilled,
  getShiftsRejected,
  updateShift,
  updateShiftFulfilled,
  updateShiftRejected,
} from "../reducers/shiftReducer";
import { getErrorMessage } from "../../utils/functions";

function* createShiftSaga(action) {
  try {
    const res = yield call(api.post, "/shift", action.payload?.data);
    // console.log("createShiftSaga", res?.data);
    if (createShift.prototype.onSuccess) {
      createShift.prototype.onSuccess();
    }
    yield put(createShiftFulfilled(res?.data));
  } catch (err) {
    if (createShift.prototype.onReject) {
      createShift.prototype.onReject();
    }
    yield put(createShiftRejected(getErrorMessage(err)));
  }
}

function* getShiftsSaga() {
  try {
    const res = yield call(api.get, "/shift");
    // console.log("getShiftsSaga", res?.data);
    if (getShifts.prototype.onSuccess) {
      getShifts.prototype.onSuccess();
    }
    yield put(getShiftsFulfilled(res?.data));
  } catch (err) {
    if (getShifts.prototype.onReject) {
      getShifts.prototype.onReject();
    }
    yield put(getShiftsRejected(getErrorMessage(err)));
  }
}

function* updateShiftSaga(action) {
  try {
    const id = action.payload?.id;
    const data = action.payload?.data;
    const res = yield call(api.patch, `/shift/${id}`, data);
    // console.log("updateShiftSaga", res?.data);
    if (updateShift.prototype.onSuccess) {
      updateShift.prototype.onSuccess();
    }
    yield put(updateShiftFulfilled(res?.data));
  } catch (err) {
    if (updateShift.prototype.onReject) {
      updateShift.prototype.onReject();
    }
    yield put(updateShiftRejected(getErrorMessage(err)));
  }
}

function* deleteShiftSaga(action) {
  try {
    const id = action.payload?.id;
    const res = yield call(api.delete, `/shift/${id}`);
    // console.log("updateShiftSaga", res?.data);
    if (deleteShift.prototype.onSuccess) {
      deleteShift.prototype.onSuccess();
    }
    yield put(deleteShiftFulfilled(res?.data));
  } catch (err) {
    if (deleteShift.prototype.onReject) {
      deleteShift.prototype.onReject();
    }
    yield put(deleteShiftRejected(getErrorMessage(err)));
  }
}

export function* shiftSaga() {
  yield takeLatest(createShift.type, createShiftSaga);
  yield takeLatest(getShifts.type, getShiftsSaga);
  yield takeLatest(updateShift.type, updateShiftSaga);
  yield takeLatest(deleteShift.type, deleteShiftSaga);
}
