import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import logo from "../../../assets/images/logo2.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/reducers/userReducer";
import { useState } from "react";
import { Modal } from "@mantine/core";
import LogoutModal from "../../../components/LogoutModal/LogoutModal";

const Sidebar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const [statusModal, setStatusModal] = useState(false);

	function onClickLogout() {
		logout.prototype.onSuccess = () => {
			navigate("/login"); // IMPORTANT: not used but keep it as app was crashing
		};
		dispatch(logout());
	}

	return (
		<div className="sidemenu">
			<div>
				<div className="sidemenu_brand">
					<img src={logo} alt="zevo_logo" />
				</div>

				<div>
					<NavLink
						to="monitor"
						className={(navLink) =>
							navLink.isActive ||
							location.pathname == "/dashboard"
								? "sidebar_menu_item checked"
								: "sidebar_menu_item"
						}
					>
						<div>
							{location.pathname == "/dashboard" && (
								<svg
									className="custom_svg"
									width="5"
									height="60%"
									viewBox="0 0 5 46"
									fill="none"
								>
									<rect
										width="5"
										height="90%"
										rx="2.5"
										fill="#029AC9"
									/>
								</svg>
							)}
							{location.pathname == "/dashboard/monitor" && (
								<svg
									className="custom_svg"
									width="5"
									height="60%"
									viewBox="0 0 5 46"
									fill="none"
								>
									<rect
										width="5"
										height="90%"
										rx="2.5"
										fill="#029AC9"
									/>
								</svg>
							)}

							<svg
								className="menu--icon"
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
							>
								<rect
									x="1"
									y="1"
									width="6"
									height="6"
									rx="1"
									stroke="#8399A3"
									strokeWidth="2"
									strokeLinejoin="round"
								/>
								<rect
									x="1"
									y="11"
									width="6"
									height="6"
									rx="1"
									stroke="#8399A3"
									strokeWidth="2"
									strokeLinejoin="round"
								/>
								<rect
									x="11"
									y="11"
									width="6"
									height="6"
									rx="1"
									stroke="#8399A3"
									strokeWidth="2"
									strokeLinejoin="round"
								/>
								<rect
									x="11"
									y="1"
									width="6"
									height="6"
									rx="1"
									stroke="#8399A3"
									strokeWidth="2"
									strokeLinejoin="round"
								/>
							</svg>
							<span className="menu--text">Dashboard</span>
						</div>
					</NavLink>

					<NavLink
						to="fleet"
						className={(navLink) =>
							navLink.isActive
								? "sidebar_menu_item checked"
								: "sidebar_menu_item"
						}
					>
						<div>
							{location.pathname == "/dashboard/fleet" && (
								<svg
									className="custom_svg"
									width="5"
									height="60%"
									viewBox="0 0 5 46"
									fill="none"
								>
									<rect
										width="5"
										height="90%"
										rx="2.5"
										fill="#029AC9"
									/>
								</svg>
							)}
							<svg
								className="menu--icon"
								width="24"
								height="20"
								viewBox="0 0 24 20"
								fill="none"
							>
								<circle
									cx="12.0716"
									cy="5.01793"
									r="4.21793"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
								<path
									d="M16.1617 5.15C16.5198 4.52985 17.1095 4.07734 17.8012 3.892C18.4929 3.70666 19.2299 3.80369 19.85 4.16173C20.4701 4.51977 20.9227 5.1095 21.108 5.80119C21.2933 6.49287 21.1963 7.22985 20.8383 7.85C20.4802 8.47015 19.8905 8.92266 19.1988 9.108C18.5071 9.29334 17.7701 9.19631 17.15 8.83827C16.5299 8.48023 16.0773 7.8905 15.892 7.19881C15.7067 6.50713 15.8037 5.77015 16.1617 5.15L16.1617 5.15Z"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M3.16173 5.15C3.51977 4.52985 4.1095 4.07734 4.80119 3.892C5.49287 3.70666 6.22985 3.80369 6.85 4.16173C7.47015 4.51977 7.92266 5.1095 8.108 5.80119C8.29334 6.49287 8.19631 7.22985 7.83827 7.85C7.48023 8.47015 6.8905 8.92266 6.19881 9.108C5.50713 9.29334 4.77015 9.19631 4.15 8.83827C3.52985 8.48023 3.07734 7.8905 2.892 7.19881C2.70666 6.50713 2.80369 5.77015 3.16173 5.15L3.16173 5.15Z"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M23.1021 16.4629L22.339 16.7031L23.1021 16.4629ZM15.5596 13.4361L15.0757 12.799L14.1503 13.5018L15.1371 14.1155L15.5596 13.4361ZM18.1953 17.5629L17.4111 17.7208L17.5404 18.3629H18.1953V17.5629ZM18.3433 13.3449C20.8463 13.3449 21.898 15.302 22.339 16.7031L23.8652 16.2227C23.3764 14.6698 21.9649 11.7449 18.3433 11.7449V13.3449ZM16.0434 14.0732C16.6035 13.6478 17.3418 13.3449 18.3433 13.3449V11.7449C16.9867 11.7449 15.9097 12.1657 15.0757 12.799L16.0434 14.0732ZM15.1371 14.1155C16.5976 15.0236 17.1826 16.5862 17.4111 17.7208L18.9796 17.4049C18.7161 16.0965 17.994 14.0078 15.982 12.7567L15.1371 14.1155ZM22.2284 16.7629H18.1953V18.3629H22.2284V16.7629ZM22.339 16.7031C22.344 16.7189 22.3415 16.7219 22.3424 16.7173C22.343 16.7144 22.3435 16.7163 22.3386 16.7223C22.328 16.7353 22.2941 16.7629 22.2284 16.7629V18.3629C23.2761 18.3629 24.2353 17.3984 23.8652 16.2227L22.339 16.7031Z"
									fill="#8399A3"
								/>
								<path
									d="M8.58292 13.4363L9.00542 14.1156L9.99206 13.502L9.06681 12.7992L8.58292 13.4363ZM1.04016 16.4628L1.80324 16.7031L1.04016 16.4628ZM5.94749 17.5629V18.3629H6.60243L6.73174 17.7208L5.94749 17.5629ZM5.79901 13.3449C6.80062 13.3449 7.53894 13.6479 8.09904 14.0733L9.06681 12.7992C8.23284 12.1658 7.15571 11.7449 5.79901 11.7449V13.3449ZM1.80324 16.7031C2.24428 15.302 3.29594 13.3449 5.79901 13.3449V11.7449C2.17744 11.7449 0.765891 14.6698 0.27707 16.2226L1.80324 16.7031ZM1.91385 16.7629C1.84819 16.7629 1.81422 16.7353 1.80364 16.7223C1.79872 16.7163 1.79925 16.7144 1.79983 16.7173C1.80076 16.7219 1.79826 16.7189 1.80324 16.7031L0.27707 16.2226C-0.0930405 17.3984 0.866158 18.3629 1.91385 18.3629V16.7629ZM5.94749 16.7629H1.91385V18.3629H5.94749V16.7629ZM6.73174 17.7208C6.96024 16.5863 7.54518 15.0238 9.00542 14.1156L8.16042 12.7569C6.14872 14.0081 5.42672 16.0966 5.16324 17.4049L6.73174 17.7208Z"
									fill="#8399A3"
								/>
								<path
									d="M12.0712 12.5449C16.7636 12.5449 17.9456 16.0552 18.2434 17.8238C18.3351 18.3684 17.8959 18.8173 17.3437 18.8173H6.79883C6.24654 18.8173 5.8074 18.3684 5.89909 17.8238C6.19686 16.0552 7.37889 12.5449 12.0712 12.5449Z"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
							</svg>
							<span className="menu--text ">
								Fleet management
							</span>
						</div>
					</NavLink>

					<NavLink
						to="salarymanagement"
						className={(navLink) =>
							navLink.isActive
								? "sidebar_menu_item checked"
								: "sidebar_menu_item"
						}
					>
						<div>
							{location.pathname ==
								"/dashboard/salarymanagement" && (
								<svg
									className="custom_svg"
									width="5"
									height="60%"
									viewBox="0 0 5 46"
									fill="none"
								>
									<rect
										width="5"
										height="90%"
										rx="2.5"
										fill="#029AC9"
									/>
								</svg>
							)}
							<svg
								className="menu--icon"
								width="21"
								height="22"
								viewBox="0 0 21 22"
								fill="none"
							>
								<path
									d="M16.8333 1.00001V1.00001C16.9881 1.00001 17.0655 1.00001 17.1309 1.00286C18.6849 1.07071 19.9293 2.31512 19.9971 3.86915C20 3.93455 20 4.01192 20 4.16667L20 6.42857C20 6.8081 20 6.99786 19.9023 7.13024C19.8726 7.17044 19.8371 7.20597 19.7969 7.23563C19.6645 7.33333 19.4748 7.33333 19.0952 7.33333L13.6667 7.33333M16.8333 1.00001V1.00001C16.6786 1.00001 16.6012 1.00001 16.5358 1.00286C14.9818 1.07071 13.7374 2.31512 13.6695 3.86915C13.6667 3.93455 13.6667 4.01192 13.6667 4.16667L13.6667 7.33333M16.8333 1.00001L5 1.00001C3.11438 1.00001 2.17157 1.00001 1.58579 1.58579C0.999999 2.17158 0.999999 3.11439 0.999999 5.00001L1 20L4.16667 18.9444L7.33333 20L10.5 18.9444L13.6667 20L13.6667 7.33333"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M5.22266 5.22266L9.44488 5.22266"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
								<path
									d="M6.27734 9.44531H5.22179"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
								<path
									d="M5.22266 13.666L8.38932 13.666"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
							</svg>

							<span className="menu--text">
								Salary management
							</span>
						</div>
					</NavLink>

					<NavLink
						to="hubsandclients"
						className={(navLink) =>
							navLink.isActive
								? "sidebar_menu_item checked"
								: "sidebar_menu_item"
						}
					>
						<div>
							{location.pathname ==
								"/dashboard/hubsandclients" && (
								<svg
									className="custom_svg"
									width="5"
									height="60%"
									viewBox="0 0 5 46"
									fill="none"
								>
									<rect
										width="5"
										height="90%"
										rx="2.5"
										fill="#029AC9"
									/>
								</svg>
							)}
							<svg
								className="menu--icon"
								width="18"
								height="24"
								viewBox="0 0 18 24"
								fill="none"
							>
								<path
									d="M13 3.28516C14.6051 3.28516 15.4077 3.28516 15.9842 3.67037C16.2337 3.83713 16.448 4.05142 16.6148 4.301C17 4.87751 17 5.68006 17 7.28516V18.1423C17 20.2973 17 21.3748 16.3305 22.0443C15.6611 22.7137 14.5836 22.7137 12.4286 22.7137H5.57143C3.41644 22.7137 2.33894 22.7137 1.66947 22.0443C1 21.3748 1 20.2973 1 18.1423V7.28516C1 5.68006 1 4.87751 1.38521 4.301C1.55197 4.05142 1.76626 3.83713 2.01584 3.67037C2.59235 3.28516 3.3949 3.28516 5 3.28516"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M5.57129 3.28571C5.57129 2.02335 6.59464 1 7.857 1H10.1427C11.4051 1 12.4284 2.02335 12.4284 3.28571C12.4284 4.54808 11.4051 5.57143 10.1427 5.57143H7.857C6.59464 5.57143 5.57129 4.54808 5.57129 3.28571Z"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M5.57129 11.2852L12.4284 11.2852"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
								<path
									d="M5.57129 15.8574L10.1427 15.8574"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
							</svg>
							<span className="menu--text">Hub Management</span>
						</div>
					</NavLink>

					<NavLink
						to="supervisormanagement"
						className={(navLink) =>
							navLink.isActive
								? "sidebar_menu_item checked"
								: "sidebar_menu_item"
						}
					>
						<div>
							{location.pathname ==
								"/dashboard/supervisormanagement" && (
								<svg
									className="custom_svg"
									width="5"
									height="60%"
									viewBox="0 0 5 46"
									fill="none"
								>
									<rect
										width="5"
										height="90%"
										rx="2.5"
										fill="#029AC9"
									/>
								</svg>
							)}
							<svg
								className="menu--icon"
								width="18"
								height="24"
								viewBox="0 0 18 24"
								fill="none"
							>
								<path
									d="M13 3.28516C14.6051 3.28516 15.4077 3.28516 15.9842 3.67037C16.2337 3.83713 16.448 4.05142 16.6148 4.301C17 4.87751 17 5.68006 17 7.28516V18.1423C17 20.2973 17 21.3748 16.3305 22.0443C15.6611 22.7137 14.5836 22.7137 12.4286 22.7137H5.57143C3.41644 22.7137 2.33894 22.7137 1.66947 22.0443C1 21.3748 1 20.2973 1 18.1423V7.28516C1 5.68006 1 4.87751 1.38521 4.301C1.55197 4.05142 1.76626 3.83713 2.01584 3.67037C2.59235 3.28516 3.3949 3.28516 5 3.28516"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M5.57129 3.28571C5.57129 2.02335 6.59464 1 7.857 1H10.1427C11.4051 1 12.4284 2.02335 12.4284 3.28571C12.4284 4.54808 11.4051 5.57143 10.1427 5.57143H7.857C6.59464 5.57143 5.57129 4.54808 5.57129 3.28571Z"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M5.57129 11.2852L12.4284 11.2852"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
								<path
									d="M5.57129 15.8574L10.1427 15.8574"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
							</svg>
							<span className="menu--text">
								Supervisor Management
							</span>
						</div>
					</NavLink>

					<NavLink
						to="challanmanagement"
						className={(navLink) =>
							navLink.isActive
								? "sidebar_menu_item checked"
								: "sidebar_menu_item"
						}
					>
						<div>
							{location.pathname ==
								"/dashboard/challanmanagement" && (
								<svg
									className="custom_svg"
									width="5"
									height="60%"
									viewBox="0 0 5 46"
									fill="none"
								>
									<rect
										width="5"
										height="90%"
										rx="2.5"
										fill="#029AC9"
									/>
								</svg>
							)}
							<svg
								className="menu--icon"
								width="18"
								height="24"
								viewBox="0 0 18 24"
								fill="none"
							>
								<path
									d="M13 3.28516C14.6051 3.28516 15.4077 3.28516 15.9842 3.67037C16.2337 3.83713 16.448 4.05142 16.6148 4.301C17 4.87751 17 5.68006 17 7.28516V18.1423C17 20.2973 17 21.3748 16.3305 22.0443C15.6611 22.7137 14.5836 22.7137 12.4286 22.7137H5.57143C3.41644 22.7137 2.33894 22.7137 1.66947 22.0443C1 21.3748 1 20.2973 1 18.1423V7.28516C1 5.68006 1 4.87751 1.38521 4.301C1.55197 4.05142 1.76626 3.83713 2.01584 3.67037C2.59235 3.28516 3.3949 3.28516 5 3.28516"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M5.57129 3.28571C5.57129 2.02335 6.59464 1 7.857 1H10.1427C11.4051 1 12.4284 2.02335 12.4284 3.28571C12.4284 4.54808 11.4051 5.57143 10.1427 5.57143H7.857C6.59464 5.57143 5.57129 4.54808 5.57129 3.28571Z"
									stroke="#8399A3"
									strokeWidth="1.6"
								/>
								<path
									d="M5.57129 11.2852L12.4284 11.2852"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
								<path
									d="M5.57129 15.8574L10.1427 15.8574"
									stroke="#8399A3"
									strokeWidth="1.6"
									strokeLinecap="round"
								/>
							</svg>
							<span className="menu--text">
								Challan Management
							</span>
						</div>
					</NavLink>
				</div>
			</div>

			<div className="logout_btn" onClick={() => setStatusModal(true)}>
				<svg width="17" height="16" viewBox="0 0 17 16" fill="none">
					<path
						d="M2 8L1.29289 8.70711L0.585786 8L1.29289 7.29289L2 8ZM11 7C11.5523 7 12 7.44771 12 8C12 8.55228 11.5523 9 11 9L11 7ZM6.29289 13.7071L1.29289 8.70711L2.70711 7.29289L7.70711 12.2929L6.29289 13.7071ZM1.29289 7.29289L6.29289 2.29289L7.70711 3.70711L2.70711 8.70711L1.29289 7.29289ZM2 7L11 7L11 9L2 9L2 7Z"
						fill="white"
					/>
					<path
						d="M13 15L14 15C15.1046 15 16 14.1046 16 13L16 3C16 1.89543 15.1046 1 14 1L13 1"
						stroke="white"
						strokeWidth="2"
					/>
				</svg>
				Logout
			</div>

			<Modal
				opened={statusModal}
				onClose={() => setStatusModal(false)}
				centered
				withCloseButton={false}
				size="sm"
				padding={0}
				radius={10}
				overlayProps={{
					blur: 5,
					color: "#fff",
					backgroundopacity: 1,
				}}
			>
				<LogoutModal
					setStatusModal={setStatusModal}
					onClickLogout={onClickLogout}
				/>
			</Modal>
		</div>
	);
};

export default Sidebar;
