import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  id: null,
  formData: {
    name: undefined,
    phone: undefined,
    state: undefined,
    city: undefined,
    pincode: undefined,
  },
};

const clientReducer = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClientId: (state, action) => {
      if (typeof action.payload === "string") {
        state.id = action.payload;
      }
    },
    setClientFormData: (state, action) => {
      if (typeof action.payload === "object") {
        Object.assign(state.formData, action.payload);
      }
    },
    createClient: (state) => {
      state.loading = true;
      state.error = null;
    },
    createClientFulfilled: (state) => {
      state.loading = false;
      state.formData = initialState.formData;
    },
    createClientRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getClients: (state) => {
      state.loading = true;
      state.error = null;
    },
    getClientsFulfilled: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) state.data = action.payload;
    },
    getClientsRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateClient: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateClientFulfilled: (state) => {
      state.loading = false;
      state.id = initialState.id;
      state.formData = initialState.formData;
    },
    updateClientRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteClient: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteClientFulfilled: (state) => {
      state.loading = false;
    },
    deleteClientRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setClientId,
  setClientFormData,
  createClient,
  createClientFulfilled,
  createClientRejected,
  getClients,
  getClientsFulfilled,
  getClientsRejected,
  updateClient,
  updateClientFulfilled,
  updateClientRejected,
  deleteClient,
  deleteClientFulfilled,
  deleteClientRejected,
} = clientReducer.actions;

export default clientReducer.reducer;
