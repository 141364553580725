import { Divider, Modal, Table, TextInput } from "@mantine/core";
import "./RecruitersTable.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	createRecruiter,
	deleteRecruiter,
	updateRecruiter,
} from "../../../../redux/reducers/recruiterReducer";
import RecruiterDeleteModal from "../../../../components/RecruiterDeleteModal/RecruiterDeleteModal";
import { useEffect } from "react";
import { toast } from "react-toastify";
const initialState = {
	formData: {
		name: undefined,
	},
};
const RecruitersTable = () => {
	const dispatch = useDispatch();
	const [addRow, setAddRow] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [editingIndex, setEditingIndex] = useState(-1);
	const [formValue, setFormValue] = useState(initialState.formData);
	const [statusModals, setModals] = useState({});

	// // For opening modal
	const openModal = (recruiterId) => {
		setModals({ ...statusModals, [recruiterId]: true });
	};

	// For closing modal
	const closeModal = (recruiterId) => {
		setModals({ ...statusModals, [recruiterId]: false });
	};

	// redux
	const recruiters = useSelector((state) => state.recruiter?.data);

	useEffect(() => {
		setFormValue(
			recruiters?.find((recruiter) => recruiter._id == editingIndex)
		);
	}, [editingIndex]);
	// const onClickDelete = (id) => {
	// 	deleteRecruiter.prototype.onSuccess = () => {
	// 		setDeleteModal(false);
	// 		setEditingIndex(-1);
	// 		toast.success("Recruiter Deleted Successfully");
	// 	};
	// 	dispatch(deleteRecruiter({ id: id }));
	// };
	const onClickDelete = (id, newRecruiterId, hasDrivers) => {
		deleteRecruiter.prototype.onSuccess = () => {
			closeModal(id);
			setEditingIndex(-1);
			toast.success("Recruiter Deleted Successfully");
		};
		dispatch(
			deleteRecruiter({
				id: id,
				newId: hasDrivers ? newRecruiterId : null,
			})
		);
	};

	const onClickAdd = () => {
		createRecruiter.prototype.onSuccess = () => {
			setDeleteModal(false);
			setEditingIndex(-1);
			toast.success("Recruiter Added Successfully");
		};
		if (formValue?.name.length > 0) {
			dispatch(createRecruiter({ data: formValue }));
			setAddRow(false);
		} else {
			toast("Recruiter name cannot be empty");
		}
	};

	const onClickUpdate = (id) => {
		updateRecruiter.prototype.onSuccess = () => {
			setDeleteModal(false);
			setEditingIndex(-1);
			toast.success("Recruiter Updated Successfully", {
				autoClose: 500,
			});
		};
		if (formValue?.name.length > 0) {
			dispatch(updateRecruiter({ id: id, data: formValue }));
			setEditingIndex(-1);
		} else {
			toast.error("Recruiter name cannot be empty");
		}
	};

	function onChangeFormValue(name, value) {
		if (!name || !value) {
			setFormValue((prev) => ({ ...prev, [name]: value }));
		}
		setFormValue((prev) => ({ ...prev, [name]: value }));
		return name;
	}

	const sorted = [...recruiters].sort((a, b) => {
		if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return -1;
		}
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return 1;
		}
		return 0;
	});

	const rows = sorted?.map((recruiter, id) => (
		<tr key={id}>
			<td
				style={{
					fontSize: "12px",
					padding: recruiter._id === editingIndex ? "0" : "5px",
				}}
			>
				{recruiter._id === editingIndex ? (
					<TextInput
						value={formValue?.name}
						w={130}
						size="xs"
						radius={10}
						placeholder="Edit Name"
						style={{ background: "#fff", borderColor: "#000" }}
						onChange={(e) => {
							onChangeFormValue("name", e.target.value);
						}}
					/>
				) : (
					recruiter?.name
				)}
			</td>
			<td
				style={{
					fontSize: "12px",
					padding: recruiter._id === editingIndex ? " 0" : "10px",
				}}
			></td>
			{recruiter._id === editingIndex ? (
				<td style={{ fontSize: "12px", textAlign: "right" }}>
					<button
						className="holiday_btns_delete"
						onClick={() => openModal(recruiter?._id)}
						style={{ marginRight: "10px" }}
					>
						<svg
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
						>
							<circle
								cx="6"
								cy="6"
								r="5.5"
								fill="white"
								stroke="#F97373"
							/>
							<path
								d="M4.12463 8.37732L5.94124 6.56071L7.7751 8.40163C7.94733 8.57453 8.13868 8.66308 8.3087 8.49306L8.47705 8.32471C8.64707 8.15469 8.55905 7.9628 8.38682 7.7899L6.55296 5.94899L8.36957 4.13238C8.53959 3.96236 8.62383 3.77064 8.4516 3.59774L8.27896 3.42444C8.10673 3.25154 7.91521 3.33558 7.74519 3.5056L5.92859 5.32221L4.09473 3.48129C3.92249 3.30839 3.73114 3.21984 3.56113 3.38986L3.39278 3.55821C3.22276 3.72823 3.31077 3.92011 3.48301 4.09301L5.31686 5.93393L3.50026 7.75054C3.33024 7.92056 3.24599 8.11228 3.41823 8.28518L3.59086 8.45848C3.7631 8.63138 3.95461 8.54733 4.12463 8.37732Z"
								fill="#F97373"
							/>
						</svg>
						Delete
					</button>
					<Modal
						opened={statusModals[recruiter?._id]}
						onClose={() => closeModal(recruiter?._id)}
						centered
						withCloseButton={false}
						size="sm"
						padding={0}
						radius={10}
						overlayProps={{
							blur: 5,
							color: "#fff",
							backgroundopacity: 1,
						}}
					>
						<RecruiterDeleteModal
							close={() => closeModal(recruiter?._id)}
							onClickDelete={onClickDelete}
							id={recruiter._id}
						/>
					</Modal>
					<button
						className="save_button2"
						onClick={() => onClickUpdate(recruiter._id)}
					>
						Save
					</button>
				</td>
			) : (
				<td style={{ fontSize: "12px", textAlign: "right" }}>
					<button
						onClick={() => {
							setAddRow(false);
							setEditingIndex(recruiter._id);
						}}
						className="holiday_btns_edit"
					>
						<svg
							width="13"
							height="12"
							viewBox="0 0 13 12"
							fill="none"
						>
							<g clipPath="url(#clip0_485_4714)">
								<path
									d="M12.4931 4.09942L4.69474 11.6218L2.01016 11.9083C1.23211 11.9913 0.569875 11.3583 0.655996 10.602L0.952962 8.01244L8.7513 0.490039C9.43135 -0.165951 10.5301 -0.165951 11.2072 0.490039L12.4901 1.72754C13.1702 2.38353 13.1702 3.44629 12.4931 4.09942ZM9.55905 4.98457L7.83367 3.32025L2.31604 8.64551L2.09925 10.5161L4.03844 10.307L9.55905 4.98457ZM11.4834 2.7015L10.2005 1.464C10.0787 1.34655 9.87977 1.34655 9.76098 1.464L8.84336 2.34915L10.5687 4.01348L11.4864 3.12832C11.6051 3.00801 11.6051 2.81895 11.4834 2.7015Z"
									fill="#06B9F0"
								/>
							</g>
							<defs>
								<clipPath id="clip0_485_4714">
									<rect width="13" height="12" fill="white" />
								</clipPath>
							</defs>
						</svg>
						Edit
					</button>
				</td>
			)}
		</tr>
	));

	return (
		<div
			style={{ display: "flex", flexDirection: "column", height: "53vh" }}
		>
			<div className="recruiterTable">
				<Table verticalSpacing={"sm"}>
					<tbody>{rows}</tbody>
				</Table>
			</div>

			{addRow && (
				<div
					style={{
						// position: "absolute",
						// bottom: 0,
						borderBottomLeftRadius: "15px",
						borderBottomRightRadius: "15px",
						background: "white",
						padding: "10px",
						width: "100%",
						flexShrink: 0,
					}}
				>
					<TextInput
						ml={10}
						size="xs"
						radius={15}
						placeholder="Add Recruiter"
						style={{ background: "#fff", borderColor: "#000" }}
						onChange={(e) => {
							onChangeFormValue("name", e.target.value);
						}}
					></TextInput>
					<Divider color="#E1EBED" mt={10} />
					<div className="recruiter_buttons">
						<button
							className="recruiter_buttons_cancel"
							onClick={() => setAddRow(false)}
						>
							Cancel
						</button>
						<button
							onClick={onClickAdd}
							className="recruiter_buttons_save"
						>
							Save
						</button>
					</div>
				</div>
			)}
			{!addRow && (
				<div
					style={{
						paddingBottom: 20,
						width: "100%",
						textAlign: "center",
					}}
				>
					<button
						onClick={() => {
							setEditingIndex(-1);
							setAddRow(true);
						}}
						className="black_button recruiter_add_btn"
					>
						<svg
							width="12"
							height="11"
							viewBox="0 0 12 11"
							fill="none"
						>
							<path
								d="M6.28119 5.69224L5.78119 5.69224L5.78119 6.19224L5.78119 10.3033C5.78119 10.3747 5.77541 10.4305 5.76676 10.4717C5.76443 10.4828 5.76202 10.4922 5.75971 10.5001L5.38697 10.5001C5.38466 10.4922 5.38225 10.4828 5.37992 10.4717C5.37127 10.4305 5.36549 10.3747 5.36549 10.3033L5.36549 6.19225L5.36549 5.69225L4.86549 5.69226L0.707432 5.69231C0.633224 5.69231 0.574529 5.68662 0.530522 5.6778C0.518576 5.6754 0.508535 5.67294 0.500197 5.67059L0.500197 5.32968C0.508535 5.32734 0.518576 5.32487 0.530522 5.32248C0.574529 5.31365 0.633224 5.30796 0.707433 5.30796L4.86549 5.30791L5.36549 5.3079L5.36549 4.8079L5.36549 0.69686C5.36549 0.625491 5.37127 0.569652 5.37993 0.528411C5.38225 0.517326 5.38466 0.507929 5.38697 0.500073L5.75971 0.500068C5.76202 0.507924 5.76443 0.517321 5.76676 0.528407C5.77541 0.569647 5.78119 0.625486 5.78119 0.696854L5.78119 4.8079L5.78119 5.3079L6.28119 5.30789L10.4392 5.30784C10.5135 5.30784 10.5722 5.31352 10.6162 5.32235C10.6281 5.32475 10.6381 5.32721 10.6465 5.32956L10.6465 5.67046C10.6381 5.67281 10.6281 5.67527 10.6162 5.67767C10.5722 5.6865 10.5135 5.69218 10.4392 5.69218L6.28119 5.69224Z"
								fill="white"
								stroke="white"
							/>
						</svg>
						Add
					</button>
				</div>
			)}
		</div>
	);
};

export default RecruitersTable;
