import axios from "axios";
import config from "../config";
import store from "../redux/store";

// Create an instance of Axios
const api = axios.create({
	// Set your custom configuration options
	baseURL: config.backend.host,
});

// Add a request interceptor
api.interceptors.request.use(
	function (config) {
		// Modify the request config
		// Add headers, authentication tokens, etc.
		const token = store.getState().user?.token;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		// console.warn("axios request", config.method, config.url, config.data);

		return config;
	},
	function (error) {
		// Handle request errors
		// console.warn("axios req error", error?.message);
		return Promise.reject(error);
	}
);

// Add a response interceptor
api.interceptors.response.use(
	function (response) {
		// Handle successful responses
		// Modify the response data, perform additional operations, etc.
		// console.warn("axios response", response.headers.get(config.tokenHeader));
		return response;
	},
	function (error) {
		// Handle response errors
		// Handle specific HTTP error codes, authentication errors, etc.
		// console.warn("axios res error", error?.message);
		return Promise.reject(error);
	}
);

export default api;
