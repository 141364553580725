import { useEffect, useState } from "react";
import "./PhotoUpload.css";
import image from "../../assets/images/user-placeholder.webp";
import { Divider, Modal } from "@mantine/core";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
const PhotoUpload = ({ onImageUpload, picture }) => {
	const [selectedUncroppedImage, setUncroppedSelectedImage] =
		useState(picture);
	const [selectedImage, setSelectedImage] = useState(picture);
	const [showCropModal, setCropModal] = useState(false);
	const [imageDimentions, setDimensions] = useState({ width: 0, height: 0 });
	const defaultCrop = {
		unit: "px",
		x: 0,
		y: 0,
		width: 100,
		height: 100,
	};
	const [crop, setCrop] = useState(defaultCrop);
	const [isActive, setIsActive] = useState(false);
	useEffect(() => {
		if (picture) {
			setUncroppedSelectedImage(picture);
			setSelectedImage(picture);
		}
	}, [picture]);

	const handleImageLoaded = (event) => {
		const { width, height } = event.target;
		setDimensions({ width, height });

		const squareDimension = Math.min(width, height);
		const centerX = (width - squareDimension) / 2;
		const centerY = (height - squareDimension) / 2;
		setCrop({
			...defaultCrop,
			...{
				width: squareDimension,
				height: squareDimension,
				x: centerX,
				y: centerY,
			},
		});
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const fileType = file.type;
			const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

			if (validImageTypes.includes(fileType)) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					setUncroppedSelectedImage(reader.result);
				};
				setCropModal(true);
			} else {
				alert(
					"Invalid file type. Please upload an image in JPG, JPEG, or PNG format."
				);
			}
		}
		e.target.value = "";
	};
	const cropImage = (e) => {
		if (crop.width && crop.height) {
			const canvas = document.createElement("canvas");
			const img = new Image();
			img.src = selectedUncroppedImage;

			img.onload = () => {
				const scaleX = img.naturalWidth / imageDimentions.width;
				const scaleY = img.naturalHeight / imageDimentions.height;

				canvas.width = crop.width * scaleX;
				canvas.height = crop.height * scaleY;
				const ctx = canvas.getContext("2d");

				ctx.drawImage(
					img,
					crop.x * scaleX,
					crop.y * scaleY,
					crop.width * scaleX,
					crop.height * scaleY,
					0,
					0,
					crop.width * scaleX,
					crop.height * scaleY
				);

				const base64Image = canvas.toDataURL("image/jpeg");
				setSelectedImage(base64Image);
				canvas.toBlob((blob) => {
					const file = new File([blob], "canvas_image.png", {
						type: "image/png",
					});
					onImageUpload(file);
					setCropModal(false);
				});
			};
		}
	};
	return (
		<div
			className={
				isActive ? "photo_upload_box active" : "photo_upload_box"
			}
		>
			<input
				type="file"
				id="upload"
				hidden
				onChange={handleImageChange}
			/>
			<label htmlFor="upload" className="image_upload_label">
				<svg width="13" height="13" viewBox="0 0 13 13" fill="none">
					<g clipPath="url(#clip0_304_2387)">
						<path
							d="M12.4931 4.14499L4.69474 11.751L2.01016 12.0406C1.23211 12.1246 0.569875 11.4845 0.655996 10.7198L0.952962 8.10149L8.7513 0.495506C9.43135 -0.167773 10.5301 -0.167773 11.2072 0.495506L12.4901 1.74676C13.1702 2.41003 13.1702 3.4846 12.4931 4.14499ZM9.55905 5.03998L7.83367 3.35716L2.31604 8.74159L2.09925 10.633L4.03844 10.4215L9.55905 5.03998ZM11.4834 2.73154L10.2005 1.48029C10.0787 1.36153 9.87977 1.36153 9.76098 1.48029L8.84336 2.37528L10.5687 4.05809L11.4864 3.1631C11.6051 3.04145 11.6051 2.85029 11.4834 2.73154Z"
							fill="white"
						/>
					</g>
					<defs>
						<clipPath id="clip0_304_2387">
							<rect width="13" height="12.1333" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</label>
			<img
				src={selectedImage ? selectedImage : image}
				alt="Uploaded"
				style={{ width: "100%", height: "100%", borderRadius: "100%" }}
			/>
			<Modal
				opened={showCropModal}
				onClose={() => setCropModal(false)}
				centered
				withCloseButton={false}
				size="lg"
				padding={0}
				radius={10}
				overlayProps={{
					blur: 5,
					color: "#fff",
					backgroundopacity: 1,
				}}
			>
				<div>
					<div className="present_modal_header">
						<div>Crop Profile Image</div>
						<svg
							onClick={() => setCropModal(false)}
							width="30"
							viewBox="0 0 36 36"
							fill="none"
						>
							<path
								d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
								fill="white"
							/>
						</svg>
					</div>
					<div
						className="AttendanceHistoryModal"
						style={{ display: "grid" }}
					>
						{selectedUncroppedImage && (
							<div
								style={{
									display: "flex",
								}}
							>
								<ReactCrop
									crop={crop}
									onChange={(c) => setCrop(c)}
									aspect={1}
									maxHeight={300}
									height={imageDimentions.height}
									width={imageDimentions.width}
									circularCrop={false}
								>
									<img
										src={selectedUncroppedImage}
										style={{
											objectFit: "contain",
											maxHeight: "500px",
										}}
										onLoad={handleImageLoaded}
									/>
								</ReactCrop>
							</div>
						)}
						<Divider color="#264147" mt={20} />
						<button
							className="yes_button"
							style={{ margin: "30px auto auto" }}
							onClick={cropImage}
						>
							Save
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default PhotoUpload;
