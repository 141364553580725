import { call, put, select, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
  createPayoutCycle,
  createPayoutCycleFulfilled,
  createPayoutCycleRejected,
  deletePayoutCycle,
  deletePayoutCycleFulfilled,
  deletePayoutCycleRejected,
  getPayoutCycles,
  getPayoutCyclesFulfilled,
  getPayoutCyclesRejected,
  updatePayoutCycle,
  updatePayoutCycleFulfilled,
  updatePayoutCycleRejected,
} from "../reducers/payoutCycleReducer";
import { getErrorMessage } from "../../utils/functions";

function* createPayoutCycleSaga(action) {
  try {
    const payoutCycle = yield select((state) => state.PayoutCycle);
    const formData = action.payload?.data || payoutCycle?.formData;
    const res = yield call(api.post, "/payout-cycle", formData);
    // console.log("createPayoutCycleSaga", res?.data);
    if (createPayoutCycle.prototype.onSuccess) {
      createPayoutCycle.prototype.onSuccess();
    }
    yield put(createPayoutCycleFulfilled(res?.data));
  } catch (err) {
    if (createPayoutCycle.prototype.onReject) {
      createPayoutCycle.prototype.onReject();
    }
    yield put(createPayoutCycleRejected(getErrorMessage(err)));
  }
}

function* getPayoutCyclesSaga() {
  try {
    const res = yield call(api.get, "/payout-cycle");
    // console.log("getPayoutCyclesSaga", res?.data);
    if (getPayoutCycles.prototype.onSuccess) {
      getPayoutCycles.prototype.onSuccess();
    }
    yield put(getPayoutCyclesFulfilled(res?.data));
  } catch (err) {
    if (getPayoutCycles.prototype.onReject) {
      getPayoutCycles.prototype.onReject();
    }
    yield put(getPayoutCyclesRejected(getErrorMessage(err)));
  }
}

function* updatePayoutCycleSaga(action) {
  try {
    const payoutCycle = yield select((state) => state.PayoutCycle);
    const id = action.payload?.id || payoutCycle?.id;
    const data = action.payload?.data || payoutCycle?.formData;
    const res = yield call(api.patch, `/payout-cycle/${id}`, data);
    // console.log("updatePayoutCycleSaga", res?.data);
    if (updatePayoutCycle.prototype.onSuccess) {
      updatePayoutCycle.prototype.onSuccess();
    }
    yield put(updatePayoutCycleFulfilled(res?.data));
  } catch (err) {
    if (updatePayoutCycle.prototype.onReject) {
      updatePayoutCycle.prototype.onReject();
    }
    yield put(updatePayoutCycleRejected(getErrorMessage(err)));
  }
}

function* deletePayoutCycleSaga(action) {
  try {
    const id = action.payload?.id;
    const res = yield call(api.delete, `/payout-cycle/${id}`);
    // console.log("updatePayoutCycleSaga", res?.data);
    if (deletePayoutCycle.prototype.onSuccess) {
      deletePayoutCycle.prototype.onSuccess();
    }
    yield put(deletePayoutCycleFulfilled(res?.data));
  } catch (err) {
    if (deletePayoutCycle.prototype.onReject) {
      deletePayoutCycle.prototype.onReject();
    }
    yield put(deletePayoutCycleRejected(getErrorMessage(err)));
  }
}

export function* payoutCycleSaga() {
  yield takeLatest(createPayoutCycle.type, createPayoutCycleSaga);
  yield takeLatest(getPayoutCycles.type, getPayoutCyclesSaga);
  yield takeLatest(updatePayoutCycle.type, updatePayoutCycleSaga);
  yield takeLatest(deletePayoutCycle.type, deletePayoutCycleSaga);
}
