import { Divider, Flex, Select } from "@mantine/core";
import "./PayoutModal.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { onChangeFormValue } from "../../utils/functions";
import { updatePayoutCycle } from "../../redux/reducers/payoutCycleReducer";
import moment from "moment";
const days = [
	{ value: "1", label: "1" },
	{ value: "2", label: "2" },
	{ value: "3", label: "3" },
	{ value: "4", label: "4" },
	{ value: "5", label: "5" },
	{ value: "6", label: "6" },
	{ value: "7", label: "7" },
	{ value: "8", label: "8" },
	{ value: "9", label: "9" },
	{ value: "10", label: "10" },
	{ value: "11", label: "11" },
	{ value: "12", label: "12" },
	{ value: "13", label: "13" },
	{ value: "14", label: "14" },
	{ value: "15", label: "15" },
];
const initialState = {
	formData: {
		disbursementDate: undefined,
		endDate: undefined,
		startDate: undefined,
	},
};

const PayoutModal = ({ setPayoutModal }) => {
	const dispatch = useDispatch();
	const payoutCycleData = useSelector((state) => state.payoutCycle?.data);
	const payoutCycle = payoutCycleData[0];
	const [formValue, setFormValue] = useState(initialState.formData);
	useEffect(() => {
		setFormValue(payoutCycle);
	}, []);

	const onClickSave = () => {
		updatePayoutCycle.prototype.onSuccess = () => {
			setPayoutModal(false);
		};
		dispatch(
			updatePayoutCycle({
				id: payoutCycle._id,
				data: formValue,
			})
		);
	};
	return (
		<div className="PayoutModal">
			<div className="present_modal_header">
				<div>Payout Cycle</div>
				<svg
					onClick={() => setPayoutModal(false)}
					width="30"
					viewBox="0 0 36 36"
					fill="none"
				>
					<path
						d="M9.04127 11.3428L15.724 18.0255L8.96481 24.7846C8.32999 25.4195 8.00524 26.1244 8.63068 26.7498L9.24997 27.3691C9.87541 27.9946 10.5803 27.6698 11.2151 27.035L17.9743 20.2758L24.657 26.9585C25.2825 27.584 25.9874 27.8935 26.6222 27.2587L27.2585 26.6224C27.8933 25.9876 27.5838 25.2827 26.9583 24.6572L20.2756 17.9745L27.0348 11.2153C27.6696 10.5805 27.9943 9.87562 27.3689 9.25018L26.7496 8.63089C26.1242 8.00545 25.4193 8.3302 24.7844 8.96502L18.0253 15.7242L11.3426 9.04148C10.7171 8.41604 10.0122 8.1065 9.3774 8.74132L8.74111 9.37761C8.10629 10.0124 8.41583 10.7173 9.04127 11.3428Z"
						fill="white"
					/>
				</svg>
			</div>
			<div className="payout_modal_body">
				<table
					style={{
						borderCollapse: "collapse",
						border: 0,
						width: "100%",
					}}
				>
					<tbody>
						<tr>
							<td style={{ border: "none" }}>
								<h2>From:</h2>
							</td>
							<td style={{ border: "none", textAlign: "end" }}>
								<h2>
									{moment()
										.startOf("month")
										.format("DD-MM-YYYY")}
								</h2>
							</td>
						</tr>
						<tr>
							<td style={{ border: "none" }}>
								<h2>To:</h2>
							</td>
							<td style={{ border: "none", textAlign: "end" }}>
								<h2>
									{moment()
										.endOf("month")
										.format("DD-MM-YYYY")}
								</h2>
							</td>
						</tr>
					</tbody>
				</table>

				<Divider color="#264147" mt={20} />
			</div>

			<h1>Salary Disbursement</h1>
			<Flex align={"center"} className="payout_modal_body">
				<h2>On:</h2>
				<Select
					maxDropdownHeight={160}
					value={formValue?.disbursementDate?.toString()}
					onChange={(value) => {
						onChangeFormValue(
							"disbursementDate",
							Number(value),
							setFormValue
						);
					}}
					w={90}
					size="xs"
					styles={{
						input: {
							color: "#fff",
							borderColor: "#8399A3",
							borderRadius: "10px",
							fontSize: "12px",
							background: "#0E2227",
						},
					}}
					rightSection={
						<svg
							width="8"
							height="8"
							viewBox="0 0 13 8"
							fill="none"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
								fill={"#fff"}
							/>
						</svg>
					}
					placeholder="Month"
					data={days}
				/>
				<h3>every month</h3>
			</Flex>
			<div className="save_button_modal">
				<button onClick={onClickSave}>Save</button>
			</div>
		</div>
	);
};

export default PayoutModal;
