import { Table } from "@mantine/core";
import "./SalaryManagementTable.css";
import { useEffect, useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import ZeroScreens from "../../../../components/ZeroScreens/ZeroScreens";
import moment from "moment/moment";
import { rupeeSymbol } from "../../../../utils/constants";

const PaidTag = () => (
	<button className="paid_tag">
		Paid
		<svg width="12" height="12" viewBox="0 0 15 15" fill="none">
			<circle cx="7.5" cy="7.5" r="7.5" fill="#03AB70" />
			<path
				d="M6.38286 10.1327L4.02798 7.78231C3.88651 7.64111 3.88651 7.41216 4.02798 7.27094L4.54032 6.75957C4.6818 6.61835 4.9112 6.61835 5.05268 6.75957L6.63903 8.34287L10.0368 4.95161C10.1783 4.8104 10.4077 4.8104 10.5492 4.95161L11.0615 5.46298C11.203 5.60418 11.203 5.83313 11.0615 5.97435L6.89521 10.1327C6.75372 10.2739 6.52433 10.2739 6.38286 10.1327Z"
				fill="white"
			/>
		</svg>
	</button>
);

const UnPaidTag = () => (
	<button className="due_today_tag">
		Unpaid
		<svg width="12" height="12" viewBox="0 0 15 15" fill="none">
			<circle cx="7.5" cy="7.5" r="7.5" fill="#FEA826" />
			<path
				d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
				fill="white"
			/>
		</svg>
	</button>
);

const SalaryManagementTable = ({ table, attendanceWithId, setCsvData }) => {
	const [loading, setLoading] = useState(false);

	function getMonthAndDays(dateString) {
		const date = new Date(dateString);
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is 0-indexed, so add 1
		const lastDayOfMonth = new Date(year, month, 0).getDate();

		return {
			month: month,
			daysInMonth: lastDayOfMonth,
		};
	}

	useEffect(() => {
		const result = table?.map((item) => getMonthAndDays(item.createdAt));
	}, [table]);

	const sorted = [...(table || [])].sort((a, b) => {
		if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return -1;
		}
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return 1;
		}
		return 0;
	});

	const rows = sorted?.map((element, id) => {
		const totalDays =
			(attendanceWithId || {})[element?._id]?.filter(
				(a) => a?.status?.toLowerCase() !== "absent"
			)?.length || 0;
		return (
			<tr key={id}>
				<td>
					<a
						href={`/dashboard/driverdetails/${element?._id}`}
						className="nostylelink"
					>
						<img
							src={element?.picture}
							alt="driver_image"
							className="driver_image"
							style={{ marginRight: "10px" }}
						/>
						{element?.name}
					</a>
				</td>
				<td>
					{rupeeSymbol}
					{element?.salary}/-
				</td>
				<td>
					{rupeeSymbol}
					{totalDays * element?.salary}
					/-
				</td>
				<td>
					{(attendanceWithId || {})[element?._id]?.filter(
						(a) => a?.status?.toLowerCase() !== "absent"
					)[0]?.salaryStatus == "paid" ? (
						<PaidTag />
					) : (attendanceWithId || {})[element?._id]?.filter(
							(a) => a?.status?.toLowerCase() !== "absent"
					  )[0]?.salaryStatus == "unpaid" ? (
						<UnPaidTag />
					) : (
						"NA"
					)}
				</td>
			</tr>
		);
	});

	const csvData = sorted?.map((element, id) => {
		const totalDays =
			(attendanceWithId || {})[element?._id]?.filter(
				(a) => a?.status?.toLowerCase() !== "absent"
			)?.length || 0;
		return {
			name: element?.name,
			dailyWage: `${element?.salary}/-`,
			salary: `${totalDays * element?.salary}/-`,
			status:
				(attendanceWithId || {})[element?._id]?.filter(
					(a) => a?.status?.toLowerCase() !== "absent"
				)[0]?.salaryStatus == "paid"
					? "Paid"
					: (attendanceWithId || {})[element?._id]?.filter(
							(a) => a?.status?.toLowerCase() !== "absent"
					  )[0]?.salaryStatus == "unpaid"
					? "Unpaid"
					: "NA",
		};
	});

	useEffect(() => {
		setCsvData(csvData);
	}, [table]);

	return (
		<div className="table_box">
			<Table horizontalSpacing={"xl"}>
				<thead className="table_thead">
					<tr>
						<th
							style={{
								paddingLeft: "79px",
							}}
						>
							Name
						</th>
						<th>Daily Wage</th>
						<th>Salary</th>
						<th>Status</th>
					</tr>
				</thead>
				{loading ? (
					<Loading />
				) : table?.length ? (
					<tbody>{rows}</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan="4">
								<ZeroScreens />
							</td>
						</tr>
					</tbody>
				)}
			</Table>
		</div>
	);
};

export default SalaryManagementTable;
