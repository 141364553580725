import { call, put, takeLatest } from "redux-saga/effects";
import {
	getAllAttendances,
	getAllAttendancesFulfilled,
	getAllAttendancesRejected,
	getAttendanceByDriverId,
	getAttendanceByDriverIdFulfilled,
	getAttendanceByDriverIdRejected,
	updateAttendanceByDriverId,
	updateAttendanceByDriverIdFulfilled,
	updateAttendanceByDriverIdRejected,
} from "../reducers/attendanceReducer";
import api from "../../api";
import { getErrorMessage } from "../../utils/functions";

// <------------------------READ----------------------------------->
function* getAllAttendancesSaga(action) {
	try {
		const res = yield call(api.get, `/attendance`);
		if (getAllAttendances.prototype.onSuccess) {
			getAllAttendances.prototype.onSuccess();
		}
		yield put(getAllAttendancesFulfilled(res?.data));
	} catch (err) {
		if (getAllAttendances.prototype.onReject) {
			getAllAttendances.prototype.onReject();
		}
		yield put(getAllAttendancesRejected(getErrorMessage(err)));
	}
}

function* getAttendanceByDriverIdSaga(action) {
	try {
		const id = action.payload?.id;
		const res = yield call(api.get, `/attendance/driver/${id}`);
		if (getAttendanceByDriverId.prototype.onSuccess) {
			getAttendanceByDriverId.prototype.onSuccess();
		}
		yield put(getAttendanceByDriverIdFulfilled(res?.data));
	} catch (err) {
		if (getAttendanceByDriverId.prototype.onReject) {
			getAttendanceByDriverId.prototype.onReject();
		}
		yield put(getAttendanceByDriverIdRejected(getErrorMessage(err)));
	}
}

// <------------------------UPDATE----------------------------------->
function* updateAttendanceByDriverIdSaga(action) {
	try {
		const id = action.payload?.id;
		const data = action.payload?.data;

		let res;
		if (id) {
			res = yield call(api.patch, `/attendance/${id}`, data);
		} else {
			res = yield call(api.post, `/attendance`, data);
		}
		console.log("updateAttendanceByDriverIdSaga", res?.data);
		if (updateAttendanceByDriverId.prototype.onSuccess) {
			updateAttendanceByDriverId.prototype.onSuccess();
		}
		yield put(updateAttendanceByDriverIdFulfilled(res?.data));
	} catch (err) {
		if (updateAttendanceByDriverId.prototype.onReject) {
			updateAttendanceByDriverId.prototype.onReject();
		}
		yield put(updateAttendanceByDriverIdRejected(getErrorMessage(err)));
	}
}

// <<<<<<<<<<<<<<<< MAIN SAGA >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export function* attendanceSaga() {
	// read
	yield takeLatest(getAllAttendances.type, getAllAttendancesSaga);
	yield takeLatest(getAttendanceByDriverId.type, getAttendanceByDriverIdSaga);

	// update
	yield takeLatest(
		updateAttendanceByDriverId.type,
		updateAttendanceByDriverIdSaga
	);
}
