import ReactDOM from "react-dom/client";
import { MantineProvider } from "@mantine/core";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import "react-toastify/dist/ReactToastify.css";
ReactDOM.createRoot(document.getElementById("root")).render(
	<BrowserRouter>
		<MantineProvider
			theme={{
				fontFamily: "Inter, sans-serif",
			}}
			withGlobalStyles
			withNormalizeCSS
		>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
					<ToastContainer
						theme="dark"
						autoClose={2000}
						pauseOnFocusLoss={false}
					/>
				</PersistGate>
			</Provider>
		</MantineProvider>
	</BrowserRouter>
);
