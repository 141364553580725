import { Table } from "@mantine/core";
import "./HubTable.css";
import ZeroScreens from "../../../../components/ZeroScreens/ZeroScreens";
import Loading from "../../../../components/Loading/Loading";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HubTable = ({ table }) => {
	const loadingHub = useSelector((state) => state.hub?.loading);
	const navigate = useNavigate();
	const sorted = [...(table || [])].sort((a, b) => {
		if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return -1;
		}
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return 1;
		}
		return 0;
	});
	const rows = sorted?.map((element, id) => (
		<tr key={id}>
			<td>
				<img
					src={element?.picture}
					alt="driver_image"
					className="driver_image"
					onClick={() => {
						navigate(`/dashboard/driverdetails/${element._id}`);
					}}
				/>
				{element?.name}
			</td>
			<td>
				{element?.active == true ? "Active" : "Inactive"} <br />{" "}
				{element?.shift?.startTime}: {element?.shift?.endTime}
			</td>
			<td>
				{element?.supervisor?.name} <br /> {element?.supervisor?.phone}
			</td>
			<td
				style={{
					maxWidth: "150px",
				}}
			>
				{element?.clients?.map((client) => client?.name)?.join(", ")}
			</td>
		</tr>
	));
	return (
		<div className="hub_table">
			<Table>
				<thead className="table_thead ">
					<tr>
						<th
							style={{
								paddingLeft: "65px",
							}}
						>
							Name
						</th>
						<th>Shift</th>
						<th>Supervisor</th>
						<th>Clients</th>
					</tr>
				</thead>
				{loadingHub ? (
					<tbody>
						<tr>
							<td colSpan="4">
								<Loading />
							</td>
						</tr>
					</tbody>
				) : table?.length ? (
					<tbody>{rows}</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan="4">
								<ZeroScreens />
							</td>
						</tr>
					</tbody>
				)}
			</Table>
		</div>
	);
};

export default HubTable;
