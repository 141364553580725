import React, { useEffect, useState } from "react";
import "./DeleteDriver.css";
import logo from "../../../assets/images/logo.svg";
import trash from "../../../assets/images/Trash.svg";
import api from "../../../api";
import { toast } from "react-toastify";

const DeleteDriver = () => {
	const [lang, setLang] = useState("en");
	const [translations, setTranslations] = useState({});

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const language = params.get("lang");
		setLang(language || "en");
		api.get("/translation/all", {
			headers: {
				lang: lang,
			},
		})
			.then((response) => {
				const data = response.data;
				setTranslations(
					data.reduce((acc, item) => {
						acc[item.lang_code] = item.lang_value;
						return acc;
					}, {})
				);
			})
			.catch((error) => {});
	}, []);

	const [phoneNumber, setPhoneNumber] = useState(null);
	const [otpId, setOtpId] = useState(null);
	const [otp, setOtp] = useState(null);
	const [isPhoneValid, setPhoneValid] = useState(false);
	const [isOtpValid, setOtpValid] = useState(false);
	const [isOtpSent, setOtpSent] = useState(false);
	const [isOtpResentAvailable, setOtpResentAvailble] = useState(true);
	const [otpButtonText, setOtpButtonText] = useState(null);
	const [phoneNumberError, setPhoneNumberError] = useState(null);
	const [otpError, setOtpError] = useState(null);
	const DEFAULT_TIMER_TIME = 60;
	const [counter, setCounter] = useState(DEFAULT_TIMER_TIME);

	const getTranslatedText = (key) => {
		return ((translations || {})[key] || {})[lang] || "";
	};

	useEffect(() => {
		setOtpButtonText(getTranslatedText("getOTP"));
	}, [translations, lang]);

	const handleOtpButton = () => {
		if (isOtpResentAvailable) {
			// send otp
			setPhoneNumberError(null);
			setOtpError(null);
			api.post(
				"/auth/sendOtp/dltUser",
				{ phone: phoneNumber },
				{
					headers: {
						lang: lang,
					},
				}
			)
				.then((response) => {
					setOtpId(response?.data?.otpId);
					setOtpSent(true);
					setOtpResentAvailble(false);
					setCounter(DEFAULT_TIMER_TIME);
					setOtpButtonText(
						getTranslatedText("registrationOTPResendIn")
							.toString()
							.replace("___", DEFAULT_TIMER_TIME)
					);
					toast.success(getTranslatedText("otpSentSuccessfully"));
				})
				.catch((error) => {
					setPhoneNumberError(error?.response?.data?.error);
				});
		}
	};
	const closeWebView = () => {
		if (CloseWebView && CloseWebView.postMessage) {
			CloseWebView.postMessage("completed");
			return true;
		}
		return false;
	};
	const handleSubmitButton = () => {
		if (isOtpValid) {
			// verify otp
			setPhoneNumberError(null);
			setOtpError(null);

			api.patch(
				"/auth/deleteRequest",
				{ id: otpId, otp: otp },
				{
					headers: {
						lang: lang,
					},
				}
			)
				.then((response) => {
					if (!closeWebView())
						toast.success(getTranslatedText("accountDltReqSent"));
				})
				.catch((error) => {
					setOtpError(error?.response?.data?.error);
				});
		}
	};
	useEffect(() => {
		let timer;
		if (!isOtpResentAvailable && counter > 0) {
			timer = setTimeout(() => {
				setCounter(counter - 1);
				setOtpButtonText(
					getTranslatedText("registrationOTPResendIn")
						.toString()
						.replace("___", counter - 1)
				);
			}, 1000);
		} else if (counter === 0) {
			setOtpResentAvailble(true);
			setOtpButtonText(getTranslatedText("getOTP"));
		}

		return () => {
			clearTimeout(timer);
		};
	}, [counter, isOtpResentAvailable]);
	return Object.values(translations).length <= 0 ? (
		<></>
	) : (
		<div
			style={{
				textAlign: "center",
				display: "flex",
				flexDirection: "column",
				height: "100vh",
			}}
			className="delete_driver"
		>
			<div style={{ marginTop: "27px" }}>
				<img style={{ width: 71.12, height: 31 }} src={logo} />
			</div>
			<div style={{ flexGrow: 1, display: "grid" }}>
				<div
					style={{
						margin: "50px auto",
						width: "100%",
						padding: "0 40px",
					}}
				>
					<img src={trash} alt={getTranslatedText("dltAccount")} />
					<p
						style={{
							color: "#0E2227",
							fontSize: 22,
							fontFamily: "Roboto",
							fontWeight: "600",
							wordWrap: "break-word",
							textAlign: "left",
							margin: "30px 0",
						}}
					>
						{getTranslatedText("wantToDltAccount")}
					</p>
					<p
						style={{
							color: "#0E2227",
							fontSize: "14px",
							fontFamily: "Roboto",
							fontWeight: "400",
							wordWrap: "break-word",
							textAlign: "left",
							margin: "0 0 40px 0",
						}}
					>
						{getTranslatedText("sendDltReq")}
					</p>
					<div>
						<div
							style={{
								width: "100%",
								padding: "18px 24px",
								background: "white",
								borderRadius: 15,
								border: "1px #0E2227 solid",
								flexDirection: "row",
								justifyContent: "center",
								display: "flex",
							}}
						>
							<div
								style={{
									color: "#8399A3",
									fontSize: 14,
									fontFamily: "Inter",
									fontWeight: "400",
									wordWrap: "break-word",
								}}
							>
								{getTranslatedText(
									"registrationCountryCodePlaceholder"
								)}
							</div>
							<div
								style={{
									borderRight: "1px solid #8399A3",
									width: "1px",
									height: "20px",
									margin: "auto 11px auto 5px",
								}}
							></div>
							<input
								type="text"
								placeholder={getTranslatedText(
									"registrationEnterPhNumber"
								)}
								style={{
									color: "#333",
									border: "none",
									margin: 0,
									fontSize: 14,
									padding: 0,
									outline: 0,
								}}
								onChange={(e) => {
									if (e.target.value?.length == 10) {
										setPhoneValid(true);
										setPhoneNumber(e.target.value);
									} else {
										setPhoneValid(false);
									}
								}}
								maxLength={10}
								disabled={isOtpSent}
							/>
						</div>
						<div
							style={{
								color: "#E96464",
								fontSize: 13,
								fontFamily: "Inter",
								fontWeight: "400",
								wordWrap: "break-word",
								margin: "5px 0 0 5px",
								display: "flex",
							}}
						>
							&nbsp;
							{phoneNumberError && (
								<span
									style={{
										display: "flex",
									}}
								>
									{phoneNumberError}
									<svg
										style={{ margin: "auto auto auto 5px" }}
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#F97373"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
								</span>
							)}
						</div>
					</div>
					<div>
						<div style={{ display: "flex", marginTop: 30 }}>
							<input
								type="text"
								placeholder={getTranslatedText(
									"registrationOTPEnterOTP"
								)}
								style={{
									color: "#333",
									border: "1px solid #8399A3",
									margin: 0,
									padding: "18px 24px",
									outline: 0,
									borderRadius: 15,
									fontSize: 14,
								}}
								maxLength={6}
								disabled={!isOtpSent}
								onChange={(e) => {
									if (e.target.value?.length == 6) {
										setOtpValid(true);
										setOtp(e.target.value);
									} else {
										setOtpValid(false);
									}
								}}
							/>
							<button
								style={{
									flexGrow: 1,
									margin: 0,
									marginLeft: 20,
									borderRadius: 15,
									padding: "16px 25px",
									textAlign: "center",
									fontSize: 14,
									fontFamily: "Inter",
									fontWeight: "500",
									wordWrap: "break-word",
									whiteSpace: "nowrap",
								}}
								onClick={handleOtpButton}
								className={
									isPhoneValid && isOtpResentAvailable
										? "enable"
										: "disable"
								}
							>
								{otpButtonText}
							</button>
						</div>
						<div
							style={{
								color: "#E96464",
								fontSize: 13,
								fontFamily: "Inter",
								fontWeight: "400",
								wordWrap: "break-word",
								margin: "5px 0 0 5px",
								display: "flex",
							}}
						>
							&nbsp;
							{otpError && (
								<span
									style={{
										display: "flex",
									}}
								>
									{otpError}
									<svg
										style={{ margin: "auto auto auto 5px" }}
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle
											cx="7.5"
											cy="7.5"
											r="7.5"
											fill="#F97373"
										/>
										<path
											d="M8.82884 3.27273L8.68821 9.43466H7.31605L7.17969 3.27273H8.82884ZM8.00213 12.0938C7.74361 12.0938 7.52202 12.0028 7.33736 11.821C7.15554 11.6392 7.06463 11.4176 7.06463 11.1562C7.06463 10.9006 7.15554 10.6818 7.33736 10.5C7.52202 10.3182 7.74361 10.2273 8.00213 10.2273C8.25497 10.2273 8.47372 10.3182 8.65838 10.5C8.84588 10.6818 8.93963 10.9006 8.93963 11.1562C8.93963 11.3295 8.8956 11.4872 8.80753 11.6293C8.7223 11.7713 8.60866 11.8849 8.46662 11.9702C8.32741 12.0526 8.17259 12.0938 8.00213 12.0938Z"
											fill="white"
										/>
									</svg>
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
			<div style={{ marginBottom: "40px" }}>
				<button
					style={{
						borderRadius: 15,
						padding: "16px 42px",
						textAlign: "center",
						fontSize: 16,
						fontFamily: "Inter",
						fontWeight: "500",
						wordWrap: "break-word",
					}}
					onClick={handleSubmitButton}
					disabled={!isOtpValid}
					className={
						isPhoneValid && isOtpValid ? "enable" : "disable"
					}
				>
					{getTranslatedText("reqDlt")}
				</button>
			</div>
		</div>
	);
};

export default DeleteDriver;
