import "./ZeroScreens.css";
import image from "../../assets/images/no data.jpg";
const ZeroScreens = ({ dropdown = false, modal = false }) => {
	return (
		<div
			className="Loading"
			style={{
				minHeight: dropdown ? "0" : "45vh",
				fontSize: dropdown ? "15px" : "18px",
				color: modal ? "#577780" : dropdown ? "#303030" : "#333",
			}}
		>
			{modal ? (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="199"
					height="177"
					viewBox="0 0 199 177"
					fill="none"
				>
					<path
						d="M34.8398 64.684L104.471 24.8945L164.076 58.9543C167.122 60.6945 169.655 63.2093 171.416 66.2438C173.176 69.2783 174.104 72.7245 174.103 76.2329V122.767C174.104 126.275 173.176 129.721 171.416 132.756C169.655 135.79 167.122 138.305 164.076 140.045L114.339 168.465C111.334 170.182 107.933 171.085 104.471 171.085C101.01 171.085 97.609 170.182 94.6036 168.465L44.8668 140.045C41.8204 138.305 39.2883 135.79 37.5273 132.756C35.7664 129.721 34.8392 126.275 34.8398 122.767V94.8942"
						stroke="#577780"
						stroke-width="9.94737"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M95.0508 99.4008C97.9491 100.961 101.189 101.778 104.481 101.778C107.772 101.778 111.013 100.961 113.911 99.4008L169.139 69.6582M104.481 104.474V169.132"
						stroke="#577780"
						stroke-width="9.94737"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M34.8421 64.6842L104.474 104.474L74.6316 114.421L5 74.6316L34.8421 64.6842ZM104.474 24.8947L174.105 64.6842L194 44.7895L124.368 5L104.474 24.8947Z"
						stroke="#577780"
						stroke-width="9.94737"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			) : (
				<img
					src={image}
					alt="zeroscreen_image"
					className="zeroscreen_image"
					style={{ height: dropdown ? "100px" : "250px" }}
				/>
			)}
			No Data Available!
		</div>
	);
};

export default ZeroScreens;
