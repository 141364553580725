import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  fetchData: {
    loading: false,
    error: null,
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchData: (state) => {
      //   console.log("fetchData");
      state.fetchData.loading = true;
      state.fetchData.error = null;
    },
    fetchDataFulFilled: (state) => {
      state.fetchData.loading = false;
    },
    fetchDataRejected: (state, action) => {
      state.fetchData.loading = false;
      state.fetchData.error = action.payload;
    },
  },
});

export const {
  fetchData,
  fetchDataFulFilled,
  fetchDataRejected,
} = appSlice.actions;

export default appSlice.reducer;
