import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const holidayReducer = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    createHoliday: (state) => {
      state.loading = true;
      state.error = null;
    },
    createHolidayFulfilled: (state) => {
      state.loading = false;
    },
    createHolidayRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getHolidays: (state) => {
      state.loading = true;
      state.error = null;
    },
    getHolidaysFulfilled: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) state.data = action.payload;
    },
    getHolidaysRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateHoliday: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateHolidayFulfilled: (state) => {
      state.loading = false;
    },
    updateHolidayRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteHoliday: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteHolidayFulfilled: (state) => {
      state.loading = false;
    },
    deleteHolidayRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createHoliday,
  createHolidayFulfilled,
  createHolidayRejected,
  getHolidays,
  getHolidaysFulfilled,
  getHolidaysRejected,
  updateHoliday,
  updateHolidayFulfilled,
  updateHolidayRejected,
  deleteHoliday,
  deleteHolidayFulfilled,
  deleteHolidayRejected,
} = holidayReducer.actions;

export default holidayReducer.reducer;
