import { Select, Table, TextInput } from "@mantine/core";
import "./AssignDriversSupervisor.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Checkbox from "../Checkbox/Checkbox";
import { toast } from "react-toastify";
import { assignSupervisor } from "../../redux/reducers/supervisorReducer";
import ZeroScreens from "../ZeroScreens/ZeroScreens";

const AssignDriversSupervisor = ({
	supervisorId,
	hubs,
	setParentDrivers,
	close,
	driversObj,
}) => {
	const [search, setSearch] = useState("");
	const drivers = useSelector((state) => state?.driver?.drivers?.data);
	const hubList = useSelector((state) => state?.hub?.data);

	const [selectedHub, setSelectedHub] = useState("all");

	const [allHubs, setHubs] = useState([]);
	const [allDrivers, setDrivers] = useState(driversObj || {});

	const [driversList, setDriversList] = useState([]);

	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		setHubs(hubList?.filter((hub) => hubs?.includes(hub._id)));
	}, [hubList]);

	useEffect(() => {
		let filteredDrivers = drivers;
		filteredDrivers = filteredDrivers?.filter((d) =>
			hubs.includes(d?.hub?._id)
		);

		if (selectedHub !== "all") {
			filteredDrivers = filteredDrivers?.filter(
				(d) => d?.hub?._id === selectedHub
			);
		}

		// if (search.length > 0) {
		filteredDrivers = filteredDrivers
			?.filter((d) =>
				d?.name?.toLowerCase().includes(search.toLowerCase())
			)
			.sort((a, b) => {
				if (a.name.toLowerCase() < b.name.toLowerCase()) {
					return -1;
				}
				if (a.name.toLowerCase() > b.name.toLowerCase()) {
					return 1;
				}
				return 0;
			});
		// }

		setDriversList(filteredDrivers);
	}, [selectedHub, search]);

	useEffect(() => {
		const updatedSelections = driversList?.map((driver) => {
			const index = selectedCheckboxes.findIndex(
				(sc) => sc?.id === driver?._id
			);
			return index !== -1
				? selectedCheckboxes[index].isSelected
				: driver?.supervisor?._id;
		});
		setSelectedCheckboxes(updatedSelections);
	}, [driversList]);

	const selectAll = (select = true) => {
		setSelectedCheckboxes(selectedCheckboxes?.map(() => select));
		drivers
			?.filter((d) => hubs.includes(d?.hub?._id))
			?.filter(
				(d) =>
					!d?.supervisor?._id?.length ||
					(d?.supervisor?._id?.length &&
						d?.supervisor?._id === supervisorId)
			)
			?.map((driver) => {
				if (driver?.hub?._id?.length) {
					let drivers = allDrivers;
					if (!drivers[driver?.hub?._id]?.length) {
						drivers[driver?.hub?._id] = [];
					}
					if (select) {
						drivers[driver?.hub?._id].push(driver);
					} else {
						if (Array.isArray(drivers[driver?.hub?._id])) {
							drivers[driver?.hub?._id] = drivers[
								driver?.hub?._id
							].filter((driver1) => driver1._id !== driver?._id);
						}
					}
					setDrivers(drivers);
				}
			});
	};

	const handleSelect = (isSelected, index, driver) => {
		const updatedSelections = [...selectedCheckboxes];
		updatedSelections[index] = isSelected;
		setSelectedCheckboxes(updatedSelections);
		if (driver?.hub?._id?.length) {
			let drivers = allDrivers;
			if (!drivers[driver?.hub?._id]?.length) {
				drivers[driver?.hub?._id] = [];
			}
			if (isSelected) {
				drivers[driver?.hub?._id].push(driver);
			} else {
				if (Array.isArray(drivers[driver?.hub?._id])) {
					drivers[driver?.hub?._id] = drivers[
						driver?.hub?._id
					].filter((driver1) => driver1._id !== driver?._id);
				}
			}
			setDrivers(drivers);
		}
	};
	function haveSameItems(list1, list2) {
		if (list1.length !== list2.length) {
			return false;
		}

		const set1 = new Set(list1);
		return list2.every((item) => set1.has(item));
	}

	const submitButton = () => {
		const hubList = Object.keys(allDrivers);
		if (
			!(
				haveSameItems(hubList, hubs) &&
				Object.values(allDrivers).filter(
					(d1) => d1.filter((d) => d?.length !== 0)?.length !== 0
				)?.length === hubs.length
			)
		) {
			toast.error("Select driver from all hubs");
		} else {
			const newDrivers = []
				.concat(...Object.values(allDrivers))
				?.map((a) => a._id);
			if (setParentDrivers) {
				setParentDrivers(newDrivers);
				if (close) close();
			}
		}
	};
	const rows = driversList?.map((driver, index) => (
		<tr key={index}>
			<td style={{ paddingLeft: "30px", width: "150px" }}>
				<div style={{ margin: "auto", width: "fit-content" }}>
					<Checkbox
						key={driver._id}
						value={driver}
						onSelect={(isSelected, value) =>
							handleSelect(isSelected, index, value)
						}
						selected={selectedCheckboxes[index] || false}
						disabled={
							driver?.supervisor?._id?.length &&
							driver?.supervisor?._id !== supervisorId
						}
					/>
				</div>
			</td>
			<td>{driver?.name}</td>
			<td>{driver?.supervisor?.name || "Unassigned"}</td>
			<td>{driver?.hub?.name}</td>
		</tr>
	));

	return (
		<div className="approvalList assignDriver">
			<div
				className="present_modal_header"
				style={{ paddingBottom: "10px", paddingTop: "20px" }}
			>
				<TextInput
					w={300}
					styles={() => ({
						input: {
							padding: "10px",
							borderRadius: "10px",
							border: "1px solid #9DB2B8",
							boxShadow: "0px 2px 8px 0px rgba(14, 34, 39, 0.10)",
						},
					})}
					placeholder="Search drivers by their name.."
					rightSection={
						<svg
							width="22"
							height="20"
							viewBox="0 0 27 28"
							fill="none"
						>
							<ellipse
								cx="10.3418"
								cy="10.5395"
								rx="8.34184"
								ry="8.65079"
								stroke="#222222"
								strokeWidth="3"
							/>
							<path
								d="M19.75 20.2969L25.3557 26.1102"
								stroke="#222222"
								strokeWidth="3"
								strokeLinecap="round"
							/>
						</svg>
					}
					onChange={(e) => {
						if (setSearch) setSearch(e?.target?.value);
					}}
				/>
				<div className="flex">
					<p className="filter_hub">Filter Hub</p>
					<Select
						disabled={false}
						onChange={(e) => {
							setSelectedHub(e);
						}}
						size="sm"
						styles={{
							rightSection: { pointerEvents: "none" },
							input: {
								borderRadius: "10px",
								fontSize: "12px",
								background: "#EFF6F7",
								borderColor: "#9DB2B8",
							},
						}}
						rightSection={
							<svg
								width="8"
								height="8"
								viewBox="0 0 13 8"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.26465 1.97306L6.05876 7.81538C6.30266 8.06154 6.69671 8.06154 6.94124 7.81538L12.7354 1.97306C13.0882 1.6177 13.0882 1.03953 12.7354 0.683534C12.3825 0.328173 11.8098 0.328173 11.4569 0.683534L6.49968 5.68131L1.54371 0.683534C1.19021 0.328173 0.617518 0.328173 0.26465 0.683534C-0.0882167 1.03953 -0.0882167 1.6177 0.26465 1.97306Z"
									fill="#0E2227"
								/>
							</svg>
						}
						placeholder="Select Hub"
						data={[
							{
								label: "All Hubs",
								value: "all",
							},
							...allHubs.map((hub) => ({
								label: hub?.name,
								value: hub?._id,
							})),
						]}
						value={selectedHub}
					/>
				</div>
			</div>
			<div className="approvalList_table" style={{ paddingBottom: 0 }}>
				<Table verticalSpacing="md" horizontalSpacing="xl">
					<thead className="approvalList_table_header">
						<tr>
							<th
								style={{
									paddingLeft: "30px",
									display: "flex",
									width: "150px",
								}}
							>
								<Checkbox
									onSelect={() => {
										selectAll(
											!(
												selectedCheckboxes?.length &&
												!selectedCheckboxes?.filter(
													(s) => s == false
												)?.length
											)
										);
									}}
									selected={
										selectedCheckboxes?.length &&
										!selectedCheckboxes?.filter(
											(s) => s == false
										)?.length
									}
								/>
								<span style={{ paddingLeft: "10px" }}>
									Select All
								</span>
							</th>
							<th>Driver Profile</th>
							<th>Supervisor</th>
							<th>Hub</th>
						</tr>
					</thead>
					<tbody>
						{rows?.length <= 0 ? (
							<tr>
								<td colSpan="4">
									<ZeroScreens modal={true} />
								</td>
							</tr>
						) : (
							rows
						)}
					</tbody>
				</Table>
			</div>
			<div className="assign_driver_button">
				<button class="holiday_add_btn" onClick={submitButton}>
					Done
				</button>
			</div>
		</div>
	);
};

export default AssignDriversSupervisor;
