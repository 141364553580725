import { Flex, Menu, Modal } from "@mantine/core";
import { useEffect, useState } from "react";
import "./VehicleSelect.css";
import VehicleRegModalSelectable from "../VehicleRegModalSelectable/VehicleRegModalSelectable";
const VehicleSelect = ({ getValue, value }) => {
	const [selectedItem, setSelectedItem] = useState(null);
	useEffect(() => {
		setSelectedItem(value);
	}, [value]);
	const [shiftVehicleRegOpen, setVehicleRegModalopen] = useState(false);

	return (
		<Flex>
			<Menu shadow="md" width={200}>
				{selectedItem ? (
					<svg
						style={{ cursor: "pointer", marginRight: "20px" }}
						width="32"
						viewBox="0 0 40 40"
						fill="none"
					>
						<rect
							x="0.5"
							y="0.5"
							width="39"
							height="39"
							rx="19.5"
							fill="#EFF5F7"
							stroke="#8399A3"
						/>
						<path
							d="M23.0607 29.1522C22.7173 29.4955 22.4947 29.7167 22.3091 29.8582C22.1352 29.9909 22.0538 30.0057 22 30.0057C21.9462 30.0057 21.8648 29.9909 21.6909 29.8582C21.5053 29.7167 21.2827 29.4955 20.9393 29.1522L13.747 21.9598C13.4291 21.6419 13.3749 21.5752 13.3457 21.5047C13.3165 21.4341 13.3076 21.3487 13.3076 20.8991L13.3076 15.2423C13.3076 14.7567 13.3087 14.4429 13.3398 14.2116C13.3689 13.9947 13.416 13.9268 13.4541 13.8887C13.4921 13.8506 13.5601 13.8036 13.777 13.7744C14.0082 13.7433 14.3221 13.7423 14.8076 13.7423L20.4645 13.7423C20.914 13.7423 20.9995 13.7511 21.07 13.7803C21.1405 13.8095 21.2072 13.8637 21.5251 14.1816L28.7175 21.374C29.0608 21.7173 29.282 21.94 29.4236 22.1255C29.5563 22.2995 29.5711 22.3808 29.5711 22.4346C29.5711 22.4885 29.5563 22.5698 29.4236 22.7438C29.282 22.9293 29.0608 23.152 28.7175 23.4953L23.0607 29.1522Z"
							fill="#EFF5F7"
							stroke="#8399A3"
						/>
					</svg>
				) : (
					<svg
						style={{ cursor: "pointer", marginRight: "20px" }}
						width="32"
						viewBox="0 0 40 40"
						fill="none"
						onClick={() => setVehicleRegModalopen(true)}
					>
						<rect
							x="0.5"
							y="0.5"
							width="39"
							height="39"
							rx="19.5"
							fill="#EFF5F7"
							stroke="#029AC9"
						/>
						<path
							d="M23.0607 29.1522C22.7173 29.4955 22.4947 29.7167 22.3091 29.8582C22.1352 29.9909 22.0538 30.0057 22 30.0057C21.9462 30.0057 21.8648 29.9909 21.6909 29.8582C21.5053 29.7167 21.2827 29.4955 20.9393 29.1522L13.747 21.9598C13.4291 21.6419 13.3749 21.5752 13.3457 21.5047C13.3165 21.4341 13.3076 21.3487 13.3076 20.8991L13.3076 15.2423C13.3076 14.7567 13.3087 14.4429 13.3398 14.2116C13.3689 13.9947 13.416 13.9268 13.4541 13.8887C13.4921 13.8506 13.5601 13.8036 13.777 13.7744C14.0082 13.7433 14.3221 13.7423 14.8076 13.7423L20.4645 13.7423C20.914 13.7423 20.9995 13.7511 21.07 13.7803C21.1405 13.8095 21.2072 13.8637 21.5251 14.1816L28.7175 21.374C29.0608 21.7173 29.282 21.94 29.4236 22.1255C29.5563 22.2995 29.5711 22.3808 29.5711 22.4346C29.5711 22.4885 29.5563 22.5698 29.4236 22.7438C29.282 22.9293 29.0608 23.152 28.7175 23.4953L23.0607 29.1522Z"
							fill="#029AC9"
							stroke="#029AC9"
						/>
					</svg>
				)}
			</Menu>

			<Flex justify={"flex-start"} align={"center"}>
				{selectedItem ? (
					<div className="client_chip">
						<div>{selectedItem?.vehicle_no}</div>
						<div
							onClick={() => {
								setSelectedItem(null);
								getValue(null);
							}}
						>
							<svg
								style={{ cursor: "pointer" }}
								width="10"
								viewBox="0 0 10 10"
								fill="none"
							>
								<path
									d="M9 1.5C9.13261 1.5 9.25979 1.55268 9.35355 1.64645C9.44732 1.74021 9.5 1.86739 9.5 2C9.5 2.13261 9.44732 2.25979 9.35355 2.35355C9.25979 2.44732 9.13261 2.5 9 2.5H8.5L8.4985 2.5355L8.032 9.071C8.01404 9.32329 7.90115 9.55941 7.71606 9.73179C7.53097 9.90417 7.28743 10 7.0345 10H2.965C2.71207 10 2.46853 9.90417 2.28344 9.73179C2.09835 9.55941 1.98546 9.32329 1.9675 9.071L1.501 2.536C1.50024 2.52402 1.49991 2.51201 1.5 2.5H1C0.867392 2.5 0.740215 2.44732 0.646447 2.35355C0.552678 2.25979 0.5 2.13261 0.5 2C0.5 1.86739 0.552678 1.74021 0.646447 1.64645C0.740215 1.55268 0.867392 1.5 1 1.5H9ZM7.4985 2.5H2.5015L2.9655 9H7.0345L7.4985 2.5ZM6 0C6.13261 0 6.25979 0.0526784 6.35355 0.146447C6.44732 0.240215 6.5 0.367392 6.5 0.5C6.5 0.632608 6.44732 0.759785 6.35355 0.853553C6.25979 0.947322 6.13261 1 6 1H4C3.86739 1 3.74021 0.947322 3.64645 0.853553C3.55268 0.759785 3.5 0.632608 3.5 0.5C3.5 0.367392 3.55268 0.240215 3.64645 0.146447C3.74021 0.0526784 3.86739 0 4 0H6Z"
									fill="#fff"
								/>
							</svg>
						</div>
					</div>
				) : (
					<></>
				)}
			</Flex>
			<Modal
				opened={shiftVehicleRegOpen}
				onClose={() => setVehicleRegModalopen(false)}
				centered
				withCloseButton={false}
				size="lg"
				padding={0}
				radius={10}
				overlayProps={{
					blur: 5,
					color: "#fff",
					backgroundopacity: 1,
				}}
			>
				<VehicleRegModalSelectable
					close={(vehicle) => {
						if (vehicle) {
							setSelectedItem(vehicle);
							console.log(vehicle);
							getValue(vehicle);
						}
						setVehicleRegModalopen(false);
					}}
				/>
			</Modal>
		</Flex>
	);
};

export default VehicleSelect;
