import { Input, Modal, TextInput, Tooltip } from "@mantine/core";
import "./AdminDetailsForm.css";
import { useEffect, useId, useState } from "react";
import { IMaskInput } from "react-imask";
import AssignDriversSupervisor from "../../../../components/AssignDriversSupervisor/AssignDriversSupervisor";
import { useDispatch, useSelector } from "react-redux";
import {
	assignSupervisor,
	checkSupervisorStatus,
	updateSupervisor,
} from "../../../../redux/reducers/supervisorReducer";
import { useParams } from "react-router-dom";
import HubSelectorSupervisor from "../../../../components/HubSelectorSupervisor/HubSelectorSupervisor";
import HubSelect from "../../../../components/HubSelect/HubSelect";

const AdminDetailsForm = ({ setActiveTab }) => {
	const { id } = useParams();
	const [email, setEmail] = useState(null);
	const [hubs, setHubs] = useState([]);

	const [opened, setOpened] = useState(false);
	const [drivers, setDrivers] = useState([]);
	const dispatch = useDispatch();

	const supervisorFormData = useSelector(
		(state) => state?.supervisor?.fetchedSupervisor?.data
	);
	useEffect(() => {
		if (supervisorFormData?.email) {
			setEmail(supervisorFormData.email);
		}
	}, [supervisorFormData]);

	const OnClickNext = () => {
		updateSupervisor.prototype.onSuccess = () => {
			if (drivers?.length)
				dispatch(
					assignSupervisor({
						id: id,
						data: { ids: drivers },
					})
				);
			else dispatch(checkSupervisorStatus({ id: id }));
		};
		assignSupervisor.prototype.onSuccess = () => {
			dispatch(checkSupervisorStatus({ id: id }));
		};
		checkSupervisorStatus.prototype.onSuccess = () => {
			setActiveTab(2);
		};

		// if (drivers?.length) {
		dispatch(
			updateSupervisor({
				id: id,
				data: {
					...(email?.length ? { email: email } : {}),
					hubs: hubs,
				},
			})
		);
		// TODO: Add supervisor to drivers
		// } else {
		// 	alert("Please fill in all the details");
		// }
	};
	const open = () => {
		setOpened(true);
	};

	const close = () => {
		setOpened(false);
	};

	return (
		<div className="tab_contents">
			<div
				className="AdminDetailsForm_box_supervisor"
				style={{ display: "block", width: "100%" }}
			>
				{/* Name */}
				<div className="row">
					<p className="title" style={{ minWidth: "15vw" }}>
						Name
					</p>
					<div style={{ display: "grid" }}>
						<TextInput
							value={(supervisorFormData || {}).name}
							w={250}
							readOnly
							placeholder="Your name"
							radius="md"
							size="sm"
							name="name"
						/>
					</div>
				</div>
				{/* Email Address */}
				<div className="row">
					<p className="title" style={{ minWidth: "15vw" }}>
						Email Address
					</p>
					<div style={{ display: "grid" }}>
						<TextInput
							value={(supervisorFormData || {}).email}
							name="email"
							onChange={setEmail}
							w={250}
							placeholder="Your Email"
							radius="md"
							size="sm"
						/>
					</div>
				</div>
				{/* Phone Number */}
				<div className="row">
					<p className="title" style={{ minWidth: "15vw" }}>
						Phone Number
					</p>
					<div style={{ display: "grid" }}>
						<Input
							value={`+91${(supervisorFormData || {}).phone}`}
							name="phone"
							readOnly
							radius="md"
							w={250}
							size="sm"
							component={IMaskInput}
							mask="+91 0000000000"
							id={useId()}
							placeholder="Your phone"
						/>
					</div>
				</div>
				{/* Hub dropdown */}
				<div className="row">
					<p className="title" style={{ minWidth: "15vw" }}>
						Assign Hub
					</p>
					<div style={{ display: "grid" }}>
						{/* <HubSelect
							getValue={(value) => {
								setHubs(value);
							}}
							value={hubs}
						/> */}
						<HubSelectorSupervisor
							getValue={(value) => {
								setHubs(value);
							}}
							value={hubs}
							supervisor={id}
							ignoreDrivers={true}
						/>
					</div>
				</div>
				{/* Driver Container */}
				<div>
					<p className="title">Add Driver(s)</p>
					<div className="add_driver_div">
						<span className="flex">
							{drivers?.length > 0 && (
								<span className="count">
									{drivers?.length} drivers added
								</span>
							)}
							<p>
								{drivers?.length ? (
									<>add more from assigned hubs</>
								) : (
									<>
										Add drivers who are assigned to your
										selected hubs
									</>
								)}
							</p>
						</span>
						<Tooltip
							withArrow
							disabled={hubs?.length}
							label="Select Hubs"
						>
							<div
								className="assign_driver"
								onClick={() => {
									if (hubs?.length) open();
								}}
							>
								<span>Assign Drivers</span>
							</div>
						</Tooltip>
						<Modal
							opened={opened}
							onClose={close}
							centered
							withCloseButton={false}
							size="xl"
							padding={0}
							radius={10}
							overlayProps={{
								blur: 5,
								color: "#fff",
								backgroundopacity: 1,
							}}
						>
							<AssignDriversSupervisor
								hubs={hubs}
								close={close}
								setParentDrivers={setDrivers}
								supervisorId={id}
							/>
						</Modal>
					</div>
				</div>

				{/* SupervisorDetailsForm_row */}
			</div>
			<div className="stepper_btn_grp2">
				<button className="prev_btn" onClick={() => setActiveTab(0)}>
					Previous
				</button>
				<Tooltip
					withArrow
					// disabled={drivers?.length}
					// multiline={true}
					label="Fill in all the details"
				>
					<button
						// disabled={!drivers?.length}
						className="next_button"
						onClick={OnClickNext}
					>
						Next
					</button>
				</Tooltip>
			</div>
		</div>
	);
};

export default AdminDetailsForm;
