//store.js
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers"; // Import your rootReducer
import rootSaga from "./sagas"; // Import your rootSaga
import {
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
	key: "root", // Specify the key for the persisted state
	version: 1,
	storage,
	whitelist: ["user", "app"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
					"driver/updateDriverPicture",
					"vehicle/createVehicle",
					"vehicle/updateVehicle",
					"vehicle/createBulkVehicles",
					"supervisor/updateSupervisorPicture",
				],
			},
		}).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
