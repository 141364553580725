import "./Loading.css";
const Loading = () => {
  return (
    <div className="Loading">
      <span className="loader"></span>
      Please Wait...
    </div>
  );
};

export default Loading;
