import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: [],
	loading: false,
	error: null,
};

const vehiclesTypeSlice = createSlice({
	name: "vehicles_type",
	initialState,
	reducers: {
		fetchAllVehiclesType: (state) => {
			state.loading = true;
		},
		fetchAllVehiclesTypeFulfilled: (state, _action) => {
			state.loading = false;
			if (Array.isArray(_action.payload)) state.data = _action.payload;
		},
		fetchAllVehiclesTypeRejected: (state, _action) => {
			state.loading = false;
			state.error = _action.payload;
		},
		createVehicleType: (state) => {
			state.loading = true;
		},
		createVehicleTypeFulfilled: (state, _action) => {
			state.loading = false;
		},
		createVehicleTypeRejected: (state, _action) => {
			state.loading = false;
			state.error = _action.payload;
		},
		deleteVehicleType: (state) => {
			state.loading = true;
		},
		deleteVehicleTypeFulfilled: (state, _action) => {
			state.loading = false;
		},
		deleteVehicleTypeRejected: (state, _action) => {
			state.loading = false;
			state.error = _action.payload;
		},
	},
});

export const {
	fetchAllVehiclesType,
	fetchAllVehiclesTypeFulfilled,
	fetchAllVehiclesTypeRejected,
	createVehicleType,
	createVehicleTypeFulfilled,
	createVehicleTypeRejected,
	deleteVehicleType,
	deleteVehicleTypeFulfilled,
	deleteVehicleTypeRejected,
} = vehiclesTypeSlice.actions;

export default vehiclesTypeSlice.reducer;
