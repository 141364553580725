import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: [],
	loading: false,
	error: null,
	vehicle: {
		data: {},
		loading: false,
		error: null,
	},
};

const vehicleSlice = createSlice({
	name: "vehicle",
	initialState,
	reducers: {
		fetchAllVehicles: (state) => {
			state.loading = true;
		},
		fetchAllVehiclesFulfilled: (state, _action) => {
			state.loading = false;
			if (Array.isArray(_action.payload)) state.data = _action.payload;
		},
		fetchAllVehiclesRejected: (state, _action) => {
			state.loading = false;
			state.error = _action.payload;
		},
		createBulkVehicles: (state) => {
			state.vehicle.loading = true;
		},
		createBulkVehiclesFulfilled: (state, _action) => {
			state.vehicle.loading = false;
		},
		createBulkVehiclesRejected: (state, _action) => {
			state.vehicle.loading = false;
			state.vehicle.error = _action.payload;
		},
		createVehicle: (state) => {
			state.vehicle.loading = true;
		},
		createVehicleFulfilled: (state, _action) => {
			state.vehicle.loading = false;
			state.vehicle.data = _action.payload;
		},
		createVehicleRejected: (state, _action) => {
			state.vehicle.loading = false;
			state.vehicle.error = _action.payload;
		},
		updateVehicle: (state) => {
			state.loading = true;
		},
		updateVehicleFulfilled: (state, _action) => {
			state.loading = false;
		},
		updateVehicleRejected: (state, _action) => {
			state.loading = false;
			state.error = _action.payload;
		},
		deleteVehicle: (state) => {
			state.loading = true;
		},
		deleteVehicleFulfilled: (state, _action) => {
			state.loading = false;
		},
		deleteVehicleRejected: (state, _action) => {
			state.loading = false;
			state.error = _action.payload;
		},
	},
});

export const {
	fetchAllVehicles,
	fetchAllVehiclesFulfilled,
	fetchAllVehiclesRejected,
	createBulkVehicles,
	createBulkVehiclesFulfilled,
	createBulkVehiclesRejected,
	createVehicle,
	createVehicleFulfilled,
	createVehicleRejected,
	updateVehicle,
	updateVehicleFulfilled,
	updateVehicleRejected,
	deleteVehicle,
	deleteVehicleFulfilled,
	deleteVehicleRejected,
} = vehicleSlice.actions;

export default vehicleSlice.reducer;
