import { all } from "redux-saga/effects";
import { userSaga } from "./userSaga";
import appSaga from "./appSaga";
import { driverSaga } from "./driverSaga";
import { holidaySaga } from "./holidaySaga";
import { shiftSaga } from "./shiftSaga";
import { supervisorSaga } from "./supervisorSaga";
import { hubSaga } from "./hubSaga";
import { recruiterSaga } from "./recruiterSaga";
import { attendanceSaga } from "./attendanceSaga";
import { clientSaga } from "./clientSaga";
import { payoutCycleSaga } from "./payoutCycleSaga";
import { payoutSaga } from "./payoutSaga";
import { notificationSaga } from "./notificationSaga";
import { vehicleSaga } from "./vehiclesSaga";
import { vehiclesTypeSaga } from "./vehiclesTypeSaga";
import { challanSaga } from "./challanSaga";

function* rootSaga() {
	yield all([
		userSaga(),
		appSaga(),
		driverSaga(),
		attendanceSaga(),
		holidaySaga(),
		shiftSaga(),
		supervisorSaga(),
		hubSaga(),
		recruiterSaga(),
		clientSaga(),
		payoutCycleSaga(),
		payoutSaga(),
		notificationSaga(),
		vehicleSaga(),
		vehiclesTypeSaga(),
		challanSaga(),
	]);
}

export default rootSaga;
