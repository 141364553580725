import { useNavigate } from "react-router-dom";

const HubSavedConfirmationModal = ({ setStatusModal, hubName }) => {
  const navigate = useNavigate();

  return (
    <div className="HubSavedConfirmationModal">
      <svg width="86" height="86" viewBox="0 0 86 86" fill="none">
        <path
          d="M31.1473 35.5924L45.2105 48.2492C45.6242 48.6216 46.2624 48.5845 46.6301 48.1666L78.5547 11.8887"
          stroke="url(#paint0_linear_491_5440)"
          strokeWidth="8"
          strokeLinecap="round"
        />
        <path
          d="M83 43C83 51.3577 80.3821 59.5055 75.5139 66.2991C70.6457 73.0927 63.7718 78.1907 55.8576 80.8772C47.9434 83.5637 39.3865 83.7037 31.3886 81.2776C23.3908 78.8515 16.3537 73.9811 11.2659 67.3505C6.178 60.7198 3.29487 52.662 3.02142 44.3088C2.74796 35.9555 5.0979 27.7264 9.74121 20.7772C14.3845 13.828 21.0879 8.50778 28.91 5.56376C36.7321 2.61975 45.2798 2.19983 53.3528 4.36297"
          stroke="url(#paint1_linear_491_5440)"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_491_5440"
            x1="78.5547"
            y1="28.9507"
            x2="61.7265"
            y2="53.1662"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#07DD92" />
            <stop offset="1" stopColor="#06B9F0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_491_5440"
            x1="39.8539"
            y1="3"
            x2="83.1568"
            y2="41.5184"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#07DD92" />
            <stop offset="1" stopColor="#06B9F0" />
          </linearGradient>
        </defs>
      </svg>
      <h1>New Hub Added</h1>
      <p>{hubName}</p>
      <button
        className="close_button"
        onClick={() => {
          setStatusModal(false);
          navigate("/dashboard/hubsandclients");
        }}
      >
        Close
      </button>
    </div>
  );
};

export default HubSavedConfirmationModal;
