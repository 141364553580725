import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../api";
import {
	fetchSupervisors,
	fetchSupervisorsFulfilled,
	fetchSupervisorsRejected,
	fetchSupervisorDrivers,
	fetchSupervisorDriversFulfilled,
	fetchSupervisorDriversRejected,
	createSupervisor,
	createSupervisorFulfilled,
	createSupervisorRejected,
	deleteSupervisor,
	deleteSupervisorFulfilled,
	deleteSupervisorRejected,
	getSupervisorById,
	getSupervisorByIdFulfilled,
	getSupervisorByIdRejected,
	getSupervisors,
	getSupervisorsFulfilled,
	getSupervisorsRejected,
	getPendingApprovalSupervisors,
	getPendingApprovalSupervisorsFulfilled,
	getPendingApprovalSupervisorsRejected,
	updateSupervisor,
	updateSupervisorFulfilled,
	updateSupervisorRejected,
	updateSupervisorApproval,
	updateSupervisorApprovalFulfilled,
	updateSupervisorApprovalRejected,
	updateSupervisorPicture,
	updateSupervisorPictureFulfilled,
	updateSupervisorPictureRejected,
	checkSupervisorStatus,
	checkSupervisorStatusFulfilled,
	checkSupervisorStatusRejected,
	assignSupervisor,
	assignSupervisorFulfilled,
	assignSupervisorRejected,
} from "../reducers/supervisorReducer";
import { createQueryString, getErrorMessage } from "../../utils/functions";
import { ApprovalStatus, Role } from "../../utils/constants";

function* createSupervisorSaga(action) {
	try {
		const res = yield call(
			api.post,
			"/user/supervisor",
			action.payload.data
		);
		if (createSupervisor.prototype.onSuccess) {
			createSupervisor.prototype.onSuccess();
		}
		yield put(createSupervisorFulfilled(res?.data));
	} catch (err) {
		if (createSupervisor.prototype.onReject) {
			createSupervisor.prototype.onReject();
		}
		yield put(createSupervisorRejected(getErrorMessage(err)));
	}
}

function* getSupervisorByIdSaga(action) {
	try {
		const id = action.payload?.id;
		const res = yield call(api.get, `/user/supervisor/${id}`);
		if (getSupervisorById.prototype.onSuccess) {
			getSupervisorById.prototype.onSuccess();
		}
		yield put(getSupervisorByIdFulfilled(res?.data));
	} catch (err) {
		if (getSupervisorById.prototype.onReject) {
			getSupervisorById.prototype.onReject();
		}
		yield put(getSupervisorByIdRejected(getErrorMessage(err)));
	}
}

function* getSupervisorsSaga() {
	try {
		const res = yield call(api.get, "/user/supervisor/all");
		// console.log("getSupervisorsSaga", res?.data);
		if (getSupervisors.prototype.onSuccess) {
			getSupervisors.prototype.onSuccess();
		}
		yield put(getSupervisorsFulfilled(res?.data));
	} catch (err) {
		if (getSupervisors.prototype.onReject) {
			getSupervisors.prototype.onReject();
		}
		yield put(getSupervisorsRejected(getErrorMessage(err)));
	}
}

function* fetchSupervisorsSaga() {
	try {
		yield put(getSupervisors());
		yield put(getPendingApprovalSupervisors());
		yield put(fetchSupervisorsFulfilled());
	} catch (err) {
		yield put(fetchSupervisorsRejected(getErrorMessage(err)));
	}
}

function* fetchSupervisorDriversSaga(action) {
	try {
		const id = action.payload?.id;
		const res = yield call(api.get, `/user/supervisor/${id}/drivers`);
		if (fetchSupervisorDrivers.prototype.onSuccess) {
			fetchSupervisorDrivers.prototype.onSuccess();
		}
		yield put(
			fetchSupervisorDriversFulfilled(
				res?.data.filter((d) => d.picture != null)
			)
		);
	} catch (err) {
		if (fetchSupervisorDrivers.prototype.onReject) {
			fetchSupervisorDrivers.prototype.onReject();
		}
		yield put(fetchSupervisorDriversRejected(getErrorMessage(err)));
	}
}

function* getPendingApprovalSupervisorsSaga() {
	try {
		const res = yield call(api.post, "/user/findMany", {
			roles: Role.Supervisor,
			"approval.status": ApprovalStatus.Pending,
			active: true,
		});
		// console.log("getPendingApprovalSupervisorsSaga", res?.data);
		if (getPendingApprovalSupervisors.prototype.onSuccess) {
			getPendingApprovalSupervisors.prototype.onSuccess();
		}
		yield put(
			getPendingApprovalSupervisorsFulfilled(
				res?.data.filter((d) => d.picture?.length)
			)
		);
	} catch (err) {
		if (getPendingApprovalSupervisors.prototype.onReject) {
			getPendingApprovalSupervisors.prototype.onReject();
		}
		yield put(getPendingApprovalSupervisorsRejected(getErrorMessage(err)));
	}
}

function* updateSupervisorSaga(action) {
	try {
		const id = action.payload?.id;
		const data = action.payload?.data;
		const res = yield call(api.patch, `/user/supervisor/${id}`, data);
		// console.log("updateSupervisorSaga", res?.data);
		if (updateSupervisor.prototype.onSuccess) {
			updateSupervisor.prototype.onSuccess();
		}
		yield put(updateSupervisorFulfilled(res?.data));
	} catch (err) {
		if (updateSupervisor.prototype.onReject) {
			updateSupervisor.prototype.onReject();
		}
		yield put(updateSupervisorRejected(getErrorMessage(err)));
	}
}

function* updateSupervisorPictureSaga(action) {
	try {
		const id = action.payload?.id;
		const file = action.payload?.file;
		const formData = new FormData();
		formData.append("file", file);
		const queryParams = action.payload?.query;
		const query = createQueryString(queryParams);
		const res = yield call(api.put, `/user/${id}${query}`, formData);
		// console.log("updateSupervisorPictureSaga", res?.data);
		if (updateSupervisorPicture.prototype.onSuccess) {
			updateSupervisorPicture.prototype.onSuccess();
		}
		yield put(updateSupervisorPictureFulfilled(res?.data));
	} catch (err) {
		console.log(err);
		if (updateSupervisorPicture.prototype.onReject) {
			updateSupervisorPicture.prototype.onReject();
		}
		yield put(updateSupervisorPictureRejected(getErrorMessage(err)));
	}
}

function* checkSupervisorStatusSaga(action) {
	try {
		const id = action.payload?.id;
		const res = yield call(api.get, `/user/supervisor/checkStatus/${id}`);
		console.log("checkSupervisorStatusSaga", res?.data);
		if (checkSupervisorStatus.prototype.onSuccess) {
			checkSupervisorStatus.prototype.onSuccess();
		}
		yield put(checkSupervisorStatusFulfilled(res?.data));
	} catch (err) {
		console.log(err);
		if (checkSupervisorStatus.prototype.onReject) {
			checkSupervisorStatus.prototype.onReject();
		}
		yield put(checkSupervisorStatusRejected(getErrorMessage(err)));
	}
}

function* updateSupervisorApprovalSaga(action) {
	try {
		const id = action.payload?.id;
		const data = action.payload?.data;
		const res = yield call(
			api.patch,
			`/user/supervisor/${id}/approval`,
			data
		);
		console.log("updateSupervisorApprovalSaga", res?.data);
		if (updateSupervisorApproval.prototype.onSuccess) {
			updateSupervisorApproval.prototype.onSuccess();
		}
		yield put(updateSupervisorApprovalFulfilled(res?.data));
	} catch (err) {
		if (updateSupervisorApproval.prototype.onReject) {
			updateSupervisorApproval.prototype.onReject();
		}
		yield put(updateSupervisorApprovalRejected(getErrorMessage(err)));
	}
}

function* assignSupervisorSaga(action) {
	try {
		const id = action.payload?.id;
		const data = action.payload?.data;
		const res = yield call(api.post, `/user/supervisor/${id}/assign`, data);
		console.log("assignSupervisor", res?.data);
		if (assignSupervisor.prototype.onSuccess) {
			assignSupervisor.prototype.onSuccess();
		}
		yield put(assignSupervisorFulfilled(res?.data));
	} catch (err) {
		console.log(err);
		if (assignSupervisor.prototype.onReject) {
			assignSupervisor.prototype.onReject();
		}
		yield put(assignSupervisorRejected(getErrorMessage(err)));
	}
}

function* deleteSupervisorSaga(action) {
	try {
		const id = action.payload?.id;
		const newId = action.payload?.newId;
		const res = yield call(api.delete, `/user/supervisor/${id}/${newId}`);
		// console.log("updateSupervisorSaga", res?.data);
		if (deleteSupervisor.prototype.onSuccess) {
			deleteSupervisor.prototype.onSuccess();
		}
		yield put(deleteSupervisorFulfilled(res?.data));
	} catch (err) {
		if (deleteSupervisor.prototype.onReject) {
			deleteSupervisor.prototype.onReject();
		}
		yield put(deleteSupervisorRejected(getErrorMessage(err)));
	}
}

export function* supervisorSaga() {
	yield takeLatest(createSupervisor.type, createSupervisorSaga);
	yield takeLatest(fetchSupervisors.type, fetchSupervisorsSaga);
	yield takeLatest(fetchSupervisorDrivers.type, fetchSupervisorDriversSaga);
	yield takeLatest(getSupervisors.type, getSupervisorsSaga);
	yield takeLatest(getSupervisorById.type, getSupervisorByIdSaga);
	yield takeLatest(
		getPendingApprovalSupervisors.type,
		getPendingApprovalSupervisorsSaga
	);
	yield takeLatest(updateSupervisor.type, updateSupervisorSaga);
	yield takeLatest(updateSupervisorPicture.type, updateSupervisorPictureSaga);
	yield takeLatest(
		updateSupervisorApproval.type,
		updateSupervisorApprovalSaga
	);
	yield takeLatest(assignSupervisor.type, assignSupervisorSaga);
	yield takeLatest(checkSupervisorStatus.type, checkSupervisorStatusSaga);

	yield takeLatest(deleteSupervisor.type, deleteSupervisorSaga);
}
